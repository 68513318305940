import React, { useContext } from "react";
import { Button } from "@foris/avocado-suite";
import { ContextApp, IContextApp } from "@config/Context/contextApp";
import { IParams } from "@models/IParams";
import { enums } from "@modules/sections/utils";
import { IEvent } from "@modules/sections/ISections";
import { useHistory, useParams } from "react-router-dom";
import icons from "@utils/icons";
import ItemInfoRow from "@modules/sections/GridSections/item-info-row";
import styles from "./bookingModalDetails.module.scss";
import { useTranslation } from "react-i18next";

interface BookingModalDetailsProps {
  event: IEvent;
}

const BookingModalDetails = ({ event }: BookingModalDetailsProps) => {
  const { origin, scenario, workspace }: IParams = useParams();
  const history = useHistory();
  const context: IContextApp = useContext(ContextApp);
  const contextUrl = `${workspace}/${scenario}/${origin}`;
  const accessToBookingAdmin = context.user?.permissions?.create;
  const accessToBookingUser =
    context?.modules?.find(item => item.code === "bookings").accesible || false;

  const { t } = useTranslation();

  let classroom = null;
  let building = null;
  let day = "";
  let startDate = "";
  let endDate = "";
  let description = "";
  let responsible = "";
  let status = "";
  let bookingId = null;

  if (event && event.resource) {
    const resource = event.resource;
    if (resource.blockRange) {
      if (resource.blockRange.start) {
        day =
          (resource.blockRange.start.day && enums.DAY_NAMES_ES[resource.blockRange.start.day]) ||
          "";
        startDate = resource.blockRange.start.startingTime.replace(":00", "") || "";
      }
      endDate = resource.blockRange.end
        ? resource.blockRange.end.endingTime.replace(":00", "")
        : "";
    }
  }

  if (event && event.bookings) {
    const bookingsInfo = event?.bookings;
    classroom = bookingsInfo?.classroom ? bookingsInfo?.classroom : { classroomType: {} };
    building = classroom?.name ? (classroom.building ? classroom?.building?.name : "") : "-";
    description = bookingsInfo.description;
    bookingId = bookingsInfo.id;
    responsible = bookingsInfo?.responsible?.name
      ? `${bookingsInfo?.responsible?.name ?? "-"} ${bookingsInfo?.responsible?.lastName ?? ""}`
      : "Sin responsable asignado";
    status = bookingsInfo.status;
  }

  return (
    <div className={styles.bookingModalDetails}>
      <section className={styles.bookingModalDetails_content}>
        <ItemInfoRow
          columns={[
            {
              icon: "calendar",
              title: `${t("calendar-view.event-modal.columns.schedule")}:`,
              text: `${day ? `${day},` : ""} ${startDate} - ${endDate}`,
            },
          ]}
        />

        <ItemInfoRow
          columns={[
            {
              icon: "building",
              title: `${t("calendar-view.event-modal.columns.classroom")}:`,
              text:
                classroom && classroom?.name && classroom.classroomType
                  ? `${classroom?.name} - ${classroom.classroomType?.name}`
                  : "Sin sala asignada",
            },
            {
              title: `${t("calendar-view.event-modal.columns.building")}:`,
              text: building,
            },
          ]}
        />

        <ItemInfoRow
          columns={[
            {
              icon: "circle-info",
              title: `${t("calendar-view.event-modal.columns.status")}:`,
              text: icons.statusIconText(status),
            },
          ]}
        />

        <ItemInfoRow
          columns={[
            {
              icon: "user",
              title: `${t("calendar-view.event-modal.columns.responsible")}:`,
              text: responsible,
            },
          ]}
        />

        <ItemInfoRow
          columns={[
            {
              title: `${t("calendar-view.event-modal.columns.description")}:`,
              text: description,
            },
          ]}
        />
      </section>

      {accessToBookingAdmin && accessToBookingUser && (
        <footer className={styles.bookingModalDetails_footer}>
          <Button
            className={styles.footerAction}
            onClick={() => history.push(`/booking/detail/${contextUrl}/${bookingId}`)}
          >
            {t("calendar-view.event-modal.booking-action-btn")}
          </Button>
        </footer>
      )}
    </div>
  );
};

export default BookingModalDetails;
