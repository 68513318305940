import React, { useContext, useMemo } from "react";
import { AppContext } from "../../context/EditSessionsContext";
import { blocksFormat } from "../../utils/sessionFormat";
import { selectedDayEn } from "@utils/days";
import CardSessionNew from "../card-session";
import css from "./compare.module.scss";

const Compare: React.FC = () => {
  const { state } = useContext(AppContext);
  const session = state?.result?.selectSession;
  const sessionCreate = state?.result?.selectCreateSession;
  const sessionToDeleteSelected = state?.result?.sessionToDeleteSelected;

  const formatDay = (day: string, start: string, end: string) => {
    const dayFormat = selectedDayEn(day).label || "";
    const startTime = start?.substring(0, 5) || "";
    const endTime = end?.substring(0, 5) || "";
    return `${dayFormat} ${startTime} - ${endTime}`;
  };

  const linkWeekValuesById = useMemo(() => {
    return (state?.link?.weeks ?? []).reduce((acc, week) => {
      acc[week?.id] = week?.value?.toString();
      return acc;
    }, {});
  }, [state]);

  const sessionIntervals = useMemo(() => {
    const saved = state?.form?.savedSessions?.find(save => save?.id === session?.id);

    const originalSessionIntervals = (session?.assignment?.intervals ?? []).map(interval =>
      state?.link?.weeks ? linkWeekValuesById[interval?.id] : interval?.id,
    );

    const modifiedSessionIntervals = (saved?.intervals ?? [])
      .filter(interval => interval.checked)
      .map(interval => (state?.link?.weeks ? linkWeekValuesById[interval?.id] : interval?.id));

    return { originalSessionIntervals, modifiedSessionIntervals };
  }, [state]);

  const sessionToCreate = useMemo(() => {
    if (!sessionCreate && !sessionToDeleteSelected) {
      return null;
    }

    const session = sessionCreate ?? sessionToDeleteSelected;

    const dayFormat = formatDay(
      session.blocks?.day,
      session.blocks?.startTime,
      session.blocks?.endTime,
    );

    const classrooms = session?.classrooms?.map(clas => ({
      code: clas.code,
      name: clas.name,
      building: clas.building?.code,
    }));

    const linkWeeksById = (state?.link?.weeks ?? []).reduce((acc, week) => {
      acc[week.id] = week;
      return acc;
    }, {});
    const newSessionWeeksIds = (session?.intervals ?? [])
      .filter(interval => interval.checked)
      .map(interval => (interval.id in linkWeeksById ? linkWeeksById[interval.id] : interval.id))
      .map(interval => interval?.value?.toString() || "");

    const sessionToCreate = {
      blockRange: session?.blocks?.selected === "hours" ? dayFormat : "No requiere horario",
      instructors: session?.instructors ?? [],
      classrooms: classrooms as any[],
      intervals: newSessionWeeksIds,
      vacancies: session?.section?.vacancies?.toString() || "-",
    };

    return { session: session, adaptedData: sessionToCreate };
  }, [state, sessionCreate, sessionToDeleteSelected]);

  const originSession = useMemo(() => {
    const selectedOgSection = state?.link?.sections?.find(
      section => section.id === session?.section?.id,
    );
    const selectedOgSession = selectedOgSection?.sessions?.find(
      ogSession => ogSession.id === session?.id,
    );

    const blockRange = blocksFormat(selectedOgSession);
    const dayFormat = formatDay(blockRange?.day, blockRange?.startTime, blockRange?.endTime);

    return {
      session: selectedOgSession,
      adaptedData: {
        blockRange: blockRange?.selected === "hours" ? dayFormat : "No requiere horario",
        instructors: selectedOgSession?.instructors.map(instructor => ({
          code: instructor.code,
          name: instructor.name,
        })),
        classrooms: selectedOgSession?.classrooms.map(clas => ({
          code: clas.code,
          name: clas.name,
          building: clas.building?.code,
        })),
        vacancies: selectedOgSession?.section?.vacancies?.toString() || "-",
        intervals: sessionIntervals?.originalSessionIntervals,
      } as any,
    };
  }, [session, state]);

  const editedSession = useMemo(() => {
    if (!session || !state?.form?.savedSessions || sessionCreate || sessionToDeleteSelected) {
      return null;
    }

    const saved = state?.form?.savedSessions?.find(save => save.id === session.id);
    let editedSession = null;

    if (saved) {
      const dayFormat = formatDay(
        saved.blocks?.day,
        saved.blocks?.startTime,
        saved.blocks?.endTime,
      );
      const classrooms = saved.classrooms.map(clas => ({
        code: clas.code,
        name: clas.name,
        building: clas.building?.code,
      }));

      editedSession = {
        blockRange: saved.blocks?.selected === "hours" ? dayFormat : "No requiere horario",
        instructors: saved?.instructors.map(instructor => ({
          code: instructor.code,
          name: instructor.name,
        })),
        classrooms,
        vacancies: session?.section?.vacancies?.toString() || "-",
        intervals: sessionIntervals.modifiedSessionIntervals,
      };
    }

    return editedSession;
  }, [session, state]);

  if (!session && !sessionCreate && !sessionToDeleteSelected) {
    return null;
  }

  return (
    <section className={css.compare}>
      {session && (
        <CardSessionNew
          originData={originSession?.adaptedData}
          editedData={editedSession}
          session={session}
        />
      )}
      {sessionCreate && (
        <CardSessionNew
          originData={sessionToCreate?.adaptedData}
          session={sessionToCreate?.session}
          isNewSession
        />
      )}
      {sessionToDeleteSelected && (
        <CardSessionNew
          originData={sessionToCreate?.adaptedData}
          session={sessionToCreate?.session}
          isDeletedSession
        />
      )}
    </section>
  );
};

export default Compare;
