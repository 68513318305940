import gql from "graphql-tag";

export const populationSearchMutation = gql`
  mutation filter(
    $scenarioId: ID!
    $originId: ID!
    $filter: PopulationFilterInput!
    $pagination: PaginationInput
  ) {
    filter(scenarioId: $scenarioId, originId: $originId) {
      createPopulationFilter(filter: $filter) {
        id
        populations(pagination: $pagination) {
          items {
            code
            size
            level
          }
          pageInfo {
            hasPreviousPage
            hasNextPage
            page
            size
          }
        }
      }
    }
  }
`;
