import React, { useContext, useMemo } from "react";
import { AppContext } from "../../context/EditSessionsContext";
import { Section, Session } from "@models/ISchema";
import { Types, EditedSession } from "../../context/formData.reducer";
import SessionsAccordion from "../sessions-accordion";
import styles from "./sectionsList.module.scss";

interface SectionsListProps {
  sections: Section[];
  setAllSessionsChecked: (value: boolean) => void;
  onCompareBySession: (value: Session) => void;
  onCompareBySection: (
    sessions: Session[],
    section: Section,
    checked: boolean,
    newSessions: EditedSession[],
  ) => boolean;
  userCanCreate: boolean;
}

const SectionsList = ({
  sections,
  setAllSessionsChecked,
  onCompareBySection,
  onCompareBySession,
  userCanCreate,
}: SectionsListProps) => {
  const { state, dispatch } = useContext(AppContext);
  const sessionsToDelete = state?.form?.sessionsToDelete ?? {};

  const getMockSession = (section: Section) => ({
    id: section.id + (section?.sessions?.length || 0),
    blocks: null,
    instructors: null,
    classrooms: null,
    intervals: null,
    session: null,
    isNew: true,
    section: section,
  });

  const sessionsToCreateBySection = useMemo(() => {
    if (!state?.form?.sessionsToCreate?.length) {
      return {};
    }

    const result: { [key: string]: EditedSession[] } = {};
    state?.form?.sessionsToCreate?.forEach(session => {
      const sectionId = session.section?.id;

      if (sectionId) {
        if (!result[sectionId]) result[sectionId] = [];

        result[sectionId].push(session);
      }
    });

    return result;
  }, [state?.form?.sessionsToCreate]);

  return (
    <div className={styles.sectionsList}>
      {sections.map((section: Section, index: number) => {
        const emptyNewSession =
          state?.form?.selectedCreateSession && state?.form?.selectedSection?.id === section.id
            ? [getMockSession(section)]
            : [];

        return (
          <SessionsAccordion
            key={section.id}
            colorIndex={index + 1}
            section={section}
            savedSessions={state?.form?.savedSessions}
            newSessions={sessionsToCreateBySection?.[section?.id] ?? []}
            selectedSessions={state?.form?.selectedSessions}
            mockSessionsToCreate={emptyNewSession}
            sessionsToDelete={sessionsToDelete}
            linkWeeks={state?.link?.weeks}
            canCreateSessions={userCanCreate}
            isCreatingSession={state?.form?.selectedCreateSession}
            onSessionClick={session => {
              setAllSessionsChecked(false);
              onCompareBySession(session as Session);
            }}
            onCreateSession={() => {
              dispatch({ type: Types.SelectedCreateSession, payload: section });
            }}
            onChangeSelectAll={(sessions, section, newSessions, checked) => {
              setAllSessionsChecked(false);
              onCompareBySection(sessions, section, checked, newSessions);
            }}
          />
        );
      })}
    </div>
  );
};

export default SectionsList;
