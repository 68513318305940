import React, { useState, useEffect, useContext } from "react";
import * as R from "ramda";
import dayjs from "dayjs";
import cx from "classnames";
import { Table, Weekly } from "@foris/avocado-ui";
import WeeksMultiSelect from "../../../components/WeeksMultiSelect";
import { useGetTermPartsByCategory } from "../../../hooks/useGetTermPartsByCategory";
import Checkbox from "../../../../../common/components/Checkbox/Checkbox";
import { ContextEdit, IContextEdit, Week, TermPartsByCategory } from "../ContextEdit/ContextEdit";
import RadioButton from "../../../../../common/components/RadioButton/RadioButton";
import css from "../itemEdit.module.scss";

interface IRepeat {
  selected: any;
  weeks: Week[];
  intervalsSessionWeek?: any;
  createSession?: boolean;
}

export interface IWeeklyItem {
  id: string;
  label: string;
  disabled: boolean;
  state?: "active" | "current";
  tooltip: { label: string };
  onClick: (item: IWeeklyItem) => void;
  highlight?: boolean;
}

interface WeekRepeat extends Week {
  checked: boolean;
}

type IWeeksByYear = { year: string; weeklyItems: IWeeklyItem[] }[];

const Repeat: React.FC<IRepeat> = (props: IRepeat) => {
  const { selected, weeks, intervalsSessionWeek, createSession } = props;
  const context: IContextEdit = useContext(ContextEdit);
  const [weeksRepeat, setWeeksRepeat] = useState<WeekRepeat[]>([]);
  const [weeksByYear, setWeeksByYear] = useState<IWeeksByYear>(null);
  const [onlySession, setOnlySession] = useState<boolean>(true);
  const [allWeeks, setAllWeeks] = useState<boolean>(false);
  const [weekSelect, setWeekSelect] = useState<boolean>(false);
  const [withWeeksGrouper, setWithWeeksGrouper] = useState<boolean>(false);

  const [termPartsByCategory, getTermPartsByCategory] = useGetTermPartsByCategory({
    onMount: () => (termPartsByCategory: TermPartsByCategory[]) => {
      context.setTermPartsByCategory(termPartsByCategory);
    },
  });

  useEffect(() => {
    const { repeat, repeatWeekSelected } = R.pipe(
      R.when(
        R.always(onlySession),
        R.pipe(
          R.set(R.lensProp<any>("repeat"), "onlySession"),
          R.set(R.lensProp<any>("repeatWeekSelected"), [selected]),
        ),
      ),
      R.when(
        R.always(allWeeks),
        R.pipe(
          R.set(R.lensProp<any>("repeat"), "allWeeks"),
          R.set(R.lensProp<any>("repeatWeekSelected"), intervalsSessionWeek),
        ),
      ),
      R.when(
        R.always(weekSelect),
        R.pipe(
          R.set(R.lensProp<any>("repeat"), "weekSelect"),
          R.set(R.lensProp<any>("repeatWeekSelected"), intervalsSessionWeek),
        ),
      ),
      R.when(
        R.always(withWeeksGrouper),
        R.pipe(
          R.set(R.lensProp<any>("repeat"), "withWeeksGrouper"),
          R.set(R.lensProp<any>("repeatWeekSelected"), weeksRepeat),
        ),
      ),
    )({ repeat: "", repeatWeekSelected: null });

    context.setCurrentData(
      R.pipe(
        R.set(R.lensProp<any>("repeat"), repeat),
        R.set(R.lensProp("weeksRepeat"), repeatWeekSelected),
      )(context.currentData),
    );
  }, [onlySession, allWeeks, weeksRepeat, setWithWeeksGrouper]);

  const dateLabel = (dateStart: string, dateEnd: string) => {
    const toDate = (dateStr: string) => dayjs(new Date(dateStr)).format("DD/MM/YYYY");
    return R.and(!!dateStart, !!dateEnd) ? `(${toDate(dateStart)} - ${toDate(dateEnd)})` : "";
  };

  const rowLabel = (row: any) => {
    const rowValue = R.view(R.lensPath(["original", "value"]), row) ?? "";
    return (
      <div>
        <span className="code">{`Semana ${rowValue} `}</span>
      </div>
    );
  };

  const dataWeek = (row: any) => {
    const dateStart = R.view(R.lensPath(["original", "startingDate"]), row) ?? "";
    const dateEnd = R.view(R.lensPath(["original", "endingDate"]), row) ?? "";
    return <span className="info">{dateLabel(dateStart, dateEnd)}</span>;
  };

  const renderTable = (columns: any) => {
    const sessionWeeksById = R.reduce(
      (acc, w: Week) => R.assoc(w.id, true, acc),
      {},
      intervalsSessionWeek ?? [],
    );

    const sessionWeeks =
      intervalsSessionWeek && weeks && allWeeks
        ? R.filter(R.pipe(R.propOr("-", "id"), R.flip(R.has)(sessionWeeksById)), weeks)
        : !!weekSelect && !!weeksRepeat?.length
        ? weeksRepeat
        : [];

    return (
      <Table.Container
        data={R.sortBy(R.propOr(null, "startingDate"), sessionWeeks)}
        columns={columns}
        secondary
        className={cx(css.item_tableRepeat, "col_12")}
      />
    );
  };

  useEffect(() => {
    if (R.or(R.isEmpty(weeks ?? []), R.not(withWeeksGrouper))) return;
    const sessionIntervalsById: { [key: string]: boolean } = R.reduce(
      (acc, w: Week) => R.assoc(w.id, true, acc),
      {},
      intervalsSessionWeek,
    );
    const newWeeksRepeat = R.map(
      week => R.assoc("checked", R.has(week.id, sessionIntervalsById), week),
      weeks,
    );
    setWeeksRepeat(newWeeksRepeat);
    getTermPartsByCategory();
  }, [weeks, withWeeksGrouper]);

  useEffect(() => {
    if (R.or(R.isEmpty(weeks ?? []), R.not(weekSelect))) return;
    const weeksValuesById = R.reduce(
      (acc, week) => R.assoc(R.prop("id", week), R.prop("value", week), acc),
      {},
      weeks,
    );

    if (intervalsSessionWeek !== null) {
      setWeeksRepeat(
        R.map(
          week => R.set(R.lensProp("value"), weeksValuesById[week?.id], week),
          intervalsSessionWeek,
        ),
      );
    } else {
      const weeksSelected = [];

      R.map(week => weeksSelected.push({ ...week, checked: false }), weeks);
      setWeeksRepeat(weeksSelected);
    }

    getTermPartsByCategory();
  }, [weeks, weekSelect]);

  useEffect(() => {
    if (R.isEmpty(weeksRepeat ?? [])) return;

    const handleWeekCheckboxClick = ({ id }: WeekRepeat) => () => {
      const updatedWeeks = R.map(
        R.when(
          R.pipe(R.prop("id"), R.equals(id)),
          R.over(R.lensProp<WeekRepeat>("checked"), R.not),
        ),
        weeksRepeat,
      );
      setWeeksRepeat(updatedWeeks);
    };

    const newWeeksByYear: { [key: string]: WeekRepeat[] } = R.reduce(
      (acc: { [key: string]: WeekRepeat[] }, week) => {
        const year = dayjs(week.startingDate).year();
        if (year in acc) acc[year].push(week);
        else acc[year] = [week];
        return acc;
      },
      {},
      weeksRepeat ?? [],
    );

    const res: IWeeksByYear = R.toPairs(newWeeksByYear)?.reduce<IWeeksByYear>(
      (acc, [year, itemWeeks]) => {
        const weeklyItems: IWeeklyItem[] = itemWeeks.map((week, idx) => ({
          id: week.id,
          label: (idx + 1).toString(),
          disabled: false,
          state: week.checked ? "active" : "current",
          tooltip: { label: `${week.title}` },
          onClick: handleWeekCheckboxClick(week),
          highlight: !!week.highlight,
        }));
        return R.append({ year, weeklyItems }, acc);
      },
      [],
    );

    context.setCurrentData(R.set(R.lensProp("weeksRepeat"), weeksRepeat, context.currentData));
    setWeeksByYear(res);
  }, [weeksRepeat]);

  /**
   * In both `weeksRepeat` state and `weeksRepeat` array from the context,
   * transform the `highlights` to `checked` and save it in the context.
   */
  const applySelectionOnContext = (weeksToModify: { [key: number]: boolean }, checked: boolean) => {
    if (R.isEmpty(weeksRepeat)) return;
    const newWeeksRepeat = R.map(
      R.pipe(
        R.when(
          R.pipe(R.prop("id"), R.flip(R.has)(weeksToModify)),
          R.set(R.lensProp<WeekRepeat>("checked"), checked),
        ),
        R.set(R.lensProp<WeekRepeat>("highlight"), false),
      ),
      weeksRepeat,
    );
    context.setCurrentData(R.set(R.lensProp("weeksRepeat"), newWeeksRepeat, context.currentData));
    setWeeksRepeat(newWeeksRepeat);
  };

  /**
   * Highlight the weeks in both `weeksRepeat` state and `weeksRepeat` array
   * from the context if the week id is in the `weekIdsToHighlight` object.
   */
  const highlightSelectionOnContext = (weekIdsToHighlight: { [key: number]: boolean }) => {
    if (R.isEmpty(weeks)) return;
    const newWeeksRepeat = R.map(
      R.ifElse(
        R.pipe(R.prop("id"), R.flip(R.has)(weekIdsToHighlight)),
        R.set(R.lensProp<WeekRepeat>("highlight"), true),
        R.set(R.lensProp<WeekRepeat>("highlight"), false),
      ),
      weeksRepeat,
    );
    context.setCurrentData(R.set(R.lensProp("weeksRepeat"), newWeeksRepeat, context.currentData));
    setWeeksRepeat(newWeeksRepeat);
  };

  /**
   * Set highlight to the given param to all weeks in both `weeksRepeat` state
   * and `weeksRepeat` array from the context.
   */
  const setWeeksHighlight = (highlight: boolean) => {
    if (R.isEmpty(weeksRepeat)) return;
    const newWeeksRepeat = R.map(R.set(R.lensProp("highlight"), highlight), weeksRepeat);
    context.setCurrentData(R.set(R.lensProp("weeksRepeat"), newWeeksRepeat, context.currentData));
    setWeeksRepeat(newWeeksRepeat);
  };

  const dataCheck = (row: any, value: boolean) => {
    if (weeksRepeat) {
      const cloneWeeksRepeat = [...weeksRepeat];
      const objIndex = cloneWeeksRepeat.findIndex((week: any) => week.id === row.original.id);
      cloneWeeksRepeat[objIndex].checked = value;
      setWeeksRepeat(cloneWeeksRepeat);
    }
  };

  const toggleWeeksChecks = (value: boolean) => {
    setWeeksRepeat(R.map(R.set(R.lensProp("checked"), value), weeksRepeat));
  };

  const renderCheck = (row: any) => {
    return <Checkbox check={row.original.checked} onChange={value => dataCheck(row, value)} />;
  };

  const columns: any = [
    {
      Header: "Semana",
      accessor: "value",
      maxWidth: 150,
      Cell: R.pipe(R.view(R.lensProp<any>("row")), rowLabel),
    },
    {
      Header: "",
      accessor: "startingDate",
      maxWidth: 250,
      Cell: R.pipe(R.view(R.lensProp<any>("row")), dataWeek),
    },
    {
      Header: "",
      accessor: "endingDate",
      maxWidth: 250,
      Cell: R.pipe(R.view(R.lensProp<any>("row")), dataWeek),
    },
  ];

  const columnsWithCheck = R.prepend(
    {
      Header: <Checkbox onChange={toggleWeeksChecks} />,
      accessor: "id",
      maxWidth: 30,
      Cell: R.pipe(R.view(R.lensProp<any>("row")), renderCheck),
    },
    columns,
  );

  return (
    <>
      <label className={cx(css.item_label, "col_12")}>Repetición</label>
      <section className={cx("container-row", css.radio_buttons_container)}>
        <RadioButton
          name="repeticiones"
          className={css.item_radio}
          label="Sesión única"
          check={onlySession}
          onClick={() => {
            setOnlySession(true);
            setAllWeeks(false);
            setWeekSelect(false);
            setWithWeeksGrouper(false);
            setWeeksRepeat(null);
          }}
        />
        {!createSession && (
          <RadioButton
            name="repeticiones"
            className={css.item_radio}
            label="Todas las semanas"
            check={allWeeks}
            onClick={() => {
              setOnlySession(false);
              setAllWeeks(true);
              setWeekSelect(false);
              setWithWeeksGrouper(false);
              setWeeksRepeat(null);
            }}
          />
        )}
        <RadioButton
          name="repeticiones"
          className={css.item_radio}
          check={weekSelect}
          label="Personalizado"
          onClick={() => {
            setOnlySession(false);
            setAllWeeks(false);
            setWeekSelect(true);
            setWithWeeksGrouper(false);
          }}
        />
        {!createSession && (
          <RadioButton
            name="repeticiones"
            className={css.item_radio}
            check={withWeeksGrouper}
            label="Agrupación de semanas"
            onClick={() => {
              setOnlySession(false);
              setAllWeeks(false);
              setWeekSelect(false);
              setWithWeeksGrouper(true);
            }}
          />
        )}
      </section>

      <section className={cx(css.item)}>
        {onlySession && selected && (
          <div className={cx(css.info_week, "container-row")}>
            <p className={css.info_week_text}>
              <span className={css.bold}>Semana {selected.value}</span>{" "}
              {dateLabel(selected.startingDate, selected.endingDate)}
            </p>
          </div>
        )}
        {allWeeks && renderTable(columns)}
        {weekSelect && renderTable(columnsWithCheck)}
        {withWeeksGrouper && (
          <>
            <WeeksMultiSelect
              weeks={weeks}
              applySelectionOnContext={applySelectionOnContext}
              highlightSelectionOnContext={highlightSelectionOnContext}
              onAllWeeksSelected={() => setWeeksHighlight(true)}
              termPartsByCategory={termPartsByCategory}
            />
            <section className={cx(css.item, css.repeat__weekly__container)}>
              {weeksByYear?.map(({ year, weeklyItems }) => (
                <div key={year} className={css.repeat__weekly}>
                  <label className={css.repeat__weekly__label}>{year}</label>
                  <Weekly className={css.repeat__weekly__weeks} weeklyItems={weeklyItems} />
                </div>
              ))}
            </section>
          </>
        )}
      </section>
    </>
  );
};

export default Repeat;
