import React, { useContext, useMemo } from "react";
import { AppContext } from "../../context/EditSessionsContext";
import { Button, Heading } from "@foris/avocado-suite";
import { ContextApp, IContextApp } from "@config/Context/contextApp";
import { Types } from "../../context/formData.reducer";
import { useHistory } from "react-router-dom";
import { IWeek } from "@modules/sections/ISections";
import { allSessionsCanBeSaved, sessionIsSavable } from "../../utils/context";
import useContextUrl from "@common/hooks/useContextUrl";
import useNavigationUrl from "@common/hooks/useNavigationUrl";
import styles from "./assignationHeader.module.scss";
import { useTranslation } from "react-i18next";

interface AssignationHeaderProps {
  locationCurrentSelectedWeek?: IWeek;
  onSubmitValidations?: () => void;
  onShowValidationWarnings?: (showWarnings: boolean) => void;
}

const AssignationHeader = ({
  locationCurrentSelectedWeek,
  onSubmitValidations,
  onShowValidationWarnings,
}: AssignationHeaderProps) => {
  const history = useHistory();
  const { id } = useContextUrl();
  const { editorUrl } = useNavigationUrl();
  const { state, dispatch } = useContext(AppContext);
  const context: IContextApp = useContext(ContextApp);

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const selectedSessionsLabel = useMemo(() => {
    const selectedSessionsLength = state?.form?.selectedSessions?.length ?? 0;

    if (selectedSessionsLength === 1) {
      return t("assignation-edit.header.singular-selected-sessions");
    }

    return t("assignation-edit.header.selected-sessions", {
      count: selectedSessionsLength,
    });
  }, [state?.form?.selectedSessions, language]);

  const userCanEditOrDeleteSessions = context?.user?.abilities?.can_edit_or_delete_sessions;
  const numberOfSavedSessions = state?.form?.savedSessions?.length ?? 0;
  const numberOfSessionsToCreate = state?.form?.sessionsToCreate?.length ?? 0;

  const numberOfDeletedSessions = useMemo(() => {
    return Object.keys(state?.form?.sessionsToDelete ?? {}).length;
  }, [state?.form?.sessionsToDelete]);

  const someCreatedSessionIsSavable = useMemo(() => {
    const anyCreatedSessionIsSavable = (state?.form?.sessionsToCreate ?? []).some(sessionIsSavable);
    const someSessionHasBeenSaved = Boolean(numberOfSavedSessions);
    const someSessionHasBeenDeleted = Boolean(numberOfDeletedSessions);
    return someSessionHasBeenSaved || someSessionHasBeenDeleted || anyCreatedSessionIsSavable;
  }, [state?.form?.savedSessions, state?.form?.sessionsToCreate]);

  const allSavedSessionsAreSavable = useMemo(
    () => (state?.form?.savedSessions ?? []).every(sessionIsSavable),
    [state?.form?.savedSessions],
  );

  const allSessionsAreDeleted = useMemo(
    () =>
      (state?.form?.selectedSessions ?? []).every(
        session => state?.form?.sessionsToDelete?.[session.id ?? "-"],
      ),
    [state?.form?.selectedSessions, state?.form?.sessionsToDelete],
  );

  const someSelectedSessionHasBeenDeleted = useMemo(
    () =>
      (state?.form?.selectedSessions ?? []).some(
        session => state?.form?.sessionsToDelete?.[session.id ?? "-"],
      ),
    [state?.form?.selectedSessions, state?.form?.sessionsToDelete],
  );

  const handleSubmitValidation = () => {
    if (!numberOfSavedSessions && !numberOfSessionsToCreate && !numberOfDeletedSessions) {
      return;
    }

    // If one or more sessionToCreate don't have enough resources to be
    // saved (meaning, at least one week and it's schedule) or a session is
    // been created, show the warning modal
    if (
      allSessionsCanBeSaved(state?.form?.sessionsToCreate) &&
      !state?.form?.selectedCreateSession
    ) {
      onSubmitValidations?.();
      return;
    }

    onShowValidationWarnings?.(true);
  };

  return (
    <header className={styles.assignationHeader}>
      <div className={styles.assignationHeader_leftContent}>
        <Heading type="h3">{selectedSessionsLabel}</Heading>

        <div className={styles.actions}>
          {userCanEditOrDeleteSessions && (
            <Button
              size="sm"
              leftIcon="trash"
              variant="ghost"
              disabled={allSessionsAreDeleted}
              onClick={() => {
                dispatch({
                  type: Types.RemoveSelectedSessions,
                  payload: state?.form?.selectedSessions,
                });
              }}
            >
              {t("assignation-edit.header.delete")}
            </Button>
          )}

          <Button
            size="sm"
            leftIcon="repeat"
            variant="ghost"
            disabled={
              !state?.form?.selectedSessions?.length ||
              (!numberOfSavedSessions &&
                !numberOfSessionsToCreate &&
                !someSelectedSessionHasBeenDeleted)
            }
            onClick={() => {
              dispatch({
                type: Types.UndoEditionOverSelectedSessions,
                payload: state?.form?.selectedSessions,
              });
            }}
          >
            {t("assignation-edit.header.restore-changes")}
          </Button>

          {userCanEditOrDeleteSessions && (
            <Button
              size="sm"
              leftIcon="copy"
              variant="ghost"
              disabled={!state?.form?.selectedSessions?.length}
              onClick={() => {
                dispatch({ type: Types.AddSessionsToCreate, payload: "cloned" });
              }}
            >
              {t("assignation-edit.header.duplicate")}
            </Button>
          )}
        </div>
      </div>

      <div className={styles.assignationHeader_rightContent}>
        <Button
          size="md"
          variant="ghost"
          onClick={() => {
            history.push(editorUrl("link", id), {
              currentSelectedWeek: locationCurrentSelectedWeek,
              sessionId: undefined,
            });
          }}
        >
          {t("assignation-edit.header.go-to-league")}
        </Button>
        <Button
          size="md"
          variant="primary"
          disabled={
            (numberOfSavedSessions === 0 &&
              numberOfSessionsToCreate === 0 &&
              numberOfDeletedSessions === 0) ||
            !someCreatedSessionIsSavable ||
            !allSavedSessionsAreSavable
          }
          onClick={handleSubmitValidation}
        >
          {t("assignation-edit.header.validate")}
        </Button>
      </div>
    </header>
  );
};

export default AssignationHeader;
