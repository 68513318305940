import React from "react";
import { Term } from "@models/ISchema";
import { IWeek } from "../ISections";
import dayjs from "dayjs";
import { useMemo } from "react";
import WeekTooltipLabel from "../components/week-tooltip-label";

interface UseGetWeeksByYear {
  weeks: IWeek[];
  selectedWeekId?: string;
  selectedTerm?: Term | null;
  termStartDate?: string;
  termEndDate?: string;
  handleWeekOnClick?: (week: IWeek) => void;
  handleWeekInitialSelection?: (week: IWeek) => void;
}

type getItemsByYearParams = Omit<UseGetWeeksByYear, "selectedTerm">;

const getItemsByYear = ({
  weeks = [],
  termStartDate,
  termEndDate,
  selectedWeekId,
  handleWeekOnClick,
  handleWeekInitialSelection,
}: getItemsByYearParams) => {
  let weeksCount = 0;
  let selectedWeekYear = null;
  const weeksRawList = [];

  const itemsByYear = weeks.reduce((years, week) => {
    const isBefore = dayjs(week.startingDate).isBefore(dayjs(termStartDate));
    const isAfter = dayjs(week.endingDate).isAfter(dayjs(termEndDate));

    if (termStartDate && termEndDate && (isBefore || isAfter)) {
      return years;
    }

    weeksCount++;
    const newYears = { ...years };
    const year = dayjs(week.startingDate).year();
    const newWeek = {
      ...week,
      value: String(weeksCount),
    };

    if (selectedWeekId === week.id) {
      selectedWeekYear = year;
    }

    const parsedWeek = {
      id: week.id.toString(),
      isDisabled: week.status === "off",
      isSelected: selectedWeekId === week.id,
      tooltipLabel: (
        <WeekTooltipLabel
          weekLabel={`S${week.value}`}
          startingDate={dayjs(week.startingDate).format("DD.MM")}
          endingDate={dayjs(week.endingDate).format("DD.MM")}
        />
      ) as any,
      label: weeksCount,
      onClick: () => {
        if (week.status === "on") {
          handleWeekOnClick?.(newWeek);
        }
      },
    };

    if (week.status === "on") {
      handleWeekInitialSelection?.(newWeek);
    }

    if (year in newYears) {
      newYears[year].push(parsedWeek);
    } else {
      newYears[year] = [parsedWeek];
    }

    weeksRawList.push(newWeek);

    return newYears;
  }, {});

  return {
    itemsByYear,
    weeksRawList,
    selectedWeekYear,
  };
};

const useGetWeeksByYear = (props: UseGetWeeksByYear) => {
  const {
    selectedTerm,
    selectedWeekId,
    weeks = [],
    handleWeekInitialSelection,
    handleWeekOnClick,
  } = props;

  const { itemsByYear, weeksRawList, selectedWeekYear } = useMemo(() => {
    return getItemsByYear({
      weeks,
      termStartDate: selectedTerm ? selectedTerm?.start_date : "",
      termEndDate: selectedTerm ? selectedTerm?.end_date : "",
      selectedWeekId,
      handleWeekOnClick,
      handleWeekInitialSelection,
    });
  }, [weeks, selectedTerm, selectedWeekId]);

  const selectedYearIndex = useMemo(
    () => Object.keys(itemsByYear).findIndex(year => year === selectedWeekYear?.toString()),
    [itemsByYear, selectedWeekYear],
  );

  return { filteredItemsByYear: itemsByYear, weeksRawList, selectedWeekYear, selectedYearIndex };
};

export default useGetWeeksByYear;
