import { useMemo } from "react";
import i18next from "i18next";
import { DimensionNamesEs } from "@edhtypes/dimensions";

/**
 * Custom hook to generate a title based on search query and dimension.
 *
 * This hook returns a dynamically generated title for the search results.
 * The title depends on whether a search term is provided or not,
 * and it adapts to the specified dimension name. It also incorporates internationalization (i18n)
 * to display text in the selected language.
 *
 * @param {string} search - The search query to include in the title (optional).
 * @param {number} total - The total number of results (default is 0).
 * @param {DimensionNamesEs} dimension - The dimension name to display in the title.
 * @returns {Object} - Returns an object containing the dynamically generated title (`getTitle`).
 *
 * @example
 * const { getTitle } = useGetTitleDimension("A00", 15, "Sala");
 * console.log(getTitle); // "15 resultados para 'A00'"
 */
const useGetTitleDimension = (search: string, total = 0, dimension: DimensionNamesEs) => {
  // useMemo is used to memoize the title string to prevent unnecessary recalculations
  const getTitle = useMemo(() => {
    // If there is a search term, display the search term in the title
    return search
      ? `${total} ${i18next.t("search-results.text-result")} "${search}"`
      : // If no search term, display the total results with the dimension name
        `${total} ${i18next.t("search-results.text-result-two-option")} ${dimension}`;
  }, [search, dimension]); // Recalculates only when 'search' or 'dimension' change

  // Return the generated title
  return { getTitle };
};

export default useGetTitleDimension;
