import React from "react";
import { useTranslation } from "react-i18next";
import { Option } from "react-select/lib/filters";
import useGetCurriculumOptions from "@modules/shared/selectors/hooks/useGetCurriculumOptions";
import AdvanceSelector from "../advance-selector";

interface AdvancedCurriculumSelectorProps {
  activeCode?: boolean;
  campusId?: string;
  departmentId?: string;
  modalityId?: string;
  onChange?: (option: Option) => void;
  programId?: string;
  scenario: string;
  shiftId?: string;
}

const AdvancedCurriculumSelector = ({
  scenario,
  onChange,
  ...filters
}: AdvancedCurriculumSelectorProps) => {
  const { loadCurriculumOptions } = useGetCurriculumOptions({
    scenario,
    ...filters,
  });

  const { t } = useTranslation();

  return (
    <AdvanceSelector
      isPaginated
      dimension={t("advanced-search.instructor.forms.select-wrapper.curriculum.label")}
      selectPlaceholder={t(
        "advanced-search.instructor.forms.select-wrapper.curriculum.placeholder",
      )}
      loadOptions={loadCurriculumOptions}
      onChange={onChange}
      notShowLabel
    />
  );
};

export default AdvancedCurriculumSelector;
