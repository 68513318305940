import React, { useMemo } from "react";
import cx from "classnames";
import { EditedSession } from "../../context/formData.reducer";
import { Text } from "@foris/avocado-suite";
import { Icon, IconTypes } from "@foris/avocado-icons";
import AssignmentLabeler from "../../models/AssignmentLabeler";
import { Week } from "@modules/sections/ISections";
import styles from "./sessionCard.module.scss";
import { useTranslation } from "react-i18next";

interface SessionCardProps {
  session: EditedSession;
  linkWeeks?: Week[];
  colorIndex?: number;
  isSelected?: boolean;
  isDeleted?: boolean;
  isUpdated?: boolean;
  isMock?: boolean;
  /**
   * Show an status icon based on the value
   */
  status?: "error" | "success";
  onClick?: (session: EditedSession) => void;
}

const getRandomColorIndex = () => Math.floor(Math.random() * 17) + 1;

const SessionCard = ({
  session,
  linkWeeks = [],
  colorIndex = 2,
  isSelected,
  isUpdated,
  isDeleted,
  isMock,
  status,
  onClick,
}: SessionCardProps) => {
  const { t } = useTranslation();

  const paletteColor = useMemo(() => {
    const index = colorIndex > 0 && colorIndex <= 17 ? colorIndex : getRandomColorIndex();

    return {
      color: `palette-${index}`,
      index,
    };
  }, [colorIndex]);

  const iconProps: {
    name: IconTypes;
    color: any;
  } = useMemo(() => {
    const isNewSession = session?.isNew || session?.isCloned || isMock;

    if (status === "error") {
      return {
        name: "circle-cross",
        filled: true,
        color: "error-50",
      };
    }

    if (status === "success") {
      return {
        name: "circle-check",
        filled: true,
        color: "success-50",
      };
    }

    if (!isDeleted && !isUpdated && !isNewSession) return null;

    if (isDeleted) {
      return {
        name: "trash",
        color: "neutral-70",
      };
    }

    if (isNewSession) {
      return {
        name: "circle-plus",
        color: `${paletteColor.color}-80`,
      };
    }

    return {
      name: "repeat",
      color: `${paletteColor.color}-80`,
    };
  }, [isSelected, isUpdated, isDeleted]);

  return (
    <div
      role="button"
      className={cx(
        styles.sessionCard,
        styles[`sessionCard__${paletteColor.index}`],
        isSelected && styles.sessionCard__selected,
        isUpdated && styles.sessionCard__updated,
        isDeleted && styles.sessionCard__deleted,
      )}
      onClick={() => onClick?.(session)}
    >
      <div className={styles.sessionCard_content}>
        <Text className={styles.schedule} weight="medium" type="sm">
          {isMock
            ? t("assignation-edit.sessions-list.new-session")
            : AssignmentLabeler.fromDate(null, session?.blocks)}
        </Text>

        {!isMock && (
          <>
            <Text className={styles.weeks} type="sm">
              {AssignmentLabeler.fromWeeks(null, session?.intervals, linkWeeks)}
            </Text>

            <Text className={styles.key} type="sm">
              ID: {session?.id}
            </Text>
          </>
        )}
      </div>

      {iconProps && <Icon className={styles.sessionCard_icon} {...iconProps} />}
    </div>
  );
};

export default SessionCard;
