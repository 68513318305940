import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox, Text } from "@foris/avocado-suite";
import { getTranslatedDays } from "@utils/days";
import css from "./checks.module.scss";

export interface IDaysReturn {
  required: boolean;
  onCallback: (value: number[]) => void;
}

const DaysCheck: React.FC<IDaysReturn> = (props: IDaysReturn) => {
  const { onCallback, required } = props;
  const [daysCheck, setDaysCheck] = useState([]);
  const days = getTranslatedDays();

  const { t } = useTranslation();

  const setDays = (day: any, value: any) => {
    const newDaysCheck = [...daysCheck];
    if (value) {
      newDaysCheck.push(day + 1);
    } else {
      const index = newDaysCheck.indexOf(day + 1);
      if (index > -1) {
        newDaysCheck.splice(index, 1);
      }
    }
    setDaysCheck(newDaysCheck);
    onCallback(newDaysCheck);
  };

  return (
    <div className={css.sharedCheckbox}>
      <div className={css.sharedCheckbox_days}>
        {days.map((day, index) => (
          <Checkbox
            key={index}
            className={css.checkboxDay}
            onChange={value => setDays(index, value?.currentTarget?.checked)}
            labelRight={day.label}
          />
        ))}
      </div>

      {required && (
        <Text type="sm" className={css.sharedCheckbox_error}>
          {t("advanced-search.classroom.forms.days.error-message")}
        </Text>
      )}
    </div>
  );
};

export default DaysCheck;
