import gql from "graphql-tag";

export const baseQuery = gql`
  query base($id: ID!) {
    base {
      user(id: $id) {
        id
        name
        abilities {
          can_assign_intervals
          can_assign_blocks
          can_assign_instructors
          can_assign_classrooms
          can_edit_links_and_sections
          instructor_scheduled_hours
          can_edit_assignment
          can_access_group_manager
          can_edit_or_delete_sessions
          can_edit_packages
          can_edit_past_sessions
          can_delete_sections
          can_delete_packages
          can_assign_instructors_from_other_courses
          can_create_groups_in_bundle_view
          can_delete_groups_in_bundle_view
          can_edit_groups_in_bundle_view
          can_migrate_groups_in_bundle_view
          can_create_sub_groups_in_group_manager
          can_edit_groups_in_group_manager
          can_delete_sessions_in_holidays
          can_unassign_intervals
          __typename
        }
        permissions {
          read
          update
          create
          delete
          __typename
        }
        context {
          workspaceId
          scenarioId
          originId
        }
      }
      modules {
        code
        url
        accesible(userId: $id)
      }
      onOff {
        groupText
      }
      customUrls {
        code
        url
      }
      __typename
    }
  }
`;
