import React, { useContext, useEffect, useState } from "react";
import Layout from "@common/layout/Layout";
import { useApolloClient } from "react-apollo";
import { useParams } from "react-router-dom";
import { IParams } from "@models/IParams";
import { Loading } from "@foris/avocado-ui";
import { Button, Heading, Switch } from "@foris/avocado-suite";
import { DataQuery, PageInfo, LinkLogEntry } from "@models/ISchema";
import { Context } from "@modules/sections/GroupsManager/context/GroupsManagerContext";
import { Types } from "@modules/sections/GroupsManager/context/tableFilters.reducer";
import { ContextEDH } from "@context/ContextEDH";
import LinkHistoryTable from "../../components/link-history-table";
import linkLogEntriesQuery from "../../graphql/linkLogEntries.query.graphql";
import cx from "classnames";
import styles from "./linkChangeHistory.module.scss";

const darwinUrl = process.env.REACT_APP_DARWINED_URL;
const PAGE_SIZE = 10;

const LinkChangeHistory = () => {
  const { state } = useContext(ContextEDH);
  const { dispatch } = useContext(Context);
  const { scenario, origin }: IParams = useParams();
  const client = useApolloClient();
  const [logEntries, setLogEntries] = useState<LinkLogEntry[]>([]);
  const [pageInfo, setPageInfo] = useState<PageInfo | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showSelfChanges, setShowSelfChanges] = useState(false);
  const [hasInitialLoad, setHasInitialLoad] = useState(false);
  const [columnsToSearchBy, setColumnsToSearchBy] = useState({
    Fecha: true,
    Liga: true,
    "Recurso ID": true,
    Responsable: true,
  });
  const [orderBy, setOrderBy] = useState({});
  const [searchBy, setSearchBy] = useState({});

  const token = localStorage?.getItem("token") ?? "";

  const getLogEntries = async (page = 1, newOrderBy = {} as any, searchBy = {} as any) => {
    setIsLoading(true);
    setOrderBy(newOrderBy);
    setSearchBy(searchBy);

    const variables = {
      query: linkLogEntriesQuery,
      variables: {
        scenarioId: scenario,
        originId: origin,
        filter: {
          fields: showSelfChanges
            ? {
                isOwner: { is: true },
              }
            : undefined,
          searchBy:
            !!searchBy?.text && !!searchBy?.fields?.length
              ? {
                  fields: searchBy?.fields ?? [],
                  text: searchBy?.text ?? "",
                }
              : undefined,
          orderBy: !!newOrderBy?.field
            ? {
                field: newOrderBy?.field,
                direction: newOrderBy?.direction ?? "ASC",
              }
            : undefined,
          pagination: {
            page,
            size: PAGE_SIZE,
          },
        },
      },
    };
    const { data } = await client.query(variables);
    const dataQuery = data?.data as DataQuery;

    setLogEntries(dataQuery?.linkLogEntry?.items ?? []);
    setPageInfo(dataQuery?.linkLogEntry?.pageInfo);

    setIsLoading(false);
    !hasInitialLoad && setHasInitialLoad(true);
  };

  const handleResetFilters = () => {
    setOrderBy({});
    setSearchBy({});
    dispatch({
      type: Types.ResetFilters,
    });
  };

  useEffect(() => {
    handleResetFilters();

    getLogEntries();

    return () => {
      handleResetFilters();
    };
  }, []);

  useEffect(() => {
    if (hasInitialLoad) {
      getLogEntries(1, orderBy, searchBy);
    }
  }, [showSelfChanges]);

  return (
    <Layout contextSearch>
      {isLoading && <Loading />}
      {hasInitialLoad && (
        <div className={cx(styles.linkChangeHistory)}>
          <header className={styles.linkChangeHistory_header}>
            <Heading type="h2" className={styles.headerTitle}>
              Historial de cambios sobre Ligas
            </Heading>
            <Switch
              label="Mis cambios"
              checked={showSelfChanges}
              className={cx(styles.headerSwitch, showSelfChanges && styles.headerSwitch__active)}
              onChange={() => {
                setShowSelfChanges(!showSelfChanges);
              }}
            />
          </header>
          <section className={styles.linkChangeHistory_banner}>
            <Button
              className={styles.bannerDownload}
              onClick={() => {
                const userIdParam = showSelfChanges
                  ? `&user_id=${state?.base?.base?.user?.id}`
                  : "";

                window.open(
                  `${darwinUrl}resultados/link_log_entries_report/${origin}?token=${token}&scenario_id=${scenario}&origin_id=${origin}${userIdParam}`,
                  "_blank",
                );
              }}
              variant="ghost"
              leftIcon="download"
            >
              Descargar reporte
            </Button>
          </section>

          {!isLoading && (
            <LinkHistoryTable
              data={logEntries}
              onLoadLogEntries={getLogEntries}
              columnsToSearchBy={columnsToSearchBy}
              setColumnsToSearchBy={setColumnsToSearchBy}
              pagination={{
                page: pageInfo?.page,
                size: PAGE_SIZE,
                total: pageInfo?.total,
              }}
            />
          )}
        </div>
      )}
    </Layout>
  );
};

export default LinkChangeHistory;
