import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Heading, TextField } from "@foris/avocado-suite";
import { Icon } from "@foris/avocado-icons";
import GroupCodeInput from "@modules/vacancies/Groups/group-code-input";
import css from "./addGroupSection.module.scss";

interface AddGroupSectionProps {
  vacancies?: string | number;
  externalErrors?: {
    type?: string;
    text: string;
  }[];
  onValidForm?: (isValid: boolean) => void;
  onStateChange?: (state: { groupCode: string | number; groupVacancies: string | number }) => void;
}

const AddGroupSection = ({
  vacancies = "",
  externalErrors = [],
  onValidForm,
  onStateChange,
}: AddGroupSectionProps) => {
  // States for handle the form
  const [groupVacancies, setGroupVacancies] = useState<number | string>(vacancies);
  const [groupCode, setGroupCode] = useState("");
  const [groupCodeErrors, setGroupCodeErrors] = useState([]);
  // Hooks for handle the translation
  const { t } = useTranslation();

  const errors = useMemo(() => [...externalErrors, ...groupCodeErrors], [
    externalErrors,
    groupCodeErrors,
  ]);

  useEffect(() => {
    onValidForm?.(!groupCodeErrors?.length && !!groupCode && !!groupVacancies);
  }, [groupCodeErrors, groupCode, groupVacancies]);

  useEffect(() => {
    onStateChange?.({
      groupCode,
      groupVacancies,
    });
  }, [groupCode, groupVacancies]);

  return (
    <Box className={css.addGroupSection}>
      <Heading className={css.addGroupSection_title} type="h3">
        {t("create-group-link.step-3.title")}
      </Heading>

      <div className={css.addGroupSection_content}>
        <div className={css.formField}>
          <GroupCodeInput
            label={t("create-group-link.step-3.input-group.label")}
            placeholder={t("create-group-link.step-3.input-group.placeholder")}
            className={css.textField}
            onChange={setGroupCode}
            onError={setGroupCodeErrors}
          />

          {errors?.length > 0 && (
            <ul className={css.formErrors}>
              {errors.map((error, index) => (
                <li key={index} className={css.formErrors_error}>
                  <Icon filled name="circle-cross" color="error-50" size="sm" />
                  {error?.text ?? error?.message}
                </li>
              ))}
            </ul>
          )}
        </div>

        <TextField
          className={css.textField}
          label={t("create-group-link.step-3.input-vacancies.label")}
          placeholder={t("create-group-link.step-3.input-vacancies.placeholder")}
          type="number"
          value={groupVacancies}
          onChange={event => {
            setGroupVacancies(event.target.value);
          }}
        />
      </div>
    </Box>
  );
};

export default AddGroupSection;
