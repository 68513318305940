import React, { memo, useMemo } from "react";
import { Link as SuiteLink } from "@foris/avocado-suite";
import { Link } from "@models/ISchema";
import { Header } from "@components/index";
import css from "@components/Header/header.module.scss";
import useContextUrl from "@common/hooks/useContextUrl";
import { useTranslation } from "react-i18next";

interface ILinkHeader {
  setHeight?: any;
  link: Link;
  isCreation: boolean;
}
const LinkHeader: React.FC<ILinkHeader> = (props: ILinkHeader) => {
  const { setHeight, link, isCreation } = props;
  const { origin, scenario, workspace } = useContextUrl();
  const { t } = useTranslation();

  if (!link) return null;

  const routeBundle = `/editor/vacancies/${workspace}/${scenario}/${origin}/${link?.bundle?.id}`;

  const program = link?.course?.curriculum?.program;
  const courseCode = link?.course?.code || "";
  const courseName = link?.course?.name || "";

  const typeGroup = useMemo(
    () =>
      link?.groups
        ? Array.from(new Set(link.groups.map(group => group.type || "-"))).join(", ")
        : "-",
    [link?.groups],
  );

  const subTitle = (empty: boolean) => {
    if (empty) return undefined;

    return (
      Boolean(link?.bundle?.id) && (
        <h2 className={css.infoItem_subTitle}>
          <SuiteLink href={routeBundle} className={css.link} iconRight="external-link" size="sm">
            {`${t("bundle.title")}: ${link?.bundle?.code}`}
          </SuiteLink>
        </h2>
      )
    );
  };

  const topTitle = isCreation
    ? courseCode && courseName
      ? `Liga ${courseCode}, ${courseName}`
      : ""
    : link?.code || "";

  const title = isCreation
    ? t("bundle.packages-edition.title-create", {
        name: courseCode,
      })
    : courseCode && courseName
    ? `${courseCode}, ${courseName}`
    : "";

  const detailsCofiguration = [
    {
      title: t("dimension.link.header.more-details.campus"),
      content: program?.campus?.code || "-",
    },
    {
      title: t("dimension.link.header.more-details.department"),
      content: program?.department?.code || "-",
    },
    {
      title: t("dimension.link.header.more-details.modality"),
      content: program?.modality?.code || "-",
    },
    { title: t("dimension.link.header.more-details.shift"), content: program?.shift?.code || "-" },
    { title: t("dimension.link.header.more-details.program"), content: program?.code || "-" },
    {
      title: t("dimension.link.header.more-details.curriculum"),
      content: link?.course?.curriculum?.code ?? "-",
    },
    {
      title: t("dimension.link.header.more-details.level"),
      content: link?.course?.level?.toString() ?? "-",
    },
    {
      title: t("dimension.link.header.more-details.vacancies"),
      content: link?.vacancies?.toString() ?? "-",
    },
    {
      title: t("dimension.link.header.more-details.total-blocks"),
      content: link?.totalBlocks?.toString() ?? "-",
    },
  ];
  if (typeGroup)
    detailsCofiguration.push({
      title: t("dimension.link.header.more-details.group-types"),
      content: typeGroup,
    });

  const details = (isCreation: boolean) => (isCreation ? undefined : detailsCofiguration);

  const breadcrumbItems = useMemo(() => {
    const items = [
      {
        title: "Home",
        path: "/",
      },
      {
        title: isCreation
          ? t("bundle.packages-edition.title-create-short")
          : t("bundle.packages-edition.title"),
      },
    ];

    if (!isCreation) {
      items.splice(1, 0, {
        title: "Bundle",
        path: routeBundle,
      });
    }

    return items;
  }, [isCreation, routeBundle, t]);

  return (
    <Header
      breadcrumbItems={breadcrumbItems}
      title={title}
      topTitle={topTitle}
      subTitle={subTitle(isCreation)}
      moreDetails={details(isCreation)}
      setHeight={setHeight}
    />
  );
};

export default memo(LinkHeader);
