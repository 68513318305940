import React, { useState, useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CardNotification, Checkbox, RadioButton, Select, TextField } from "@foris/avocado-suite";
import { Session } from "@models/ISchema";
import { Option } from "@modules/booking/context/types";
import { useWarnings } from "../../hooks/useWarnings";
import { AppContext } from "../../context/EditSessionsContext";
import { EditedSession, Types, FormPageType } from "../../context/formData.reducer";
import FormItem from "../FormEdit/FormItem";
import css from "./blockRange.module.scss";

const getHourLabel = (date: string) => {
  const [hour, mins] = date.split(":");
  return `${hour}:${mins}`;
};

interface BlockRangeProps {
  disabled?: boolean;
}

const BlockRange = ({ disabled = false }: BlockRangeProps) => {
  const { state, dispatch } = useContext(AppContext);
  const [checkHours, setCheckHours] = useState(true);
  const { t } = useTranslation();

  const [warnings, setWarnings] = useWarnings(
    {
      blockTypeSelection: {
        message: "assignation-edit.form.notifications.schedule-validations.option-required",
        active: false,
        predicate: (form: FormPageType) => {
          return !form?.editedSessions?.blocks?.selected;
        },
      },
      mandatoryBlocks: {
        message:
          "assignation-edit.form.notifications.schedule-validations.numeric-greater-than-zero",
        active: false,
        predicate: (form: FormPageType) => {
          const selection = form?.editedSessions?.blocks?.selected;
          const blocks = form?.editedSessions?.blocks;
          return selection === "blocks" && !blocks?.blocks;
        },
      },
      timeSelection: {
        message: "assignation-edit.form.notifications.schedule-validations.time-required",
        active: false,
        predicate: (form: FormPageType) => {
          const selection = form?.editedSessions?.blocks?.selected;
          const blocks = form?.editedSessions?.blocks;
          return selection === "hours" && (!blocks?.endTime || !blocks?.startTime);
        },
      },
      timeConsistency: {
        message:
          "assignation-edit.form.notifications.schedule-validations.end-time-greater-than-start-time",
        active: false,
        predicate: (form: FormPageType) => {
          const selection = form?.editedSessions?.blocks?.selected;
          const blocks = form?.editedSessions?.blocks;
          return (
            selection === "hours" &&
            !!blocks?.endTime &&
            !!blocks?.startTime &&
            blocks?.endTime <= blocks?.startTime
          );
        },
      },
    },
    form =>
      (!form?.savedSessionsToCreateIds && !form?.selectedSessions?.length) ||
      !form?.assignmentEdited?.blocks,
  );

  /**
   * Handle warnings evaluations
   */
  useEffect(() => {
    setWarnings(state?.form);
  }, [state?.form?.assignmentEdited?.blocks, state?.form?.editedSessions?.blocks]);

  const errors = state?.form?.errors;
  const editedSessions = state?.form?.editedSessions;
  const selectedSessions = state?.form?.selectedSessions;
  const blocksCount =
    selectedSessions.length !== 1
      ? null
      : (selectedSessions[0] as Session)?.blocksCount
      ? (selectedSessions[0] as Session)?.blocksCount
      : (selectedSessions[0] as EditedSession)?.blocks?.blocks;

  const someDeletedSessionIsSelected = useMemo(() => {
    const selectedSessionIds = selectedSessions.map(session => session?.id || "-");
    return selectedSessionIds.some(id => state?.form?.sessionsToDelete?.hasOwnProperty(id));
  }, [selectedSessions, state?.form?.sessionsToDelete]);

  const optionStart = useMemo(() => {
    const selectedOption = (state?.link?.blockRanges?.start ?? [])?.find(
      option => option.value === editedSessions?.blocks?.startTime,
    );

    if (selectedOption?.value) return selectedOption;

    if (editedSessions?.blocks?.startTime) {
      return {
        label: getHourLabel(editedSessions?.blocks?.startTime),
        value: editedSessions?.blocks?.startTime,
      };
    }

    return null;
  }, [state?.link?.blockRanges?.start, editedSessions?.blocks?.startTime]);

  const optionEnd = useMemo(() => {
    const selectedOption = (state?.link?.blockRanges?.end ?? [])?.find(
      option => option.value === editedSessions?.blocks?.endTime,
    );

    if (selectedOption?.value) return selectedOption;

    if (editedSessions?.blocks?.endTime) {
      return {
        label: getHourLabel(editedSessions?.blocks?.endTime),
        value: editedSessions?.blocks?.endTime,
      };
    }

    return null;
  }, [state?.link?.blockRanges?.end, editedSessions?.blocks?.endTime]);

  const sortTimeOptions = (times: Option[] = []): Option[] =>
    times.sort((a, b) => {
      const [hour1, mins1] = a.label.split(":").map(Number);
      const [hour2, mins2] = b.label.split(":").map(Number);

      if (hour1 < hour2 || (hour1 === hour2 && mins1 < mins2)) return -1;
      if (hour1 > hour2 || (hour1 === hour2 && mins1 > mins2)) return 1;
      return 0;
    });

  const changeWithBlockRange = (start: string) => {
    const blocks = state?.link?.blockRanges;
    let indexStart = blocks?.end.findIndex(item => item.value === start);
    if (indexStart === -1) {
      indexStart =
        blocks?.end.findIndex(item => {
          const [hour1, mins1] = start?.split(":")?.map(v => parseInt(v));
          const [hour2, mins2] = item.value?.split(":")?.map(v => parseInt(v));
          return hour2 > hour1 || (hour2 === hour1 && mins2 > mins1);
        }) - 1;
    }
    const endValue = blocks?.end[indexStart + blocksCount]?.value;
    return endValue;
  };

  const clearBlockErrors = () => {
    dispatch({
      type: Types.FormError,
      payload: errors?.filter(error => error.type !== "BlockRanges"),
    });
  };

  const scrollToTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  useEffect(() => {
    if (checkHours && editedSessions?.blocks?.startTime) {
      const startTime = editedSessions?.blocks?.startTime;
      const hourBlocks = checkHours && blocksCount ? changeWithBlockRange(startTime) : null;
      const endHour = hourBlocks || editedSessions?.blocks?.endTime;
      clearBlockErrors();
      dispatch({
        type: Types.BlocksEditedSessions,
        payload: {
          blocks: {
            ...editedSessions?.blocks,
            startTime,
            endTime: endHour,
          },
        },
      });
    }
  }, [checkHours]);

  useEffect(() => {
    if ((errors ?? []).length > 0 && (errors ?? []).some(error => error.type === "BlockRanges")) {
      scrollToTop();
    }
  }, []);

  return (
    <FormItem title={t("assignation-edit.form.schedule.title")} type="blocks" isDisabled={disabled}>
      <section className={css.blockRange}>
        <div className={css.blockRange_radioOptions}>
          <RadioButton
            className={css.radioButton}
            checked={editedSessions?.blocks?.selected === "hours"}
            disabled={someDeletedSessionIsSelected || disabled}
            labelRight={t("assignation-edit.form.schedule.radio-options.with-schedule")}
            name="isRequiere"
            onChange={() => {
              dispatch({
                type: Types.BlocksEditedSessions,
                payload: {
                  blocks: {
                    ...editedSessions?.blocks,
                    selected: "hours",
                  },
                },
              });
              clearBlockErrors();
            }}
          />
          <RadioButton
            className={css.radioButton}
            checked={editedSessions?.blocks?.selected === "blocks"}
            disabled={someDeletedSessionIsSelected || disabled}
            labelRight={t("assignation-edit.form.schedule.radio-options.not-required-schedule")}
            name="isRequiere"
            onChange={() => {
              dispatch({
                type: Types.BlocksEditedSessions,
                payload: {
                  blocks: {
                    ...editedSessions?.blocks,
                    selected: "blocks",
                  },
                },
              });
              clearBlockErrors();
            }}
          />
        </div>

        {editedSessions?.blocks?.selected === "hours" && (
          <>
            {blocksCount && (
              <Checkbox
                disabled={someDeletedSessionIsSelected || disabled}
                checked={checkHours}
                labelRight={t(
                  "assignation-edit.form.schedule.checkbox-options.keep-session-duration",
                  {
                    count: blocksCount,
                  },
                )}
                className={css.blockRange_checkbox}
                onChange={() => setCheckHours(!checkHours)}
              />
            )}

            <div className={css.blockRange_timeOptions}>
              <Select
                disabled={someDeletedSessionIsSelected || disabled}
                value={optionStart || null}
                placeholder="hh:mm"
                label={t("assignation-edit.form.schedule.selectors.start-time")}
                className={css.timeOption}
                options={sortTimeOptions(state?.link?.blockRanges?.start)}
                onChange={value => {
                  const hourBlocks =
                    checkHours && blocksCount ? changeWithBlockRange(value?.value) : null;
                  const endHour = hourBlocks || editedSessions?.blocks?.endTime;
                  clearBlockErrors();
                  dispatch({
                    type: Types.BlocksEditedSessions,
                    payload: {
                      blocks: {
                        ...editedSessions?.blocks,
                        startTime: value?.value,
                        endTime: endHour,
                      },
                    },
                  });
                }}
              />

              <span className={css.divider}></span>

              <Select
                value={optionEnd || null}
                placeholder="hh:mm"
                label={t("assignation-edit.form.schedule.selectors.end-time")}
                className={css.timeOption}
                options={sortTimeOptions(
                  (state?.link?.blockRanges?.end ?? []).filter(({ label }: Option) => {
                    if (!state?.form?.editedSessions?.blocks?.startTime) return true;
                    const [
                      startHour,
                      startMins,
                    ] = state?.form?.editedSessions?.blocks?.startTime
                      ?.split(":")
                      ?.map(val => parseInt(val));
                    const [endHour, endMins] = label.split(":")?.map(val => parseInt(val));
                    return endHour > startHour || (endHour === startHour && endMins > startMins);
                  }),
                )}
                disabled={
                  someDeletedSessionIsSelected ||
                  (checkHours && blocksCount ? true : false) ||
                  disabled
                }
                onChange={value => {
                  clearBlockErrors();
                  dispatch({
                    type: Types.BlocksEditedSessions,
                    payload: {
                      blocks: {
                        ...editedSessions?.blocks,
                        endTime: value?.value,
                      },
                    },
                  });
                }}
              />
            </div>
          </>
        )}
        {editedSessions?.blocks?.selected === "blocks" && (
          <TextField
            className={css.blockRange_input}
            disabled={someDeletedSessionIsSelected || disabled}
            label={t("assignation-edit.form.schedule.selectors.blocks")}
            type="number"
            value={editedSessions?.blocks?.blocks || ""}
            onChange={value => {
              dispatch({
                type: Types.BlocksEditedSessions,
                payload: {
                  blocks: {
                    ...editedSessions?.blocks,
                    blocks: parseInt(value?.target?.value),
                  },
                },
              });
            }}
          />
        )}

        {warnings.map(warning => (
          <div key={warning}>
            <CardNotification
              state="warning"
              key={warning}
              title={t("assignation-edit.form.notifications.schedule-validations.title")}
              outlined
            >
              {t(warning)}
            </CardNotification>
          </div>
        ))}
      </section>
    </FormItem>
  );
};

export default BlockRange;
