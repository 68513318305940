import { Button, Heading, Modal, Text } from "@foris/avocado-suite";
import React from "react";
import { useTranslation } from "react-i18next";

interface GroupDeleteModalProps {
  isOpen?: boolean;
  groupLabel?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
}

const GroupDeleteModal = ({ isOpen, groupLabel, onCancel, onConfirm }: GroupDeleteModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      header={<Heading type="h2">{t("bundle.modals.group-delete-modal.title")}</Heading>}
      open={isOpen}
      onClose={onCancel}
      actions={
        <>
          <Button variant="secondary" onClick={onCancel}>
            {t("bundle.actions.cancel")}
          </Button>

          <Button variant="danger" onClick={onConfirm}>
            {t("bundle.actions.delete-group")}
          </Button>
        </>
      }
    >
      <Text>
        {t("bundle.modals.group-delete-modal.description", {
          groupLabel,
        })}
      </Text>
    </Modal>
  );
};

export default GroupDeleteModal;
