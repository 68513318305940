import React, { useState, useContext, useMemo } from "react";
import { Checkbox } from "@foris/avocado-suite";
import { AppContext } from "../../context/EditSessionsContext";
import { Session, Section } from "@models/ISchema";
import { Types, EditedSession } from "../../context/formData.reducer";
import { IContextApp, ContextApp } from "@config/Context/contextApp";
import SectionsList from "../sections-list";
import css from "./accordion.module.scss";
import { useTranslation } from "react-i18next";

interface AccordionSectionsProps {
  onCompareBySession: (value: Session) => void;
  onCompareBySection: (
    sessions: Session[],
    section: Section,
    checked: boolean,
    newSessions: EditedSession[],
  ) => boolean;
}

const AccordionSections = (props: AccordionSectionsProps) => {
  const { state, dispatch } = useContext(AppContext);
  const contextApp: IContextApp = useContext(ContextApp);
  const [allSessionsChecked, setAllSessionsChecked] = useState(false);
  const { t } = useTranslation();

  if (!state?.link?.sections) return null;

  const userCanCreate =
    contextApp?.user?.permissions?.create &&
    contextApp?.user?.abilities?.can_edit_links_and_sections &&
    contextApp?.user?.abilities?.can_edit_or_delete_sessions;

  const orderedSections = useMemo(() => {
    if (!state?.link?.sections?.length) return null;

    return [...state.link.sections].sort((a, b) => {
      const codeA = a.component?.code ?? "";
      const codeB = b.component?.code ?? "";
      return codeA.localeCompare(codeB);
    });
  }, [state?.link?.sections]);

  const handleAllSessionsCheckedClick = () => {
    dispatch({
      type: Types.ToggleAllSessionsSelection,
      payload: { link: state?.link, currentCheckbox: allSessionsChecked },
    });

    setAllSessionsChecked(!allSessionsChecked);
  };

  if (!orderedSections?.length) return <></>;

  return (
    <div>
      {(orderedSections?.length ?? 0) > 1 && (
        <Checkbox
          className={css.selectAllBtn}
          labelRight={t("assignation-edit.sessions-list.select-all")}
          checked={allSessionsChecked}
          onChange={handleAllSessionsCheckedClick}
        />
      )}

      <SectionsList
        sections={orderedSections}
        setAllSessionsChecked={setAllSessionsChecked}
        onCompareBySession={props.onCompareBySession}
        onCompareBySection={props.onCompareBySection}
        userCanCreate={userCanCreate}
      />
    </div>
  );
};

export default AccordionSections;
