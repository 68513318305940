/**
 * ActionCards component
 *
 * Displays the main action card for editing schedules and links,
 * including advanced search, group management, package creation,
 * and access to various related reports and change history.
 *
 * Also renders additional grouped action cards (RestActions) below the main block.
 */

import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Button, Heading, Text, DropdownButton, Divider } from "@foris/avocado-suite";
import { Icon } from "@foris/avocado-icons";
import useNavigationUrl from "@common/hooks/useNavigationUrl";
import Search from "../search";
import RestActions from "../rest-actions";
import css from "./actionCards.module.scss";

const ActionCards: FC = () => {
  // Hooks for translations and routing
  const { t } = useTranslation();
  const history = useHistory();

  // Navigation URLs used in the buttons and dropdowns
  const {
    changeHistoryUrl,
    linkChangeHistoryUrl,
    packagesAndGroupsReportUrl,
    groupsManagerUrl,
    createSectionUrl,
    createPackageUrl,
    getPackageClashReportUrl,
  } = useNavigationUrl();

  return (
    <div className={css.actionCards}>
      {/* Main card for editing schedules */}
      <Box className={css.actionCards_main}>
        <Icon name="calendar-edh" size="lg" />

        <article className={css.content}>
          <Heading className={css.content_title} type="h2">
            {t("action-cards.editing-schedules.title")}
          </Heading>

          <Text className={css.content_description}>
            {t("action-cards.editing-schedules.description")}
          </Text>

          {/* Advanced search with redirection logic */}
          <Search />

          <Divider className={css.content_divider} />
          {/* Button group with navigation actions and dropdowns */}
          <div className={css.content_buttonGroup}>
            <Button variant="secondary" onClick={() => history.push(groupsManagerUrl())}>
              {t("action-cards.editing-schedules.btn-group-management")}
            </Button>
            <Button variant="secondary" onClick={() => history.push(createSectionUrl())}>
              {t("action-cards.editing-schedules.btn-create-link")}
            </Button>
            <Button variant="secondary" onClick={() => history.push(createPackageUrl())}>
              {t("action-cards.editing-schedules.btn-create-package")}
            </Button>
            {/* Dropdown for report-related actions */}
            <DropdownButton
              variant="secondary"
              items={[
                {
                  label: t(
                    "action-cards.editing-schedules.dropdown-button-reports.btn-package-and-group",
                  ),
                  onClick: () => history.push(packagesAndGroupsReportUrl()),
                },
                {
                  label: t("action-cards.editing-schedules.dropdown-button-reports.btn-package"),
                  onClick: () => window.open(getPackageClashReportUrl(), "_blank"),
                },
              ]}
            >
              {t("action-cards.editing-schedules.dropdown-button-reports.title")}
            </DropdownButton>
            {/* Dropdown for change history actions */}
            <DropdownButton
              variant="secondary"
              items={[
                {
                  label: t("action-cards.editing-schedules.dropdown-button-chanlog.btn-group"),
                  onClick: () => history.push(changeHistoryUrl()),
                },
                {
                  label: t("action-cards.editing-schedules.dropdown-button-chanlog.btn-link"),
                  onClick: () => history.push(linkChangeHistoryUrl()),
                },
              ]}
            >
              {t("action-cards.editing-schedules.dropdown-button-chanlog.title")}
            </DropdownButton>
          </div>
        </article>
      </Box>
      {/* Additional grouped action cards */}
      <RestActions />
    </div>
  );
};

export default ActionCards;
