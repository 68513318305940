import React, { useContext } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { AppContext } from "../../context/EditSessionsContext";
import { Week } from "../../context/linkData.reducer";
import {
  IntervalValidationErrors,
  EditorValidationError,
  CustomValidation,
  StudentClash,
  Holiday,
} from "@models/ISchema";
import css from "./incidenceTable.module.scss";
import { getFormattedDate } from "@modules/sections/utils/formats";
import { Text } from "@foris/avocado-suite";

dayjs.extend(utc);

interface IWeekColumnProps {
  week?: Week;
  column: EditorValidationError | CustomValidation | IntervalValidationErrors | StudentClash;
}

const WeekColumn: React.FC<IWeekColumnProps> = (props: IWeekColumnProps) => {
  const { state } = useContext(AppContext);
  const { column, week } = props;

  let interval = 0;
  let numberWeek = "1";
  let rangeWeek = "";

  if (column?.__typename === "InvalidSessionInHoliday" && week?.value) {
    const [year, month, day] = getFormattedDate(
      ((column?.holiday as unknown) as Holiday)?.date,
    ).split("-");

    return (
      <Text className={css.weekColumn}>
        <span className={css.numberWeek}>Semana {week?.value}</span> | {`${day}/${month}/${year}`}
      </Text>
    );
  }

  if (column?.__typename !== "IntervalValidationErrors" || !column?.interval?.id) {
    return (
      <Text className={css.weekColumn}>
        <span className={css.numberWeek}>Todas las semanas</span>
      </Text>
    );
  } else {
    let matchWeek: Week;
    if (state?.link?.weeks) {
      matchWeek = state?.link?.weeks.find(item => item.id === column.interval.id);
    }
    if (column.interval.id) {
      const dateStart = column.interval.startingDate;
      const dateEnd = column.interval.endingDate;
      interval = column.interval.id ? column.interval.id.length : 0;
      numberWeek = interval === 1 ? "0" + column.interval.id : column.interval.id;
      const startFormat = `${dayjs(new Date(dateStart))
        .utc()
        .format("DD/MM/YYYY")}`;
      const endFormat = `${dayjs(new Date(dateEnd))
        .utc()
        .format("DD/MM/YYYY")}`;
      rangeWeek = `(${startFormat} - ${endFormat})`;
    }

    if (!matchWeek) return <div> </div>;

    return (
      <Text className={css.weekColumn}>
        <span className={css.numberWeek}>
          Semana {matchWeek && matchWeek.value ? matchWeek.value : numberWeek}
        </span>{" "}
        {rangeWeek}
      </Text>
    );
  }
};

export default WeekColumn;
