import React, { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Heading } from "@foris/avocado-suite";
import { RegularOption } from "@foris/avocado-ui/lib/types/models/GenericProps";
import { CourseMultiKey } from "@modules/shared/selectors";
import TermSelect from "@modules/vacancies/Groups/term-select";
import CampusSelect from "@modules/shared/selectors/Selectors/Campus";
import ModalitySelect from "@modules/shared/selectors/Selectors/Modality";
import ProgramSelect from "@modules/shared/selectors/Selectors/Program";
import css from "./selectors.module.scss";

interface SelectorsReturn {
  idCampus: string;
  idDepartment: string;
  idModality: string;
  idShift: string;
  objLevel: any;
  idProgram: string;
  idCourse: string;
  idTerm: string;
  idCurriculum: string;
  groupCode?: string;
  groupVacancies?: string;
}

interface SelectorsProps {
  onReturn: (value: SelectorsReturn) => void;
}

const Selectors: FC<SelectorsProps> = ({ onReturn }) => {
  // States for handle the form
  const [idTerm, setIdTerm] = useState(null);
  const [objCourse, setObjCourse] = useState<SelectorsReturn>(null);
  const [selectedCampus, setSelectedCampus] = useState("");
  const [selectedModality, setSelectedModality] = useState("");
  const [selectedProgram, setSelectedProgram] = useState("");
  // Hooks for handle translation
  const { t } = useTranslation();

  const isRequiredFieldsSelected = !!(selectedCampus || selectedModality || selectedProgram);
  const courseKey = useMemo(() => `${selectedCampus}-${selectedModality}-${selectedProgram}`, [
    selectedCampus,
    selectedModality,
    selectedProgram,
  ]);

  const setTerm = (values: any) => {
    if (values.term) {
      setIdTerm(values.term);
    }
  };

  useEffect(() => {
    if (objCourse && idTerm) {
      onReturn({
        ...objCourse,
        idTerm,
      });
    }
  }, [idTerm, objCourse]);

  return (
    <Box className={css.selectors}>
      <Heading type="h3" className={css.selectors_title}>
        {t("create-group-link.step-1.title")}
      </Heading>

      <div className={css.selectors_groupFields}>
        <CampusSelect
          label={t("create-group-link.step-1.campus-select.label")}
          placeholder={t("create-group-link.step-1.campus-select.placeholder")}
          className={css.select}
          onCallback={(campus: RegularOption) => setSelectedCampus(campus?.value || "")}
        />
        <ModalitySelect
          className={css.select}
          label={t("create-group-link.step-1.mode-select.label")}
          placeholder={t("create-group-link.step-1.mode-select.placeholder")}
          shouldHasDepartment={false}
          onCallback={(modality: RegularOption) => setSelectedModality(modality?.value || "")}
        />
        <ProgramSelect
          className={css.select}
          label={t("create-group-link.step-1.career-select.label")}
          placeholder={t("create-group-link.step-1.career-select.placeholder")}
          campus={selectedCampus}
          modality={selectedModality}
          shouldHaveFilters={false}
          filterBy="modality"
          onCallback={(program: RegularOption) => setSelectedProgram(program?.value || "")}
        />
      </div>

      <div className={css.selectors_multiKey}>
        <CourseMultiKey
          key={courseKey}
          label={t("create-group-link.step-1.subject-select.label")}
          placeholder={t("create-group-link.step-1.subject-select.placeholder")}
          onCallback={newSelectorReturn => setObjCourse(newSelectorReturn)}
          campusId={selectedCampus}
          modalityId={selectedModality}
          programId={selectedProgram}
          isDisabled={!isRequiredFieldsSelected}
        />
      </div>

      <div className={css.selectors_term}>
        <TermSelect
          label={t("create-group-link.step-1.period-select.label")}
          placeholder={t("create-group-link.step-1.period-select.placeholder")}
          sendValues={(values: any) => setTerm(values)}
        />
      </div>
    </Box>
  );
};

export default Selectors;
