import React from "react";
import { Option } from "react-select/lib/filters";
import { useTranslation } from "react-i18next";
import useGetShiftOptions from "@modules/shared/selectors/hooks/useGetShiftOptions";
import AdvanceSelector from "../advance-selector";

interface AdvancedShiftSelectorProps {
  onChange?: (option: Option) => void;
  scenario: string;
}

const AdvancedShiftSelector = ({ scenario, onChange }: AdvancedShiftSelectorProps) => {
  const { loadOptions } = useGetShiftOptions({
    scenario,
  });

  const { t } = useTranslation();

  return (
    <AdvanceSelector
      isPaginated
      dimension={t("advanced-search.instructor.forms.select-wrapper.shift.label")}
      selectPlaceholder={t("advanced-search.instructor.forms.select-wrapper.shift.placeholder")}
      loadOptions={loadOptions}
      onChange={onChange}
      notShowLabel
    />
  );
};

export default AdvancedShiftSelector;
