import { IErrorWarning } from "@models/Errors/ErrorWarning";
import GroupCreate from "@models/Errors/GroupCreate";
import {
  CreateGroupInput,
  CubeMutation,
  Group,
  GroupPayload,
  GroupValidationErrorUnion,
} from "@models/ISchema";
import { getValidationErrorReason, linkValidation } from "../utils";
import { useState } from "react";
import { ADD_GROUPS_MUTATION } from "../graphql/groups.queries";
import { useMutation } from "react-apollo";

interface useAddGroupProps {
  scenario: string;
  origin: string;
  onSuccess?: () => void;
}

const useAddGroup = ({ scenario, origin, onSuccess }: useAddGroupProps) => {
  const [addGroupsMutation] = useMutation(ADD_GROUPS_MUTATION, {});
  const [isLoading, setLoading] = useState(false);
  const [validationArray, setValidationArray] = useState<IErrorWarning[]>([]);
  const [validationStandardArray, setvalidationStandardArray] = useState<IErrorWarning[]>([]);

  const handleMutationErrors = (
    dataMutation: CubeMutation,
    input: CreateGroupInput,
    cb?: (input: CreateGroupInput, commit: boolean) => void,
  ) => {
    const valueCode = input?.code;
    const validationErrors: Array<GroupValidationErrorUnion> =
      dataMutation.createGroup.validationErrors;
    let errorsValidations = [];
    const errorStandard: IErrorWarning[] = [];

    if (!!validationErrors?.length) {
      validationErrors.forEach((error: GroupValidationErrorUnion) => {
        // new standard for validations (errors and warnings)
        const groupError: Group = {
          id: undefined,
          name: valueCode,
        };
        const errors = new GroupCreate(error, groupError);
        const errorsMessage = errors.getMessage();
        errorsMessage && errorStandard.push(errorsMessage);

        // old validations
        if (error && error.__typename === "InvalidFieldValidation") {
          const errorObj = getValidationErrorReason(error.reason);
          errorsValidations.push(errorObj);
        } else if (error && error.__typename === "InvalidLinkChanges") {
          const errorLinks = linkValidation(error);
          errorsValidations = [...errorsValidations, ...errorLinks];
        } else if (error && error?.__typename === "InvalidGroupCodeValidation") {
          const errors = [
            ...errorsValidations,
            {
              type: "error",
              text: "Ingresa números enteros del 1 al 999",
            },
          ];

          if (String(valueCode).startsWith("0")) {
            errors.push({
              type: "error",
              text: "El número no puede ser antecedido por uno o más ceros (01, 001)",
            });
          }

          errorsValidations = errors;
        } else {
          console.error(error && error.__typename);
        }
      });

      setValidationArray(errorsValidations);
      setvalidationStandardArray(errorStandard);
      setLoading(false);
    } else {
      cb?.(input, true);
    }
  };

  const addGroup = async (input: CreateGroupInput, commit: boolean, onlySimulate = false) => {
    try {
      setLoading(true);

      const data = await addGroupsMutation({
        variables: {
          scenarioId: parseInt(scenario),
          originId: parseInt(origin),
          input: {
            ...input,
            dryRun: !commit,
            skipValidations: commit,
          },
        },
      });

      if (!commit) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        handleMutationErrors(data?.data?.cube, input, onlySimulate ? undefined : addGroup);
      } else {
        const dataMutation: GroupPayload = data.data.cube.createGroup;
        if (dataMutation.commited) {
          onSuccess?.();
        }

        setLoading(false);
      }

      return !data?.data?.cube?.createGroup?.validationErrors?.length;
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  return {
    addGroup,
    isLoading,
    validationArray,
    validationStandardArray,
  };
};

export default useAddGroup;
