import React, { useContext } from "react";
import cx from "classnames";
import { useHistory } from "react-router-dom";
import { Button } from "@foris/avocado-suite";
import { ContextApp, IContextApp } from "@config/Context/contextApp";
import css from "./vacancies.module.scss";
import { useTranslation } from "react-i18next";

interface IFooterVacancies {
  activeEdit: boolean;
  setActiveEdit: (active: boolean) => void;
  warningsCount: number;
  canEditSomeGroup: boolean;
  onSave: (commit: boolean) => void;
  disabledConfirm?: boolean;
}

const FooterVacancies: React.FC<IFooterVacancies> = (props: IFooterVacancies) => {
  const {
    activeEdit,
    setActiveEdit,
    warningsCount,
    onSave,
    disabledConfirm,
    canEditSomeGroup,
  } = props;
  const context: IContextApp = useContext(ContextApp);
  const showEditButton =
    context?.user?.permissions?.update &&
    context?.user?.abilities?.can_edit_groups_in_bundle_view &&
    !context?.user?.abilities?.can_access_group_manager &&
    canEditSomeGroup;

  const { t } = useTranslation();
  const history = useHistory();

  return (
    <footer className={cx(css.footer, "container-row")}>
      {!activeEdit && (
        <>
          <Button
            variant="ghost"
            onClick={() => {
              history.goBack();
            }}
          >
            {t("bundle.actions.back")}
          </Button>
          {showEditButton && (
            <Button
              onClick={() => {
                setActiveEdit(true);
              }}
            >
              {t("bundle.actions.edit")}
            </Button>
          )}
        </>
      )}
      {activeEdit && (
        <>
          <Button
            variant="ghost"
            onClick={() => {
              setActiveEdit(false);
            }}
          >
            {t("bundle.actions.cancel")}
          </Button>
          {warningsCount === 0 && (
            <Button
              onClick={() => {
                onSave(false);
              }}
            >
              {t("bundle.actions.save-changes")}
            </Button>
          )}
          {warningsCount > 0 && (
            <Button
              disabled={disabledConfirm}
              onClick={() => {
                onSave(true);
              }}
            >
              {t("bundle.actions.confirm-changes")}
            </Button>
          )}
        </>
      )}
    </footer>
  );
};

export default FooterVacancies;
