import { ActionMap } from "@models/IContext";
import { Setting, BaseQuery } from "@models/ISchema";

export type BookingProgramRequired = "OPTIONAL" | "REQUIRED" | "HIDDEN";

export type GroupsInLinkRestringtion = {
  enabled: boolean;
  groups_allowed: number;
};

export type BookingClassroomOrder = {
  field: string;
  direction: string;
};

export type CampusShort = {
  id: string;
  code: string;
};

export type CampusCategory = {
  code: string;
  name: string;
  campus: CampusShort[];
};

export type CampusCategoriesConfig = {
  enabled: boolean;
  campus_categories: CampusCategory[];
};

export type BookingClassroomOrderPayload = BookingClassroomOrder[];

export type BaseTypes = {
  settings?: Setting[];
  isMouseflowEnabled?: boolean;
  isGroupManagerFilterEnabled?: boolean;
  createGroupWithinLinkCreation?: "MANDATORY" | "OPTIONAL" | "DISABLED";
  groupsInLinkRestriction?: GroupsInLinkRestringtion;
  bookingClassroomOrder?: BookingClassroomOrderPayload;
  campusCategoriesConfig?: CampusCategoriesConfig;
  bookingsProgramRequired?: BookingProgramRequired;
  base?: BaseQuery;
  locale?: "es" | "en";
};

export enum Types {
  SetSettings = "SET_SETTINGS",
  SetMouseflowEnabled = "SET_MOUSEFLOW_ENABLED",
  setCreateGroupWithinLinkCreation = "SET_CREATE_GROUP_WITHIN_LINK_CREATION",
  setGroupManagerFilterEnabled = "SET_GROUP_MANAGER_FILTER_ENABLED",
  setGroupsInLinkRestriction = "SET_GROUPS_IN_LINK_RESTRICTION",
  setBookingClassroomOrder = "SET_BOOKING_CLASSROOM_ORDER",
  setCampusCategoriesConfig = "SET_CAMPUS_CATEGORIES_CONFIG",
  setBookingsProgramRequired = "SET_BOOKINGS_PROGRAM_REQUIRED",
  SetBase = "SET_BASE",
  setLocale = "SET_LOCALE",
}

type BasePayload = {
  [Types.SetSettings]: Setting[];
  [Types.SetMouseflowEnabled]: boolean;
  [Types.setGroupManagerFilterEnabled]: boolean;
  [Types.setCreateGroupWithinLinkCreation]: "MANDATORY" | "OPTIONAL" | "DISABLED";
  [Types.setGroupsInLinkRestriction]: GroupsInLinkRestringtion;
  [Types.setBookingClassroomOrder]: BookingClassroomOrderPayload;
  [Types.setCampusCategoriesConfig]: CampusCategoriesConfig;
  [Types.setBookingsProgramRequired]: BookingProgramRequired;
  [Types.SetBase]: BaseQuery;
  [Types.setLocale]: "es" | "en";
};

export type BaseActions = ActionMap<BasePayload>[keyof ActionMap<BasePayload>];

export const baseReducer = (state: BaseTypes, action: BaseActions) => {
  switch (action.type) {
    case Types.SetSettings:
      return {
        ...state,
        settings: action.payload,
      };
    case Types.SetMouseflowEnabled:
      return {
        ...state,
        isMouseflowEnabled: action.payload,
      };
    case Types.setGroupManagerFilterEnabled:
      return {
        ...state,
        isGroupManagerFilterEnabled: action.payload,
      };
    case Types.setCreateGroupWithinLinkCreation:
      return {
        ...state,
        createGroupWithinLinkCreation: action.payload,
      };
    case Types.setGroupsInLinkRestriction:
      return {
        ...state,
        groupsInLinkRestriction: action.payload,
      };
    case Types.setBookingClassroomOrder:
      return {
        ...state,
        bookingClassroomOrder: action.payload,
      };
    case Types.setCampusCategoriesConfig:
      return {
        ...state,
        campusCategoriesConfig: action.payload,
      };
    case Types.setBookingsProgramRequired:
      return {
        ...state,
        bookingsProgramRequired: action.payload,
      };
    case Types.SetBase:
      return {
        ...state,
        base: action.payload,
      };
    case Types.setLocale:
      return {
        ...state,
        locale: action.payload,
      };
    default:
      return state;
  }
};
