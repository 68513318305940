import React from "react";
import { useTranslation } from "react-i18next";
import DimensionsSearchResults from "@dimensions/common/dimensions-search-results";
import useGetTitle from "@dimensions/hooks/useGetTitle";
import {
  getColumnCell,
  getHeadCell,
  getLinkCell,
  getPillCell,
  getUsableCell,
} from "@dimensions/utils/tableUtils";
import { Instructor } from "@models/ISchema";
import { DimensionNameEs } from "@models/IDimension";
import { ISearchProps } from "@dimensions/common/ISearch";
import useNavigationUrl from "@common/hooks/useNavigationUrl";

/**
 * <InstructorTable> component that displays a table of instructors with relevant information.
 *
 * This component fetches instructors data and renders it in a table format, displaying information
 * such as instructors code, name, priority, type-contract, usable, total-blocks-assigned, capacity, and utilization percentages.
 * It integrates pagination, search, and internationalization (i18n) for multi-language support.
 *
 * @param {DimensionSearchResultsProps} props - The properties passed to the component.
 * @param {Array<Classroom>} props.data - Data of instructors to display in the table.
 * @param {string} props.search - The search query to filter results.
 * @param {Object} props.pageInfo - Pagination information (total results, current page, etc.).
 * @param {Function} props.setPage - Function to change the current page.
 * @param {boolean} props.loading - Boolean indicating if the data is still loading.
 * @param {boolean} props.error - Boolean indicating if there was an error during data fetch.
 * @param {Function} props.onChangeSizePerPage - Function to handle page size changes.
 * @returns {JSX.Element} - The rendered InstructorTable component.
 *
 * @example
 * <InstructorTable
 *   data={instructorsData}
 *   search="Math"
 *   pageInfo={pageInfo}
 *   setPage={setPage}
 *   loading={isLoading}
 *   error={hasError}
 *   onChangeSizePerPage={handlePageSizeChange}
 * />
 */

const InstructorTable = (props: ISearchProps) => {
  const { data, search, pageInfo, loading, error, setPage, onChangeSizePerPage } = props;

  // Get the dynamic title for the search results using the custom hook `useGetTitle`
  const title = useGetTitle(search, pageInfo?.total || 0, "docente");

  // Get the context URL for advanced search functionality
  const { advancedSearchUrl, editorUrl } = useNavigationUrl();
  const getEditorUrl = (id: string) => editorUrl("instructor", id);

  // Use the i18n hook to get translation functions
  const { t } = useTranslation();

  // Define the columns for the table, including the header and cell rendering logic
  const columns = [
    {
      header: getHeadCell(t("search-results.dimension.instructor.table.header.code")),
      renderCell: ({ code, id }: Instructor) => getLinkCell(getEditorUrl(id), null, code),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "124px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.instructor.table.header.name")),
      renderCell: ({ name, id }: Instructor) => getLinkCell(getEditorUrl(id), null, name),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "260px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.instructor.table.header.priority")),
      renderCell: ({ priority }: Instructor) => getColumnCell(priority),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "120px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.instructor.table.header.type-contract")),
      renderCell: ({ contract }: Instructor) => getColumnCell(contract),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "200px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.instructor.table.header.usable")),
      renderCell: ({ usable }: Instructor) => getUsableCell(usable),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "120px" },
      },
    },
    {
      header: getHeadCell(
        t("search-results.dimension.instructor.table.header.total-assigned-blocks"),
      ),
      renderCell: ({ assignment }: Instructor) => getColumnCell(assignment?.totalAssignedBlocks),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "200px" },
      },
    },
    {
      header: getHeadCell(
        t("search-results.dimension.instructor.table.header.occupancy-percentage"),
      ),
      renderCell: ({ assignment }: Instructor) => getPillCell(assignment?.usagePercentage),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "150px" },
      },
    },
  ];

  // Render the DimensionsSearchResults component with the table data and configurations
  return (
    <DimensionsSearchResults
      onChangeSizePerPage={onChangeSizePerPage}
      title={title.getTitle}
      columns={columns}
      data={data}
      pageInfo={pageInfo}
      setPage={setPage}
      loading={loading}
      error={error}
      dimension={t("dimension.instructor.title") as DimensionNameEs}
      advanceLink={advancedSearchUrl("instructor")}
    />
  );
};

export default InstructorTable;
