import React, { useState } from "react";
import { useQuery } from "react-apollo";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import Layout from "@common/layout/Layout";
import PopulationTable from "./PopulationTable";
import populationsCubeQuery from "../graphql/populationsCubeQuery.graphql";
import populationsFilterQuery from "../graphql/populationsFilterQuery.graphql";
import useGetQueryVariables from "@dimensions/hooks/useGetQueryVariables";
import { PopulationPage } from "@models/ISchema";

const PopulationSearch: React.FC = () => {
  // Using React Router's useLocation hook to get the query parameters from the URL
  const location = useLocation();
  const params: queryString.ParsedQuery<string> = queryString.parse(location.search);

  // State for managing current page
  const [page, setPageCount] = useState(1);

  // Get the query variables using the custom hook, based on the URL parameters and pagination settings
  const variables = useGetQueryVariables(params, page, undefined);

  // Choose the appropriate query based on the 'advance' parameter from the URL
  const querySearch = params?.advance ? populationsFilterQuery : populationsCubeQuery;

  // Fetch data using the Apollo useQuery hook with the chosen query and variables
  const { loading, error, data } = useQuery(querySearch, { variables });
  let populations: PopulationPage = data?.cube?.populations;

  // Assign the fetched data depending on the query used
  if (params?.advance) populations = data?.filter?.populations;

  // Render the Layout component and pass the populations data to the <PopulationsTable> component for display
  return (
    <Layout contextSearch>
      <PopulationTable
        data={populations?.items} // Items from populations data
        search={params?.search?.toString()} // Search query from URL
        pageInfo={populations?.pageInfo} // Page information for pagination
        setPage={(value: number) => setPageCount(value)} // Function to update the page number
        loading={loading} // Pass loading state from useQuery
        error={error} // Pass error state from useQuery
      />
    </Layout>
  );
};

export default PopulationSearch;
