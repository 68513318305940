import { Session, Instructor, Classroom, Section, Day } from "@models/ISchema";
import { ActionMap } from "@models/IContext";
import { Week, LinkPageType } from "./linkData.reducer";
import {
  addSessionsToCreate,
  addSessionsToCreateBySelectedDays,
  addSessionsToCreateWithNoDays,
  assignmentEdited,
  blocksEditedSessions,
  classroomEditedSessions,
  cleanForm,
  cleanFormCreateSession,
  cleanUnsavableSessionsToCreate,
  formCancel,
  formError,
  forkSelectedSessions,
  instructorEditedSessions,
  intervalsEditedSessions,
  notSelectedCreateSession,
  undoEditionOverSelectedSessions,
  removeSelectedSessions,
  savedNewSession,
  savedSessions,
  selectedCreateSession,
  selectedSaveSession,
  selectedSessionsBySection,
  setSessionFromLocationSelected,
  setSessionRecommendationIdSelected,
  setRemovedWeekIdsBySessionId,
  toggleAllSessionsSelection,
  selectedSessions,
  setBlockingThreshold,
} from "./formData.actions";

export type ErrorType = "BlockRanges" | "Intervals";
export type TypeAssignment = "blocks" | "day" | "instructors" | "classrooms" | "intervals";

export interface ErrorForm {
  type: ErrorType;
  message: string;
}

export interface BlockRanges {
  days?: Day[];
  day?: string;
  startTime?: string;
  endTime?: string;
  blocks?: number;
  selected?: "hours" | "blocks";
}

export interface Assignment {
  blocks?: boolean;
  day?: boolean;
  instructors?: boolean;
  classrooms?: boolean;
  intervals?: boolean;
}

/**
 * @deprecated Replace with `EditedSession` if you're trying to refer a single
 * session or `ResourcesType` if you're trying to refer to the multi-edit form
 * type.
 */
export interface EditedSessions {
  id?: string;
  blocks?: BlockRanges;
  instructors?: Instructor[];
  classrooms?: Classroom[];
  intervals?: Week[];
  session?: Session;
  isNew?: boolean;
  isCloned?: boolean;
  section?: Section;
  allWeeks?: boolean;
  forkedWeeksByOriginSessions?: { [key: string]: string[] };
}

/**
 * TODO:
 * later refactor. This type should be used as the form of the
 * `EditedSessions`, instead of the `EditedSessons` type.
 */
export interface ResourcesType {
  blocks?: BlockRanges;
  instructors?: Instructor[];
  classrooms?: Classroom[];
  intervals?: Week[];
}

export type EditedSession = EditedSessions;

export interface SelectedSessionsBySectionPayload {
  checked: boolean;
  sessionsOfSection: Session[];
  sectionId: string;
}

export type FormPageType = {
  blockingThreshold: string | null;
  assignmentEdited: Assignment;
  assignmentSame: Assignment;
  cloneSelected: EditedSession[];
  editedSessions: EditedSession;
  errors?: ErrorForm[];
  removedWeekIdsBySessionId: any; // { [key: string]: string[] };
  savedSessions: EditedSession[];
  savedSessionsToCreateIds: any; // { [key: string]: boolean };
  selectedCreateSession: boolean;
  selectedSection: Section;
  selectedSessions: (Session | EditedSession)[];
  sessionFromLocationSelected: boolean;
  sessionRecommendationIdSelected: string;
  sessionsToCreate: EditedSession[];
  sessionsToDelete: any; // { [key: string]: EditedSession };
  unmarkedWeekIdsBySessionId: { [sessionId: string]: { [weekId: string | number]: Week } };
};

export enum Types {
  AddSessionsToCreate = "ADD_SESSIONS_TO_CREATE",
  AddSessionsToCreateBySelectedDays = "ADD_SESSIONS_TO_CREATE_BY_SELECTED_DAYS",
  AddSessionsToCreateWithNoDays = "ADD_SESSIONS_TO_CREATE_WITH_NO_DAYS",
  AssignmentEdited = "SET_EDITED_ASSIGNMENT_SESSIONS",
  BlocksEditedSessions = "SET_EDITED_SESSIONS_BLOCK_RANGE",
  ClassroomEditedSessions = "SET_EDITED_SESSIONS_CLASSROOM",
  CleanForm = "CLEAN_FORM",
  CleanFormCreateSession = "CLEAN_FORM_NEW_SESSION",
  CleanUnsavableSessionsToCreate = "CLEAN_UNSAVABLE_SESSIONS_TO_CREATE",
  ForkSelectedSessions = "FORK_SELECTED_SESSIONS",
  FormCancel = "SET_SESSION_CANCEL",
  FormError = "SET_EDITED_ERROR",
  InstructorEditedSessions = "SET_EDITED_SESSIONS_INSTRUCTOR",
  IntervalsEditedSessions = "SET_EDITED_SESSIONS_INTERVAL",
  NotSelectedCreateSession = "NOT_SELECTED_NEW_SESSION",
  RemoveSelectedSessions = "REMOVE_SAVED_SESSION",
  SavedNewSession = "SET_SAVE_NEW_SESSION",
  SavedSessions = "SET_SAVED_SESSIONS_FORM",
  SelectedCreateSession = "SELECT_CREATE_SESSION",
  SelectedSaveSession = "SET_SELECTED_NEW_SESSION",
  SelectedSessions = "SET_SELECTED_SESSIONS_FORM",
  SelectedSessionsBySection = "SET_SELECTED_SESSIONS_BY_SECTION_FORM",
  SetRemovedWeekIdsBySessionId = "SET_REMOVED_WEEK_IDS_BY_SESSION_ID",
  SetSessionFromLocationSelected = "SET_SESSION_FROM_LOCATION_SELECTED",
  SetSessionRecommendationIdSelected = "SET_SESSION_RECOMMENDATION_ID_SELECTED",
  ToggleAllSessionsSelection = "TOGGLE_ALL_SESSIONS_SELECTION",
  UndoEditionOverSelectedSessions = "UNDO_EDITION_OF_SELECTED_SESSIONS",
  SetBlockingThreshold = "SET_BLOCKING_THRESHOLD",
}

type FormPayload = {
  [Types.AddSessionsToCreateBySelectedDays]: undefined;
  [Types.AddSessionsToCreateWithNoDays]: undefined;
  [Types.AddSessionsToCreate]: "new" | "cloned";
  [Types.AssignmentEdited]: Assignment;
  [Types.BlocksEditedSessions]: EditedSession;
  [Types.ClassroomEditedSessions]: EditedSession;
  [Types.CleanFormCreateSession]: undefined;
  [Types.CleanForm]: boolean;
  [Types.CleanUnsavableSessionsToCreate]: undefined;
  [Types.ForkSelectedSessions]: undefined;
  [Types.FormCancel]: (Session | EditedSession)[];
  [Types.FormError]: ErrorForm[];
  [Types.InstructorEditedSessions]: EditedSession;
  [Types.IntervalsEditedSessions]: EditedSession & {
    weeks?: { [weekId: string | number]: Week };
    originalWeeksBySessionId?: { [key: string]: string[] };
  };
  [Types.NotSelectedCreateSession]: boolean;
  [Types.RemoveSelectedSessions]: (Session | EditedSession)[];
  [Types.SavedNewSession]: EditedSession;
  [Types.SavedSessions]: EditedSession[];
  [Types.SelectedCreateSession]: Section;
  [Types.SelectedSaveSession]: EditedSession;
  [Types.SelectedSessionsBySection]: SelectedSessionsBySectionPayload;
  [Types.SelectedSessions]: Session | EditedSession;
  [Types.SetRemovedWeekIdsBySessionId]: { [key: string]: string[] };
  [Types.SetSessionFromLocationSelected]: boolean;
  [Types.SetSessionRecommendationIdSelected]: string;
  [Types.ToggleAllSessionsSelection]: { currentCheckbox: boolean; link: LinkPageType };
  [Types.UndoEditionOverSelectedSessions]: EditedSession[];
  [Types.SetBlockingThreshold]: string | null;
};

export type FormActions = ActionMap<FormPayload>[keyof ActionMap<FormPayload>];

export const formReducer = (state: FormPageType, link: LinkPageType, action: FormActions) => {
  switch (action?.type) {
    case Types.AddSessionsToCreate:
      return addSessionsToCreate(state, action?.payload);
    case Types.AddSessionsToCreateBySelectedDays:
      return addSessionsToCreateBySelectedDays(link, state);
    case Types.AddSessionsToCreateWithNoDays:
      return addSessionsToCreateWithNoDays(state);
    case Types.AssignmentEdited:
      return assignmentEdited(action?.payload, state);
    case Types.BlocksEditedSessions:
      return blocksEditedSessions(action?.payload, state);
    case Types.ClassroomEditedSessions:
      return classroomEditedSessions(action?.payload, state);
    case Types.CleanForm:
      return cleanForm(state);
    case Types.CleanFormCreateSession:
      return cleanFormCreateSession(state);
    case Types.CleanUnsavableSessionsToCreate:
      return cleanUnsavableSessionsToCreate(state);
    case Types.ForkSelectedSessions:
      return forkSelectedSessions(link, state);
    case Types.FormCancel:
      return formCancel(action?.payload, link, state);
    case Types.FormError:
      return formError(action?.payload, state);
    case Types.InstructorEditedSessions:
      return instructorEditedSessions(action?.payload, state);
    case Types.IntervalsEditedSessions:
      return intervalsEditedSessions(action?.payload, state);
    case Types.NotSelectedCreateSession:
      return notSelectedCreateSession(state);
    case Types.RemoveSelectedSessions:
      return removeSelectedSessions(action?.payload, state);
    case Types.SavedNewSession:
      return savedNewSession(action?.payload, state);
    case Types.SavedSessions:
      return savedSessions(link, state);
    case Types.SelectedCreateSession:
      return selectedCreateSession(action?.payload, link, state);
    case Types.SelectedSaveSession:
      return selectedSaveSession(action?.payload, state);
    case Types.SelectedSessions:
      return selectedSessions(action?.payload, link, state);
    case Types.SelectedSessionsBySection:
      return selectedSessionsBySection(action?.payload, link, state);
    case Types.SetRemovedWeekIdsBySessionId:
      return setRemovedWeekIdsBySessionId(action?.payload, state);
    case Types.SetSessionFromLocationSelected:
      return setSessionFromLocationSelected(action?.payload, state);
    case Types.SetSessionRecommendationIdSelected:
      return setSessionRecommendationIdSelected(action?.payload, state);
    case Types.ToggleAllSessionsSelection:
      return toggleAllSessionsSelection(action?.payload, state);
    case Types.UndoEditionOverSelectedSessions:
      return undoEditionOverSelectedSessions(action?.payload, state);
    case Types.SetBlockingThreshold:
      return setBlockingThreshold(action?.payload, state);
    default:
      return state;
  }
};
