import React, { useState, useEffect } from "react";
import { Accordion, Checkbox, Button, Text, Heading, Tooltip } from "@foris/avocado-suite";
import { TermPart } from "@models/ISchema";
import { Week, TermPartsByCategory } from "../ISections";
import css from "./weeksMultiSelect.module.scss";
import { useTranslation } from "react-i18next";

interface IWeeksMultiSelectProps {
  weeks: Week[];
  applySelectionOnContext: (weeksToCheck: { [key: number]: boolean }, checked?: boolean) => void;
  highlightSelectionOnContext: (weekIdsToHighlight: { [key: number]: boolean }) => void;
  onAllWeeksSelected: () => void;
  termPartsByCategory: TermPartsByCategory[];
  disabled?: boolean;
}

const WeeksMultiSelect: React.FC<IWeeksMultiSelectProps> = ({
  weeks,
  applySelectionOnContext,
  highlightSelectionOnContext,
  onAllWeeksSelected,
  termPartsByCategory,
  disabled,
}) => {
  const [numberOfHighlightedWeeks, setNumberOfHighlightedWeeks] = useState<number>(0);
  const [termPartsSelected, setTermPartsSelected] = useState<{ [key: string]: boolean }>(null);
  const [allWeeksSelected, setAllWeeksSelected] = useState<boolean>(false);

  const { t } = useTranslation();

  const weeksByTermPartId = weeks?.reduce((acc, week) => {
    if (!week?.termParts) return acc;
    week.termParts.forEach(({ id }) => {
      if (id in acc) acc[id].push(week);
      else acc[id] = [week];
    });
    return acc;
  }, {});

  const initializeTermPartsChecks = () => {
    const checks: { [key: number]: boolean } = {};
    termPartsByCategory?.forEach(category => {
      category?.termParts?.forEach(({ id }) => (checks[id] = false));
    });
    setTermPartsSelected(checks);
    setNumberOfHighlightedWeeks(0);
  };

  const handleTermPartCheckboxClick = ({ id }: TermPart) => () => {
    if (!termPartsSelected) return;
    setTermPartsSelected(prevState => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const applySelection = (checked: boolean) => {
    if (!termPartsSelected) return;

    // Determine weeks to check based on selection
    const weeksToCheck = allWeeksSelected
      ? // If all weeks are selected, filter active and highlighted weeks
        [...weeks]
          .filter(week => week.isInTerm && week.highlight)
          .reduce((acc, week) => {
            acc[week.id] = true;
            return acc;
          }, {})
      : // Otherwise, iterate over selected term parts and mark their weeks
        Object.entries(termPartsSelected).reduce((acc, [termPartId, checked]) => {
          if (checked) {
            weeksByTermPartId[termPartId].forEach(({ id }) => {
              acc[id] = true;
            });
          }
          return acc;
        }, {});

    initializeTermPartsChecks();
    setAllWeeksSelected(false);
    applySelectionOnContext(weeksToCheck, checked);
  };

  const highlightWeeksBySelectedTermParts = () => {
    if (termPartsSelected === null) return;
    const weekIdsToHighlight = Object.entries(termPartsSelected)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .filter(([_, isChecked]) => isChecked)
      .reduce((acc, [termPartId]) => {
        (weeksByTermPartId[termPartId] || []).forEach(({ id }) => {
          acc[id] = true;
        });
        return acc;
      }, {});
    setNumberOfHighlightedWeeks(Object.keys(weekIdsToHighlight).length);
    highlightSelectionOnContext(weekIdsToHighlight);
  };

  useEffect(initializeTermPartsChecks, [termPartsByCategory]);

  useEffect(() => {
    if (allWeeksSelected === null) return;

    if (!allWeeksSelected) {
      highlightWeeksBySelectedTermParts();
    } else {
      setNumberOfHighlightedWeeks([...weeks]?.filter(week => week?.isInTerm).length);
      onAllWeeksSelected();
    }
  }, [allWeeksSelected]);

  useEffect(() => {
    if (!allWeeksSelected) highlightWeeksBySelectedTermParts();
  }, [termPartsSelected]);

  const termPartLabel = (termPart: TermPart, hasRelatedWeeks: boolean) => {
    return (
      <Tooltip
        className={css.termPartLabel}
        label={`Sin semanas relacionadas. Código ${termPart?.code}`}
        visible={!hasRelatedWeeks ? undefined : false}
      >
        {termPart.name}
      </Tooltip>
    );
  };

  return (
    <Accordion
      className={css.multiSelect}
      title={t("assignation-edit.form.repeat-section.weeks-multi-select.title")}
      disabled={disabled}
    >
      <section className={css.multiSelect_termPartByCategory}>
        <Heading type="h4">
          {t("assignation-edit.form.repeat-section.weeks-multi-select.other-options")}
        </Heading>

        <div className={css.termParts}>
          <Checkbox
            disabled={disabled}
            className={css.termParts_item}
            labelRight={t(
              "assignation-edit.form.repeat-section.weeks-multi-select.all-weeks-option",
            )}
            checked={allWeeksSelected}
            onChange={() => setAllWeeksSelected(!allWeeksSelected)}
          />
        </div>
      </section>

      {termPartsByCategory?.map(item => (
        <section key={item.category.id} className={css.multiSelect_termPartByCategory}>
          <Heading type="h4">{item.category.name}</Heading>
          <div className={css.termParts}>
            {item.termParts.map(termPart => {
              const hasRelatedWeeks = !!weeksByTermPartId?.[termPart?.id];

              return (
                <Checkbox
                  className={css.termParts_item}
                  key={termPart.id}
                  disabled={disabled || !hasRelatedWeeks}
                  labelRight={termPartLabel(termPart, hasRelatedWeeks)}
                  checked={termPartsSelected ? termPartsSelected[termPart.id] : false}
                  onChange={handleTermPartCheckboxClick(termPart)}
                />
              );
            })}
          </div>
        </section>
      ))}

      <Text className={css.multiSelect_count} type="sm">
        {numberOfHighlightedWeeks === 1
          ? t("assignation-edit.form.repeat-section.weeks-multi-select.single-week-selected")
          : t("assignation-edit.form.repeat-section.weeks-multi-select.weeks-selected", {
              count: numberOfHighlightedWeeks,
            })}
      </Text>

      <section className={css.multiSelect_buttonsSection}>
        <Button
          variant="secondary"
          disabled={disabled || numberOfHighlightedWeeks === 0}
          onClick={() => applySelection(false)}
        >
          {t("assignation-edit.form.repeat-section.weeks-multi-select.discard-selection")}
        </Button>
        <Button
          variant="primary"
          disabled={disabled || numberOfHighlightedWeeks === 0}
          onClick={() => applySelection(true)}
        >
          {t("assignation-edit.form.repeat-section.weeks-multi-select.apply-selection")}
        </Button>
      </section>
    </Accordion>
  );
};

export default WeeksMultiSelect;
