import React, { useState, useEffect, FC } from "react";
import cx from "classnames";
import { useParams } from "react-router-dom";
import { useApolloClient } from "react-apollo";
import { Select } from "@foris/avocado-suite";
import { classroomQuery } from "../graphql/classroom.queries";
import { ISelectorReturn, IOption } from "./ISelectors";
import { ClassroomType, DataQuery } from "@models/ISchema";
import { IParams } from "@models/IParams";
import css from "./selectors.module.scss";

interface ClassRoomSelectProps extends Omit<ISelectorReturn, "label"> {
  label?: boolean;
  labelValue?: string;
}

const ClassRoomSelect: FC<ClassRoomSelectProps> = ({
  onCallback,
  className,
  placeholder,
  label,
  labelValue,
}) => {
  // States
  const [optionsClassroom, setOptionsClassroom] = useState([]);
  const [valueClassroom, setValueClassroom] = useState(null);

  // Hooks
  const client = useApolloClient();
  const { scenario }: IParams = useParams();

  const variablesClassRoom = {
    query: classroomQuery,
    variables: {
      scenarioId: scenario,
    },
  };

  const setClassroomOptions = async (data: any) => {
    if (data && data.data) {
      const dataQuery: DataQuery = data.data.data;
      const optCompuses = [];
      if (dataQuery.classroomTypes.items.length > 0) {
        dataQuery.classroomTypes.items.forEach((classroom: ClassroomType) => {
          optCompuses.push({ label: classroom.name, value: classroom.id });
        });
      }
      setOptionsClassroom(optCompuses);
    }
  };

  const getClassroomOptions = async () => {
    try {
      const data = await client.query(variablesClassRoom);
      await setClassroomOptions(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getCacheClassroomOptions = async () => {
    try {
      const data = await client.readQuery(variablesClassRoom);
      await setClassroomOptions(data);
    } catch (error) {
      getClassroomOptions();
    }
  };

  useEffect(() => {
    if (optionsClassroom.length === 0) {
      getCacheClassroomOptions();
    }
  });
  return (
    <div className={cx(css.selectors, className)}>
      <Select
        label={label ? labelValue : undefined}
        placeholder={placeholder}
        options={optionsClassroom}
        isClearable
        value={valueClassroom}
        onChange={(value: IOption) => {
          if (value) {
            setValueClassroom(value);
            onCallback(value);
          } else {
            setValueClassroom("");
            onCallback(value);
          }
        }}
      />
    </div>
  );
};

export default ClassRoomSelect;
