import React from "react";
import { useTranslation } from "react-i18next";
import { Option } from "react-select/lib/filters";
import useGetLevelOptions from "@modules/shared/selectors/hooks/useGetLevelOptions";
import AdvanceSelector from "../advance-selector";

interface AdvancedLevelSelectorProps {
  onChange?: (option: Option) => void;
  scenario: string;
}

const AdvancedLevelSelector = ({ scenario, onChange }: AdvancedLevelSelectorProps) => {
  const { loadOptions } = useGetLevelOptions({
    scenario,
  });

  const { t } = useTranslation();

  return (
    <AdvanceSelector
      isPaginated
      dimension={t("advanced-search.instructor.forms.select-wrapper.level.label")}
      selectPlaceholder={t("advanced-search.instructor.forms.select-wrapper.level.placeholder")}
      loadOptions={loadOptions}
      onChange={onChange}
      notShowLabel
    />
  );
};

export default AdvancedLevelSelector;
