import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "@foris/avocado-icons";
import { RoundButton, Table, Pill, TableColumn } from "@foris/avocado-suite";
import { CustomPackage, LinkPackageObj } from "../context/types";
import { getColumnCell, getLinkCell } from "@dimensions/utils/tableUtils";
import { key } from "../utils";
import useContextUrl from "@common/hooks/useContextUrl";
import css from "./packagesTable.module.scss";

interface Props {
  linkPackages: CustomPackage[];
  onDelete: (customPackage: CustomPackage) => void;
  linkId: string;
  deletions: LinkPackageObj;
  linkPackagesErrors: { [key: string]: boolean };
  hasEditPermissions?: boolean;
}

const headers = {
  code: "bundle.packages-edition.table.header.code",
  term: "bundle.packages-edition.table.header.term",
  campus: "bundle.packages-edition.table.header.campus",
  modality: "bundle.packages-edition.table.header.modality",
  shift: "bundle.packages-edition.table.header.shift",
  program: "bundle.packages-edition.table.header.program",
  curriculum: "bundle.packages-edition.table.header.curriculum",
  level: "bundle.packages-edition.table.header.level",
  packageIndex: "bundle.packages-edition.table.header.package-index",
  vacancies: "bundle.packages-edition.table.header.vacancies",
  status: "bundle.packages-edition.table.header.status",
};

const desktopSharedOptions = {
  headCellStyles: {
    minWidth: "80px",
  },
};

const PackagesTable = (props: Props) => {
  const {
    linkPackages,
    onDelete,
    linkId,
    deletions,
    linkPackagesErrors,
    hasEditPermissions,
  } = props;

  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { origin, scenario, workspace } = useContextUrl();
  const basePackageRoute = `/scheduler/editor/package/${workspace}/${scenario}/${origin}`;

  const getLeftActionColumn = (id: string, isNew = false) => {
    if (key(id, linkId) in linkPackagesErrors) {
      return <Icon name="alert-triangle" size="md" />;
    }

    if (key(id, linkId) in deletions) {
      return <Icon name="trash" size="md" />;
    }

    if (isNew) {
      return <Icon name="circle-plus" size="md" filled />;
    }

    return "";
  };

  const hasDeletionsOrErrorsOrNew = useMemo(() => {
    const hasNew = linkPackages.some(({ isNew }) => isNew);

    return (
      hasNew || Object.keys(deletions).length > 0 || Object.keys(linkPackagesErrors).length > 0
    );
  }, [deletions, linkPackagesErrors]);

  const newcolumns = useMemo(() => {
    const columns = [
      {
        key: "code",
        header: t(headers.code),
        renderCell: ({ code, id }: CustomPackage) => getLinkCell(basePackageRoute, id, code),
        desktopOptions: desktopSharedOptions,
      },
      {
        key: "term",
        header: t(headers.term),
        renderCell: ({ population }: CustomPackage) => getColumnCell(population?.term?.name),
        desktopOptions: desktopSharedOptions,
      },
      {
        key: "campus",
        header: t(headers.campus),
        renderCell: ({ population }: CustomPackage) => getColumnCell(population?.campus?.code),
        desktopOptions: desktopSharedOptions,
      },
      {
        key: "modality",
        header: t(headers.modality),
        renderCell: ({ population }: CustomPackage) => getColumnCell(population?.modality?.code),
        desktopOptions: desktopSharedOptions,
      },
      {
        key: "shift",
        header: t(headers.shift),
        renderCell: ({ population }: CustomPackage) => getColumnCell(population?.shift?.code),
        desktopOptions: desktopSharedOptions,
      },
      {
        key: "program",
        header: t(headers.program),
        renderCell: ({ population }: CustomPackage) => getColumnCell(population?.program?.code),
        desktopOptions: desktopSharedOptions,
      },
      {
        key: "curriculum",
        header: t(headers.curriculum),
        renderCell: ({ population }: CustomPackage) => getColumnCell(population?.curriculum),
        desktopOptions: desktopSharedOptions,
      },
      {
        key: "level",
        header: t(headers.level),
        renderCell: ({ population }: CustomPackage) => getColumnCell(population?.level),
        desktopOptions: desktopSharedOptions,
      },
      {
        key: "packageIndex",
        header: t(headers.packageIndex),
        renderCell: ({ index }: CustomPackage) => getColumnCell(index),
        desktopOptions: desktopSharedOptions,
      },
      {
        key: "vacancies",
        header: t(headers.vacancies),
        renderCell: ({ vacancies }: CustomPackage) => getColumnCell(vacancies),
        desktopOptions: desktopSharedOptions,
      },
      {
        key: "status",
        header: t(headers.status),
        renderCell: ({ isPublished }: CustomPackage) => (
          <Pill className={css.statusPill} icon={isPublished ? "check" : undefined}>
            {isPublished
              ? t("bundle.packages-edition.table.body.status.published")
              : t("bundle.packages-edition.table.body.status.unpublished")}
          </Pill>
        ),
      },
    ];

    if (hasDeletionsOrErrorsOrNew) {
      columns.unshift({
        key: "leftActions",
        header: "",
        renderCell: (data: CustomPackage) => <>{getLeftActionColumn(data?.id, data?.isNew)}</>,
        desktopOptions: {
          headCellStyles: {
            minWidth: "50px",
            width: "50px",
          } as any,
        },
      });
    }

    return columns;
  }, [basePackageRoute, hasDeletionsOrErrorsOrNew, linkPackagesErrors, language]);

  return (
    <Table
      data={linkPackages}
      columns={newcolumns as TableColumn[]}
      rightActions={
        hasEditPermissions && [
          {
            renderContent(row) {
              const packageRow = row as CustomPackage;

              return (
                <RoundButton
                  size="sm"
                  variant="ghost"
                  icon="circle-minus"
                  onClick={() => onDelete(packageRow)}
                  disabled={packageRow?.isPublished}
                />
              );
            },
          },
        ]
      }
    />
  );
};

export default PackagesTable;
