import React, { useState } from "react";
import { useQuery } from "react-apollo";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import Layout from "@common/layout/Layout";
import ClassRoomTable from "./ClassRoomTable";
import classRoomsCubeQuery from "../graphql/classRoomsCubeQuery.graphql";
import classroomsFilterQuery from "../graphql/classroomsFilterQuery.graphql";
import { ClassroomPage } from "@models/ISchema";
import useGetQueryVariables from "@dimensions/hooks/useGetQueryVariables";

const ClassRoomSearch: React.FC = () => {
  // State for managing current page and size per page for pagination
  const [page, setPageCount] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(20);

  // Using React Router's useLocation hook to get the query parameters from the URL
  const location = useLocation();
  const params: queryString.ParsedQuery<string> = queryString.parse(location?.search); // Parse query string from the URL

  // Get the query variables using the custom hook, based on the URL parameters and pagination settings
  const variables = useGetQueryVariables(params, page, undefined, sizePerPage);

  // Choose the appropriate query based on the 'advance' parameter from the URL
  const querySearch = params?.advance ? classroomsFilterQuery : classRoomsCubeQuery;

  // Fetch data using the Apollo useQuery hook with the chosen query and variables
  const { loading, error, data } = useQuery(querySearch, { variables });

  // Default to null classroom data
  let classroom: ClassroomPage = null;

  // Assign the fetched data depending on the query used
  if (params?.advance) {
    classroom = data?.filter?.classrooms; // When using advanced search query
  } else {
    classroom = data?.cube?.classrooms; // When using basic cube query
  }

  // Render the Layout component and pass the classroom data to the ClassRoomTable component for display
  return (
    <Layout contextSearch>
      <ClassRoomTable
        data={classroom?.items} // Items from classroom data
        search={params?.search?.toString()} // Search query from URL
        pageInfo={classroom?.pageInfo} // Page information for pagination
        setPage={(value: number) => setPageCount(value)} // Function to update the page number
        loading={loading} // Pass loading state from useQuery
        error={error} // Pass error state from useQuery
        onChangeSizePerPage={setSizePerPage} // Function to update the size of items per page
      />
    </Layout>
  );
};

export default ClassRoomSearch;
