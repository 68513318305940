import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { Checkbox } from "@foris/avocado-suite";
import { IErrorWarning } from "@models/Errors/ErrorWarning";
import css from "./validations.module.scss";

export interface ValidationsProps {
  /** Array of errors and warnings  */
  validations: IErrorWarning[];
  /** Callback of selected check  */
  onCallback: (check: boolean) => void;
  /** overwrite className */
  className?: string;
}

const Validations: FC<ValidationsProps> = ({ onCallback, validations, className }) => {
  // State for check
  const [check, setCheck] = useState(false);
  // Hooks for translation
  const { t } = useTranslation();

  // Filter array by type ErrorWarning
  const errors = validations.filter(error => error.type === "ERROR");
  const warnings = validations.filter(warning => warning.type === "WARNING");

  /**
   * set local state check and return this value
   * @param value "value of check"
   */
  const onChangeCheck = (value: boolean) => {
    setCheck(value);
    onCallback(value);
  };

  return (
    <>
      <ul className={cx(css.list_validation, className && className, "container-row")}>
        {errors.map((error: IErrorWarning, indexError: number) => (
          <li
            key={indexError}
            className={cx(css.list_validation_item, css.list_validation_item__error, "col_12")}
          >
            {error.message}
          </li>
        ))}
        {warnings.map((warning: IErrorWarning, indexWarning: number) => (
          <li key={indexWarning} className={cx(css.list_validation_item, "col_12")}>
            {warning.message}
          </li>
        ))}
      </ul>
      {warnings.length > 0 && errors.length === 0 && (
        <Checkbox
          checked={check}
          className={css.list_validation_checkbox}
          labelRight={t("create-group-link.step-2.component.checkbox.label")}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            onChangeCheck(event.target.checked);
          }}
        />
      )}
    </>
  );
};

export default Validations;
