import React, { useMemo } from "react";
import cx from "classnames";
import { map, pipe, propOr, sort } from "ramda";
import { Classroom, GroupScheduleSummary, Instructor } from "@models/ISchema";
import css from "./groupDetail.module.scss";

interface Props {
  schedules: GroupScheduleSummary[];
  primaryInstructorId: string;
}

interface IStringifiedSchedule {
  days: string;
  hourTime: string;
  classrooms: string;
  dateRange: string;
}

const ScheduleSummary = ({ schedules, primaryInstructorId }: Props) => {
  // for every schedule, sort the instructors by `primaryInstructorId` first
  const instructorsBySchedule = map(
    pipe(
      propOr([], "instructors"),
      sort((a: Instructor, b: Instructor) => {
        if (a?.id === primaryInstructorId) return -1;
        if (b?.id === primaryInstructorId) return 1;
        return 0;
      }),
    ),
    schedules ?? [],
  );

  const groupedSchedules = useMemo(() => {
    const stringifySchedules: IStringifiedSchedule[] = schedules?.map(
      (schedule: GroupScheduleSummary) => {
        const days = schedule?.days
          ?.map(day => day.code)
          ?.filter(code => !!code)
          ?.join(", ");
        const hourTime = schedule?.schedule;
        const dateRange = schedule?.dateRange;
        const classrooms = schedule?.classrooms
          ?.map((classroom: Classroom) => {
            const building = classroom?.building?.code;
            const campus = classroom?.building?.campus?.code;
            const capacity = classroom?.capacity;

            if (!building && !campus && !classroom?.code) {
              return null;
            }

            return `${classroom?.code || "-"} | ${building || "-"} | ${campus ||
              "-"} (${capacity})`;
          })
          ?.filter(classroom => !!classroom)
          ?.join(", ");

        return { days, hourTime, classrooms, dateRange };
      },
    );

    // group schedules by `datetime`, `classrooms` and `instructors`
    const newGroupedSchedules: Record<string, IStringifiedSchedule[]> = {};

    (stringifySchedules ?? []).forEach((schedule: IStringifiedSchedule) => {
      const key = `${schedule?.days}${schedule?.hourTime}-${schedule?.classrooms}`;
      if (!newGroupedSchedules[key]) {
        newGroupedSchedules[key] = [];
      }
      newGroupedSchedules[key].push(schedule);
    });

    if (!Object.keys(newGroupedSchedules).length) {
      return {
        empty: [
          {
            days: null,
            hourTime: null,
            classrooms: null,
            dateRange: null,
          },
        ],
      };
    }

    return newGroupedSchedules ?? {};
  }, [schedules]);

  return (
    <>
      {Object.entries(groupedSchedules)?.map(([key, stringifySchedules], idx) => {
        const days = stringifySchedules[0]?.days;
        const hourTime = stringifySchedules[0]?.hourTime;
        const dateRange = stringifySchedules.map(schedule => schedule?.dateRange).join(" | ");
        const classrooms = stringifySchedules[0]?.classrooms;

        return (
          <li key={key} className={"container-row"}>
            <ul className={css.schedule_item}>
              <li>
                <h3 className={css.schedule_item_title}>Periodo</h3>
                <p className={css.schedule_item_value}>{dateRange || "Sin periodo"}</p>
              </li>
            </ul>

            <ul className={css.schedule_item}>
              <li>
                <h3 className={css.schedule_item_title}>Horario</h3>
                <p className={css.schedule_item_value}>
                  {days && hourTime ? `${days} ${hourTime}` : "Sin horarios"}
                </p>
              </li>
            </ul>

            <ul className={css.schedule_item}>
              <li>
                <h3 className={css.schedule_item_title}>Salas</h3>
                <p className={css.schedule_item_value}>{classrooms || "Sin salas"}</p>
              </li>
            </ul>

            <ul className={cx(css.schedule_item, css.instructors)}>
              <h3 className={css.schedule_item_title}>Docentes</h3>
              {instructorsBySchedule[idx]?.length ? (
                instructorsBySchedule[idx]?.map((instructor: Instructor) => (
                  <li key={`${idx}-${instructor?.id}`} className={css.instructor}>
                    <p className={css.instructor_name}>{instructor?.name || "Sin docentes"}</p>
                    {instructor?.id === primaryInstructorId && (
                      <div className={css.instructor_mainLabel}>Principal</div>
                    )}
                  </li>
                ))
              ) : (
                <li className={css.instructor}>
                  <p className={css.instructor_name}>Sin docentes</p>
                </li>
              )}
            </ul>
          </li>
        );
      })}
    </>
  );
};

export default ScheduleSummary;
