import React from "react";
import { useTranslation } from "react-i18next";
import { Package } from "@models/ISchema";
import { Pill, Tooltip } from "@foris/avocado-suite";
import { Icon } from "@foris/avocado-icons";
import SystemLabel from "@common/components/system-label";
import css from "./../GridSections/package.module.scss";
import headerCss from "../../../../common/components/Header/header.module.scss";

interface PackageHeaderSubtitleProps {
  packageData?: Package;
  headerDataAdapted?: any;
}

const PackageHeaderSubtitle = ({ packageData, headerDataAdapted }: PackageHeaderSubtitleProps) => {
  const { t } = useTranslation();

  return (
    <div className={headerCss.headerSubtitle}>
      {packageData?.isPublished ? (
        <div className={css.headerPills}>
          <Tooltip
            label={t("dimension.package.header.published-package.tooltip")}
            placement="topLeft"
          >
            <Pill className={css.headerPills_pill}>
              <Icon name="check" size="sm" />{" "}
              {t("dimension.package.header.published-package.status")}
            </Pill>
          </Tooltip>

          <Pill className={css.headerPills_pill}>
            <Icon name={packageData?.isVisible ? "open-eye" : "closed-eye"} size="sm" />
            {packageData?.isVisible
              ? t("dimension.package.header.published-package.visible")
              : t("dimension.package.header.published-package.no-visible")}
          </Pill>
        </div>
      ) : null}

      <div className={css.headerPills}>
        <Pill color="gray-70" textColor="gray-10">
          {t("dimension.package.header.more-details.period")}: {packageData?.population?.term?.code}
        </Pill>
        <Pill color="gray-70" textColor="gray-10">
          {t("dimension.package.header.more-details.total-credits")}:{" "}
          {packageData?.packageTotalCredits}
        </Pill>
      </div>
      {headerDataAdapted?.systemLabels?.length > 0 && (
        <div className={headerCss.headerSubtitle_systemLabels}>
          {headerDataAdapted?.systemLabels?.map(item => (
            <SystemLabel key={item?.id} label={item?.name} />
          ))}
        </div>
      )}
    </div>
  );
};

export default PackageHeaderSubtitle;
