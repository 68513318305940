import { IGroup, IGroupValue } from "@modules/vacancies/contextVacancies/ContextVacancies";
import { useEffect, useState } from "react";

interface useGroupsInputStateProps {
  groups: IGroup[];
  linkId: string;
  onChange?: (values: IGroupValue[]) => void;
}

/**
 * Custom hook to manage the state of group inputs.
 *
 * @param {useGroupsInputStateProps} [props] - Optional properties to initialize the hook.
 * @param {IGroup[]} [props.groups=[]] - Array of group objects.
 * @param {string} [props.linkId] - Identifier for the link.
 *
 * @returns {Object} - Returns an object containing the current state of group vacancies and a function to handle input changes.
 * @returns {IGroupValue[]} countVacancies - The current state of group vacancies.
 * @returns {Function} onChangeInput - Function to handle input changes.
 *
 * @function onChangeInput
 * @param {any} value - The new value for the input.
 * @param {string} groupId - The ID of the group to update.
 *
 * @function setInitialState
 * Initializes the state with the initial group vacancies.
 *
 * @example
 * const { countVacancies, onChangeInput } = useGroupsInputState({ groups, linkId });
 *
 * // To handle input change
 * onChangeInput(newValue, groupId);
 */
const useGroupsInputState = (props?: useGroupsInputStateProps) => {
  const { groups = [], linkId, onChange } = props;
  const [countVacancies, setCountVacancies] = useState(null);

  const onChangeInput = (value: any, groupId: string) => {
    const newValue = value ? parseInt(value) : 0;
    setCountVacancies((prevState: IGroupValue[]) => {
      const updatedVacancies = [...prevState];
      const indexGroup = updatedVacancies.findIndex(
        (group: IGroupValue) => group.group.id === groupId,
      );

      updatedVacancies[indexGroup] = {
        ...updatedVacancies[indexGroup],
        value: newValue,
      };

      onChange?.(updatedVacancies);

      return updatedVacancies;
    });
  };

  const setInitialState = () => {
    const countGroupVacancies = groups.map((group: IGroup) => ({
      linkId,
      group,
      value: group.vacancies,
    }));

    setCountVacancies(countGroupVacancies);
  };

  useEffect(() => {
    if (!countVacancies) {
      setInitialState();
    }
  }, [groups]);

  return { countVacancies, onChangeInput };
};

export default useGroupsInputState;
