import React, { useState } from "react";
import { useQuery } from "react-apollo";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import Layout from "@common/layout/Layout";
import LinkTable from "./LinkTable";
import linksCubeQuery from "../graphql/linksCubeQuery.graphql";
import linksFilterQuery from "../graphql/linksFilterQuery.graphql";
import useGetQueryVariables from "@dimensions/hooks/useGetQueryVariables";
import { LinkPage } from "@models/ISchema";

const LinkSearch: React.FC = () => {
  // Using React Router's useLocation hook to get the query parameters from the URL
  const location = useLocation();
  const params: queryString.ParsedQuery<string> = queryString.parse(location.search);

  // State for managing current page and size per page for pagination
  const [page, setPageCount] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(20);

  // Get the query variables using the custom hook, based on the URL parameters and pagination settings
  const variables = useGetQueryVariables(params, page, true, sizePerPage);

  // Choose the appropriate query based on the 'advance' parameter from the URL
  const querySearch = params?.advance ? linksFilterQuery : linksCubeQuery;

  // Fetch data using the Apollo useQuery hook with the chosen query and variables
  const { loading, error, data } = useQuery(querySearch, { variables });

  // Assign the fetched data depending on the query used
  let links: LinkPage = data?.cube?.links;
  if (params?.advance) links = data?.filter?.links;

  // Render the Layout component and pass the classroom data to the LinkTable component for display
  return (
    <Layout contextSearch>
      <LinkTable
        data={links?.items} // Items from links data
        search={params?.search?.toString()} // Search query from URL
        pageInfo={links?.pageInfo} // Page information for pagination
        setPage={(value: number) => setPageCount(value)} // Function to update the page number
        loading={loading} // Pass loading state from useQuery
        error={error} // Pass error state from useQuery
        onChangeSizePerPage={setSizePerPage} // Function to update the size of items per page
      />
    </Layout>
  );
};

export default LinkSearch;
