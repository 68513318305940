import React, { useEffect, useContext } from "react";
import { lensPath } from "ramda";
import { useParams } from "react-router-dom";
import cx from "classnames";
import { Button, Icon, Loading, Switch } from "@foris/avocado-ui";
import Layout from "@common/layout/Layout";
import { IParams } from "@models/IParams";
import { ContextEDH } from "@context/ContextEDH";
import * as mouseflow from "@utils/mouseflow";
import { useGetGroupsChanges } from "../../hooks/useGetGroupChanges";
import GroupsHistoryTable from "../../components/GroupsHistoryTable/GroupsHistoryTable";
import { Context } from "../../context/GroupsManagerContext";
import { Types as FilterTypes } from "../../context/filters.reducer";
import { Types as TableFiltersTypes } from "../../context/tableFilters.reducer";
import { FiltersReducerType, TableFiltersReducerType } from "../../context/types";
import { GroupsManagerGroupChangesOrderByFieldsEnum, OrderByDirection } from "@models/ISchema";
import buildReport from "../../utils/reports";
import css from "./changeHistory.module.scss";

const ChangeHistory: React.FC = () => {
  const { origin, scenario, workspace }: IParams = useParams();
  const { state, dispatch } = useContext(Context);
  const { state: outerState } = useContext(ContextEDH);
  const [groupChanges, loading, pageInfo, getGroupChanges] = useGetGroupsChanges(scenario, origin);

  const requestGroupChanges = (
    owned: boolean,
    page: number,
    orderBy: TableFiltersReducerType["orderBy"],
    searchBy: TableFiltersReducerType["searchBy"],
  ) => {
    getGroupChanges(page, owned, undefined, orderBy, searchBy, {
      isGroupedByClientCode: {
        is: false,
      },
    });
  };

  useEffect(() => {
    requestGroupChanges(
      state?.filters?.owned,
      1,
      state?.tableFilters?.orderBy,
      state?.tableFilters?.searchBy,
    );
    // set `owned` to `false` on mounted
    dispatch({
      type: FilterTypes.SetAdvancedFilter,
      payload: { lens: lensPath(["owned"]), value: false },
    });

    // set the group's CRN as the default orderBy
    dispatch({
      type: TableFiltersTypes.SetOrderBy,
      payload: {
        header: "Fecha",
        direction: OrderByDirection.Desc,
        field: GroupsManagerGroupChangesOrderByFieldsEnum.Date,
      },
    });
  }, []);

  return (
    <Layout contextSearch>
      {loading && <Loading />}
      {!loading && (
        <div className={cx(css.main)}>
          <header className={css.header}>
            <strong className={css.header__text}>Historial de cambios</strong>
            <Switch
              className={css.header__switch}
              labelRight="Mis cambios"
              checked={state?.filters?.owned}
              onChange={() => {
                const value = !state?.filters?.owned;
                dispatch({
                  type: FilterTypes.SetAdvancedFilter,
                  payload: { lens: lensPath(["owned"]), value },
                });
                requestGroupChanges(
                  value,
                  1,
                  state?.tableFilters?.orderBy,
                  state?.tableFilters?.searchBy,
                );
              }}
            />
          </header>
          <section className={css.download}>
            <Button
              className={css.download_button}
              onClick={() => {
                const reportLink = buildReport("changeHistory", {
                  context: { origin, scenario },
                  filters: { owned: state?.filters?.owned } as FiltersReducerType,
                });
                mouseflow.actionTag(
                  "action_groups_manager_change_history_report",
                  outerState?.base?.isMouseflowEnabled,
                );
                window.open(reportLink, "_blank");
              }}
              variant="ghost"
            >
              <Icon icon={"download"} className={css.download_icon} size={16} />
              <span className={css.download_text}>Descargar reporte</span>
            </Button>
          </section>
          <GroupsHistoryTable
            groupChanges={groupChanges}
            pageInfo={pageInfo}
            requestGroupCapacityChangeDetails={(
              page: number,
              orderBy: TableFiltersReducerType["orderBy"],
              searchBy: TableFiltersReducerType["searchBy"],
            ) => requestGroupChanges(state?.filters?.owned, page, orderBy, searchBy)}
            contextUrl={`${workspace}/${scenario}/${origin}`}
          />
        </div>
      )}
    </Layout>
  );
};

export default ChangeHistory;
