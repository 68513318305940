import React, { useMemo, useState } from "react";
import { useMutation } from "react-apollo";
import { useTranslation } from "react-i18next";
import { Loading, Button, Box, Divider, Heading } from "@foris/avocado-suite";
import { useHistory } from "react-router-dom";
import { sectionSearchMutation } from "../graphql/advanceSearch.queries";
import useContextUrl from "@common/hooks/useContextUrl";
import useNavigationUrl from "@common/hooks/useNavigationUrl";
import { TimeRange } from "@modules/shared/inputs";
import { Days, Assignment } from "@modules/shared/checks";
import EmptyResultsModal from "@modules/shared/empty-results-modal";
import AdvancedSelectorsWrapper from "@modules/advance-search/components/advanced-selectors-wrapper";
import WeeksRange from "@modules/shared/weeksRange/WeeksRange";
import { validations } from "@modules/advance-search/utils";
import { objectEmpty } from "@utils/validations";
import {
  IAvailability,
  ITimeRangeDependency,
  IWeeksRangeDependency,
  IAssignment,
} from "@modules/advance-search/IAdvanceSearch";
import { AssignmentFilterInput } from "@models/ISchema";
import css from "./advanceSearch.module.scss";

const AdvanceSearch: React.FC<any> = (props: any) => {
  // States for validations
  const [daysError, setDaysError] = useState(false);
  const [error, setError] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [timeRangeError, setTimeRangeError] = useState(false);
  const [objAvailability, setObjAvailability] = useState<IAvailability>({});
  const [objAssignment, setObjAssignment] = useState<AssignmentFilterInput>({});
  const [loading, setLoading] = useState(false);
  const [selectorsState, setSelectorsState] = useState({
    campus: null,
    department: null,
    modality: null,
    shift: null,
    program: null,
    curriculum: null,
    level: null,
    course: null,
  });
  // Hooks for navigation
  const history = useHistory();
  const [advanceSearchMutation] = useMutation(sectionSearchMutation, {});
  const { t } = useTranslation();
  const { origin, scenario } = useContextUrl();
  const { advancedSearchResultsUrl } = useNavigationUrl();

  const hasSomeFilter = useMemo(() => {
    return (
      !objectEmpty(objAvailability) ||
      !objectEmpty(objAssignment) ||
      Object.values(selectorsState).some(value => !!value)
    );
  }, [objAvailability, objAssignment, selectorsState]);

  const setDays = (value: number[]) => {
    const validation = validations.days(value, objAvailability);
    setObjAvailability(validation.availability);
    setError(validation.error);
    setTimeRangeError(validation.timeRangeError);
    setDaysError(validation.daysError);
  };

  const setTimeRange = (value: ITimeRangeDependency, error: boolean) => {
    if (error) {
      setError(true);
      return;
    }
    const validation = validations.timeRange(value, objAvailability);
    setObjAvailability(validation.availability);
    setError(validation.error);
    setTimeRangeError(validation.timeRangeError);
    setDaysError(validation.daysError);
  };

  const setWeeksRange = (values: IWeeksRangeDependency) => {
    const validation = validations.weeksRange(values, objAvailability);
    setObjAvailability(validation.availability);
  };

  const setAssignmentChecks = (values: IAssignment) => {
    const validation = validations.assignmentChecks(values, objAssignment);
    setObjAssignment(validation);
  };

  const createObjectMutation = () => {
    const filterArray = [];
    const programFilters = [];

    !objectEmpty(objAvailability) && filterArray.push(objAvailability);
    !objectEmpty(objAssignment) && filterArray.push({ assignment: { ...objAssignment } });

    selectorsState.level && filterArray.push({ level: { eq: selectorsState.level } });
    selectorsState.curriculum &&
      filterArray.push({
        course: {
          curriculum: {
            code: { eq: selectorsState.curriculum },
          },
        },
      });
    selectorsState.course && filterArray.push({ courseId: { eq: selectorsState.course } });
    selectorsState.campus && programFilters.push({ campusId: { eq: selectorsState.campus } });
    selectorsState.modality && programFilters.push({ modalityId: { eq: selectorsState.modality } });
    selectorsState.department &&
      programFilters.push({ departmentId: { eq: selectorsState.department } });
    selectorsState.shift && programFilters.push({ shiftId: { eq: selectorsState.shift } });
    selectorsState.program && programFilters.push({ code: { eq: selectorsState.program } });

    if (programFilters.length > 0) {
      filterArray.push({
        program: {
          and: programFilters,
        },
      });
    }

    const mutationFilter = {
      originId: origin,
      scenarioId: scenario,
      filter: {
        fields: {
          and: [...filterArray],
        },
      },
      pagination: {
        page: 1,
        size: 10,
      },
    };
    return mutationFilter;
  };
  const callUpdateHook = async () => {
    setLoading(true);
    try {
      const variables = createObjectMutation();
      const { data } = await advanceSearchMutation({ variables });
      setLoading(false);
      const dataItems = data.filter.createSectionFilter;
      if (dataItems.sections.items.length > 0) {
        const idSearch = dataItems.id;
        history.push(advancedSearchResultsUrl("section", idSearch));
      } else {
        setIsEmpty(true);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  let weeksDisabled = true;
  if (objAvailability.availability) {
    const daysExist = objAvailability.availability.hasOwnProperty("dayId");
    const rangeExist = objAvailability.availability.hasOwnProperty("blockRange");
    if (rangeExist) {
      const start = objAvailability.availability.blockRange.hasOwnProperty("start");
      const end = objAvailability.availability.blockRange.hasOwnProperty("end");
      if (start && end && daysExist) weeksDisabled = false;
    }
  }

  return (
    <div className={css.ctnAdvanceSearch}>
      <EmptyResultsModal show={isEmpty} onClose={() => setIsEmpty(false)} />

      <Box className={css.ctnAdvanceSearch_boxContent}>
        <AdvancedSelectorsWrapper scenario={scenario} onChangeState={setSelectorsState} />

        <Divider className={css.divider} />

        <Heading type="h4">{t("advanced-search.section.forms.days.title")}</Heading>

        <Days required={daysError} onCallback={values => setDays(values)} />

        <Divider className={css.divider} />

        <Heading className={css.title} type="h4">
          {t("advanced-search.section.forms.time-range.title")}
        </Heading>
        <TimeRange
          required={timeRangeError}
          onCallback={(values, error) => setTimeRange(values, error)}
        />

        <Divider className={css.divider} />
        <WeeksRange
          {...props}
          disabled={weeksDisabled}
          onCallback={value => setWeeksRange(value)}
        />

        <Divider className={css.divider} />
        <Heading className={css.title} type="h4">
          {t("advanced-search.section.forms.assignment.title")}
        </Heading>
        <Assignment onCallback={value => setAssignmentChecks(value)} />
      </Box>

      {loading ? (
        <Loading />
      ) : (
        <Button
          className={css.ctnAdvanceSearch_actionButton}
          disabled={error || !hasSomeFilter}
          onClick={() => callUpdateHook()}
        >
          {t("advanced-search.section.forms.btn-search")}
        </Button>
      )}
    </div>
  );
};

export default AdvanceSearch;
