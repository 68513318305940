import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useApolloClient } from "react-apollo";
import { Loading } from "@foris/avocado-suite";
import Layout from "../../../../common/layout/Layout";
import { EmptyResults, Header } from "../../../../common/components";
import SectionsContainer from "../../../sections/Sections";
import { ISectionData } from "../../../sections/ISections";
import { functionsData } from "../../../sections/utils/";
import { CubeQuery, Course, EditorInfoUnion } from "../../../../models/ISchema";
import { IParams } from "../../../../models/IParams";
import { courseSection } from "../graphql/courseSection.query";
import useGetDimension from "@dimensions/hooks/useGetDimension";
import { Dimension } from "@models/IDimension";

const CourseCalendar: React.FC = () => {
  const client = useApolloClient();
  const { id, origin, scenario, workspace }: IParams = useParams();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const [calendarData, setCalendar] = useState(null);
  const [ogHeaderData, setOgHeaderData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [dimension] = useGetDimension(Dimension.course);

  const variables = {
    query: courseSection,
    variables: {
      resourceId: id,
      scenarioId: scenario,
      originId: origin,
      resourceType: "COURSE",
    },
  };

  const headerInfo = (infoCourse: Course) => {
    let details = [];
    if (infoCourse.demand) {
      details = [
        {
          title: t("dimension.course.header.more-details.demand-vacancies"),
          content: infoCourse.demand?.vacancies?.toString() || "",
        },
        {
          title: t("dimension.course.header.more-details.demand-crosslist"),
          content: infoCourse.demand?.crosslist ? "Si" : "No",
        },
        {
          title: t("dimension.course.header.more-details.campus"),
          content: infoCourse?.curriculum?.program?.campus?.name || "",
        },
      ];
    }

    details.push({
      title: t("dimension.course.header.more-details.sections"),
      content: infoCourse?.sectionsCount?.toString() || "-",
    });

    return {
      title: infoCourse.name || "",
      topTitle: infoCourse.code?.toString() || "",
      moreDetails: details,
    };
  };

  const headerData = useMemo(() => (ogHeaderData ? headerInfo(ogHeaderData) : null), [
    ogHeaderData,
    language,
  ]);

  const getDataSections = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await client.query(variables);
      if (data) {
        const cubeQuery: CubeQuery = data.cube;
        const info: EditorInfoUnion = cubeQuery.editorView.info;
        if (info.__typename === "CourseInfo") {
          const courseInfo: Course = info?.course;
          if (courseInfo) {
            setOgHeaderData(courseInfo);
            const sections: ISectionData = functionsData.serializeSections(data);
            setCalendar(sections);
          } else {
            setError(true);
          }
        }
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setError(true);
      setLoading(false);
    }
  }, [client, variables]);

  const setDataCalendar = (data: ISectionData, callback: () => void) => {
    setCalendar(data);
    callback && callback();
  };

  useEffect(() => {
    if (calendarData === null && error === false) getDataSections();
  }, [calendarData, error, getDataSections]);

  return (
    <Layout contextSearch>
      {headerData && (
        <Header
          breadcrumbItems={[
            {
              path: "/",
              title: t("dimension.course.breadcrumb.previous"),
            },
            {
              title: t("dimension.course.breadcrumb.current"),
            },
          ]}
          dimension={dimension.label}
          {...headerData}
        />
      )}
      {loading && <Loading />}
      {error && <EmptyResults dimension="Asignatura" />}
      {calendarData && (
        <SectionsContainer
          config={{
            scenarioId: parseInt(scenario),
            originId: parseInt(origin),
            workspaceId: parseInt(workspace),
          }}
          data={calendarData}
          setDataGrid={setDataCalendar}
        />
      )}
    </Layout>
  );
};

export default CourseCalendar;
