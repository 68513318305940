import React, { ClassAttributes, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Heading } from "@foris/avocado-suite";
import cx from "classnames";
import ContextSearch from "./ContextSearch/ContextSearch";
import { IParams } from "../../models/IParams";
import MainMenu from "./MainMenu/MainMenu";
import MenuHeader from "./Menu/Menu";
import ContextScenario from "./ContextScenario/ContextScenario";
import logo from "../../assets/svg/logoDarwin.svg";
import "../../assets/scss/app.scss";
import css from "./layout.module.scss";

interface ILayoutProps extends ClassAttributes<any> {
  contextSearch: boolean;
  children?: React.ReactNode | undefined;
}

const pageTitles = {
  EDH: "Editor de Horarios",
  Booking: "Reservas",
};

const Layout: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const { origin, scenario, workspace }: IParams = useParams();
  const { contextSearch, children } = props;
  const location = useLocation();

  const isInBooking = location?.pathname.includes("booking");
  const pageTitle = isInBooking ? pageTitles.Booking : pageTitles.EDH;

  useEffect(() => {
    document.title = `Darwin | ${pageTitle}`;
  }, [pageTitle]);

  const urlHome =
    workspace && scenario && origin ? `/home/${workspace}/${scenario}/${origin}` : "/home`";

  return (
    <section className={css.layout}>
      <header className={cx(css.header, "container-row", "row--noWrap")}>
        <MenuHeader />
        <Link to={urlHome} className={css.cntImage}>
          <img
            src={logo}
            alt="Editor de Horarios"
            className={css.img}
            width="108.203px"
            height="28.668px"
          />
        </Link>

        <Heading type="h3" className={css.header_moduleName}>
          {pageTitle}
        </Heading>

        <div className={css.cntInfo}>
          {!!contextSearch && <ContextSearch className={css.cntInfo_autocomplete} />}
        </div>

        <ContextScenario />
        <div className={css.products}>
          <MainMenu />
        </div>
      </header>
      {children}
    </section>
  );
};

export default Layout;
