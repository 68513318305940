import React, { useContext, useMemo } from "react";
import { Heading, Link, RoundButton, Switch, Table, Text, TextField } from "@foris/avocado-suite";
import { IGroup, IGroupValue } from "@modules/vacancies/contextVacancies/ContextVacancies";
import useContextUrl from "@common/hooks/useContextUrl";
import styles from "./groupsTable.module.scss";
import GroupTableCourseColumn from "../group-table-course-column";
import { ContextApp } from "@config/Context/contextApp";
import { ContextEDH } from "@context/ContextEDH";
import useGroupsInputState from "../hooks/useGroupsInputState";
import { useTranslation } from "react-i18next";

interface GroupsTableProps {
  groups: IGroup[];
  edhCanEdit?: boolean;
  isEditing?: boolean;
  linkId: string;
  onCreateGroup?: () => void;
  onToggleActive?: (group: IGroup, checked: boolean) => void;
  onDeleteGroup?: (group: IGroup) => void;
  onMigrateGroup?: (group: IGroup) => void;
  onToggleVisibility?: (group: IGroup) => void;
  onChangeInputValue?: (countVacancies: IGroupValue[]) => void;
}

const GroupsTable = ({
  groups = [],
  edhCanEdit,
  isEditing,
  linkId,
  onToggleVisibility,
  onToggleActive,
  onCreateGroup,
  onDeleteGroup,
  onMigrateGroup,
  onChangeInputValue,
}: GroupsTableProps) => {
  const context = useContext(ContextApp);
  const { state } = useContext(ContextEDH);
  const { workspace, origin, scenario } = useContextUrl();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const userCanMigrate =
    context?.user?.permissions?.update &&
    context?.user?.abilities?.can_migrate_groups_in_bundle_view;

  const userCanDelete =
    context?.user?.permissions?.delete &&
    context?.user?.abilities?.can_delete_groups_in_bundle_view;

  const userCanEdit =
    context?.user?.permissions?.update &&
    context?.user?.abilities?.can_edit_groups_in_bundle_view &&
    !context?.user?.abilities?.can_access_group_manager;

  const canAddMoreGroups = useMemo(() => {
    {
      if (state?.base?.groupsInLinkRestriction?.enabled) {
        return groups?.length < state?.base?.groupsInLinkRestriction?.groups_allowed;
      }

      return true;
    }
  }, [groups, state?.base?.groupsInLinkRestriction]);

  const canAddGroup =
    edhCanEdit &&
    context?.user?.permissions?.create &&
    context?.user?.abilities?.can_create_groups_in_bundle_view &&
    canAddMoreGroups;

  const { countVacancies, onChangeInput } = useGroupsInputState({
    groups,
    linkId,
    onChange: onChangeInputValue,
  });

  const countVacanciesById = useMemo(() => {
    return countVacancies?.reduce((acc: { [id: string]: IGroupValue }, item: IGroupValue) => {
      acc[item.group.id] = item;
      return acc;
    }, {}) as { [id: string]: IGroupValue };
  }, [countVacancies]);

  const totalGroupsData = useMemo(() => {
    return groups?.reduce(
      (acc, item) => {
        acc.enrollment += item.enrollment;
        acc.waitList += item.waitList;
        return acc;
      },
      { enrollment: 0, waitList: 0 },
    );
  }, [groups]);

  const totalGroupsCountVacancies = useMemo(() => {
    return countVacancies?.reduce((acc, item) => acc + item.value, 0);
  }, [countVacancies]);

  const columns = useMemo(() => {
    return [
      {
        key: "isActive",
        header: t("bundle.link-card.group-table.header.active"),
        renderCell: (data: any) => {
          if (data?.isFooter)
            return <Text weight="medium">{t("bundle.link-card.table-footer.total")}</Text>;

          const item = data as IGroup;
          return (
            <Switch
              checked={item.isActive}
              onChange={(checked: boolean) => onToggleActive?.(item, checked)}
              disabled={!userCanEdit || !item?.course?.edhCanEdit}
            />
          );
        },
        desktopOptions: {
          headCellStyles: {
            width: "75px",
          },
        },
      },
      {
        key: "openGroup",
        header: t("bundle.link-card.group-table.header.visible"),
        renderCell: (data: any) => {
          if (data?.isFooter) return "";

          const item = data as IGroup;
          return (
            <RoundButton
              className={styles.openGroup}
              variant="secondary"
              size="sm"
              icon={item.openGroup ? "open-eye" : "closed-eye"}
              onClick={() => onToggleVisibility?.(item)}
              disabled={!userCanEdit || !item?.course?.edhCanEdit}
            />
          );
        },
        desktopOptions: {
          headCellStyles: {
            width: "70px",
          },
        },
      },
      {
        key: "clientCode",
        header: t("bundle.link-card.group-table.header.group-client-code"),
        renderCell: (data: any) => {
          if (data?.isFooter) return "";

          const item = data as IGroup;
          return (
            <Link
              href={`/scheduler/editor/group/${workspace}/${scenario}/${origin}/${item.id}`}
              className={styles.link}
              colored
            >
              {item.label}{" "}
              {item.clientCode && (
                <>
                  <span>|</span> <span className={styles.link_regular}>{item.clientCode}</span>
                </>
              )}
            </Link>
          );
        },

        desktopOptions: {
          headCellStyles: {
            width: "160px",
            minWidth: "80px",
          },
        },
      },
      {
        key: "course",
        header: t("bundle.link-card.group-table.header.subject-key"),
        renderCell: (data: any) => {
          if (data?.isFooter) return "";

          return <GroupTableCourseColumn group={data} />;
        },
        desktopOptions: {
          headCellStyles: {
            width: "250px",
            minWidth: "80px",
          },
        },
      },
      {
        key: "vacancies",
        header: t("bundle.link-card.group-table.header.vacancies"),
        renderCell: (data: any) => {
          if (data?.isFooter) return <Text weight="medium">{totalGroupsCountVacancies}</Text>;

          const item = data as IGroup;
          return (
            <TextField
              className={styles.groupVacanciesInput}
              type="number"
              value={countVacanciesById?.[item?.id]?.value}
              onChange={e => onChangeInput?.(e.target.value, item?.id)}
              disabled={!isEditing}
            />
          );
        },
        desktopOptions: {
          headCellStyles: {
            width: "90px",
            minWidth: "80px",
          },
        },
      },
      {
        key: "enrollment",
        header: t("bundle.link-card.group-table.header.enrollments"),
        renderCell: (data: any) => {
          if (data?.isFooter) return <Text weight="medium">{totalGroupsData.enrollment}</Text>;

          const item = data as IGroup;
          return <Text>{item.enrollment ?? 0}</Text>;
        },
        desktopOptions: {
          headCellStyles: {
            width: "90px",
            minWidth: "80px",
          },
        },
      },
      {
        key: "waitList",
        header: t("bundle.link-card.group-table.header.waiting-list"),
        renderCell: (data: any) => {
          if (data?.isFooter) return <Text weight="medium">{totalGroupsData.waitList}</Text>;

          const item = data as IGroup;
          return <Text>{item.waitList ?? 0}</Text>;
        },
        desktopOptions: {
          headCellStyles: {
            width: "130px",
            minWidth: "80px",
          },
        },
      },
      {
        key: "actions",
        header: "",
        renderCell: (data: any) => {
          if (data?.isFooter) {
            return (
              canAddGroup && (
                <div className={styles.actions}>
                  <RoundButton
                    variant="ghost"
                    size="sm"
                    icon="circle-plus"
                    className={styles.action}
                    onClick={() => onCreateGroup?.()}
                  />
                </div>
              )
            );
          }

          const group = data as IGroup;

          return (
            <div className={styles.actions}>
              {group?.course?.edhCanEdit && userCanMigrate && (
                <RoundButton
                  variant="ghost"
                  size="sm"
                  icon="circle-migrate"
                  className={styles.action}
                  onClick={() => onMigrateGroup?.(group)}
                />
              )}

              {group?.course?.edhCanEdit && userCanDelete && (
                <RoundButton
                  variant="ghost"
                  size="sm"
                  icon="circle-minus"
                  className={styles.action}
                  disabled={group && group.enrollment && group.enrollment > 0}
                  onClick={() => onDeleteGroup?.(group)}
                />
              )}
            </div>
          );
        },
        desktopOptions: {
          headCellStyles: {
            width: "100px",
            minWidth: "80px",
          },
        },
      },
    ];
  }, [
    workspace,
    origin,
    scenario,
    totalGroupsData,
    groups,
    isEditing,
    countVacanciesById,
    totalGroupsCountVacancies,
    language,
  ]);

  return (
    <div className={styles.groupsTable}>
      <Heading type="h4" className={styles.groupsTable_title}>
        {t("bundle.link-card.group-table.title")}
      </Heading>

      <Table
        data={[
          ...(groups ?? []),
          {
            isFooter: true,
          },
        ]}
        columns={columns}
      />
    </div>
  );
};

export default GroupsTable;
