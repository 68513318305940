import React from "react";
import { Button, Heading, Modal, Text } from "@foris/avocado-suite";
import { IGroup } from "@modules/vacancies/contextVacancies/ContextVacancies";
import { useTranslation } from "react-i18next";

interface GroupActiveModalProps {
  isOpen?: boolean;
  selectedGroup?: IGroup;
  onConfirm?: () => void;
  onCancel?: () => void;
}

const GroupActiveModal = ({
  isOpen,
  selectedGroup,
  onCancel,
  onConfirm,
}: GroupActiveModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      header={
        <Heading type="h2">
          {t("bundle.modals.group-active-modal.title", {
            action: selectedGroup?.isActive
              ? t("bundle.modals.group-active-modal.inactive")
              : t("bundle.modals.group-active-modal.active"),
          })}
        </Heading>
      }
      open={isOpen}
      onClose={onCancel}
      actions={
        <>
          <Button variant="secondary" onClick={onCancel}>
            {t("bundle.actions.cancel")}
          </Button>
          <Button onClick={onConfirm}>{t("bundle.actions.confirm")}</Button>
        </>
      }
    >
      <Text>
        {t("bundle.modals.group-active-modal.description-one")}{" "}
        <span className="isTextMedium">{selectedGroup?.label}</span>{" "}
        {t("bundle.modals.group-active-modal.description-two", {
          action: selectedGroup?.isActive
            ? t("bundle.modals.group-active-modal.state-inactive")
            : t("bundle.modals.group-active-modal.state-active"),
        })}
      </Text>
    </Modal>
  );
};

export default GroupActiveModal;
