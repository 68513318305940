import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Layout from "@common/layout/Layout";
import { Header } from "@common/components";
import CreationForm from "./CreationForm";
import usePackagesCrud from "../hooks/usePackagesCrud";
import useContextUrl from "@common/hooks/useContextUrl";
import useNavigationUrl from "@common/hooks/useNavigationUrl";
import { ContextProvider } from "../context/PackagesContext";
import { PackageCrudResult } from "@models/ISchema";
import { DataReducerType } from "../context/types";
import { ContextEDH } from "@context/ContextEDH";

const PackageCreation = () => {
  // Hooks for hanlde the history and the context
  const history = useHistory();
  const { state } = useContext(ContextEDH);
  const { origin, scenario } = useContextUrl();
  const { editorUrl } = useNavigationUrl();
  const [resultPackagesCrud, submitPackagesCrud] = usePackagesCrud({ scenario, origin });
  const { t } = useTranslation();

  const userCanEditPackages = state?.base?.base?.user?.abilities?.can_edit_packages;

  const onPackageCrudSubmit = async (
    dryRun: boolean,
    skipValidations: boolean,
    data?: DataReducerType,
  ) => {
    if (resultPackagesCrud.isLoading) return;

    await submitPackagesCrud(data, false, skipValidations);
  };

  useEffect(() => {
    if (!!state?.base?.base?.user?.abilities && !userCanEditPackages) {
      history.goBack();
    }
  }, [userCanEditPackages]);

  useEffect(() => {
    const result = resultPackagesCrud?.result as PackageCrudResult;

    if (result?.payload?.creates?.length) {
      const createdPackage = result?.payload?.creates[0]?.package;
      history.push(editorUrl("package", createdPackage?.id));
    }
  }, [resultPackagesCrud?.result]);

  return (
    <Layout contextSearch>
      <ContextProvider>
        <Header
          breadcrumbItems={[
            {
              title: t("create-package.breadcrumb.previous"),
              path: "/",
            },
            {
              title: t("create-package.breadcrumb.current"),
            },
          ]}
          dimension={t("create-package.title")}
          title={t("create-package.description")}
        />

        <CreationForm
          onSubmit={onPackageCrudSubmit}
          isLoading={resultPackagesCrud?.isLoading}
          onCancel={history.goBack}
        />
      </ContextProvider>
    </Layout>
  );
};

export default PackageCreation;
