import i18n from "i18next";

export const daysEn = [
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
  "SUNDAY",
];

export const daysEs = ["LUNES", "MARTES", "MIÉRCOLES", "JUEVES", "VIERNES", "SÁBADO", "DOMINGO"];

export const getTranslatedDays = () => [
  { label: i18n.t("advanced-search.classroom.forms.days.monday"), value: daysEn[0] },
  { label: i18n.t("advanced-search.classroom.forms.days.tuesday"), value: daysEn[1] },
  { label: i18n.t("advanced-search.classroom.forms.days.wednesday"), value: daysEn[2] },
  { label: i18n.t("advanced-search.classroom.forms.days.thursday"), value: daysEn[3] },
  { label: i18n.t("advanced-search.classroom.forms.days.friday"), value: daysEn[4] },
  { label: i18n.t("advanced-search.classroom.forms.days.saturday"), value: daysEn[5] },
  { label: i18n.t("advanced-search.classroom.forms.days.sunday"), value: daysEn[6] },
];

/**
 * Return selected day
 * @param dayEn
 */
export const selectedDayEn = (dayEn: string) => {
  const days = getTranslatedDays();
  let valueEs = "";

  daysEn.forEach((value: string, index: number) => {
    if (value === dayEn) valueEs = days?.[index]?.label;
  });

  return { label: valueEs, value: dayEn };
};
