import React, { useMemo } from "react";
import i18n from "@config/i18n";
import { useApolloClient } from "react-apollo";
import { packageSection, packageSectionWithLinks } from "../graphql/packageSection.query";
import { useState } from "react";
import { ISectionData } from "@modules/sections/ISections";
import { functionsData } from "@modules/sections/utils";
import { CubeQuery, Label, Package, PackageInfo, Population } from "@models/ISchema";
import { IInfoHeader } from "@common/components/Header/IHeaderModule";
import { Tooltip } from "@foris/avocado-ui";
import { Link } from "react-router-dom";

interface UseGetPackageSectionParams {
  resourceId: string;
  scenarioId: string;
  originId: string;
  workspaceId: string;
  fetchWithlinks?: boolean;
}

type HeaderData = Pick<IInfoHeader, "moreDetails" | "title" | "topTitle"> & {
  labels: Label[];
  systemLabels: Label[];
};

const getPopulationTitles = (population: Population) => {
  const titles = [];

  population.campus?.code && titles.push(population.campus?.code);
  population.program?.code && titles.push(population.program?.code);
  population.modality?.code && titles.push(population.modality?.code);
  population.shift?.code && titles.push(population.shift?.code);
  population.curriculum && titles.push(population.curriculum);
  population.level && titles.push(population.level);

  return titles;
};

const getHeaderInfo = (info: Package, contextUrl: string): HeaderData => {
  const vacanciesSource = {
    GROUP: "Vacante calculada desde los grupos",
    PACKAGE: "Vacante calculada por el sistema",
  };

  const details = [
    {
      title: i18n.t("dimension.package.header.more-details.vacancies"),
      content: (
        <Tooltip label={vacanciesSource?.[info?.vacanciesSource] || ""} placement="topLeft">
          {info?.vacancies?.toString() || ""}
        </Tooltip>
      ),
    },
  ];

  if (!!info?.isReplicated && info?.sourcePackage?.code) {
    details.push({
      title: "Replicado desde",
      content: (
        <Link to={`/scheduler/editor/package/${contextUrl}/${info?.sourcePackage?.id}`}>
          {info?.sourcePackage?.code}
        </Link>
      ),
    });
  }

  const titles = info?.population ? getPopulationTitles(info.population) : [];

  const labels = [];
  const systemLabels = [];

  info?.labels?.forEach(label => {
    if (label?.category?.labelSource?.code !== "USER-DEFINED") {
      systemLabels.push(label);
    } else {
      labels.push(label);
    }
  });

  return {
    title: info?.name || titles.join(", "),
    topTitle: info?.code ?? "",
    moreDetails: details,
    systemLabels,
    labels,
  };
};

const useGetPackageSection = ({
  resourceId,
  scenarioId,
  originId,
  workspaceId,
  fetchWithlinks = false,
}: UseGetPackageSectionParams) => {
  const client = useApolloClient();

  const [packageData, setPackageData] = useState<Package | null>(null);
  const [ogHeaderData, setOgHeaderData] = useState<Package | null>(null);
  const [sections, setSections] = useState<ISectionData | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const loadPackageData = async () => {
    try {
      setIsLoading(true);

      const { data } = await client.query({
        query: fetchWithlinks ? packageSectionWithLinks : packageSection,
        variables: {
          resourceId,
          scenarioId,
          originId,
          resourceType: "PACKAGE",
        },
      });

      if (data) {
        const cubeQuery: CubeQuery = data.cube;
        const info = cubeQuery.editorView.info as PackageInfo;

        if (info && info.__typename === "PackageInfo") {
          const sections: ISectionData = functionsData.serializeSections(data);
          setOgHeaderData(info.package);
          setSections(sections);
          setPackageData(info?.package);
        } else {
          setHasError(true);
        }
      }
    } catch (error) {
      console.error(error);
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const headerData = useMemo(() => {
    if (ogHeaderData) {
      return getHeaderInfo(ogHeaderData, `${workspaceId}/${scenarioId}/${originId}`) as HeaderData;
    }
    return {} as HeaderData;
  }, [ogHeaderData, i18n.language]);

  return {
    isLoading,
    hasError,
    sections,
    setSections,
    headerData,
    loadPackageData,
    packageData,
  };
};

export default useGetPackageSection;
