import React, { useState, useEffect } from "react";
import cx from "classnames";
import { useApolloClient } from "react-apollo";
import { useTranslation } from "react-i18next";
import { Heading } from "@foris/avocado-suite";
import { useParams } from "react-router-dom";
import { intervalQuery } from "./graphql/intervalWeeks.queries";
import Range from "@common/components/Range/Range";
import { objectEmpty } from "@utils/validations";
import { DataQuery } from "@models/ISchema";
import { IParams } from "@models/IParams";
import css from "./weeksrange.module.scss";

export interface IWeeksRangeValues {
  minWeek: number;
  maxWeek: number;
  weekRange?: Array<number>;
}

export interface IWeeksRangeReturn {
  disabled: boolean;
  onCallback: (value: IWeeksRangeValues) => void;
}

const WeeksRange: React.FC<IWeeksRangeReturn> = props => {
  const { onCallback, disabled } = props;
  // Hooks
  const client = useApolloClient();
  const { scenario }: IParams = useParams();
  const { t } = useTranslation();
  // States for handle the weeks range
  const [weeks, setWeeks] = useState({});
  const [initialWeeks, setInitialWeeks] = useState({});
  const [data, setData] = useState(null);

  const variablesWeeks = {
    query: intervalQuery,
    variables: {
      scenarioId: scenario,
    },
  };

  /**
   * Get weeks interval (min-max)
   * query data graph
   */
  const getWeeksInterval = async () => {
    try {
      const { data } = await client.query(variablesWeeks);
      const dataWeeks: DataQuery = data.data;
      setData(dataWeeks);
      if (dataWeeks.intervals.length > 1) {
        const weeks = { min: 1, max: dataWeeks.intervals.length };
        setWeeks(weeks);
        setInitialWeeks(weeks);
      }
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * Get weeks interval (min-max)
   * query data cache
   */
  const getCacheWeeksInterval = async () => {
    try {
      const { data } = await client.readQuery(variablesWeeks);
      const dataWeeks: DataQuery = data.data;
      if (dataWeeks.intervals.length > 1) {
        const weeks = { min: 1, max: dataWeeks.intervals.length };
        setWeeks(weeks);
        setInitialWeeks(weeks);
      }
    } catch (error) {
      getWeeksInterval();
    }
  };

  const onChangeRange = (value: any) => {
    const dataWeek = data && data.intervals.slice(value[0] - 1, value[1]);
    setWeeks({ min: value[0], max: value[1] });
    onCallback({
      minWeek: parseInt(data.intervals[value[0] - 1].id),
      maxWeek: parseInt(data.intervals[value[1] - 1].id),
      weekRange: dataWeek.map((week: any) => parseInt(week.id)),
    });
  };

  useEffect(() => {
    if (objectEmpty(initialWeeks)) {
      getCacheWeeksInterval();
    }
  });

  if (props.disabled) {
    if (!objectEmpty(initialWeeks) && !objectEmpty(weeks)) {
      if (JSON.stringify(weeks) !== JSON.stringify(initialWeeks)) {
        onCallback({
          minWeek: null,
          maxWeek: null,
        });
        setWeeks(initialWeeks);
      }
    }
  }

  return (
    <>
      {!objectEmpty(initialWeeks) && !objectEmpty(weeks) && (
        <div className={cx(css.sharedWeeks, "container-row")}>
          <Heading className={css.sharedWeeks_title} type="h4">
            {t("advanced-search.classroom.forms.weeks.title")}
          </Heading>
          <Range
            className="col_md_12 col_7"
            disabled={disabled}
            step={1}
            min={initialWeeks["min"]}
            max={initialWeeks["max"]}
            textRange="S"
            onChange={value => onChangeRange(value)}
            valuesRange={[weeks["min"], weeks["max"]]}
          />
        </div>
      )}
    </>
  );
};

export default WeeksRange;
