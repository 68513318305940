import React from "react";
import { Link, Text, Pill } from "@foris/avocado-suite";
import { Icon } from "@foris/avocado-icons";
import css from "./tableUtils.module.scss";

export const getLinkCell = (route: string, id: string | null, value: string) => {
  return (
    <Link colored className={css.columnLink} href={id ? `${route}/${id}` : route}>
      {value}
    </Link>
  );
};

export const getColumnCell = (text: React.ReactNode) => {
  return <Text className={css.columnCell}>{text}</Text>;
};

export const getUsableCell = (usable: boolean) => {
  return usable ? <Icon name="check" /> : <Icon name="close" />;
};

export const getOpenGroupCell = (visible: boolean) => {
  return (
    <div className={css.columnOpenGroup}>
      {visible ? <Icon name="open-eye" /> : <Icon name="closed-eye" />}
    </div>
  );
};

export const getHeadCell = (text: string) => text;

export const getPillCell = (percent: number) => {
  let pillColor:
    | "gray-50"
    | "gray-00"
    | "error-opacity"
    | "error-60"
    | "warning-opacity"
    | "warning-60"
    | "success-opacity"
    | "success-60";
  let textColor:
    | "gray-50"
    | "gray-00"
    | "error-opacity"
    | "error-60"
    | "warning-opacity"
    | "warning-60"
    | "success-opacity"
    | "success-60";
  let pillContent: React.ReactNode;

  if (isNaN(percent)) {
    pillColor = "gray-50";
    textColor = "gray-00";
    pillContent = "—";
  } else {
    const roundedPercent = Math.round(percent);
    pillContent = `${roundedPercent}%`;

    if (roundedPercent < 30) {
      pillColor = "error-opacity";
      textColor = "error-60";
    } else if (roundedPercent < 90) {
      pillColor = "warning-opacity";
      textColor = "warning-60";
    } else {
      pillColor = "success-opacity";
      textColor = "success-60";
    }
  }

  return (
    <Pill className={css.columnPill} color={pillColor} textColor={textColor}>
      {pillContent}
    </Pill>
  );
};

export const getLabelCell = (labels: { id: string; name: string }[]) => {
  const visibleLabels = labels.slice(0, 2);
  const isMoreLables = labels.length > 2;

  return (
    <div className={css.columnLabels}>
      {visibleLabels.map(label => (
        <Pill key={label.id}>{label.name}</Pill>
      ))}
      {isMoreLables && <Pill>+{labels.length - 2}</Pill>}
    </div>
  );
};
