import React, { useState, useEffect, useMemo } from "react";
import { WeeklyGroup } from "@foris/avocado-suite";
import { useLocation } from "react-router-dom";
import { IWeek } from "../ISections";
import TermSelect from "@modules/vacancies/Groups/term-select";
import { Term } from "@models/ISchema";
import useGetWeeksByYear from "../hooks/useGetWeeksByYear";
import css from "./info-sections.module.scss";
import { useTranslation } from "react-i18next";

interface ILocationState {
  currentSelectedWeek?: IWeek;
}

export interface IWeeklyItem {
  id: string;
  label: string;
  disabled: boolean;
  state?: "active" | "current";
  tooltip: { label: string };
  onClick: (item: IWeeklyItem) => void;
}

interface IInfoSections {
  onSelectWeek: any;
  weeks: any;
  selected: any;
  defaultTerm?: Term;
  onUpdateWeeks?: (weeks: IWeek[]) => void;
}

let isInitialSelectedWeekSaved = false;
let savedWeeks = [];

const InfoSections: React.FC<IInfoSections> = (props: IInfoSections) => {
  const { onSelectWeek, weeks = [], selected, onUpdateWeeks, defaultTerm } = props;
  const [selectedWeekId, setSelectedWeekId] = useState<string>(props?.selected?.id.toString());
  const [weeklyItemsByYear, setWeeklyItemsByYear] = useState<any[]>([]);
  const [selectedTerm, setSelectedTerm] = useState<Term | null>(null);

  const location = useLocation();
  const locationState: ILocationState = location.state;

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const TERM_OPTIONS = useMemo(() => {
    return [
      {
        label: t("calendar-view.term-selector.all-weeks"),
        value: "all_weeks",
      },
    ];
  }, [language]);

  const handleWeekSelection = (selectedWeek: IWeek) => {
    onSelectWeek(selectedWeek);
    setSelectedWeekId(selectedWeek.id);
  };

  const handleInitialPeriodWeekSelection = (week: IWeek) => {
    if (!isInitialSelectedWeekSaved) {
      isInitialSelectedWeekSaved = true;
      handleWeekSelection(week);
    }
  };

  const { filteredItemsByYear, weeksRawList, selectedYearIndex } = useGetWeeksByYear({
    weeks,
    termStartDate: selectedTerm ? selectedTerm?.start_date : "",
    termEndDate: selectedTerm ? selectedTerm?.end_date : "",
    selectedWeekId,
    selectedTerm,
    handleWeekOnClick: handleWeekSelection,
    handleWeekInitialSelection: handleInitialPeriodWeekSelection,
  });

  useEffect(() => {
    if (!savedWeeks.length) {
      savedWeeks = [...weeks];
    }
  }, [weeks]);

  const handleSelectTerm = (term: Term) => {
    isInitialSelectedWeekSaved = false;
    setSelectedTerm(term || null);
  };

  useEffect(() => {
    // if setted, set selected week when component is mounted
    if (!!locationState?.currentSelectedWeek) {
      handleWeekSelection(locationState.currentSelectedWeek);
    }

    if (defaultTerm?.id) {
      handleSelectTerm(defaultTerm);
    }
  }, []);

  useEffect(() => {
    const newWeeklyItemsByYear = Object.keys(filteredItemsByYear).map(year => ({
      label: year.toString(),
      items: filteredItemsByYear?.[year] ?? [],
    }));

    setWeeklyItemsByYear(newWeeklyItemsByYear);
    onUpdateWeeks?.(weeksRawList);
  }, [filteredItemsByYear, selectedWeekId]);

  useEffect(() => {
    setSelectedWeekId(selected.id);
  }, [selected]);

  return (
    <div className={css.infoPanel}>
      <TermSelect
        externalOptions={TERM_OPTIONS}
        defaultTerm={defaultTerm ?? TERM_OPTIONS[0]}
        isClearable={false}
        label=""
        onSelectTerm={handleSelectTerm}
      />

      <div className={css.infoPanel_weekly}>
        <WeeklyGroup
          key={selectedTerm?.id || TERM_OPTIONS[0].value}
          carouselOptions={{
            startIndex: selectedYearIndex ?? 0,
          }}
          headerTitle={t("calendar-view.weekly.label")}
          groups={weeklyItemsByYear}
          showDots={weeklyItemsByYear?.length > 1}
        />
      </div>
    </div>
  );
};

export default InfoSections;
