import React, { useState, useEffect, useContext, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Loading } from "@foris/avocado-suite";
import { Context } from "../context/PackagesContext";
import { CustomPackage } from "../context/types";
import { Types as DataTypes } from "../context/data.reducer";
import { Types as PageTypes } from "../context/page.reducer";
import { useGetLink } from "../hooks/useGetLinks";
import { key } from "../utils";
import { PackageLinkAssignmentResult, PackagePayload } from "@models/ISchema";
import useContextUrl from "@common/hooks/useContextUrl";
import useNavigationUrl from "@common/hooks/useNavigationUrl";
import usePackageLinkAssignment from "../hooks/usePackageLinkAssignment";
import usePackagesCrud from "../hooks/usePackagesCrud";
import LinkHeader from "../components/LinkHeader";
import CreationForm from "./CreationForm";
import PackagesEdition, { linkPackagesClash } from "./PackagesEdition";

const PackagesApp = () => {
  const { origin, scenario } = useContextUrl();
  const { bundleUrl } = useNavigationUrl();
  const { state, dispatch } = useContext(Context);
  const [linkData, getLinkData] = useGetLink();
  const [evaluateSubmitionResult, setEvaluateSubmitionResult] = useState(false);
  const [updateLinkData, setUpdateLinkData] = useState(false);
  const [evaluatePackageLinkAssignmentResult, setEvaluatePackageLinkAssignmentResult] = useState(
    false,
  );
  const [linkPackages, setLinkPackages] = useState<CustomPackage[]>([]);
  const [resultPackagesCrud, submitPackagesCrud] = usePackagesCrud({ scenario, origin });
  const [resultPackageLinkAssignment, submitPackageLinkAssignment] = usePackageLinkAssignment({
    scenario,
    origin,
  });
  const [linkPackagesErrors, setLinkPackagesErrors] = useState<{
    [key: string]: boolean;
  }>({});
  const [linkPackagesClash, setLinkPackagesClash] = useState<linkPackagesClash>({});
  const history = useHistory();

  /**
   * Execute both `packageCrud` and `packageLinkAssignment`, if necessary. At
   * least one of them will be executed.
   */
  const onSave = async (dryRun: boolean, skipValidations: boolean) => {
    const { creations, deletions, assignments } = state?.data;
    const someCreation = creations && Object.keys(creations).length > 0;
    const someDeletion = deletions && Object.keys(deletions).length > 0;
    const someAssignment = assignments && Object.keys(assignments).length > 0;

    dispatch({ type: PageTypes.SetLoading, payload: true });

    if (someCreation) {
      await submitPackagesCrud(state?.data, dryRun, skipValidations);
      setEvaluateSubmitionResult(true);
    }

    if (someDeletion || someAssignment) {
      await submitPackageLinkAssignment(state?.data, dryRun, skipValidations);
      setEvaluatePackageLinkAssignmentResult(true);
    }
  };

  /**
   * Submit a packageCrud request and force the evaluation of the submition.
   */
  const onPackageCrudSubmit = useCallback(
    async (dryRun: boolean, skipValidations: boolean) => {
      await submitPackagesCrud(state?.data, dryRun, skipValidations);
      setEvaluateSubmitionResult(true);
    },
    [state?.data],
  );

  /**
   * Submit a packageLinkAssignment request and force the evaluation of the submition.
   */
  const onPackageLinkAssignmentSubmit = async (dryRun: boolean, skipValidations: boolean) => {
    await submitPackageLinkAssignment(state?.data, dryRun, skipValidations);
    setEvaluatePackageLinkAssignmentResult(true);
  };

  /**
   * If no `linkData` has been defined just yet or `updateLinkData = true, get
   * and update the `linkData`.
   */
  useEffect(() => {
    if (linkData == null || updateLinkData) {
      getLinkData();
      setUpdateLinkData(false);
    }
  }, [updateLinkData]);

  /**
   * If no there's no `state?.data?.link`, set it in the context.
   */
  useEffect(() => {
    if (!!state?.data?.link) {
      setLinkPackages((linkData?.packages ?? []).sort((a, b) => a.index - b.index));
      dispatch({ type: PageTypes.SetLoading, payload: false });
    }
  }, [state?.data?.link]);

  /**
   * Handle a `resultPackagesCrud` request.
   *
   * If the request wasn't commited we only will update the `linkPackages`. If
   * it was commited, we will also request the `linkData` again and clean the
   * `creations` from the context.
   */
  useEffect(() => {
    const { creations } = state?.data;
    if (
      !evaluateSubmitionResult ||
      !Boolean(resultPackagesCrud) ||
      !resultPackagesCrud?.result ||
      Object.keys(resultPackagesCrud?.result ?? {}).length === 0 ||
      Object.keys(creations ?? {}).length === 0
    ) {
      return;
    }

    setEvaluateSubmitionResult(false);

    if (!resultPackagesCrud?.result?.commited) {
      // replace the `creation` rows for the real ones
      // `currentLinkPackages` is used to filter the new packages that are
      // already created. This is important because if we don't do it, this
      // useEffect will enter in an infinite loop...
      const currentLinkPackages = linkPackages.reduce((acc, item) => {
        acc[key(item?.code, state?.data?.link?.id)] = true;
        return acc;
      }, {});

      const newPackages = (resultPackagesCrud?.result?.payload?.creates ?? [])
        .map(pkg => ({ ...pkg.package, isNew: true }))
        .filter(item => !(key(item?.code, state?.data?.link?.id) in currentLinkPackages));

      if (newPackages.length > 0) {
        setLinkPackages([...linkPackages, ...newPackages]);
      }

      return;
    }

    // *** --- *** --- *** --- *** --- *** --- *** --- *** --- **
    // At this point we can assume that the request was commited
    // *** --- *** --- *** --- *** --- *** --- *** --- *** --- **

    setUpdateLinkData(true);

    dispatch({ type: DataTypes.CleanCreations });
  }, [resultPackagesCrud]);

  const assignReplicatedPackages = (packagePayload = []) => {
    const payload = packagePayload.map(pkg => ({
      package: pkg?.package,
      linkId: pkg?.link?.id,
    }));

    dispatch({ type: DataTypes.AddAssignments, payload });
  };

  /**
   * Handle `packageLinkKey` request.
   *
   * If the request wasn't committed we will update the `linkPackages` and the
   * `linkPackagesErrors` if necessary. If it was commited, we will also request
   * the `linkData` again and clean the `assignments` and `deletions` from the context.
   */
  useEffect(() => {
    const { assignments, deletions } = state?.data;

    const result = resultPackageLinkAssignment?.result as PackageLinkAssignmentResult;

    if (
      result == null ||
      !evaluatePackageLinkAssignmentResult ||
      (Object.keys(assignments || {}).length === 0 && Object.keys(deletions || {}).length === 0)
    ) {
      return;
    }

    setEvaluatePackageLinkAssignmentResult(false);

    if (!result?.commited) {
      const linkId = state?.data?.link?.id;
      const creates = result?.payload?.creates ?? [];
      const canSkipValidations = result?.userCanSkipValidations;

      if (creates?.length) {
        assignReplicatedPackages(creates);
      }

      const currentLinkPackages = linkPackages.reduce((acc, item: CustomPackage) => {
        acc[key(item?.id, linkId)] = true;
        return acc;
      }, {});
      const newPackages: CustomPackage[] = creates
        .map(payload => ({ ...payload.package, isNew: true }))
        .filter(item => !(key(item?.id, linkId) in currentLinkPackages));

      // define rows with errors
      setLinkPackagesClash({});
      const errors = creates
        .filter((payload: PackagePayload) => !!payload?.validationErrors?.length)
        .reduce((acc: { [key: string]: boolean }, payload: PackagePayload) => {
          if (payload.validationErrors[0].__typename === "ClashesBetweenPackages") {
            setLinkPackagesClash(state => ({
              ...state,
              [key(payload?.package?.id, linkId)]: canSkipValidations ? "warning" : "error",
            }));

            return acc;
          }

          return {
            ...acc,
            [key(payload?.package?.id, linkId)]: true,
          };
        }, {});

      setLinkPackagesErrors(errors);

      if (newPackages.length > 0) {
        setLinkPackages([...linkPackages, ...newPackages]);
      }

      return;
    }

    // *** --- *** --- *** --- *** --- *** --- *** --- *** --- **
    // At this point we can assume that the request was committed
    // *** --- *** --- *** --- *** --- *** --- *** --- *** --- **

    setUpdateLinkData(true);

    dispatch({ type: DataTypes.CleanAssignments });
    dispatch({ type: DataTypes.CleanDeletions });
  }, [resultPackageLinkAssignment]);

  /**
   * If new `linkData` was requested, update it in the context.
   */
  useEffect(() => {
    if (!!linkData?.id) {
      dispatch({ type: DataTypes.SetLink, payload: linkData });
      dispatch({ type: PageTypes.SetLoading, payload: false });
    }
  }, [linkData]);

  /**
   * If any `creations` is deleted, update the `linkPackages` removing the
   * deleted package
   */
  useEffect(() => {
    if (state?.data?.creations == null) return;

    const { creations, assignments } = state?.data;
    const linkId = state?.data?.link?.id;

    const oldLinkPackages = linkPackages.filter(pkg => !pkg.isNew);
    const newLinkPackages = linkPackages.filter(pack => {
      if (!pack.isNew) return false;
      const populationLinkKey = key(pack?.population?.code, linkId);
      const packageLinkKey = key(pack?.id, linkId);
      return creations?.[populationLinkKey] || assignments?.[packageLinkKey];
    });

    const linkPackagesToSet = [...oldLinkPackages, ...newLinkPackages];

    // remove the linkPackagesErrors that were removed from the assignments
    const currentLinkPackagesKeys = linkPackagesToSet
      .filter(pkg => pkg.isNew)
      .reduce((acc, pkg) => {
        acc[key(pkg.id, linkId)] = true;
        return acc;
      }, {});

    const newLinkPackagesErrors = Object.keys(linkPackagesErrors).reduce((acc, pair) => {
      if (currentLinkPackagesKeys[pair]) acc[pair] = true;
      return acc;
    }, {});

    const newLinkPackagesClash = Object.keys(linkPackagesClash).reduce((acc, pair) => {
      if (currentLinkPackagesKeys[pair]) {
        acc[pair] = linkPackagesClash[pair];
      }
      return acc;
    }, {});

    setLinkPackages(linkPackagesToSet);
    setLinkPackagesErrors(newLinkPackagesErrors);
    setLinkPackagesClash(newLinkPackagesClash);
  }, [state?.data?.creations, state?.data?.assignments]);

  if (state?.page?.loading) return <Loading />;

  if (!!state?.data?.link?.course && !state?.data?.link?.course?.generatesPackages) {
    history.replace(bundleUrl(state?.data?.link?.bundle?.id));
  }

  return (
    <>
      {resultPackageLinkAssignment?.isLoading && <Loading />}
      {!!linkData?.id && (
        <LinkHeader link={linkData} isCreation={state?.page?.active === "CREATION"} />
      )}
      {state?.page?.active === "EDITION" && (
        <PackagesEdition
          onSubmit={onSave}
          linkPackages={linkPackages}
          onPackageLinkAssignmentSubmit={onPackageLinkAssignmentSubmit}
          linkPackagesErrors={linkPackagesErrors}
          linkPackagesClash={linkPackagesClash}
        />
      )}
      {state?.page?.active === "CREATION" && <CreationForm onSubmit={onPackageCrudSubmit} />}
    </>
  );
};

export default PackagesApp;
