import React, { useEffect, useState, useContext, useMemo } from "react";
import * as R from "ramda";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  Heading,
  SelectPagination,
  Button,
  Text,
  CardNotification,
  Link,
} from "@foris/avocado-suite";
import { RegularOption } from "@foris/avocado-ui/lib/types/models/GenericProps";
import queryString from "query-string";
import { Package } from "@models/ISchema";
import { Context } from "../context/PackagesContext";
import { ContextApp } from "@config/Context/contextApp";
import { CustomPackage } from "../context/types";
import { Types as DataTypes } from "../context/data.reducer";
import { Types as PageTypes } from "../context/page.reducer";
import { usePaginatedPackages } from "../hooks/usePaginatedPackages";
import { key } from "../utils";
import i18n from "@config/i18n";
import useContextUrl from "@common/hooks/useContextUrl";
import PackagesTable from "../components/PackagesTable";
import css from "./packages.module.scss";

interface ISelectedPackage {
  id: string;
  code: string;
  label: string;
  value: string;
  self: CustomPackage;
}

export type linkPackagesClash = { [key: string]: "error" | "warning" };

interface Props {
  onSubmit: (dryRun: boolean, skipValidations: boolean) => void;
  onPackageLinkAssignmentSubmit: (dryRun: boolean, skipValidations: boolean) => void;
  linkPackages: CustomPackage[];
  linkPackagesErrors: { [key: string]: boolean };
  linkPackagesClash?: linkPackagesClash;
}

interface ErrorMessageProps {
  errors: { [key: string]: Package };
  origin: string;
  scenario: string;
  workspace: string;
}

const getPackageOption = (data: Package) => {
  return {
    label: (
      <div className={css.packageLabel}>
        <Text className={css.packageLabel_mainLabel}>
          PE: <b>{data?.population?.term?.code}</b> | SE: <b>{data?.population?.campus?.code}</b> |
          CA: <b>{data?.population?.program?.code}</b> | CU: <b>{data?.population?.curriculum}</b> |
          NI: <b>{data?.population?.level}</b> | PQ: <b>{data?.index}</b>
        </Text>

        <Text className={css.packageLabel_secondaryLabel}>
          ES: <b>{data?.population?.program?.department?.code}</b> | MO:{" "}
          <b>{data?.population?.modality?.code}</b> | JO: <b>{data?.population?.shift?.code}</b>
        </Text>
      </div>
    ) as any,
    value: data?.id,
    self: data,
  };
};

const getPackagesKeyObj = (packages: Package[] = []) => {
  const packagesObj = {};

  packages.forEach(item => {
    packagesObj[item.id] = item;
  });

  return packagesObj;
};

const getPackagesErrorsObj = (
  errors: { [key: string]: boolean } | linkPackagesClash,
  packagesObj: { [key: string]: Package },
) => {
  const errorsObj = {};

  Object.keys(errors).forEach(err => {
    const [code] = err.split("-");

    if (code in packagesObj) {
      errorsObj[code] = packagesObj[code];
    }
  });

  return errorsObj;
};

const getErrorLinks = ({ origin, scenario, workspace, errors }: ErrorMessageProps) => {
  const baseRoute = `/scheduler/editor/package/${workspace}/${scenario}/${origin}`;

  const keys = Object.keys(errors);
  return keys.map((key, index) => {
    const error = errors[key];

    return (
      <>
        <Link
          key={error?.code + index}
          href={`${baseRoute}/${error?.id}`}
          className={css.packageLink}
          colored
          size="sm"
        >
          [{error?.code}]
        </Link>
        {index + 1 < keys.length ? ", " : ""}
      </>
    );
  });
};

const getErrorMessage = (payload: ErrorMessageProps) => {
  const links = getErrorLinks(payload) || [];
  const singular = i18n.t("bundle.packages-edition.errors.vinculation-error.singular-message");
  const plural = i18n.t("bundle.packages-edition.errors.vinculation-error.plural-message");

  const isSingular = links.length <= 1;

  return (
    <Text type="sm">
      {isSingular ? singular : plural} {links}{" "}
      {i18n.t("bundle.packages-edition.errors.vinculation-error.message", {
        letter: !isSingular ? "n" : "",
      })}
    </Text>
  );
};

const getClashMessage = (payload: ErrorMessageProps, isError = false) => {
  const links = getErrorLinks(payload) || [];

  return (
    <Text type="sm">
      {i18n.t("bundle.packages-edition.errors.clashes-between-packages.message-01")} {links}{" "}
      {i18n.t("bundle.packages-edition.errors.clashes-between-packages.message-02")}{" "}
      {isError
        ? i18n.t("bundle.packages-edition.errors.clashes-between-packages.complementary-message")
        : ""}
    </Text>
  );
};

const PackagesEdition = (props: Props) => {
  const {
    onSubmit,
    onPackageLinkAssignmentSubmit,
    linkPackages,
    linkPackagesErrors,
    linkPackagesClash,
  } = props;
  const { state, dispatch } = useContext(Context);
  const { user } = useContext(ContextApp);
  const { origin, scenario, workspace } = useContextUrl();
  const params: queryString.ParsedQuery<string> = queryString.parse(location.search);
  const [inputText, setInputText] = useState("");
  const [doSubmission, setDoSubmission] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState<ISelectedPackage>(null);
  const history = useHistory();
  const { t } = useTranslation();

  const hasEditPermissions = !!user?.abilities?.can_edit_packages;

  const hasClashError = useMemo(
    () => Object.values(linkPackagesClash).some(val => val === "error"),
    [linkPackagesClash],
  );

  const clashErrors = useMemo(() => {
    const clashErrors = {};

    if (hasClashError) {
      Object.keys(linkPackagesClash).forEach(key => {
        if (linkPackagesClash[key] === "error") {
          clashErrors[key] = true;
        }
      });
    }

    return clashErrors;
  }, [hasClashError]);

  const allNewPackagesAreSavable = (): boolean => R.isEmpty(linkPackagesErrors) && !hasClashError;

  const someCreation = useMemo(() => {
    const { creations } = state?.data ?? {};
    return creations && Object.keys(creations).length > 0;
  }, [state?.data?.creations]);

  const deletionsCount = useMemo(() => {
    const { deletions } = state?.data ?? {};
    return deletions && Object.keys(deletions).length;
  }, [state?.data?.deletions]);

  const someDeletion = useMemo(() => deletionsCount > 0, [deletionsCount]);

  const someAssignment = useMemo(() => {
    const { assignments } = state?.data ?? {};
    return assignments && Object.keys(assignments).length > 0;
  }, [state?.data?.assignments]);

  const canSubmit = (): boolean => {
    return !(someDeletion || someCreation || someAssignment) || !allNewPackagesAreSavable();
  };

  const addAssignment = () => {
    const payload = { package: selectedPackage?.self, linkId: state?.data?.link?.id };
    dispatch({ type: DataTypes.AddAssignment, payload });
    setSelectedPackage(null);
    setDoSubmission(true);
  };

  const handleCancel = () => {
    if (canSubmit()) {
      history.replace(
        `/editor/vacancies/${workspace}/${scenario}/${origin}/${state?.data?.link?.bundle?.id}`,
      );
      return;
    }

    dispatch({ type: DataTypes.CleanCreations });
    dispatch({ type: DataTypes.CleanDeletions });
    dispatch({ type: DataTypes.CleanAssignments });
  };

  const onDelete = (customPackage: CustomPackage) => {
    const linkId = state?.data?.link?.id;
    if (customPackage?.isNew) {
      const { creations, assignments } = state?.data;
      const packageLinkKey = key(customPackage?.id, linkId);
      const populationLinkKey = key(customPackage?.population?.code, linkId);

      if (customPackage?.isReplicated !== null && !!customPackage?.sourcePopulationCode) {
        const assignmentsClone = { ...assignments };

        Object.keys(assignmentsClone).forEach(assignmentKey => {
          const pkg = assignmentsClone?.[assignmentKey];

          if (
            pkg?.package?.isReplicated !== null &&
            [pkg?.package?.code, pkg?.package?.sourcePopulationCode].includes(
              customPackage?.sourcePopulationCode,
            )
          ) {
            delete assignmentsClone?.[assignmentKey];
          }
        });

        dispatch({ type: DataTypes.DeleteAssignments, payload: assignmentsClone });
      } else if (R.has(packageLinkKey, assignments)) {
        const payload = { package: customPackage, linkId };
        dispatch({ type: DataTypes.DeleteAssignment, payload });
      } else if (R.has(populationLinkKey, creations)) {
        const payload = { population: customPackage?.population, linkId };
        dispatch({ type: DataTypes.DeleteCreation, payload });
      } else {
        // unreachable (allegedly...)
        console.error("No package-link or population-link to delete...");
      }
    } else {
      dispatch({ type: DataTypes.AddDeletion, payload: { package: customPackage, linkId } });
    }
  };

  useEffect(() => {
    if (R.not(R.isEmpty(state?.data?.assignments)) && doSubmission) {
      setDoSubmission(false);
      onPackageLinkAssignmentSubmit(true, true);
    }
  }, [state?.data?.assignments]);

  /**
   * Reject the packages that are already related to the link or are currently been created
   */
  const selectablePackages = (packgs: Package[]): Package[] => {
    const createdPackagesIds = Object.values(state?.data?.assignments).reduce(
      (acc, { package: pkg }) => {
        acc[pkg.id] = true;
        return acc;
      },
      {},
    );

    const currentPackagesIds = (state?.data?.link?.packages ?? []).reduce((acc, pkg) => {
      acc[pkg.id] = true;
      return acc;
    }, {});

    const alreadyDisplayed = (id: string) => createdPackagesIds[id] || currentPackagesIds[id];

    return packgs.filter(pkg => !alreadyDisplayed(pkg.id));
  };

  const packagesObj = useMemo(() => getPackagesKeyObj(linkPackages), [linkPackages]);
  const packagesErrorsObj = useMemo(() => getPackagesErrorsObj(linkPackagesErrors, packagesObj), [
    linkPackagesErrors,
    packagesObj,
  ]);
  const errorMessage = useMemo(
    () =>
      getErrorMessage({
        errors: packagesErrorsObj,
        origin,
        scenario,
        workspace,
      }),
    [packagesErrorsObj],
  );
  const hasErrorMessages = !!Object.keys(packagesErrorsObj).length;

  const packagesClashObj = useMemo(() => getPackagesErrorsObj(linkPackagesClash, packagesObj), [
    linkPackagesClash,
    packagesObj,
  ]);
  const hasPackagesClash = !!Object.keys(packagesClashObj).length;
  const clashMessage = useMemo(
    () =>
      getClashMessage(
        {
          errors: packagesClashObj,
          origin,
          scenario,
          workspace,
        },
        hasClashError,
      ),
    [packagesClashObj],
  );

  const packagesFilterMethod = (packages: Package[] = []): RegularOption[] => {
    const packagesFiltered = selectablePackages([...packages]);
    const options = packagesFiltered.map(getPackageOption);

    return options;
  };

  const linkCampus = state?.data?.link?.course?.curriculum?.program?.campus;
  const isGlobalCampus = !!linkCampus?.isGlobal;

  const { loadPackages, isLoading: isLoadingPackagesOptions } = usePaginatedPackages({
    scenarioId: scenario,
    originId: origin,
    filterId: params?.advance,
    searchTerm: inputText,
    campusId: isGlobalCampus ? undefined : linkCampus?.id,
    fields: {
      isPublished: {
        is: false,
      },
    },
    packagesFilterMethod,
  });

  return (
    <section className={css.main}>
      <Heading type="h2">
        {hasEditPermissions
          ? t("bundle.packages-edition.title")
          : t("bundle.packages-edition.short-title")}
      </Heading>

      {/* Top Selector */}
      {hasEditPermissions && (
        <div className={css.selector}>
          <SelectPagination
            key={`selector-${linkPackages?.length}`}
            className={css.selector_input}
            label={t("bundle.packages-edition.selector-label")}
            placeholder={t("bundle.packages-edition.selector-placeholder")}
            value={selectedPackage}
            onInputChange={setInputText}
            onChange={setSelectedPackage}
            loadOptions={loadPackages}
            isLoading={isLoadingPackagesOptions}
            debounceTimeout={1000}
          />

          <Button
            variant="secondary"
            disabled={!Boolean(selectedPackage)}
            className={css.selector_button}
            onClick={addAssignment}
          >
            {t("bundle.packages-edition.btn-add-package")}
          </Button>

          <Button
            variant="secondary"
            className={css.selector_button}
            onClick={() => dispatch({ type: PageTypes.SetActivePage, payload: "CREATION" })}
            leftIcon="circle-plus"
          >
            {t("bundle.packages-edition.btn-create-package")}
          </Button>
        </div>
      )}

      {/* Packages Table */}
      <PackagesTable
        linkPackages={linkPackages ?? []}
        onDelete={onDelete}
        linkId={state?.data?.link?.id}
        deletions={state?.data?.deletions}
        linkPackagesErrors={{
          ...linkPackagesErrors,
          ...clashErrors,
        }}
        hasEditPermissions={hasEditPermissions}
      />

      {hasErrorMessages && (
        <CardNotification
          className={css.errorCard}
          state="error"
          title={t("bundle.packages-edition.errors.vinculation-error.generic-title")}
          outlined
        >
          {errorMessage}
        </CardNotification>
      )}

      {hasPackagesClash && (
        <CardNotification
          className={css.errorCard}
          state={hasClashError ? "error" : "warning"}
          title={
            hasClashError
              ? t("bundle.packages-edition.errors.clashes-between-packages.title")
              : t("bundle.packages-edition.errors.clashes-between-packages.warning-title")
          }
          outlined
        >
          {clashMessage}
        </CardNotification>
      )}

      {/* Bottom buttons */}
      <section className={css.actions}>
        <Button variant="ghost" onClick={handleCancel}>
          {t("bundle.packages-edition.btn-cancel")}
        </Button>

        {hasEditPermissions && (
          <Button disabled={canSubmit()} onClick={() => onSubmit(false, true)}>
            {someDeletion && !someAssignment && !someCreation
              ? deletionsCount === 1
                ? t("bundle.packages-edition.btn-delete")
                : t("bundle.packages-edition.btn-delete-plural")
              : t("bundle.packages-edition.btn-save")}
          </Button>
        )}
      </section>
    </section>
  );
};

export default PackagesEdition;
