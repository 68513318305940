import gql from "graphql-tag";

export const shortMegaSearch = gql`
  query megaSearch($scenarioId: ID!, $filter: PackageFilterInput!, $originId: ID!) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      packages(filter: $filter) {
        items {
          id
          vacancies
          index
          code
          name
          labels {
            id
            code
            name
            category {
              id
              code
              name
            }
          }
        }
        pageInfo {
          size
          page
          total
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`;
