import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useQuery } from "react-apollo";
import cx from "classnames";
import {
  Heading,
  Loading,
  Button,
  TextField,
  CardNotification,
  Text,
  Checkbox,
} from "@foris/avocado-suite";
import { Course, CreateGroupInput } from "../../../models/ISchema";
import { IParams } from "../../../models/IParams";
import { IObjValidationError } from "../utils";
import { CourseMultiKey } from "../../shared/selectors";
import TermSelect from "./term-select";
import { courseMultiKeyQuery } from "./../graphql/courseMultiKey.queries";
import { IErrorWarning } from "../../../models/Errors/ErrorWarning";
import { Header } from "../../../common/components";
import { useGetSharedPackComponents } from "../hooks/useGetSharedPackComponents";
import GroupComponentInputs from "./GroupComponentInputs";
import {
  AdaptedSharedPackComponent,
  AdaptedSharedPackComponentPayload,
} from "./GroupComponentInputs/GroupComponentInputs";
import GroupCodeInput from "./group-code-input";
import useAddGroup from "../hooks/useAddGroup";
import css from "./groups.module.scss";

export interface IAddGroupsProps {
  onClose?: () => void;
  linkId: string;
  bundleId: string | number;
  headerData?: { subTitle: string; title: string };
}

interface IAddGroupReturn {
  idCampus: string;
  idDepartment: string;
  idModality: string;
  idShift: string;
  objLevel: any;
  idProgram: string;
  idCourse: string;
  idTerm: string;
  idCurriculum: string;
}

const emptyObjGroup = {
  idCampus: null,
  idDepartment: null,
  idModality: null,
  idShift: null,
  objLevel: null,
  idProgram: null,
  idCourse: null,
  idTerm: null,
  idCurriculum: null,
};

const AddGroups: React.FC<IAddGroupsProps> = (props: IAddGroupsProps) => {
  const { onClose, linkId, headerData, bundleId } = props;
  const { origin, scenario }: IParams = useParams();
  const [objGroup, setObjGroup] = useState<IAddGroupReturn>(emptyObjGroup);
  const [idTerm, setIdTerm] = useState(null);
  const [valueCode, setValueCode] = useState(null);
  const [valueCapacity, setValueCapacity] = useState(null);
  const [internalValidationArray, setInternalValidationArray] = useState(null);
  const [intervalValidationStandardArray, setInternalValidationStandardArray] = useState<
    IErrorWarning[]
  >([]);
  const [check, setCheck] = useState(false);
  const [componentsPayload, setComponentsPayload] = useState<AdaptedSharedPackComponent[]>([]);
  const [isComponentsFormValid, setIsComponentsFormValid] = useState(false);
  const { t } = useTranslation();

  const {
    addGroup,
    isLoading: loading,
    validationArray: groupValidationArray,
    validationStandardArray: groupValidationStandardArray,
  } = useAddGroup({
    scenario,
    origin,
    onSuccess: () => {
      onClose?.();
      window.location.reload();
    },
  });

  const validationArray = useMemo(() => {
    return [...(internalValidationArray ?? []), ...(groupValidationArray ?? [])];
  }, [internalValidationArray, groupValidationArray]);

  const validationStandardArray = useMemo(() => {
    return [...(intervalValidationStandardArray ?? []), ...(groupValidationStandardArray ?? [])];
  }, [intervalValidationStandardArray, groupValidationStandardArray]);

  const { sharedPackComponents, isLoading, getSharedPacks } = useGetSharedPackComponents({
    scenarioId: scenario,
    originId: origin,
  });

  const { data } = useQuery<any, any>(courseMultiKeyQuery, {
    variables: {
      scenarioId: scenario,
      originId: origin,
      id: linkId,
    },
  });
  const defaultCourse: Course = data?.cube?.link?.course || null;
  const defaultTerm: any = data?.cube?.link.bundle.term || null;

  const errorArray = validationArray
    ? validationArray.filter((error: IObjValidationError) => error.type?.toUpperCase() === "ERROR")
    : [];
  const errorStandardArray = validationStandardArray.filter(
    error => error && error.type === "ERROR",
  );
  const warningArray = validationArray
    ? validationArray.filter((validation: IObjValidationError) => validation.type === "warning")
    : [];
  const warningStandardArray = validationStandardArray.filter(
    error => error && error.type === "WARNING",
  );

  const warningArrayByReason = useMemo(() => {
    return warningArray.reduce((acc, curr) => {
      if (!acc[curr.reason]) {
        acc[curr.reason] = {
          title: curr?.title || curr?.reason,
          items: [],
        };
      }

      acc[curr.reason]?.items?.push(curr);

      return acc;
    }, {});
  }, [warningArray]);

  const errorArrayByReason = useMemo(() => {
    return errorArray.reduce((acc, curr) => {
      if (!acc[curr.reason]) {
        acc[curr.reason] = {
          title: curr?.title || curr?.reason,
          items: [],
        };
      }

      acc[curr.reason]?.items?.push(curr);

      return acc;
    }, {});
  }, [errorArray]);

  const handleAddGroup = (commit: boolean) => {
    const sharedPackComponent = componentsPayload?.length
      ? componentsPayload?.map(({ component, label }) => ({
          label,
          component,
        }))
      : undefined;

    const objMutation: CreateGroupInput = {
      linkId: linkId,
      programId: objGroup.idProgram,
      courseId: objGroup.idCourse,
      termId: idTerm,
      code: valueCode,
      sharedPackComponent,
      capacity: parseInt(valueCapacity),
    };

    addGroup(objMutation, commit);
  };

  const handleCourseMultiKeyCallback = (newObjGroup: IAddGroupReturn) => {
    setObjGroup(newObjGroup || emptyObjGroup);
  };

  useEffect(() => {
    if (objGroup?.idCourse) {
      getSharedPacks(linkId, bundleId as string, objGroup?.idCourse);
    }
  }, [objGroup]);

  const hasCodeOrSharedPacks =
    (!sharedPackComponents?.length && valueCode) ||
    (!!sharedPackComponents?.length && isComponentsFormValid);

  const isSubmitActive =
    objGroup.idCampus &&
    objGroup.idDepartment &&
    objGroup.idModality &&
    objGroup.idShift &&
    objGroup.idProgram &&
    objGroup.idCurriculum &&
    objGroup.idCourse &&
    idTerm &&
    valueCapacity &&
    hasCodeOrSharedPacks &&
    errorArray.length === 0;

  /**
   * set term filter
   * termId {
   *    eq
   * }
   */
  const setTerm = (values: any) => {
    if (values.term) {
      setIdTerm(values.term);
    }
  };

  const handleChangeComponents = (payload: AdaptedSharedPackComponentPayload) => {
    setValueCode(payload?.groupCode);
    setComponentsPayload(payload?.sharedPackComponent);
  };

  const isWarningError = validationArray?.length > 0 || validationStandardArray?.length > 0;
  const isWarning = warningArray?.length > 0 || warningStandardArray?.length > 0;

  return (
    <article className={cx(css.addGroups)}>
      {(loading || isLoading) && <Loading />}
      <Header
        breadcrumbItems={[
          { title: t("bundle.create-group.breadcrumb.previous"), path: "/" },
          { title: t("bundle.create-group.breadcrumb.current") },
        ]}
        topTitle={headerData.subTitle}
        title={`${t("bundle.create-group.title")} | ${headerData.title} `}
      />
      <section className={cx(css.addGroups_contentAddGroup)}>
        <Heading className={css.title} type="h3">
          {t("bundle.create-group.title")}
        </Heading>
        <CourseMultiKey
          className={css.courseMultiKey}
          defaultCourse={defaultCourse}
          onCallback={handleCourseMultiKeyCallback}
        />

        <div className={css.formOptions}>
          <TermSelect
            {...props}
            defaultTerm={defaultTerm}
            sendValues={(values: any) => setTerm(values)}
          />

          <TextField
            label={t("bundle.create-group.forms.input-vacancies.label")}
            placeholder={t("bundle.create-group.forms.input-vacancies.placeholder")}
            type="number"
            value={valueCapacity}
            onChange={event => {
              setValueCapacity(parseInt(event.target.value));
              setInternalValidationArray([]);
              setInternalValidationStandardArray([]);
            }}
          />

          {!sharedPackComponents?.length ? (
            <GroupCodeInput
              value={valueCode}
              onChange={setValueCode}
              onError={setInternalValidationStandardArray}
            />
          ) : (
            <GroupComponentInputs
              sharedPackComponents={sharedPackComponents}
              onChangeComponents={handleChangeComponents}
              onFormValid={setIsComponentsFormValid}
            />
          )}
        </div>

        {isWarningError && (
          <>
            {Object.values(warningArrayByReason).map((warning: any, index) => (
              <CardNotification
                key={`warning-${index}`}
                className={css.notificationCard}
                title={warning.title}
                state="warning"
                outlined
              >
                <div className={css.notificationCard_messages}>
                  {warning.items.map((item: any, i) => (
                    <Text key={`text-${i}`} type="sm">
                      {item.text}
                    </Text>
                  ))}

                  {errorArray?.length === 0 && !check && (
                    <Button
                      className={css.cardAction}
                      variant="ghost"
                      size="sm"
                      onClick={() => setCheck(true)}
                    >
                      {t("bundle.create-group.btn-ignore-warning")}
                    </Button>
                  )}
                </div>
              </CardNotification>
            ))}

            {Object.values(errorArrayByReason).map((error: any, index) => (
              <CardNotification
                key={`error-${index}`}
                className={css.notificationCard}
                title={error.title}
                state="error"
                outlined
              >
                <div className={css.notificationCard_messages}>
                  {error.items.map((item: any, i) => (
                    <Text key={`text-error-${i}`} type="sm">
                      {item.text}
                    </Text>
                  ))}
                </div>
              </CardNotification>
            ))}

            {warningStandardArray?.length || errorStandardArray?.length ? (
              <ul className={cx(css.list_validation, "container-row")}>
                {warningStandardArray.map((validation, index) => (
                  <li key={index} className={cx(css.list_validation_item, "col_12")}>
                    {validation.message}
                  </li>
                ))}

                {errorStandardArray.map((validation, index) => (
                  <li
                    key={index}
                    className={cx(
                      css.list_validation_item,
                      css.list_validation_item__error,
                      "col_12",
                    )}
                  >
                    {validation.message}
                  </li>
                ))}
              </ul>
            ) : null}
            {isWarning && errorArray?.length === 0 && !!warningStandardArray?.length && (
              <Checkbox
                labelRight="Ignorar advertencias"
                checked={check}
                onChange={event => setCheck(event.currentTarget.checked)}
              />
            )}
          </>
        )}

        <div className={css.actionsButtons}>
          <Button variant="ghost" className={cx(css.footer_btn)} onClick={() => onClose()}>
            {t("bundle.create-group.btn-cancel")}
          </Button>
          {warningArray.length === 0 && (
            <Button
              className={cx(css.footer_btn)}
              onClick={() => handleAddGroup(false)}
              disabled={!isSubmitActive}
            >
              {t("bundle.create-group.btn-save")}
            </Button>
          )}
          {warningArray.length > 0 && (
            <Button
              className={cx(css.footer_btn)}
              onClick={() => handleAddGroup(true)}
              disabled={!isSubmitActive || (warningArray.length > 0 && !check)}
            >
              {t("bundle.create-group.btn-confirm")}
            </Button>
          )}
        </div>
      </section>
    </article>
  );
};

export default AddGroups;
