import useContextUrl from "@common/hooks/useContextUrl";
import { CubeMutation, GroupValidationErrorUnion } from "@models/ISchema";
import { IGroup } from "@modules/vacancies/contextVacancies/ContextVacancies";
import disableGroupMutation from "../../graphql/disableGroup.mutation.graphql";
import { groupValidation } from "@modules/vacancies/utils";
import { useState } from "react";
import { useApolloClient } from "react-apollo";

interface useDisableGroupProps {
  selectedGroup?: IGroup;
  onValidationArray?: (array: any[]) => void;
}

const useDisableGroup = (props?: useDisableGroupProps) => {
  const client = useApolloClient();
  const { origin, scenario } = useContextUrl();
  const { selectedGroup, onValidationArray } = props;

  const [isLoading, setIsLoading] = useState(false);

  const handleDisabledGroupMutation = async () => {
    try {
      setIsLoading(true);

      if (selectedGroup) {
        const { data } = await client.mutate({
          mutation: disableGroupMutation,
          variables: {
            originId: parseInt(origin),
            scenarioId: parseInt(scenario),
            groupId: parseInt(selectedGroup.id),
          },
          fetchPolicy: "no-cache",
        });

        const dataMutation: CubeMutation = data.cube;
        const validationErrors: Array<GroupValidationErrorUnion> =
          dataMutation.disableGroup.validationErrors;
        let errorsValidations = [];

        if (validationErrors && validationErrors.length > 0) {
          validationErrors.forEach((error: GroupValidationErrorUnion) => {
            const link = dataMutation.disableGroup.link;
            const errorLinks = groupValidation([error], link);
            errorsValidations = [...errorsValidations, ...errorLinks];
          });

          onValidationArray?.(errorsValidations);
        }

        setIsLoading(false);

        if (dataMutation.disableGroup.commited) {
          window.location.reload();
        }
      }
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    handleDisableGroup: handleDisabledGroupMutation,
  };
};

export default useDisableGroup;
