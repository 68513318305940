import React, { useContext, useMemo } from "react";
import cx from "classnames";
import dayjs from "dayjs";
import { DataGridProps } from "@foris/avocado-ui/lib/types/components/DataGrid/DataGrid";
import { Button, DataGrid } from "@foris/avocado-ui";
import { OrderByDirection, LinkLogEntry } from "@models/ISchema";
import { Link } from "@foris/avocado-suite";
import { Icon } from "@foris/avocado-icons";
import { PagerProps } from "@foris/avocado-ui/lib/types/components/Pager/Pager";
import { useParams } from "react-router-dom";
import { IParams } from "@models/IParams";
import { linkChangesTableHeaderToOrderByObj } from "../../utils/historyTableUtils";
import { Context } from "@modules/sections/GroupsManager/context/GroupsManagerContext";
import { Column, SortInfo } from "@foris/avocado-ui/lib/types/components/DataGrid/types";
import { Types as TableFiltersTypes } from "@modules/sections/GroupsManager/context/tableFilters.reducer";
import TableFilter from "@modules/sections/GroupsManager/components/TableFilter/TableFilter";
import styles from "./linkHistoryTable.module.scss";

interface LinkHistoryTableProps {
  data: LinkLogEntry[];
  pagination?: PagerProps;
  columnsToSearchBy?: {
    Fecha: boolean;
    Liga: boolean;
    "Recurso ID": boolean;
    Responsable: boolean;
  };
  setColumnsToSearchBy?: (columns: any) => void;
  onLoadLogEntries?: (
    page: number,
    newOrderBy: {
      header: string;
      field: string;
      direction: OrderByDirection;
    },
    searchBy: {
      fields: string[];
      text: string;
    },
  ) => void;
}

const LinkHistoryTable = ({
  data = [],
  onLoadLogEntries,
  pagination,
  columnsToSearchBy,
  setColumnsToSearchBy,
}: LinkHistoryTableProps) => {
  const { workspace, origin, scenario }: IParams = useParams();
  const { state, dispatch } = useContext(Context);

  const columns: DataGridProps<LinkLogEntry>["columns"] = [
    {
      header: "Fecha",
      renderer: (linkLogEntry: LinkLogEntry) => {
        return (
          <span className={cx(styles.tableCol, styles.tableCol__rightGap)}>
            {linkLogEntry?.date}
          </span>
        );
      },
    },
    {
      header: "Liga",
      renderer: (linkLogEntry: LinkLogEntry) => {
        return linkLogEntry?.link?.enabled ? (
          <Link
            href={`/scheduler/editor/link/${workspace}/${scenario}/${origin}/${linkLogEntry?.link?.id}`}
            className={cx(styles.tableCol, styles.tableCol__link, styles.tableCol__rightGap)}
          >
            {linkLogEntry?.link?.code}
          </Link>
        ) : (
          linkLogEntry?.link?.code
        );
      },
    },
    {
      header: "Recurso Modificado",
      renderer: (linkLogEntry: LinkLogEntry) => {
        return (
          <span className={cx(styles.tableCol, styles.tableCol__rightGap)}>
            {linkLogEntry?.resource}
          </span>
        );
      },
    },
    {
      header: "Recurso ID",
      renderer: (linkLogEntry: LinkLogEntry) => {
        return (
          <span className={cx(styles.tableCol, styles.tableCol__rightGap)}>
            {linkLogEntry?.objectId ?? "-"}
          </span>
        );
      },
    },
    {
      header: "Tipo de edición",
      renderer: (linkLogEntry: LinkLogEntry) => {
        return (
          <ul
            className={cx(styles.tableCol, styles.tableCol__listShort, styles.tableCol__rightGap)}
          >
            {linkLogEntry?.changeType?.map((changeType, index) => (
              <li key={index}>{changeType}</li>
            ))}
          </ul>
        );
      },
      styles: {
        minWidth: "200px",
      },
    },
    {
      header: "Acción",
      renderer: (linkLogEntry: LinkLogEntry) => {
        return (
          <ul className={cx(styles.tableCol, styles.tableCol__list, styles.tableCol__rightGap)}>
            {linkLogEntry?.change?.map((change, index) => (
              <li key={index}>{change}</li>
            ))}
          </ul>
        );
      },
      styles: {
        minWidth: "220px",
      },
    },
    {
      header: "Responsable",
      renderer: (linkLogEntry: LinkLogEntry) => {
        return linkLogEntry?.user ? (
          <div className={styles.tableCol}>
            <Icon name="user" />
            <span>
              {linkLogEntry?.user?.id} {linkLogEntry?.user?.name}
            </span>
          </div>
        ) : (
          "-"
        );
      },
      styles: {
        minWidth: "180px",
      },
    },
  ];

  const renderLeftHeader = () => {
    const now = dayjs().format("DD/MM/YYYY HH:mm");

    return (
      <div className={styles.tableLeftHeader}>
        <span className={styles.tableLeftHeader_total}>{pagination?.total ?? 0} Sesiones</span>
        <span className={styles.tableLeftHeader_separator}></span>
        <span className={styles.tableLeftHeader_updatedAt}>
          <strong className={styles.updatedAtLabel}>Última actualización</strong>: {now}
        </span>
      </div>
    );
  };

  const renderMiddleHeader = () => {
    return (
      <Button
        className={styles.tableMiddleAction}
        color="primary"
        variant="outline"
        onClick={() =>
          onLoadLogEntries?.(
            pagination?.page ?? 1,
            state?.tableFilters?.orderBy,
            state?.tableFilters?.searchBy,
          )
        }
      >
        <Icon name="repeat" size="md" />
        Actualizar vista
      </Button>
    );
  };

  const onHeaderClick = (column: Column<LinkLogEntry>) => {
    const currentOrderBy = state?.tableFilters?.orderBy;
    if (column?.header === currentOrderBy?.header) {
      const newDirection =
        currentOrderBy?.direction === OrderByDirection.Asc
          ? OrderByDirection.Desc
          : OrderByDirection.Asc;

      const newOrderBy = {
        ...currentOrderBy,
        direction: newDirection,
      };

      dispatch({
        type: TableFiltersTypes.SetOrderBy,
        payload: newOrderBy,
      });
      onLoadLogEntries?.(1, newOrderBy, state?.tableFilters?.searchBy);
    } else {
      const newOrderBy = linkChangesTableHeaderToOrderByObj(column?.header);
      dispatch({
        type: TableFiltersTypes.SetOrderBy,
        payload: newOrderBy as any,
      });
      onLoadLogEntries?.(1, newOrderBy, state?.tableFilters?.searchBy);
    }
  };

  const nonSortableColumns = useMemo(() => {
    const columnsSet = new Set<string>();

    columns.forEach(({ header }) => {
      if (["Acción", "Recurso Modificado"].includes(header)) {
        columnsSet.add(header);
      }
    });

    return columnsSet;
  }, [columns]);

  return (
    <div className={styles.linkHistoryTable}>
      <DataGrid
        className={styles.linkHistoryTable_table}
        columns={columns}
        nonSortableColumns={nonSortableColumns}
        batch={data}
        columnsToHide={state?.tableFilters?.columnsToHide}
        onHeaderClick={onHeaderClick}
        sortInfo={{
          header: state?.tableFilters?.orderBy?.header ?? "",
          direction:
            (state?.tableFilters?.orderBy?.direction?.toLowerCase() as SortInfo["direction"]) ??
            "desc",
        }}
        pagination={{
          page: pagination?.page ?? 1,
          total: pagination?.total ?? 0,
          size: pagination?.size ?? 10,
          onPageChange: (page: number) => {
            onLoadLogEntries?.(page, state?.tableFilters?.orderBy, state?.tableFilters?.searchBy);
          },
        }}
        header={{
          className: styles.tableHeader,
          left: renderLeftHeader(),
          middle: renderMiddleHeader(),
          right: (
            <TableFilter
              columnsToSearchBy={columnsToSearchBy}
              nonSortableColumns={nonSortableColumns}
              setColumnsToSearchBy={setColumnsToSearchBy as any}
              headerToOrderByObjCallback={linkChangesTableHeaderToOrderByObj as any}
              selectableColumns={
                columns?.map(col => col.header)?.filter(col => col !== "Acción") as any
              }
              request={(
                newOrderBy: {
                  header: string;
                  field: string;
                  direction: OrderByDirection;
                },
                newSearchBy: {
                  fields: string[];
                  text: string;
                },
              ) => {
                onLoadLogEntries?.(1, newOrderBy, newSearchBy);
              }}
            />
          ),
        }}
      />
    </div>
  );
};

export default LinkHistoryTable;
