import React, { useState } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { Checkbox } from "@foris/avocado-suite";
import css from "./checks.module.scss";

export interface IAssignment {
  blocks: boolean;
  instructor: boolean;
  classroom: boolean;
}

export interface IAssignmentProps {
  onCallback: (value: IAssignment) => void;
}

const AssignmentCheckbox: React.FC<IAssignmentProps> = ({ onCallback }) => {
  // States for handling the checkboxes
  const [blockCheck, setBlockCheck] = useState(false);
  const [instructorCheck, setInstructorCheck] = useState(false);
  const [classroomCheck, setClassroomCheck] = useState(false);
  // Hooks
  const { t } = useTranslation();

  return (
    <div className={cx(css.sharedCheckbox, css.sharedCheckbox__noPadding)}>
      <div className={css.sharedCheckbox_assignments}>
        <Checkbox
          labelRight={t(
            "advanced-search.section.forms.assignment.checkbox-assignment.without-schedule.label",
          )}
          onClick={event => {
            const value = (event.target as HTMLInputElement).checked;
            setBlockCheck(value);
            onCallback({
              blocks: value,
              instructor: instructorCheck,
              classroom: classroomCheck,
            });
          }}
        />

        <Checkbox
          labelRight={t(
            "advanced-search.section.forms.assignment.checkbox-assignment.without-teacher.label",
          )}
          onClick={event => {
            const value = (event.target as HTMLInputElement).checked;
            setInstructorCheck(value);
            onCallback({
              blocks: blockCheck,
              instructor: value,
              classroom: classroomCheck,
            });
          }}
        />

        <Checkbox
          labelRight={t(
            "advanced-search.section.forms.assignment.checkbox-assignment.without-room.label",
          )}
          onClick={event => {
            const value = (event.target as HTMLInputElement).checked;
            setClassroomCheck(value);
            onCallback({
              blocks: blockCheck,
              instructor: instructorCheck,
              classroom: value,
            });
          }}
        />
      </div>
    </div>
  );
};

export default AssignmentCheckbox;
