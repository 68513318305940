import React from "react";
import Modal from "@modules/sections/GroupsManager/components/Modal/Modal";
import { Text } from "@foris/avocado-suite";
import { useTranslation } from "react-i18next";

interface DeletePackageModalProps {
  isOpen?: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
}

const DeletePackageModal = ({ isOpen, onCancel, onConfirm }: DeletePackageModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      typeState="confirm"
      show={isOpen}
      title={t("dimension.package.modals.delete-package.title")}
      subtitle={t("dimension.package.modals.general-warning-subtitle")}
      onClose={() => onCancel?.()}
      colorButtonPrincipal="danger"
      textButtonPrincipal={t("dimension.package.modals.delete-package.btn-delete")}
      textButtonSecondary={t("dimension.package.modals.delete-package.btn-cancel")}
      onClickPrincipal={() => onConfirm?.()}
      onClickSecondary={() => onCancel?.()}
    >
      <Text>{t("dimension.package.modals.delete-package.description")}</Text>
      <br />
      <Text>{t("dimension.package.modals.delete-package.second-description")}</Text>
    </Modal>
  );
};

export default DeletePackageModal;
