import gql from "graphql-tag";

export const linkSearchMutation = gql`
  mutation($scenarioId: ID!, $originId: ID!, $filter: LinkFilterInput) {
    filter(scenarioId: $scenarioId, originId: $originId) {
      createLinkFilter(filter: $filter) {
        id
        links {
          items {
            id
            vacancies
          }
          pageInfo {
            hasPreviousPage
            hasNextPage
            page
            size
            total
          }
        }
      }
    }
  }
`;
