import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import * as R from "ramda";
import { useApolloClient } from "react-apollo";
import { useHistory, useParams, useLocation } from "react-router-dom";
import queryString from "query-string";
import useDebounce from "@modules/sections/EditSessions/hooks/useDebounce";
import queries from "./contextsearch.queries";
import Autocomplete from "@components/Autocomplete/Autocomplete";
import { Dimension } from "@models/IDimension";
import { IParams } from "@models/IParams";
import { getOptionsFilter, getQueryString } from "./contextOptions";

const FIRST_SEARCH = 6;

interface IContextSearch {
  sendDimension?: any;
  className?: string;
}

const ContextSearch: React.FC<IContextSearch> = (props: IContextSearch) => {
  const { sendDimension, className } = props;
  const client = useApolloClient();
  const { origin, scenario, workspace }: IParams = useParams();
  const history = useHistory();
  const location = useLocation();

  // create states
  const [activeSearch, setActiveSearch] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const params: any = queryString.parse(location.search);
  const [innerValueSearch, setInnerValueSearch] = useState(params.search || "");
  const valueSearch = useDebounce(innerValueSearch, 500);

  const {
    t,
    i18n: { language },
  } = useTranslation();

  // change option select - get route
  const [propFilter, setPropFilter] = useState(location.pathname.split("/")[4]);
  const valueOption = useMemo(
    () => getOptionsFilter().find(value => value.value === propFilter) || null,
    [propFilter, language],
  );
  const valueData = useMemo(() => (valueOption ? valueOption.value : ""), [valueOption]);

  const serializeOptions = useCallback(
    (data: any) => {
      const optionsSerialize = [];
      let valueLabel = "";
      if (Boolean(data?.items?.length)) {
        const courseCampus = R.view(R.lensPath(["curriculum", "program", "campus", "code"]));
        const classroomCampus = R.view(R.lensPath(["building", "campus", "code"]));

        data?.items?.forEach((value: any) => {
          const labels = {
            [Dimension.instructor]: () => `${value.code} - ${value.name}`,
            [Dimension.course]: () => `${value.code} - ${value.name} - ${courseCampus(value)}`,
            [Dimension.classroom]: () =>
              `${value.code} - ${value.name} - ${classroomCampus(value)}`,
            [Dimension.group]: () =>
              `${value.code} - ${value.name} - ${courseCampus(value?.course)} - ${
                value?.term?.name
              }`,
            [Dimension.section]: () => `${value.code} - ${value?.course?.name}`,
            [Dimension.population]: () => `${value.code} - ${value?.term?.name}`,
            [Dimension.package]: () => `${value.code} - ${value?.population?.term?.name}`,
            [Dimension.league]: () => `${value.code} - ${value?.bundle?.term?.name}`,
          };

          valueLabel = valueData in labels ? labels[valueData]() : value?.code;
          optionsSerialize.push({ label: valueLabel, value: value.id });
        });
      } else {
        const notInfo = {
          label: t("selectors.context-search.empty-results"),
          value: "empty",
        };
        optionsSerialize.push(notInfo);
      }

      if (data.pageInfo.total > FIRST_SEARCH) {
        const moreItems = {
          label: t("selectors.context-search.all-results", { count: data.pageInfo.total }),
          value: "all",
        };
        optionsSerialize.push(moreItems);
      }
      setOptions(optionsSerialize);
    },
    [valueData],
  );

  useEffect(() => {
    const queryOptions = (value: string) => {
      const valueChange = getQueryString(valueData);
      const pagination = {
        page: 1,
        size: FIRST_SEARCH,
        searchTerm: value,
      };

      return {
        query: queries[`${valueChange.toUpperCase()}_QUERY`],
        variables: {
          originId: origin,
          scenarioId: scenario,
          filter: [Dimension.package, Dimension.league].includes(valueData as Dimension)
            ? {
                pagination,
              }
            : pagination,
        },
      };
    };
    const getDataOptions = async (value: any) => {
      try {
        if (activeSearch && value !== "") {
          setLoading(true);
          const query = queryOptions(value);
          const { data } = await client.query(query);
          setLoading(false);
          const valueChange = getQueryString(valueData);
          serializeOptions(data.cube[valueChange]);
        }
      } catch (error) {
        console.error(error);
      }
    };
    const getCacheDataOptions = async (value: any) => {
      try {
        if (activeSearch && value !== "") {
          setLoading(true);
          const query = queryOptions(value);
          const data = await client.readQuery(query);
          setLoading(false);
          const valueChange = getQueryString(valueData);
          serializeOptions(data.cube[valueChange]);
        }
      } catch (error) {
        getDataOptions(value);
      }
    };
    getCacheDataOptions(valueSearch);
  }, [valueSearch, activeSearch, client, valueData, origin, scenario, serializeOptions]);

  const urlContext = `${workspace}/${scenario}/${origin}`;
  const routeSearchBy = "/scheduler/editor/searchBy";

  return (
    <Autocomplete
      className={className && className}
      filter={{
        value: valueOption ? valueOption.label : t("header.dimension.title"),
        options: getOptionsFilter(),
        onSelect: (value: any) => {
          setPropFilter(value.value);
          sendDimension && sendDimension(value.value);
        },
      }}
      input={{
        loading,
        placeholder:
          valueData === ""
            ? t("selectors.context-search.placeholder")
            : t("selectors.context-search.search-placeholder"),
        disabled: valueData === "",
        value: innerValueSearch,
        options,
        onSelect: value => {
          const link = valueData === "league" ? "link" : valueData;
          if (value.value === "all") {
            setInnerValueSearch("");
            history.push(`${routeSearchBy}/${link}/${urlContext}/?search=${valueSearch}`);
          } else {
            history.push(`/scheduler/editor/${link}/${urlContext}/${value.value}`);
          }
        },
        onEnter: () => {
          const link = valueData === "league" ? "link" : valueData;
          if (valueSearch !== "")
            history.push(`${routeSearchBy}/${link}/${urlContext}/?search=${valueSearch}`);
        },
        onChange: term => {
          setActiveSearch(true);
          setInnerValueSearch(term);
        },
      }}
    />
  );
};

export default ContextSearch;
