import gql from "graphql-tag";

const COURSESWITHSECTIONS_QUERY = gql`
  query megaSearch($scenarioId: ID!, $originId: ID!, $filter: PaginationInput!) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      coursesWithSections(filter: $filter) {
        items {
          id
          name
          code
          curriculum {
            id
            name
            program {
              id
              code
              campus {
                id
                code
                name
                __typename
              }
            }
          }
        }
        pageInfo {
          hasPreviousPage
          hasNextPage
          page
          size
          total
        }
      }
    }
  }
`;

const PACKAGES_QUERY = gql`
  query megaSearch($scenarioId: ID!, $filter: PackageFilterInput!, $originId: ID!) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      packages(filter: $filter) {
        items {
          id
          code
          index
          population {
            id
            curriculum
            level
            term {
              id
              name
            }
            program {
              id
              code
              name
            }
          }
        }
        pageInfo {
          size
          page
          total
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`;

const POPULATIONS_QUERY = gql`
  query megaSearch($scenarioId: ID!, $filter: PaginationInput!, $originId: ID!) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      populations(filter: $filter) {
        items {
          id
          level
          code
          curriculum
          term {
            id
            name
          }
          program {
            id
            code
            name
          }
        }
        pageInfo {
          size
          page
          total
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`;

const SECTIONS_QUERY = gql`
  query megaSearch($scenarioId: ID!, $filter: PaginationInput!, $originId: ID!) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      sections(filter: $filter) {
        items {
          id
          code
          course {
            id
            name
            code
          }
          courseComponent {
            id
            component {
              id
              code
            }
          }
        }
        pageInfo {
          size
          page
          total
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`;
const INSTRUCTORS_QUERY = gql`
  query megaSearch($scenarioId: ID!, $filter: PaginationInput!, $originId: ID!) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      instructors(filter: $filter) {
        items {
          id
          name
          code
        }
        pageInfo {
          size
          page
          total
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`;

const CLASSROOMS_QUERY = gql`
  query megaSearch($scenarioId: ID!, $filter: PaginationInput!, $originId: ID!) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      classrooms(filter: $filter) {
        items {
          id
          code
          name
          capacity
          allowedOverflow
          classroomType {
            id
            code
            name
          }
          building {
            id
            name
            campus {
              id
              name
              code
            }
          }
        }
        pageInfo {
          size
          page
          total
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`;

export const LINKS_QUERY = gql`
  query megaSearch($scenarioId: ID!, $filter: LinkFilterInput!, $originId: ID!) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      links(filter: $filter) {
        items {
          id
          code
          vacancies
          groups {
            id
            code
            campus {
              id
            }
          }
          bundle {
            id
            term {
              id
              name
              code
            }
          }
          course {
            id
            name
            code
            curriculum {
              program {
                campus {
                  id
                  code
                }
              }
            }
            courseComponents {
              id
              component {
                id
                code
                name
              }
            }
          }
          sectionsCount
        }
        pageInfo {
          size
          page
          total
          hasNextPage
          hasPreviousPage
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

export const GROUPS_QUERY = gql`
  query groupSearch($scenarioId: ID!, $originId: ID!, $filter: PaginationInput) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      groups(filter: $filter) {
        items {
          id
          code
          label
          name
          term {
            id
            name
          }
          course {
            code
            name
            curriculum {
              id
              name
              program {
                id
                code
                campus {
                  id
                  code
                  name
                  __typename
                }
              }
            }
          }
        }
        pageInfo {
          hasPreviousPage
          hasNextPage
          page
          size
          total
        }
      }
    }
  }
`;

export default {
  COURSESWITHSECTIONS_QUERY,
  PACKAGES_QUERY,
  POPULATIONS_QUERY,
  SECTIONS_QUERY,
  INSTRUCTORS_QUERY,
  CLASSROOMS_QUERY,
  LINKS_QUERY,
  GROUPS_QUERY,
};
