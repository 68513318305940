import React, { useState } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { TextField, Text, Heading } from "@foris/avocado-suite";
import css from "./blocks.module.scss";

export interface IBlocksValues {
  minBlock: string;
  maxBlock: string;
}

export interface ICapacityReturn {
  className?: string;
  onCallback: (value: IBlocksValues, error?: boolean) => void;
}

const BlocksInput: React.FC<ICapacityReturn> = ({ onCallback, className }) => {
  // States for validations
  const [error, setError] = useState(false);
  const [minBlock, setMinBlock] = useState("");
  const [maxBlock, setMaxBlock] = useState("");

  // Hooks
  const { t } = useTranslation();

  const handleError = (min: string, max: string) => {
    if (parseInt(min) > parseInt(max)) {
      setError(true);
      return true;
    } else {
      setError(false);
      return false;
    }
  };

  return (
    <div className={css.sharedBlocks}>
      <Heading type="h4" className={css.sharedBlocks_title}>
        {t("advanced-search.instructor.forms.assigned-blocks.title")}
      </Heading>
      <div className={cx(css.sharedBlocks_grid, className)}>
        <TextField
          type="number"
          placeholder={t("advanced-search.instructor.forms.assigned-blocks.input-min.placeholder")}
          value={minBlock}
          onChange={e => {
            const value = e.target.value;

            if (parseInt(value) >= 0) {
              setMinBlock(value);
              const error = handleError(value, maxBlock);
              onCallback(
                {
                  minBlock: value,
                  maxBlock: maxBlock,
                },
                error,
              );
            }
          }}
        />

        <span className={css.separator}>-</span>

        <TextField
          type="number"
          placeholder={t("advanced-search.instructor.forms.assigned-blocks.input-max.placeholder")}
          value={maxBlock}
          onChange={e => {
            const value = e.target.value;

            if (parseInt(value) >= 0) {
              setMaxBlock(value);
              const error = handleError(minBlock, value);
              onCallback(
                {
                  minBlock: minBlock,
                  maxBlock: value,
                },
                error,
              );
            }
          }}
        />
      </div>

      {error && (
        <Text type="sm" className={css.sharedBlocks_error}>
          {t("advanced-search.instructor.forms.assigned-blocks.error-message")}
        </Text>
      )}
    </div>
  );
};

export default BlocksInput;
