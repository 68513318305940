import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Heading, Button } from "@foris/avocado-suite";
import { AppContext } from "../../context/EditSessionsContext";
import { Types } from "../../context/switchPage.reducer";
import { Types as TypeResult } from "../../context/result.reducer";
import { IContextApp, ContextApp } from "@config/Context/contextApp";
import { Types as FormTypes } from "../../context/formData.reducer";
import css from "./resultsEditHeader.module.scss";

interface ResultsEditHeaderProps {
  onSubmit: () => void;
}

const ResultsEditHeader: React.FC<ResultsEditHeaderProps> = (props: ResultsEditHeaderProps) => {
  const { onSubmit } = props;
  const { state, dispatch } = useContext(AppContext);
  const context: IContextApp = useContext(ContextApp);
  const userCanEdit =
    context?.user?.permissions?.update && context?.user?.abilities?.can_edit_assignment;

  const { t } = useTranslation();

  const numberOfSessionsToValidate = useMemo(
    () =>
      (state?.form?.savedSessions?.length ?? 0) +
      (state?.form?.sessionsToCreate.length ?? 0) +
      Object.keys(state?.form?.sessionsToDelete ?? {}).length,
    [state?.form?.savedSessions, state?.form?.sessionsToCreate, state?.form?.sessionsToDelete],
  );

  const createValidation = state?.result?.createValidation;
  const editValidation = state?.result?.resultValidation;
  const createUserCanSkipValidations = createValidation
    ? createValidation?.userCanSkipValidations
    : true;
  const editUserCanSkipValidations = editValidation ? editValidation?.userCanSkipValidations : true;
  const userCanSkipValidations = createUserCanSkipValidations && editUserCanSkipValidations;

  const title = useMemo(() => {
    if (numberOfSessionsToValidate === 1) {
      return t("assignation-edit.incidence-table.header.single-change-to-validate");
    }

    return t("assignation-edit.incidence-table.header.changes-to-validate", {
      count: numberOfSessionsToValidate,
    });
  }, [numberOfSessionsToValidate]);

  const userCanMutateSessions = () => {
    const abilitiesValidations = {
      InstructorAssignmentNotAllowed: true,
      BlocksAssignmentNotAllowed: true,
      ClassroomsAssignmentNotAllowed: true,
      IntervalsAssignmentNotAllowed: true,
    };

    const sessionPayload = [
      ...(editValidation?.sessionsPayload ?? []),
      ...(createValidation?.sessionsPayload ?? []),
    ];

    if (sessionPayload.length === 0) {
      return true;
    }

    return sessionPayload.every(({ validationErrors }) =>
      validationErrors.every(
        ({ __typename }) => !(__typename && __typename in abilitiesValidations),
      ),
    );
  };

  return (
    <header className={css.resultsEditHeader}>
      <Heading className={css.resultsEditHeader_title} type="h3">
        {title}
      </Heading>

      <div className={css.resultsEditHeader_actions}>
        <Button
          variant="ghost"
          onClick={() => {
            dispatch({ type: FormTypes.CleanFormCreateSession });
            dispatch({ type: Types.FormPage, payload: true });
            dispatch({ type: TypeResult.SelectSession, payload: null });
            dispatch({ type: TypeResult.ResultValidation, payload: null });
            dispatch({ type: TypeResult.CreateValidation, payload: null });
          }}
        >
          {t("assignation-edit.incidence-table.header.back-to-edit")}
        </Button>

        {userCanEdit && (
          <Button disabled={!userCanSkipValidations || !userCanMutateSessions()} onClick={onSubmit}>
            {t("assignation-edit.incidence-table.header.confirm-changes")}
          </Button>
        )}
      </div>
    </header>
  );
};

export default ResultsEditHeader;
