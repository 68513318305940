import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useQuery } from "react-apollo";
import queryString from "query-string";
import Layout from "@common/layout/Layout";
import GroupTable from "./GroupTable";
import groupsCubeQuery from "../graphql/groupsCubeQuery.graphql";
import groupsFilterQuery from "../graphql/groupsFilterQuery.graphql";
import useGetQueryVariables from "@dimensions/hooks/useGetQueryVariables";
import { GroupPage } from "@models/ISchema";

const GroupSearch = () => {
  // Using React Router's useLocation hook to get the query parameters from the URL
  const location = useLocation();
  const params: queryString.ParsedQuery<string> = queryString.parse(location.search);

  // State for managing current page and size per page for pagination
  const [sizePerPage, setSizePerPage] = useState(20);
  const [page, setPageCount] = useState(1);

  // Get the query variables using the custom hook, based on the URL parameters and pagination settings
  const variables = useGetQueryVariables(params, page, undefined, sizePerPage);

  // Choose the appropriate query based on the 'advance' parameter from the URL
  const querySearch = params?.advance ? groupsFilterQuery : groupsCubeQuery;

  // Fetch data using the Apollo useQuery hook with the chosen query and variables
  const { loading, error, data } = useQuery(querySearch, { variables });

  // Assign the fetched data depending on the query used
  let groups: GroupPage = data?.cube?.groups;
  if (params?.advance) {
    groups = data?.filter?.groups;
  }

  // Render the Layout component and pass the groups data to the GroupTable component for display
  return (
    <Layout contextSearch>
      <GroupTable
        data={groups?.items} // Items from groups data
        search={params?.search?.toString()} // Search query from URL
        pageInfo={groups?.pageInfo} // Page information for pagination
        setPage={(value: number) => setPageCount(value)} // Function to update the page number
        loading={loading} // Pass loading state from useQuery
        error={error} // Pass error state from useQuery
        onChangeSizePerPage={setSizePerPage} // Function to update the size of items per page
      />
    </Layout>
  );
};

export default GroupSearch;
