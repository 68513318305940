import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Option } from "react-select/lib/filters";
import AdvanceSelector from "../advance-selector";
import useGetBuildingOptions from "@modules/shared/selectors/hooks/useGetBuildingOptions";

interface AdvancedBuildingSelectorProps {
  scenario: string;
  campusId?: string;
  onChange?: (option: Option) => void;
}

const AdvancedBuildingSelector = ({
  scenario,
  campusId,
  onChange,
}: AdvancedBuildingSelectorProps) => {
  const { getCacheBuildingOptions, optionsBuilding } = useGetBuildingOptions({
    scenario,
    campusId,
  });

  useEffect(() => {
    getCacheBuildingOptions();
  }, []);

  const { t } = useTranslation();

  return (
    <AdvanceSelector
      notShowLabel
      dimension={t("advanced-search.classroom.forms.building.select-property.placeholder")}
      selectPlaceholder={t("advanced-search.classroom.forms.building.select-value.placeholder")}
      options={optionsBuilding}
      onChange={onChange}
    />
  );
};

export default AdvancedBuildingSelector;
