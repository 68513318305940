/**
 * RestActions component
 *
 * Displays a set of grouped action cards related to data management,
 * student management, programming tools, and mass actions.
 *
 * Each card contains an icon, title, description, and one or more action buttons.
 * Some buttons perform navigations using React Router and custom navigation hooks.
 */

import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Icon } from "@foris/avocado-icons";
import { Box, Button, Heading, Text } from "@foris/avocado-suite";
import useNavigationUrl from "@common/hooks/useNavigationUrl";
import css from "./restActions.module.scss";

const RestActions: FC = () => {
  // Hooks for translations and routing
  const { t } = useTranslation();
  const history = useHistory();
  const { bookingListUrl } = useNavigationUrl();

  return (
    <div className={css.restActions}>
      <Box className={css.restActions_box}>
        <Icon name="database" size="lg" />
        <article className={css.content}>
          <Heading className={css.content_title} type="h2">
            {t("action-cards.data-management.title")}
          </Heading>

          <Text className={css.content_description}>
            {t("action-cards.data-management.description")}
          </Text>
          <Button className={css.content_button} variant="secondary">
            {t("action-cards.data-management.btn-catalogs")}
          </Button>
        </article>
      </Box>

      <Box className={css.restActions_box}>
        <Icon name="user-check" size="lg" />

        <article className={css.content}>
          <Heading className={css.content_title} type="h2">
            {t("action-cards.student-management.title")}
          </Heading>

          <Text className={css.content_description}>
            {t("action-cards.student-management.description")}
          </Text>
          <div className={css.content_buttonGroup}>
            <Button className={css.content_button} variant="secondary">
              {t("action-cards.student-management.btn-projections")}
            </Button>
            <Button className={css.content_button} variant="secondary">
              {t("action-cards.student-management.btn-lawsuit-editor")}
            </Button>
          </div>
        </article>
      </Box>

      <Box className={css.restActions_box}>
        <Icon name="report" size="lg" />

        <article className={css.content}>
          <Heading className={css.content_title} type="h2">
            {t("action-cards.programming-management.title")}
          </Heading>

          <Text className={css.content_description}>
            {t("action-cards.programming-management.description")}
          </Text>

          <div className={css.content_buttonGroup}>
            <Button className={css.content_button} variant="secondary">
              {t("action-cards.programming-management.btn-assignments")}
            </Button>
            <Button
              className={css.content_button}
              variant="secondary"
              onClick={() => history.push(bookingListUrl())}
            >
              {t("action-cards.programming-management.btn-reservations")}
            </Button>
            <Button className={css.content_button} variant="secondary">
              {t("action-cards.programming-management.btn-analytics")}
            </Button>
          </div>
        </article>
      </Box>

      <Box className={css.restActions_box}>
        <Icon name="layers" size="lg" />

        <article className={css.content}>
          <div className={css.content_header}>
            <Heading className={css.title} type="h2">
              {t("action-cards.mass-actions.title")}
            </Heading>

            <Button variant="ghost" rightIcon="external-link" size="sm">
              {t("action-cards.mass-actions.btn-monitor")}
            </Button>
          </div>

          <Text className={css.content_description}>
            {t("action-cards.mass-actions.description")}
          </Text>

          <Button className={css.content_button} variant="secondary">
            {t("action-cards.mass-actions.btn-programming-load")}
          </Button>
        </article>
      </Box>
    </div>
  );
};

export default RestActions;
