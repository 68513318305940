import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Loading, Checkbox } from "@foris/avocado-suite";
import { useHistory, useParams } from "react-router-dom";
import { IReturnComponent } from "./components/component-item/ComponentItem";
import Layout from "@common/layout/Layout";
import Header from "@common/components/Header/Header";
import AddLinkSection from "./components/add-link-section";
import Selectors from "./components/selectors";
import AddGroupSection from "./components/add-group-section";
import useCreateLink from "../hooks/useCreateLink";
import { ContextEDH } from "@context/ContextEDH";
import { PathParams } from "@edhtypes/general";
import css from "./createSection.module.scss";

const CreateSection: React.FC<any> = () => {
  // States for handle the form
  const { state } = useContext(ContextEDH);
  const [isInStep2, setIsInStep2] = useState(false);
  const [createSections, setCreateSections] = useState<IReturnComponent[]>([]);
  const [selectorInfo, setSelectorInfo] = useState(null);
  const [showGroupForm, setShowGroupForm] = useState(false);
  const [showGroupFormActivator, setShowGroupFormActivator] = useState(false);
  const [isGroupFormValid, setIsGroupFormValid] = useState(false);
  const [isLinkFormValid, setIsLinkFormValid] = useState(false);
  // Hooks for handle
  const { scenario, origin, workspace }: PathParams = useParams();
  const history = useHistory();
  const { t } = useTranslation();

  const canCreateGroupInBundleView = !!state?.base?.base?.user?.abilities
    ?.can_create_groups_in_bundle_view;
  const createGroupWithinLinkCreation = state?.base?.createGroupWithinLinkCreation;

  const handleReturnStep2 = data => {
    setSelectorInfo(data);
    setIsInStep2(true);
  };

  const courseId = selectorInfo && selectorInfo.idCourse ? selectorInfo.idCourse : null;
  const termId = selectorInfo && selectorInfo.idTerm ? selectorInfo.idTerm : null;
  const programId = selectorInfo && selectorInfo.idProgram ? selectorInfo.idProgram : null;

  const handleGroupFormChange = payload => {
    const { groupCode, groupVacancies } = payload;

    setSelectorInfo({
      ...selectorInfo,
      groupCode,
      groupVacancies,
    });
  };

  const handleShowGroupForm = (shouldShow: boolean) => {
    if (
      createGroupWithinLinkCreation === "DISABLED" ||
      !canCreateGroupInBundleView ||
      !termId ||
      !courseId
    ) {
      setShowGroupForm(false);
      setShowGroupFormActivator(false);
      return;
    }

    if (createGroupWithinLinkCreation === "OPTIONAL") {
      setShowGroupFormActivator(shouldShow);
      return;
    }

    setShowGroupForm(shouldShow);
  };

  const { handleCreateLink, isLoading, linkCreationErrors } = useCreateLink({
    scenario,
    origin,
    workspace,
  });

  const mutationErrors = useMemo(() => {
    const groupErrors = [];
    const linkErrors = [...(linkCreationErrors?.validationStandardArray ?? [])];

    linkCreationErrors?.validationArray?.forEach(error => {
      if (
        error?.reason && [
          "NUMERIC_GROUP_CODE_VALIDATION",
          "GROUP_CODE_ALREADY_TAKEN",
          "INVALID_GROUP_CODE",
        ]
      ) {
        groupErrors.push(error);
      } else {
        linkErrors.push(error);
      }
    });

    return {
      groupErrors,
      linkErrors,
    };
  }, [linkCreationErrors]);

  const handleSubmit = () => {
    handleCreateLink({
      courseId,
      termId: String(termId),
      createSections,
      groupCode: showGroupForm ? selectorInfo?.groupCode ?? undefined : undefined,
      programId: programId as string,
      groupVacancies: showGroupForm ? selectorInfo?.groupVacancies ?? undefined : undefined,
    });
  };

  useEffect(() => {
    if (state?.base?.createGroupWithinLinkCreation === "MANDATORY") {
      const abilities = state?.base?.base?.user?.abilities;

      (!abilities?.can_create_groups_in_bundle_view || !abilities?.can_edit_links_and_sections) &&
        history?.goBack();
    }
  }, [state?.base?.createGroupWithinLinkCreation]);

  return (
    <Layout contextSearch>
      {isLoading && <Loading />}
      <Header
        title={t("create-group-link.title")}
        breadcrumbItems={[
          { title: t("create-group-link.breadcrumb.previous"), path: "/" },
          { title: t("create-group-link.breadcrumb.current") },
        ]}
      />

      <div className={css.createSection}>
        <div className={css.createSection_content}>
          <Selectors onReturn={handleReturnStep2} />

          {isInStep2 && courseId && termId && (
            <AddLinkSection
              key={courseId}
              courseId={parseInt(courseId)}
              termId={parseInt(termId)}
              programId={programId}
              externalErrors={mutationErrors.linkErrors}
              onShowNextStep={handleShowGroupForm}
              onValidForm={setIsLinkFormValid}
              onCreateSections={setCreateSections}
            />
          )}

          {showGroupFormActivator && (
            <Checkbox
              labelRight={t("create-group-link.checkbox.label")}
              checked={showGroupForm}
              onChange={e => setShowGroupForm(e.target.checked)}
            />
          )}

          {showGroupForm && (
            <AddGroupSection
              key={`course-${courseId}`}
              externalErrors={mutationErrors.groupErrors}
              onValidForm={setIsGroupFormValid}
              onStateChange={handleGroupFormChange}
            />
          )}
        </div>

        <div className={css.createSection_actionsButtons}>
          <Button variant="ghost" onClick={() => history?.replace("/home")}>
            {t("create-group-link.btn-cancel")}
          </Button>
          <Button
            disabled={
              (showGroupForm && !isGroupFormValid) || !courseId || !termId || !isLinkFormValid
            }
            onClick={handleSubmit}
          >
            {showGroupForm
              ? t("create-group-link.btn-validate-and-create")
              : t("create-group-link.btn-create")}
          </Button>
        </div>
      </div>
    </Layout>
  );
};

export default CreateSection;
