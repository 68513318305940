import React from "react";
import { useTranslation } from "react-i18next";
import { Option } from "react-select/lib/filters";
import AdvanceSelector from "../advance-selector";
import useGetCampusOptions from "@modules/shared/selectors/hooks/useGetCampusOptions";

interface AdvanceCampusSelectorProps {
  scenario: string;
  onChange?: (option: Option) => void;
}

const AdvanceCampusSelector = ({ scenario, onChange }: AdvanceCampusSelectorProps) => {
  const { loadOptions } = useGetCampusOptions({ scenario });
  const { t } = useTranslation();

  return (
    <AdvanceSelector
      isPaginated
      dimension={t("advanced-search.classroom.forms.campus.select-property.placeholder")}
      selectPlaceholder={t("advanced-search.classroom.forms.campus.select-value.placeholder")}
      loadOptions={loadOptions}
      onChange={onChange}
    />
  );
};

export default AdvanceCampusSelector;
