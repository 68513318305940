import React from "react";
import { Pill, Tooltip } from "@foris/avocado-suite";
import { useTranslation } from "react-i18next";

interface SystemLabelProps {
  label: string;
}

const SystemLabel = ({ label }: SystemLabelProps) => {
  const { t } = useTranslation();

  return (
    <Tooltip label={t("header.tag-automatically.tooltip")}>
      <Pill textColor="gray-80" color="gray-10">
        {label}
      </Pill>
    </Tooltip>
  );
};

export default SystemLabel;
