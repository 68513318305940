import { useParams } from "react-router-dom";
import { PathParams } from "@edhtypes/general";

const useContextUrl = () => {
  const { workspace, origin, scenario, id }: PathParams = useParams();

  return {
    contextUrl: `${workspace}/${scenario}/${origin}`,
    workspace,
    origin,
    scenario,
    id,
  };
};

export default useContextUrl;
