import React from "react";
import { useTranslation } from "react-i18next";
import { Pill } from "@foris/avocado-suite";
import DimensionsSearchResults from "@dimensions/common/dimensions-search-results";
import useGetTitle from "../../hooks/useGetTitle";
import {
  getColumnCell,
  getHeadCell,
  getLinkCell,
  getOpenGroupCell,
  getLabelCell,
} from "@dimensions/utils/tableUtils";
import { ISearchProps } from "@dimensions/common/ISearch";
import { Group } from "@models/ISchema";
import { DimensionNameEs } from "@models/IDimension";
import css from "@dimensions/utils/tableUtils.module.scss";
import useNavigationUrl from "@common/hooks/useNavigationUrl";

/**
 * GroupTable component that displays a table of groups with relevant information.
 *
 * This component fetches groups data and renders it in a table format, displaying information
 * such as goruos group, campus, period, bundle, league, quota-enrolled, labels and etc.
 * It integrates pagination, search, and internationalization (i18n) for multi-language support.
 *
 * @param {DimensionSearchResultsProps} props - The properties passed to the component.
 * @param {Array<Group>} props.data - Data of groups to display in the table.
 * @param {string} props.search - The search query to filter results.
 * @param {Object} props.pageInfo - Pagination information (total results, current page, etc.).
 * @param {Function} props.setPage - Function to change the current page.
 * @param {boolean} props.loading - Boolean indicating if the data is still loading.
 * @param {boolean} props.error - Boolean indicating if there was an error during data fetch.
 * @param {Function} props.onChangeSizePerPage - Function to handle page size changes.
 * @returns {JSX.Element} - The rendered GroupTable component.
 *
 * @example
 * <GroupTable
 *   data={groupsData}
 *   search="Math"
 *   pageInfo={pageInfo}
 *   setPage={setPage}
 *   loading={isLoading}
 *   error={hasError}
 *   onChangeSizePerPage={handlePageSizeChange}
 * />
 */

const GroupTable = (props: ISearchProps) => {
  const { data, search, pageInfo, error, loading, setPage, onChangeSizePerPage } = props;

  // Get the dynamic title for the search results using the custom hook `useGetTitle`
  const title = useGetTitle(search, pageInfo?.total || 0, "grupo");

  // Get the context URL for advanced search functionality
  const { advancedSearchUrl, editorUrl, bundleUrl } = useNavigationUrl();
  const getEditorUrl = (dimension: "group" | "link", id: string) => editorUrl(dimension, id);

  // Use the i18n hook to get translation functions
  const { t } = useTranslation();

  // Formats group capacity, enrollments, and waitlist count into a column cell
  const capacityCell = (group: Group) => {
    const capacity = group?.capacity;
    const enrollmentsCount = group?.enrollmentStats?.enrollmentsCount
      ? group.enrollmentStats?.enrollmentsCount
      : "sin inscritos";
    const waitingListsCount = group?.enrollmentStats?.waitingListsCount
      ? group.enrollmentStats?.waitingListsCount
      : "";

    return getColumnCell(
      `${capacity} | ${enrollmentsCount}  ${waitingListsCount && `(+${waitingListsCount})`}`,
    );
  };

  // Define the columns for the table, including the header and cell rendering logic
  const columns = [
    {
      header: getHeadCell(t("search-results.dimension.group.table.header.group")),
      renderCell: ({ id, code, name }: Group) =>
        getLinkCell(getEditorUrl("group", id), null, `${code} ${name}`),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "210px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.group.table.header.campus")),
      renderCell: ({ course }: Group) => getColumnCell(course?.curriculum?.program?.campus?.code),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "110px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.group.table.header.period")),
      renderCell: ({ term }: Group) => getColumnCell(term?.code),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "110px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.group.table.header.bundle")),
      renderCell: ({ links }: Group) =>
        getLinkCell(bundleUrl(links?.[0]?.bundle?.id), null, `${links?.[0]?.bundle?.code}`),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "110px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.group.table.header.league")),
      renderCell: ({ links }: Group) =>
        getLinkCell(getEditorUrl("link", links?.[0]?.id), null, `${links?.[0]?.code}`),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "110px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.group.table.header.quota-enrolled")),
      renderCell: capacityCell,
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "190px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.group.table.header.labels")),
      renderCell: (group: Group) => getLabelCell(group?.labels),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "145px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.group.table.header.status.title")),
      renderCell: (group: Group) => (
        <Pill className={css.columnPill}>
          {group?.isActive
            ? t("search-results.dimension.group.table.header.status.active")
            : t("search-results.dimension.group.table.header.status.inactive")}
        </Pill>
      ),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "110px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.group.table.header.visibility")),
      renderCell: (group: Group) => getOpenGroupCell(group.visibleForEnrollment),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "120px" },
      },
    },
  ];

  // Render the DimensionsSearchResults component with the table data and configurations
  return (
    <DimensionsSearchResults
      onChangeSizePerPage={onChangeSizePerPage}
      title={title.getTitle}
      columns={columns}
      data={data}
      pageInfo={pageInfo}
      setPage={setPage}
      loading={loading}
      error={error}
      dimension={t("dimension.group.title") as DimensionNameEs}
      advanceLink={advancedSearchUrl("group")}
    />
  );
};

export default GroupTable;
