import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox, Heading } from "@foris/avocado-suite";
import css from "./checks.module.scss";

export interface IFictionalProps {
  onCallback: (value: boolean) => void;
}

const FictionalCheckbox: React.FC<IFictionalProps> = (props: IFictionalProps) => {
  const [fictionalCheck, setFictionalCheck] = useState(false);

  const { t } = useTranslation();
  return (
    <div className={css.sharedCheckbox}>
      <Heading type="h4" className={css.sharedCheckbox_title}>
        {t("advanced-search.instructor.forms.fictional.title")}
      </Heading>
      <Checkbox
        labelRight={t("advanced-search.instructor.forms.fictional.checkbox.label")}
        onClick={() => {
          setFictionalCheck(!fictionalCheck);
          props.onCallback(!fictionalCheck);
        }}
      />
    </div>
  );
};

export default FictionalCheckbox;
