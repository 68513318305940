import React, { useContext, useMemo, useState } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-apollo";
import { useParams } from "react-router-dom";
import { Heading, Button } from "@foris/avocado-suite";
import { IParams } from "@models/IParams";
import { InstructorPage, Session } from "@models/ISchema";
import { AppContext } from "../../../context/EditSessionsContext";
import { Types } from "../../../context/formData.reducer";
import { Types as TypeSwitch } from "../../../context/switchPage.reducer";
import { instructorsAdvancedSearch } from "../../../graphql/instructorAdvanceSearch.query";
import { daysEn } from "@utils/days";
import AdvanceTable from "../../../components/Instructor/AdvanceTable";
import css from "./instructorAdvanceSearch.module.scss";

const InstructorAdvanceSearch: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);
  const [page, setPage] = useState(1);
  const [selected, setSelected] = useState(state?.form?.editedSessions?.instructors);
  const { scenario, origin }: IParams = useParams();
  const selectedCreate = state?.form?.selectedCreateSession;
  const size = 10;
  let codeSessions = [];
  if (!selectedCreate) {
    codeSessions = state?.form?.selectedSessions.map(value => value.id);
  } else {
    codeSessions = ["Nueva sesión"];
  }

  const { t } = useTranslation();

  const intervalsCheck = state?.form?.editedSessions?.intervals?.filter(
    value => value.checked === true,
  );

  const availabilities = state?.form?.selectedSessions
    ?.map(_value => {
      const value = _value as Session;
      const intervals =
        intervalsCheck.length > 0
          ? intervalsCheck.map(value => parseInt(value.id))
          : value?.assignment?.intervals.map(value => parseInt(value.id));
      const dayEdited = state?.form?.editedSessions?.blocks?.day;
      const day = dayEdited ?? value?.assignment?.blockRange?.start?.day;
      if (day) {
        let dayNumber = daysEn.findIndex(value => value === day);
        dayNumber = dayNumber || dayNumber === 0 ? dayNumber + 1 : 0;
        const startEdited = state?.form?.editedSessions?.blocks?.startTime;
        const start = startEdited ?? value?.assignment?.blockRange?.start?.startingTime;
        const endEdited = state?.form?.editedSessions?.blocks?.endTime;
        const end = endEdited ?? value?.assignment?.blockRange?.end?.endingTime;
        return {
          dayId: [dayNumber?.toString()],
          blockRange: {
            start: start,
            end: end,
          },
          intervals,
        };
      }
      return null;
    })
    .filter(value => value !== null);

  const variables = useMemo(() => {
    const shouldFilter = state?.page?.active?.advanceSearchFilterInstructor;
    let fieldsAnd = [];

    if (shouldFilter) {
      fieldsAnd = [
        {
          courseId: {
            eq: parseInt(state?.link?.info?.course?.id),
          },
        },
      ];
    }

    return {
      pagination: {
        page,
        size,
      },
      fields: {
        and: [
          ...fieldsAnd,
          {
            availabilities,
          },
        ],
      },
    };
  }, [state?.page?.active?.advanceSearchFilterInstructor, page]);

  const variablesQuery = {
    originId: origin,
    scenarioId: scenario,
    filter: variables,
  };

  const { loading, error, data, refetch: fetchInstructors } = useQuery(instructorsAdvancedSearch, {
    variables: variablesQuery,
  });

  if (error) console.error(error);

  const dataQuery: InstructorPage = data?.cube?.instructorsAdvancedSearch;

  return (
    <article className={css.advanceSearch}>
      <header className={css.advanceSearch_header}>
        <Heading type="h2" className={css.headerTitle}>
          {t("assignation-edit.advanced-search.instructor.header.title", {
            count: dataQuery?.pageInfo?.total ?? 0,
          })}
        </Heading>

        <span className={css.separatorTitle} />

        <Heading type="h3" className={css.headerSubtitle}>
          {codeSessions?.length === 1
            ? t("assignation-edit.advanced-search.instructor.header.session")
            : t("assignation-edit.advanced-search.instructor.header.sessions")}{" "}
          {codeSessions.join(" | ")}
        </Heading>

        {state?.page?.active?.advanceSearchFilterInstructor ? (
          <>
            <span className={css.separatorTitle} />
            <Heading type="h3" className={css.headerSubtitle}>
              {t("assignation-edit.advanced-search.instructor.header.only-filtered")}
            </Heading>
          </>
        ) : null}
      </header>

      <section className={css.content}>
        <AdvanceTable
          selected={selected}
          setSelected={setSelected}
          loading={loading}
          data={dataQuery?.items}
          size={size}
          total={dataQuery?.pageInfo?.total}
          page={page}
          onPageChange={page => {
            setPage(page);
            fetchInstructors();
          }}
        />
      </section>

      <footer className={cx(css.footer, "container-row")}>
        <Button
          onClick={() => {
            dispatch({ type: TypeSwitch.FormPage, payload: true });
          }}
          variant="ghost"
          className={css.footer_btn}
        >
          {t("assignation-edit.advanced-search.instructor.actions.cancel")}
        </Button>
        <Button
          className={css.footer_btn}
          disabled={dataQuery?.pageInfo?.total === 0}
          onClick={() => {
            dispatch({
              type: Types.InstructorEditedSessions,
              payload: {
                instructors: selected,
              },
            });
            state?.form?.selectedSessions?.length &&
              dispatch({ type: Types.SavedSessions, payload: [state.form.editedSessions] });
            dispatch({ type: TypeSwitch.FormPage, payload: true });
          }}
        >
          {t("assignation-edit.advanced-search.instructor.actions.save")}
        </Button>
      </footer>
    </article>
  );
};

export default InstructorAdvanceSearch;
