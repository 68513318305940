import React from "react";
import { CardNotification, Text } from "@foris/avocado-suite";
import { Link } from "@models/ISchema";
import { Link as RouterLink } from "react-router-dom";
import i18n from "@config/i18n";
import css from "../PackageLinkManagement/packageLinkManagement.module.scss";

interface LinkAssignmentErrorsProps {
  origin: string;
  scenario: string;
  workspace: string;
  errorMessagesByType: {
    type: string;
    userCanSkipValidations: boolean;
    links: {
      [key: string]: Link;
    };
  }[];
}

type ErrorMessageProps = {
  origin: string;
  scenario: string;
  workspace: string;
  type: string;
  links: { [key: string]: Link };
  userCanSkipValidations: boolean;
};

const getErrorTitle = (type: string, userCanSkipValidations: boolean) => {
  if (type === "ClashesBetweenPackages") {
    return !userCanSkipValidations
      ? i18n.t("package-link-management.errors.clashes-between-packages.title")
      : i18n.t("package-link-management.errors.clashes-between-packages.warning-title");
  }

  return i18n.t("package-link-management.errors.vinculation-error");
};

const getErrorLinkPage = (origin: string, scenario: string, workspace: string, links: Link[]) => {
  const baseRoute = `/scheduler/editor/link/${workspace}/${scenario}/${origin}`;

  return links.map((link, index) => {
    return (
      <>
        <RouterLink key={link.id} to={`${baseRoute}/${link?.id}`} className={css.packageLink}>
          {link?.code}
        </RouterLink>
        {index + 1 < links.length ? ", " : ""}
      </>
    );
  });
};

export const isErrorMessage = (type: string, userCanSkipValidations: boolean) => {
  if (type === "ClashesBetweenPackages" && userCanSkipValidations) {
    return false;
  }

  return true;
};

const getErrorMessage = ({
  links,
  type,
  userCanSkipValidations,
  origin,
  scenario,
  workspace,
}: ErrorMessageProps) => {
  const linksArr = Object.values(links);
  const linkCodes = getErrorLinkPage(origin, scenario, workspace, linksArr);

  if (type === "ClashesBetweenPackages") {
    const isPlural = linksArr?.length > 1;
    const isError = isErrorMessage(type, userCanSkipValidations);
    const pluralLetter = isPlural ? "s" : "";

    return (
      <Text type="sm">
        {i18n.t("package-link-management.errors.clashes-between-packages.message", {
          pluralLetter,
        })}{" "}
        {linkCodes} {i18n.t("package-link-management.errors.clashes-between-packages.message-2")}
        {isError
          ? i18n.t("package-link-management.errors.clashes-between-packages.complementary-message")
          : ""}
      </Text>
    );
  }

  if (type === "InvalidLinksCourse") {
    const isSingular = linksArr.length <= 1;

    return (
      <Text type="sm">
        {isSingular
          ? i18n.t("package-link-management.errors.invalid-links-course.singular-message")
          : i18n.t("package-link-management.errors.invalid-links-course.plural-message")}{" "}
        {linkCodes}{" "}
        {i18n.t("package-link-management.errors.invalid-links-course.message-2", {
          letter: isSingular ? "" : "n",
        })}
      </Text>
    );
  }

  return "";
};

const LinkAssignmentErrors = ({
  errorMessagesByType,
  origin,
  scenario,
  workspace,
}: LinkAssignmentErrorsProps) => {
  return (
    <>
      {errorMessagesByType?.map(({ links, type, userCanSkipValidations }) => (
        <CardNotification
          key={type}
          className={css.errorCard}
          state={!isErrorMessage(type, userCanSkipValidations) ? "warning" : "error"}
          title={getErrorTitle(type, userCanSkipValidations)}
          outlined
        >
          {getErrorMessage({ type, links, userCanSkipValidations, origin, scenario, workspace })}
        </CardNotification>
      ))}
    </>
  );
};

export default LinkAssignmentErrors;
