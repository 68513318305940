import React, { useMemo, useState } from "react";
import { Option } from "react-select/lib/filters";
import { useMutation } from "react-apollo";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Loading, Box, Divider, Heading, Button } from "@foris/avocado-suite";
import { classRoomSearchMutation } from "../graphql/advanceSearch.queries";
import AdvanceCampusSelector from "@modules/advance-search/components/advance-campus-selector";
import AdvancedBuildingSelector from "@modules/advance-search/components/advanced-building-selector";
import { ClassRoom } from "@modules/shared/selectors";
import { Capacity, TimeRange } from "@modules/shared/inputs";
import { Days } from "@modules/shared/checks";
import EmptyResultsModal from "@modules/shared/empty-results-modal";
import WeeksRange from "@modules/shared/weeksRange/WeeksRange";
import useContextUrl from "@common/hooks/useContextUrl";
import useNavigationUrl from "@common/hooks/useNavigationUrl";
import { objectEmpty } from "@utils/validations";
import { validations } from "./utils";
import * as utilsAdvance from "@modules/advance-search/utils";
import { getAdvancedSelectorsFilters } from "@dimensions/utils/searchDimension";
import {
  IAvailability,
  ITimeRangeDependency,
  IWeeksRangeDependency,
} from "@modules/advance-search/IAdvanceSearch";
import css from "./advanceSearch.module.scss";

const AdvanceSearch: React.FC<any> = (props: any) => {
  // States for validations
  const [error, setError] = useState(false);
  const [daysError, setDaysError] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [timeRangeError, setTimeRangeError] = useState(false);
  const [objCapacity, setObjCapacity] = useState({});
  const [objAvailability, setObjAvailability] = useState<IAvailability>({});
  const [loading, setLoading] = useState(false);
  const [selectedCampus, setSelectedCampus] = useState(null);
  const [selectedBuilding, setSelectedBuilding] = useState(null);
  const [selectedClassrronType, setSelectedClassrronType] = useState(null);
  // Hooks
  const { t } = useTranslation();
  const history = useHistory();
  const { origin, scenario } = useContextUrl();
  const { advancedSearchResultsUrl } = useNavigationUrl();
  const [advanceSearchMutation] = useMutation(classRoomSearchMutation, {});

  const hasSomeFilter = useMemo(() => {
    return (
      !objectEmpty(objCapacity) ||
      !objectEmpty(objAvailability) ||
      selectedBuilding ||
      selectedCampus ||
      selectedClassrronType
    );
  }, [objCapacity, objAvailability, selectedBuilding, selectedCampus, selectedClassrronType]);

  const hasSomeError = error || daysError || timeRangeError;

  const handleSelectCampus = (option: Option) => {
    setSelectedCampus(option?.value ?? null);
    setSelectedBuilding(null);
  };

  const setCapacity = (values: any, error: any) => {
    if (error) {
      setError(true);
      return;
    }

    const validation = validations.capacity(values, objCapacity);
    setObjCapacity(validation.objCapacity);
    setError(validation.error);
  };

  const setDays = (value: number[]) => {
    const validation = utilsAdvance.validations.days(value, objAvailability);
    setObjAvailability(validation.availability);
    setError(validation.error);
    setTimeRangeError(validation.timeRangeError);
    setDaysError(validation.daysError);
  };
  const setTimeRange = (value: ITimeRangeDependency, error: boolean) => {
    if (error) {
      setError(true);
      return;
    }
    const validation = utilsAdvance.validations.timeRange(value, objAvailability);
    setObjAvailability(validation.availability);
    setError(validation.error);
    setTimeRangeError(validation.timeRangeError);
    setDaysError(validation.daysError);
  };

  const setWeeksRange = (values: IWeeksRangeDependency) => {
    const validation = utilsAdvance.validations.weeksRange(values, objAvailability);
    setObjAvailability(validation.availability);
  };

  let weeksDisabled = true;
  if (objAvailability.availability) {
    const daysExist = objAvailability.availability.hasOwnProperty("dayId");
    const rangeExist = objAvailability.availability.hasOwnProperty("blockRange");
    if (rangeExist) {
      const start = objAvailability.availability.blockRange.hasOwnProperty("start");
      const end = objAvailability.availability.blockRange.hasOwnProperty("end");
      if (start && end && daysExist) weeksDisabled = false;
    }
  }

  const createObjectMutation = () => {
    const filterArray = [];

    const selectorsFilterArray = getAdvancedSelectorsFilters({
      campusId: selectedCampus,
      buildingId: selectedBuilding,
    });

    !objectEmpty(objCapacity) && filterArray.push(objCapacity);
    !objectEmpty(objAvailability) && filterArray.push(objAvailability);

    const mutationFilter = {
      originId: parseInt(origin),
      scenarioId: parseInt(scenario),
      filter: {
        fields: {
          and: [
            // Set default filter 'cause now we bring only the ones with assignment
            { hasEvents: true },
            ...selectorsFilterArray,
            ...filterArray,
          ],
        },
      },
      pagination: {
        page: 1,
        size: 10,
      },
    };
    return mutationFilter;
  };

  const callUpdateHook = async () => {
    setLoading(true);
    try {
      const variables = createObjectMutation();
      const { data } = await advanceSearchMutation({ variables });
      setLoading(false);
      const dataItems = data.filter.createClassroomFilter;
      if (dataItems.classrooms.items.length > 0) {
        const idSearch = dataItems.id;
        history.push(advancedSearchResultsUrl("classroom", idSearch));
      } else {
        setIsEmpty(true);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <div className={css.ctnAdvanceSearch}>
      <EmptyResultsModal show={isEmpty} onClose={() => setIsEmpty(false)} />

      <Box className={css.ctnAdvanceSearch_boxContent}>
        <div className={css.selectorContainer}>
          <AdvanceCampusSelector scenario={scenario} onChange={handleSelectCampus} />
          <AdvancedBuildingSelector
            key={`building-${selectedCampus}`}
            scenario={scenario}
            campusId={selectedCampus}
            onChange={option => setSelectedBuilding(option?.value ?? null)}
          />
        </div>
        <Divider className={css.divider} />
        <div className={css.selectorContainer}>
          <ClassRoom
            label
            labelValue={t("advanced-search.classroom.forms.select-type-classroom.label")}
            placeholder={t("advanced-search.classroom.forms.select-type-classroom.placeholder")}
            className={css.typeClassroom}
            onCallback={values => setSelectedClassrronType(values ? values.value : null)}
          />
        </div>
        <Divider className={css.divider} />
        <Capacity onCallback={(values, error) => setCapacity(values, error)} />
        <Divider className={css.divider} />
        <Heading type="h4">{t("advanced-search.classroom.forms.days.title")}</Heading>
        <Days required={daysError} onCallback={values => setDays(values)} />
        <Divider className={css.divider} />
        <Heading type="h4">{t("advanced-search.classroom.forms.time-range.title")}</Heading>
        <TimeRange
          required={timeRangeError}
          onCallback={(values, error) => setTimeRange(values, error)}
        />
        <Divider className={css.divider} />
        <WeeksRange
          {...props}
          disabled={weeksDisabled}
          onCallback={values => setWeeksRange(values)}
        />
      </Box>
      {loading ? (
        <Loading />
      ) : (
        <Button
          className={css.ctnAdvanceSearch_actionButton}
          disabled={hasSomeError || !hasSomeFilter}
          onClick={() => callUpdateHook()}
        >
          {t("advanced-search.classroom.forms.btn-search")}
        </Button>
      )}
    </div>
  );
};

export default AdvanceSearch;
