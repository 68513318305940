import { PackageCrudResult } from "@models/ISchema";
import packagesCrud from "../../../../modules/sections/Packages/graphql/packagesCrud.mutation.graphql";
import { ApolloError } from "apollo-boost";
import { useState } from "react";
import { useMutation } from "react-apollo";

interface UsePackageUpdateProps {
  originId: string;
  scenarioId: string;
  onSuccess?: (data: PackageCrudResult) => void;
  onDeleted?: () => void;
  onErrorDeleting?: () => void;
  onError?: (error: ApolloError) => void;
}

interface handleUpdatePackageParams {
  packageId: string;
  isVisible?: boolean;
  isPublished?: boolean;
  replicated?: boolean;
}

type UsePackageUpdateData = {
  isLoading: boolean;
  hasError: ApolloError;
  response: PackageCrudResult;
};

interface UsePackageUpdate {
  data: UsePackageUpdateData;
  handleUpdatePackage: (params: handleUpdatePackageParams) => Promise<void>;
  handleDeletePackage: (params: Pick<handleUpdatePackageParams, "packageId">) => Promise<void>;
}

const usePackageUpdate = (props: UsePackageUpdateProps): UsePackageUpdate => {
  const { originId, scenarioId, onSuccess, onError, onDeleted, onErrorDeleting } = props;
  const [mutation, options] = useMutation(packagesCrud, {});
  const [response, setResponse] = useState<PackageCrudResult>({});

  const handleUpdatePackage = async ({
    packageId,
    isPublished,
    isVisible,
    replicated,
  }: handleUpdatePackageParams) => {
    try {
      const variables = {
        originId,
        scenarioId,
        input: {
          dryRun: false,
          skipValidations: true,
          changesets: {
            updates: [
              {
                packageId,
                visibility: isVisible,
                published: isPublished,
                replicated,
              },
            ],
            deletes: [],
          },
        },
      };

      const { data } = await mutation({ variables });
      setResponse(data?.cube?.packagesCrud);
      onSuccess?.(response);
    } catch (error) {
      onError?.(error as ApolloError);
    }
  };

  const handleDeletePackage = async ({
    packageId,
  }: Pick<handleUpdatePackageParams, "packageId">) => {
    try {
      const variables = {
        originId,
        scenarioId,
        input: {
          dryRun: false,
          skipValidations: true,
          changesets: {
            updates: [],
            deletes: [
              {
                packageId,
              },
            ],
          },
        },
      };

      const { data } = await mutation({ variables });
      setResponse(data?.cube?.packagesCrud);

      if (!!data?.cube?.packagesCrud?.commited) {
        onDeleted?.();
      } else {
        onErrorDeleting?.();
      }
    } catch (error) {
      onError?.(error as ApolloError);
    }
  };

  return {
    data: {
      isLoading: options.loading,
      hasError: options.error,
      response,
    },
    handleUpdatePackage,
    handleDeletePackage,
  };
};

export default usePackageUpdate;
