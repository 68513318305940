import React from "react";
import { Heading, Modal, Text } from "@foris/avocado-suite";
import { getColorId } from "@utils/dataColors";
import ItemInfo from "../ItemInfo/ItemInfo";
import BookingModalDetails from "@modules/sections/components/booking-modal-details";
import cx from "classnames";
import styles from "./eventDetailsModal.module.scss";
import { IEvent } from "@modules/sections/ISections";

interface EventDetailsModalProps {
  isOpen?: boolean;
  event?: any;
  selectedWeek?: any;
  eventHasError?: boolean;
  dimension?: string;
  onClose?: () => void;
  onUndoPosition?: (event: IEvent) => void;
  onOpenEdit?: (event: IEvent) => void;
}

const EventDetailsModal = ({
  isOpen,
  event,
  selectedWeek,
  eventHasError,
  dimension,
  onClose,
  onUndoPosition,
  onOpenEdit,
}: EventDetailsModalProps) => {
  return (
    <Modal
      className={styles.eventDetailsModal}
      onClose={onClose}
      open={isOpen}
      headerStatusColor={
        eventHasError
          ? "danger"
          : event
          ? event?.bookings
            ? "palette_4_50"
            : (`palette_${getColorId(event?.colorIndex)}_50` as any)
          : null
      }
      header={
        <div className={styles.eventDetailsModal_header}>
          {!event.bookings && (
            <Text className={styles.headerTopTitle}>{`Sesión ${event.sessionId}`}</Text>
          )}

          <Heading
            type="h2"
            className={cx(styles.headerTitle, !!event.bookings && styles.headerTitle_customTitle)}
          >
            {event.title}
          </Heading>
        </div>
      }
    >
      <div className={styles.eventDetailsModal_content}>
        {event.bookings ? (
          <BookingModalDetails event={event} />
        ) : (
          <ItemInfo
            event={event}
            error={eventHasError}
            onUndo={(event: IEvent) => onUndoPosition?.(event)}
            openEditModal={onOpenEdit}
            dimension={dimension}
            currentSelectedWeek={selectedWeek}
          />
        )}
      </div>
    </Modal>
  );
};

export default EventDetailsModal;
