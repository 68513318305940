import React, { useMemo, useState } from "react";
import { Header } from "@common/components";
import Layout from "@common/layout/Layout";
import CampusSelect from "@modules/shared/selectors/Selectors/Campus";
import css from "./packagesAndGroupsReport.module.scss";
import ModalitySelect from "@modules/shared/selectors/Selectors/Modality";
import ProgramSelect from "@modules/shared/selectors/Selectors/Program";
import { IParams } from "@models/IParams";
import { useHistory, useParams } from "react-router-dom";
import { Button, Icon } from "@foris/avocado-ui";

const darwinLogin = process.env.REACT_APP_DARWINED_URL;

const PackagesAndGroupsReport = () => {
  const { origin, scenario, workspace }: IParams = useParams();
  const history = useHistory();

  const [selectedCampus, setSelectedCampus] = useState(null);
  const [selectedModality, setSelectedModality] = useState(null);
  const [selectedProgram, setSelectedProgram] = useState(null);

  const hasSomeFilterSelected = !!selectedCampus || !!selectedModality || !!selectedProgram;

  const groupsAndPackageReportUrl = useMemo(() => {
    const campusFilter = selectedCampus ? `&campus=${selectedCampus}` : "";
    const modalityFilter = selectedModality ? `&modality=${selectedModality}` : "";
    const programFilter = selectedProgram ? `&program=${selectedProgram}` : "";

    return !!darwinLogin
      ? `${darwinLogin}groups_manager/group_packages/${origin}?scenario_id=${scenario}${programFilter}${campusFilter}${modalityFilter}`
      : "";
  }, [selectedCampus, selectedModality, selectedProgram]);

  const handleSelectCampus = (value: string | null) => {
    setSelectedCampus(value);
    setSelectedProgram(null);
  };

  const handleSelectModality = (value: string | null) => {
    setSelectedModality(value);
    setSelectedProgram(null);
  };

  const handleBack = () => {
    if (history.length > 2) {
      history.goBack();
    } else {
      history.push(`/home/${workspace}/${scenario}/${origin}`);
    }
  };

  const handleDownloadReport = () => {
    if (groupsAndPackageReportUrl && hasSomeFilterSelected) {
      window.open(groupsAndPackageReportUrl, "_blank");
    }
  };

  return (
    <Layout contextSearch>
      <Header title="Descarga Reporte de Paquetes y Grupos" />

      <main className={css.packagesAndGroupsReport}>
        <div className={css.packagesAndGroupsReport_content}>
          <CampusSelect
            placeholder="Ingresa o selecciona sede"
            onCallback={opt => handleSelectCampus(opt.value)}
          />

          <ModalitySelect
            placeholder="Ingresa o selecciona modalidad"
            shouldHasDepartment={false}
            onCallback={opt => handleSelectModality(opt.value)}
          />

          <ProgramSelect
            key={`program-${selectedCampus}-${selectedModality}`}
            placeholder="Ingresa o selecciona carrera"
            filterBy="modality"
            campus={selectedCampus}
            modality={selectedModality}
            shouldHaveFilters={false}
            onCallback={opt => setSelectedProgram(opt.value)}
          />
        </div>

        <footer className={css.packagesAndGroupsReport_actions}>
          <Button className={css.actionButton} variant="outline" onClick={handleBack}>
            Cancelar
          </Button>

          <Button
            className={css.actionButton}
            disabled={!hasSomeFilterSelected}
            onClick={handleDownloadReport}
          >
            <Icon icon="download" />
            Descargar Reporte
          </Button>
        </footer>
      </main>
    </Layout>
  );
};

export default PackagesAndGroupsReport;
