import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Link, Pill } from "@foris/avocado-suite";
import { useParams } from "react-router-dom";
import { useApolloClient } from "react-apollo";
import SectionsAction from "./SectionsAction";
import { ISectionData, IWeek } from "../../../sections/ISections";
import { functionsData } from "../../../sections/utils";
import {
  CubeQuery,
  BaseQuery,
  SectionInfo,
  Tag,
  StripePriority,
  ClassroomPriority,
  Group,
  Component,
  Link as ILink,
  CourseComponent,
} from "../../../../models/ISchema";
import { IParams } from "../../../../models/IParams";
import { sectionEditorView } from "../graphql/sectionCalendar.query";
import css from "../../../../common/components/Header/header.module.scss";

export interface ICurrentData {
  week: IWeek;
  weekList: Array<IWeek>;
  selectedEvent: ISectionData;
  vacancies: number;
  component: Component;
  courseId: number;
  sectionId: string;
  title: string;
  links: ILink[];
  courseComponent: CourseComponent;
}

const SectionCalendar: React.FC = () => {
  const client = useApolloClient();
  const { id, origin, scenario, workspace }: IParams = useParams();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const [calendarData, setCalendar] = useState(null);
  const [ogHeaderData, setOgHeaderData] = useState(null);
  const [customFields, setCustomFields] = useState<Tag[]>([]);
  const [isHasSessions, setIsHasSessions] = useState(false);
  const [currentData, setCurrentData] = useState<ICurrentData>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [resolvedHeaderData, setResolvedHeaderData] = useState<Record<string, any>>({});

  const variables = {
    query: sectionEditorView,
    variables: {
      resourceId: id,
      scenarioId: scenario,
      originId: origin,
      resourceType: "SECTION",
      customField: "COURSE",
    },
  };

  const stringTitle = (value: string[]) => {
    let stringValue = "";
    value.forEach((text: string) => {
      if (stringValue && text) stringValue = `${stringValue}, ${text}`;
      if (!stringValue && text) stringValue = `${text}`;
    });
    return stringValue;
  };

  const prioritiesInfo = useCallback(async (priorities: StripePriority[]) => {
    const stripe = [];
    priorities.forEach(value => {
      if (value?.stripe?.code && !stripe.includes(value?.stripe?.code))
        stripe.push(value.stripe.code);
    });
    return stringTitle(stripe);
  }, []);

  const classroomInfo = useCallback(async (classroom: ClassroomPriority[]) => {
    const classroomType = [];
    classroom.forEach(value => {
      if (value?.classroomType?.code && !classroomType.includes(value?.classroomType?.code))
        classroomType.push(value.classroomType.code);
    });
    return stringTitle(classroomType);
  }, []);

  const typeGroupInfo = useCallback(async (classroom: Group[]) => {
    const groupType = [];
    classroom.forEach(value => {
      if (value?.type && !groupType.includes(value?.type)) groupType.push(value?.type);
    });
    return stringTitle(groupType);
  }, []);

  const headerInfo = useCallback(
    async (info: SectionInfo, customFields?: Tag[], hasSessions = false) => {
      const classroomTypeInfo = info?.courseComponent?.classroomPriorities || [];
      const stripeInfo = info?.courseComponent?.stripePriorities || [];
      const groupsInfo = info?.section?.groups || [];
      const stripeValue = await prioritiesInfo(stripeInfo);
      const classroomValue = await classroomInfo(classroomTypeInfo);
      const typeGroup = await typeGroupInfo(groupsInfo);

      const details = [
        {
          title: t("dimension.section.header.more-details.campus"),
          content: info?.campus?.code || "-",
        },
        {
          title: t("dimension.section.header.more-details.school"),
          content: info?.department?.code || "-",
        },
        {
          title: t("dimension.section.header.more-details.modality"),
          content: info?.modality?.code || "-",
        },
        {
          title: t("dimension.section.header.more-details.shift"),
          content: info?.shift?.name || "-",
        },
        {
          title: t("dimension.section.header.more-details.career"),
          content: info?.program?.code || "-",
        },
        {
          title: t("dimension.section.header.more-details.curriculum"),
          content: info?.curriculum?.code || "-",
        },
        {
          title: t("dimension.section.header.more-details.level"),
          content: info?.course?.level || "-",
        },
        {
          title: t("dimension.section.header.more-details.modulation"),
          content: info?.courseComponent?.modulation || "-",
        },
        {
          title: t("dimension.section.header.more-details.vacancies-max"),
          content: info?.courseComponent?.academicStandard || "-",
        },
        {
          title: t("dimension.section.header.more-details.vacancies"),
          content: info?.section?.vacancies || "-",
        },
        {
          title: t("dimension.section.header.more-details.time-slots"),
          content: stripeValue || "-",
        },
        {
          title: t("dimension.section.header.more-details.room-types"),
          content: classroomValue || "-",
        },
        {
          title: t("dimension.section.header.more-details.total-section-blocks"),
          content: info?.section?.totalBlocks?.toString() || "-",
        },
      ];

      if (typeGroup) {
        details.push({
          title: t("dimension.section.header.more-details.room-types"),
          content: typeGroup || "-",
        });
      }

      if (customFields) {
        customFields.forEach(item => {
          const value = info.course.customFields.find(customData => customData.code === item.code);
          if (value) {
            details.push({
              title: item.label,
              content: value?.value?.replace(",", ", "),
            });
          }
        });
      }

      const title = info.course ? info.course.name : "";
      const componentTitle =
        info.courseComponent && info.courseComponent.component
          ? info.courseComponent.component.code
          : "";
      const topPillCode = `${info.section ? info.section.code : ""} ${componentTitle}`;
      const canEditCourse = info.course.edhCanEdit;
      const topTitle = info?.course ? info.course.code : "";

      const subTitle = () => {
        const idBundle = info?.section?.bundle?.id;
        const routeBundle = `/editor/vacancies/${workspace}/${scenario}/${origin}/${idBundle}`;

        return (
          idBundle && (
            <h2 className={css.infoItem_subTitle}>
              <Pill textColor="gray-10" color="gray-70">
                {topPillCode}
              </Pill>
              <Link href={routeBundle} className={css.link} iconRight="external-link" size="sm">
                {`${t("dimension.section.link")}: ${info?.section?.bundle?.code}`}
              </Link>
            </h2>
          )
        );
      };

      return {
        title,
        topTitle,
        subTitle: subTitle(),
        moreDetails: details,
        canEditCourse,
        type: "Sections",
        hasSessions,
      };
    },
    [classroomInfo, prioritiesInfo, typeGroupInfo],
  );

  const fetchHeaderData = useCallback(async () => {
    if (ogHeaderData) {
      return await headerInfo(ogHeaderData, customFields, isHasSessions);
    }
    return {};
  }, [ogHeaderData, customFields, isHasSessions, headerInfo]);

  useEffect(() => {
    const updateHeaderData = async () => {
      setLoading(true);

      try {
        const data = await fetchHeaderData();
        setResolvedHeaderData(data);
        setError(false);
        setLoading(false);
      } catch (err) {
        setError(!!err);
        setLoading(false);
      }
    };

    updateHeaderData();
  }, [fetchHeaderData, language]);

  const getDataSections = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await client.query(variables);
      if (data) {
        const sections: ISectionData = functionsData.serializeSections(data);
        const cubeQuery: CubeQuery = data.cube;
        const baseQuery: BaseQuery = data.base;
        const infoSection = cubeQuery.editorView?.info;
        const customFields = baseQuery.customFields;
        const week = sections.selectedWeek
          ? sections.selectedWeek
          : sections.weekList[0]
          ? sections.weekList[0]
          : new Date();
        if (infoSection && infoSection.__typename === "SectionInfo") {
          const hasSessions = (cubeQuery?.editorView?.sections || []).some(
            section => section?.sessions?.length > 0,
          );
          setOgHeaderData(infoSection);
          setCustomFields(customFields);
          setIsHasSessions(hasSessions);
          const current: ICurrentData = {
            week,
            weekList: sections?.weekList,
            selectedEvent: sections,
            vacancies: infoSection?.section?.vacancies,
            component: infoSection?.courseComponent?.component,
            courseId: parseInt(infoSection?.course?.id),
            sectionId: infoSection?.section?.code,
            title: `${infoSection?.course?.code} ${infoSection?.course?.name}`,
            links: infoSection?.section?.links ?? [],
            courseComponent: infoSection?.courseComponent,
          };
          setCurrentData(current);
          setCalendar(sections);
        } else {
          setError(true);
        }
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setError(true);
      setLoading(false);
    }
  }, [client, variables, headerInfo]);

  const setDataCalendar = (data: ISectionData, callback: () => void) => {
    setCalendar(data);
    callback && callback();
  };

  useEffect(() => {
    if (calendarData === null && error === false) getDataSections();
  }, [calendarData, error, getDataSections]);

  return (
    <SectionsAction
      current={currentData}
      calendarData={calendarData}
      headerData={resolvedHeaderData}
      loading={loading}
      setDataCalendar={setDataCalendar}
      setCurrentData={setCurrentData}
      error={error}
    />
  );
};

export default SectionCalendar;
