import React from "react";
import cx from "classnames";
import * as R from "ramda";
import { useTranslation } from "react-i18next";
import { Link as LinkRouter, Pill } from "@foris/avocado-suite";
import { useParams } from "react-router-dom";
import { Link } from "@models/ISchema";
import { IParams } from "@models/IParams";
import { Header } from "@components/index";
import useGetDimension from "@dimensions/hooks/useGetDimension";
import { Dimension } from "@models/IDimension";
import css from "@components/Header/header.module.scss";

interface ILinkHeader {
  setHeight?: any;
  link: Link;
}

const LinkHeader = (props: ILinkHeader) => {
  const { setHeight, link } = props;
  const { origin, scenario, workspace }: IParams = useParams();
  const { t } = useTranslation();
  const info = link;

  const [dimension] = useGetDimension(Dimension.league);

  if (!info) return null;

  const program = info?.course?.curriculum?.program;
  const courseCode = info?.course?.code || "";
  const courseName = info?.course?.name || "";
  let typeGroup = "";
  info?.groups?.forEach(value => {
    const groupsSection = typeGroup && typeGroup.split(",").find(R.equals(value.type));
    if (typeGroup === "") {
      typeGroup = value.type;
    } else {
      if (!groupsSection) typeGroup = `${typeGroup}, ${value.type}`;
    }
  });

  const subTitle = () => {
    const idBundle = info?.bundle?.id;
    const termCode = info?.bundle?.term?.code;
    const routeBundle = `/editor/vacancies/${workspace}/${scenario}/${origin}/${idBundle}`;

    return (
      !!(idBundle || termCode) && (
        <div className={cx(css.infoItem_subTitle, css.infoItem_subTitleWrapper)}>
          <Pill textColor="gray-10" color="gray-70">
            {t("dimension.link.label-pill")}: {termCode}
          </Pill>
          <LinkRouter href={routeBundle} className={css.link} iconRight="external-link" size="sm">
            {`${t("dimension.link.header.more-details.bundle")}: ${info?.bundle?.code}`}
          </LinkRouter>
        </div>
      )
    );
  };

  const details = [
    { title: t("dimension.link.header.more-details.campus"), content: program?.campus?.code || "" },
    {
      title: t("dimension.link.header.more-details.department"),
      content: program?.department?.code || "",
    },
    {
      title: t("dimension.link.header.more-details.modality"),
      content: program?.modality?.code || "",
    },
    { title: t("dimension.link.header.more-details.shift"), content: program?.shift?.code || "" },
    { title: t("dimension.link.header.more-details.program"), content: program?.code || "" },
    {
      title: t("dimension.link.header.more-details.curriculum"),
      content: info?.course?.curriculum?.code || "",
    },
    {
      title: t("dimension.link.header.more-details.level"),
      content: info?.course?.level?.toString() || "",
    },
    {
      title: t("dimension.link.header.more-details.vacancies"),
      content: info?.vacancies?.toString() || "",
    },
    {
      title: t("dimension.link.header.more-details.total-blocks"),
      content: info?.totalBlocks?.toString() || "",
    },
    {
      title: t("dimension.link.header.more-details.credits"),
      content: info?.course?.credits?.toString() || "",
    },
  ];

  if (typeGroup)
    details.push({
      title: t("dimension.link.header.more-details.group-types"),
      content: typeGroup,
    });
  const moreDetails = [details];

  const headerInfo = {
    title: courseCode && courseName ? `${courseCode}, ${courseName}` : "",
    topTitle: info?.code || "",
    subTitle: subTitle(),
    moreDetails,
  };
  return (
    <Header
      dimension={dimension.label}
      breadcrumbItems={[
        {
          path: "/",
          title: t("dimension.link.breadcrumb.previous"),
        },
        {
          title: t("dimension.link.breadcrumb.current"),
        },
      ]}
      {...headerInfo}
      setHeight={setHeight}
    />
  );
};

export default LinkHeader;
