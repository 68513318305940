import React from "react";
import { useTranslation } from "react-i18next";
import DimensionsSearchResults from "@dimensions/common/dimensions-search-results";
import useGetTitle from "../../hooks/useGetTitle";
import { getColumnCell, getHeadCell, getLinkCell } from "@dimensions/utils/tableUtils";
import { ISearchProps } from "@dimensions/common/ISearch";
import { DimensionNameEs } from "@models/IDimension";
import { Link as Links } from "@models/ISchema";
import useNavigationUrl from "@common/hooks/useNavigationUrl";

/**
 * LinkTable component that displays a table of links with relevant information.
 *
 * This component fetches links data and renders it in a table format, displaying information
 * such as links code, campus, course-code, course-name, term. sections-count total-blocks, and vacancies.
 * It integrates pagination, search, and internationalization (i18n) for multi-language support.
 *
 * @param {DimensionSearchResultsProps} props - The properties passed to the component.
 * @param {Array<Links>} props.data - Data of classrooms to display in the table.
 * @param {string} props.search - The search query to filter results.
 * @param {Object} props.pageInfo - Pagination information (total results, current page, etc.).
 * @param {Function} props.setPage - Function to change the current page.
 * @param {boolean} props.loading - Boolean indicating if the data is still loading.
 * @param {boolean} props.error - Boolean indicating if there was an error during data fetch.
 * @param {Function} props.onChangeSizePerPage - Function to handle page size changes.
 * @returns {JSX.Element} - The rendered LinkTable component.
 *
 * @example
 * <LinTable
 *   data={linksData}
 *   search="Math"
 *   pageInfo={pageInfo}
 *   setPage={setPage}
 *   loading={isLoading}
 *   error={hasError}
 *   onChangeSizePerPage={handlePageSizeChange}
 * />
 */

const LinkTable = (props: ISearchProps) => {
  const { data, search, loading, error, setPage, pageInfo } = props;

  // Get the dynamic title for the search results using the custom hook `useGetTitle`
  const title = useGetTitle(search, pageInfo?.total || 0, "liga");

  // Get the context URL for advanced search functionality
  const { advancedSearchUrl, editorUrl } = useNavigationUrl();

  // Use the i18n hook to get translation functions
  const { t } = useTranslation();

  // Define the columns for the table, including the header and cell rendering logic
  const columns = [
    {
      header: getHeadCell(t("search-results.dimension.link.table.header.code")),
      renderCell: ({ id, code }: Links) => getLinkCell(editorUrl("link", id), null, code),
      desktopOptions: {
        headCellStyles: {
          minWidth: "80px",
          width: "120px",
        },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.link.table.header.campus")),
      renderCell: ({ course }: Links) =>
        getColumnCell(course?.curriculum?.program?.campus?.code || "-"),
      desktopOptions: {
        headCellStyles: {
          minWidth: "80px",
          width: "120px",
        },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.link.table.header.course-code")),
      renderCell: ({ course }: Links) => getColumnCell(course?.code || "-"),
      desktopOptions: {
        headCellStyles: {
          minWidth: "80px",
          width: "165px",
        },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.link.table.header.course-name")),
      renderCell: ({ course }: Links) => getColumnCell(course?.name || "-"),
      desktopOptions: {
        headCellStyles: {
          minWidth: "80px",
          width: "230px",
        },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.link.table.header.term")),
      renderCell: ({ bundle }: Links) => getColumnCell(bundle?.term?.code || "-"),
      desktopOptions: {
        headCellStyles: {
          minWidth: "80px",
          width: "120px",
        },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.link.table.header.sections-count")),
      renderCell: ({ sectionsCount }: Links) => getColumnCell(sectionsCount ?? "-"),
      desktopOptions: {
        headCellStyles: {
          minWidth: "80px",
          width: "160px",
        },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.link.table.header.total-blocks")),
      renderCell: ({ totalBlocks }: Links) => getColumnCell(totalBlocks ?? "-"),
      desktopOptions: {
        headCellStyles: {
          minWidth: "80px",
          width: "140px",
        },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.link.table.header.vacancies")),
      renderCell: ({ vacancies }: Links) => getColumnCell(vacancies ?? "-"),
      desktopOptions: {
        headCellStyles: {
          minWidth: "80px",
          width: "124px",
        },
      },
    },
  ];

  // Render the DimensionsSearchResults component with the table data and configurations
  return (
    <DimensionsSearchResults
      title={title.getTitle}
      columns={columns}
      data={data}
      pageInfo={pageInfo}
      setPage={setPage}
      loading={loading}
      error={error}
      dimension={t("dimension.link.title") as DimensionNameEs}
      advanceLink={advancedSearchUrl("link")}
      onChangeSizePerPage={props?.onChangeSizePerPage}
    />
  );
};

export default LinkTable;
