import { useMemo } from "react";
import queryString from "query-string";
import useContextUrl from "@common/hooks/useContextUrl";

const useGetQueryVariables = (
  params: queryString.ParsedQuery<string>,
  page: number,
  useFilterPagination?: boolean,
  sizePerPage?: number,
) => {
  const { scenario, origin } = useContextUrl();

  const getVariables = useMemo(() => {
    const variables = {
      scenarioId: scenario,
      originId: origin,
      filterId: params?.advance,
      filter: {
        page: page,
        size: sizePerPage ?? 20,
        searchTerm: params?.search,
      },
      pagination: {
        page: page,
        size: sizePerPage ?? 20,
      },
    };
    if (params?.search) {
      delete variables.filterId;
      delete variables.pagination;
    }
    if (params?.advance) {
      delete variables.filter;
      delete variables.originId;
    }
    if (useFilterPagination) {
      delete variables.pagination;

      variables.filter = {
        pagination: {
          page: page,
          size: sizePerPage ?? 20,
          searchTerm: params?.search,
        },
      } as any;
    }
    return variables;
  }, [scenario, origin, page, useFilterPagination, sizePerPage]);

  return getVariables;
};

export default useGetQueryVariables;
