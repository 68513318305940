import React from "react";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Select, TextField, SelectPagination } from "@foris/avocado-suite";
import { Option } from "react-select/lib/filters";
import css from "./advanceSelector.module.scss";

interface AdvanceSelectorProps {
  notShowLabel?: boolean;
  dimension: string;
  condition?: string;
  selectPlaceholder: string;
  isPaginated?: boolean;
  isLoading?: boolean;
  options?: Option[];
  /**
   *
   * @param loadOptions
   * Should be use only if isPaginated is true
   */
  loadOptions?: (
    searchTerm: string,
  ) => Promise<{
    options: any[];
    hasMore: boolean;
    additional: {
      page: number;
    };
  }>;
  onChange?: (option: Option) => void;
}

const AdvanceSelector = ({
  dimension,
  condition = i18n.t("advanced-search.classroom.forms.campus.select-condition.placeholder"),
  selectPlaceholder,
  isPaginated = false,
  options = [],
  isLoading = false,
  loadOptions,
  onChange,
  notShowLabel,
}: AdvanceSelectorProps) => {
  const { t } = useTranslation();
  return (
    <div className={css.advanceSelector}>
      <TextField
        className={css.advanceSelector_field}
        label={
          notShowLabel ? null : t("advanced-search.classroom.forms.campus.select-property.label")
        }
        placeholder={dimension}
        disabled
      />
      <TextField
        className={css.advanceSelector_field}
        label={
          notShowLabel ? null : t("advanced-search.classroom.forms.campus.select-condition.label")
        }
        placeholder={condition}
        disabled
      />
      <div className={css.advanceSelector_field}>
        {isPaginated ? (
          <SelectPagination
            label={
              notShowLabel ? null : t("advanced-search.classroom.forms.campus.select-value.label")
            }
            placeholder={selectPlaceholder}
            noOptionsMessage={() => (
              <>{t("advanced-search.classroom.forms.campus.select-value.empty-options")}</>
            )}
            loadingMessage={() => (
              <>{t("advanced-search.classroom.forms.campus.select-value.loadingMessage")}</>
            )}
            loadOptions={loadOptions}
            onChange={onChange}
            isClearable
          />
        ) : (
          <Select
            label={
              notShowLabel ? null : t("advanced-search.classroom.forms.building.select-value.label")
            }
            placeholder={selectPlaceholder}
            noOptionsMessage={() => (
              <>{t("advanced-search.classroom.forms.building.select-value.empty-options")}</>
            )}
            loadingMessage={() => (
              <>{t("advanced-search.classroom.forms.building.select-value.loadingMessage")}</>
            )}
            options={options}
            onChange={onChange}
            isClearable
            isLoading={isLoading}
          />
        )}
      </div>
    </div>
  );
};

export default AdvanceSelector;
