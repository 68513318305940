import React from "react";
import { useTranslation } from "react-i18next";
import DimensionsSearchResults from "@dimensions/common/dimensions-search-results";
import useGetTitle from "../../hooks/useGetTitle";
import {
  getColumnCell,
  getHeadCell,
  getLinkCell,
  getUsableCell,
} from "@dimensions/utils/tableUtils";
import { Section } from "@models/ISchema";
import { ISearchProps } from "@dimensions/common/ISearch";
import { DimensionNameEs } from "@models/IDimension";
import useNavigationUrl from "@common/hooks/useNavigationUrl";

/**
 * SectionTable component that displays a table of sections with relevant information.
 *
 * This component fetches sections data and renders it in a table format, displaying information
 * such as links code, campus, subject-code, career, curriculum, total-instructors and schedule.
 * It integrates pagination, search, and internationalization (i18n) for multi-language support.
 *
 * @param {DimensionSearchResultsProps} props - The properties passed to the component.
 * @param {Array<Section>} props.data - Data of sections to display in the table.
 * @param {string} props.search - The search query to filter results.
 * @param {Object} props.pageInfo - Pagination information (total results, current page, etc.).
 * @param {Function} props.setPage - Function to change the current page.
 * @param {boolean} props.loading - Boolean indicating if the data is still loading.
 * @param {boolean} props.error - Boolean indicating if there was an error during data fetch.
 * @param {Function} props.onChangeSizePerPage - Function to handle page size changes.
 * @returns {JSX.Element} - The rendered SectionTable component.
 *
 * @example
 * <SectionTable
 *   data={sectionsData}
 *   search="Math"
 *   pageInfo={pageInfo}
 *   setPage={setPage}
 *   loading={isLoading}
 *   error={hasError}
 *   onChangeSizePerPage={handlePageSizeChange}
 * />
 */

const SectionTable = (props: ISearchProps) => {
  const { data, search, pageInfo, loading, error, setPage, onChangeSizePerPage } = props;

  // Get the dynamic title for the search results using the custom hook `useGetTitle`
  const title = useGetTitle(search, pageInfo?.total || 0, "sección");

  // Get the context URL for advanced search functionality
  const { advancedSearchUrl, editorUrl } = useNavigationUrl();
  const getEditorUrl = (dimension: "section" | "course", id: string) => editorUrl(dimension, id);

  // Use the i18n hook to get translation functions
  const { t } = useTranslation();

  // Extracts unique instructor names from sessions and returns a formatted cell
  const getInstructorName = ({ sessions }: Section) => {
    const totalInstructors = sessions.map(({ assignment }) => assignment?.instructor?.name);
    const uniqueInstructors = Array.from(new Set(totalInstructors));
    return getColumnCell(uniqueInstructors?.length);
  };

  // Retrieves unique classroom codes from sessions and returns a formatted cell
  const getClassrooms = ({ sessions }: Section) => {
    const classrooms: any = {};

    sessions.forEach(({ assignment }) => {
      const code = assignment?.classroom?.code;

      if (code && !(code in classrooms)) {
        classrooms[code] = code;
      }
    });

    const classroomsKeys = Object.keys(classrooms);

    return getColumnCell(classroomsKeys.length);
  };

  // Define the columns for the table, including the header and cell rendering logic
  const columns = [
    {
      header: getHeadCell(t("search-results.dimension.section.table.header.code")),
      renderCell: ({ id, code }: Section) => getLinkCell(getEditorUrl("section", id), null, code),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "110px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.section.table.header.campus")),
      renderCell: ({ course }: Section) =>
        getColumnCell(course?.curriculum?.program?.campus?.code || "-"),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "130px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.section.table.header.subject-code")),
      renderCell: ({ code, course }: Section) =>
        getLinkCell(getEditorUrl("course", course?.id), null, `${code} ${course?.name}`),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "260px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.section.table.header.career")),
      renderCell: ({ code }: Section) => getColumnCell(code),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "250px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.section.table.header.curriculum")),
      renderCell: ({ course }: Section) => getColumnCell(course?.curriculum?.code),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "140px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.section.table.header.total-instructors")),
      renderCell: getInstructorName,
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "150px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.section.table.header.total-rooms")),
      renderCell: getClassrooms,
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "124px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.section.table.header.schedule")),
      renderCell: ({ schedule }: Section) => getUsableCell(schedule?.resourceAssigment?.schedule),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "110px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.section.table.header.vacancies")),
      renderCell: ({ vacancies }: Section) => getColumnCell(vacancies),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "110px" },
      },
    },
  ];

  // Render the DimensionsSearchResults component with the table data and configurations
  return (
    <DimensionsSearchResults
      onChangeSizePerPage={onChangeSizePerPage}
      title={title.getTitle}
      columns={columns}
      data={data}
      pageInfo={pageInfo}
      setPage={setPage}
      loading={loading}
      error={error}
      dimension={t("dimension.section.title") as DimensionNameEs}
      advanceLink={advancedSearchUrl("section")}
    />
  );
};

export default SectionTable;
