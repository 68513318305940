import gql from "graphql-tag";

export const sectionSearchMutation = gql`
  mutation filter(
    $scenarioId: ID!
    $originId: ID!
    $filter: SectionFilterInput!
    $pagination: PaginationInput
  ) {
    filter(scenarioId: $scenarioId, originId: $originId) {
      createSectionFilter(filter: $filter) {
        id
        sections(pagination: $pagination) {
          items {
            id
            course {
              id
              name
              code
            }
          }
        }
      }
    }
  }
`;
