import React, { useEffect, useState, useContext, useMemo } from "react";
import cx from "classnames";
import { useParams } from "react-router-dom";
import { useApolloClient } from "react-apollo";
import { IParams } from "@models/IParams";
import { Instructor, CubeQuery } from "@models/ISchema";
import { AppContext } from "../../../context/EditSessionsContext";
import { Types } from "../../../context/switchPage.reducer";
import { Types as FormDataTypes } from "../../../context/formData.reducer";
import FormItem from "../../../components/FormEdit/FormItem";
import Autocomplete from "../../../components/Instructor/Autocomplete";
import { useDebounce } from "../../../hooks/useDebounce";
import { ContextApp } from "@config/Context/contextApp";
import { instructorSearch } from "../../../graphql/instructor.query";
import { Checkbox, Link, Tooltip } from "@foris/avocado-suite";
import UsableLabel from "@common/components/Autocomplete/usabe-label";
import SimpleResourceList from "@modules/sections/EditSessions/components/simple-resource-list";
import styles from "./instructorSection.module.scss";
import { useTranslation } from "react-i18next";

interface InstructorSectionProps {
  disabled?: boolean;
}

interface InstructorOption extends Instructor {
  label: string;
}

const getFormattedLabel = (option: InstructorOption) => {
  return <UsableLabel label={`${option.code} - ${option.name}`} isUsable={option?.usable} />;
};

const InstructorSection = ({ disabled = false }: InstructorSectionProps) => {
  const { state, dispatch } = useContext(AppContext);
  const { user } = useContext(ContextApp);
  const client = useApolloClient();
  const { scenario, origin }: IParams = useParams();
  const [valueSearch, setValueSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const { t } = useTranslation();

  const shouldFilterInstructors = state?.page?.active?.advanceSearchFilterInstructor;
  const setShouldFilterInstructors = (val: boolean) =>
    dispatch({
      type: Types.SetFilterInstructors,
      payload: val,
    });

  const debouncedSearch = useDebounce(valueSearch, 500);

  const someDeletedSessionIsSelected = useMemo(() => {
    return state?.form?.selectedSessions
      .map(session => session.id || "-")
      .some(id => state?.form?.sessionsToDelete.hasOwnProperty(id));
  }, [state?.form?.selectedSessions, state?.form?.sessionsToDelete]);

  const adaptedInstructors = useMemo(() => {
    return state?.form?.editedSessions?.instructors?.map(instructor => ({
      id: instructor?.code,
      label: instructor?.name,
      rawData: instructor,
    }));
  }, [state?.form?.editedSessions?.instructors]);

  const serializeOptions = (isntructors: Instructor[]) => {
    const instructorOptions = [];

    if (isntructors.length === 0) return instructorOptions;

    isntructors.forEach(instructor => {
      instructorOptions.push({
        ...instructor,
        label: `${instructor.code} - ${instructor.name}`,
        value: instructor.id,
        isDisabled: !instructor?.usable,
      });
    });
    return instructorOptions;
  };

  const queryOptions = (value: string) => {
    return {
      query: instructorSearch,
      variables: {
        originId: origin,
        scenarioId: scenario,
        filter: {
          fields: shouldFilterInstructors
            ? {
                courseId: {
                  eq: state?.link?.info?.course?.id,
                },
              }
            : undefined,
          pagination: {
            page: 1,
            size: 20,
            searchTerm: value || undefined,
          },
        },
      },
    };
  };

  const getDataOptions = async (value: string) => {
    try {
      setLoading(true);
      if (value !== "") {
        const query = queryOptions(value);
        const { data } = await client.query(query);
        const dataQuery: CubeQuery = data?.cube;
        const insOptions = serializeOptions(dataQuery.instructorsAdvancedSearch.items);
        setOptions(insOptions);
        setLoading(false);
      }
    } catch (error) {
      setOptions([]);
      setLoading(false);
    }
  };

  const handleDeleteInstructor = (instructor: Instructor) => {
    const listInstructors = [...state?.form?.editedSessions?.instructors];
    const indexInstructor = listInstructors.findIndex(item => item.id === instructor?.id);
    listInstructors.splice(indexInstructor, 1);

    dispatch({
      type: FormDataTypes.InstructorEditedSessions,
      payload: {
        instructors: listInstructors,
      },
    });
  };

  useEffect(() => {
    if (debouncedSearch) {
      setLoading(true);
      getDataOptions(debouncedSearch);
    } else {
      setOptions([]);
    }
  }, [debouncedSearch]);

  return (
    <FormItem
      title={t("assignation-edit.form.instructor.title")}
      type="instructors"
      isDisabled={disabled}
    >
      <div className={styles.instructor}>
        {!user?.abilities?.can_assign_instructors_from_other_courses ? (
          <Tooltip
            placement="topLeft"
            label={t("assignation-edit.form.instructor.can-assign-instructors-from-other-courses")}
          >
            <div>
              <Checkbox
                disabled
                className={styles.instructor_checkbox}
                labelRight={t("assignation-edit.form.instructor.only-filtered-instructors")}
                checked
              />
            </div>
          </Tooltip>
        ) : (
          <Checkbox
            disabled={disabled}
            className={styles.instructor_checkbox}
            labelRight={t("assignation-edit.form.instructor.only-filtered-instructors")}
            checked={shouldFilterInstructors}
            onChange={e => setShouldFilterInstructors(e.target.checked)}
          />
        )}

        <div
          className={cx(
            styles.instructor_fields,
            !adaptedInstructors?.length && styles.instructor_fields__noSpacing,
          )}
        >
          <Autocomplete
            disabled={someDeletedSessionIsSelected || disabled}
            loading={loading}
            options={options}
            className={styles.fieldSelect}
            formatOptionLabel={getFormattedLabel}
            setValueSearch={value => setValueSearch(value)}
          />

          <Link
            className={styles.fieldLink}
            iconRight="external-link"
            size="sm"
            disabled={someDeletedSessionIsSelected || disabled}
            onClick={() => {
              dispatch({
                type: Types.AdvanceSearchInstructor,
                payload: true,
              });
            }}
          >
            {t("assignation-edit.form.instructor.advanced-search")}
          </Link>
        </div>

        <SimpleResourceList
          items={adaptedInstructors}
          isDisabled={someDeletedSessionIsSelected || disabled}
          onDeleteResource={(instructor: Instructor) => handleDeleteInstructor(instructor)}
        />
      </div>
    </FormItem>
  );
};

export default InstructorSection;
