import React, { useState } from "react";
import { Pill, Text, Tooltip } from "@foris/avocado-suite";
import { Icon } from "@foris/avocado-icons";
import { IGroup } from "@modules/vacancies/contextVacancies/ContextVacancies";
import styles from "./groupTableCourseColumn.module.scss";
import { useTranslation } from "react-i18next";

interface GroupTableCourseColumnProps {
  group: IGroup;
}

/**
 * Component that renders a table column for displaying group course information.
 *
 * @component
 * @param {GroupTableCourseColumnProps} props - The props for the component.
 * @param {IGroup} props.group - The group object containing course and related information.
 *
 * @returns {JSX.Element} The rendered component.
 */
const GroupTableCourseColumn = ({ group }: GroupTableCourseColumnProps) => {
  const { t } = useTranslation();
  const [showPills, setShowpills] = useState(false);

  return (
    <div className={styles.groupTableCourseColumn}>
      <Tooltip label={`${group?.course?.code} - ${group?.course?.name}`} placement="top">
        <Text className={styles.groupTableCourseColumn_code}>
          {group?.course?.code} - {group?.course?.name}
        </Text>
      </Tooltip>

      <div className={styles.groupTableCourseColumn_pills}>
        <Pill className={styles.coursePill} outlined>
          {t("bundle.link-card.group-table.body.subject-key.term")}: {group?.term?.code ?? "-"}
        </Pill>
        <Pill className={styles.coursePill} outlined>
          {t("bundle.link-card.group-table.body.subject-key.campus")}:{" "}
          {group?.course?.curriculum?.program?.campus?.code ?? "-"}
        </Pill>

        {showPills && (
          <>
            <Pill className={styles.coursePill} outlined>
              {t("bundle.link-card.group-table.body.subject-key.department")}:{" "}
              {group?.course?.curriculum?.program?.department?.code ?? "-"}
            </Pill>
            <Pill className={styles.coursePill} outlined>
              {t("bundle.link-card.group-table.body.subject-key.modality")}:{" "}
              {group?.course?.curriculum?.program?.modality?.code ?? "-"}
            </Pill>
            <Pill className={styles.coursePill} outlined>
              {t("bundle.link-card.group-table.body.subject-key.shift")}:{" "}
              {group?.course?.curriculum?.program?.shift?.code ?? "-"}
            </Pill>
            <Pill className={styles.coursePill} outlined>
              {t("bundle.link-card.group-table.body.subject-key.program")}:{" "}
              {group?.course?.curriculum?.program?.code ?? "-"}
            </Pill>
            <Pill className={styles.coursePill} outlined>
              {t("bundle.link-card.group-table.body.subject-key.curriculum")}:{" "}
              {group?.course?.curriculum?.code ?? "-"}
            </Pill>
          </>
        )}

        <button className={styles.togglePills} onClick={() => setShowpills(!showPills)}>
          <Icon name="circle-dots" size="sm" color="neutral-70" />
        </button>
      </div>
    </div>
  );
};

export default GroupTableCourseColumn;
