import React, { useMemo } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { Heading, Pill, Text } from "@foris/avocado-suite";
import { Classroom, Instructor, Session } from "@models/ISchema";
import { EditedSession } from "../../context/formData.reducer";
import styles from "./cardSession.module.scss";

interface SessionData {
  blockRange: string;
  instructors: Partial<Instructor>[];
  classrooms: Partial<Classroom>[];
  intervals: string[];
  vacancies?: string;
}

interface CardSessionProps {
  session: Session | EditedSession;
  originData: SessionData;
  editedData?: SessionData;
  isNewSession?: boolean;
  isDeletedSession?: boolean;
}

type status = "same" | "new" | "deleted";

interface DetailsItemProps {
  title: string;
  text?: string;
  isGroupedText?: boolean;
  textsWithUpdates?: {
    value: string;
    status: status;
  }[];
}

const DetailsItem = ({ title, text, isGroupedText, textsWithUpdates = [] }: DetailsItemProps) => (
  <div className={styles.detailsItem}>
    <Text className={styles.detailsItem_title} type="sm" weight="medium">
      {title}
    </Text>

    {!!text && <Text className={styles.detailsItem_text}>{text}</Text>}

    {textsWithUpdates?.length > 0 && (
      <div
        className={cx(
          styles.detailsItem_updates,
          isGroupedText && styles.detailsItem_updates__grouped,
        )}
      >
        {textsWithUpdates.map((textWithUpdate, key) => (
          <Text
            key={key}
            className={cx(styles.detailsItem_text, [
              styles[`detailsItem_text__${textWithUpdate?.status}`],
            ])}
          >
            {textWithUpdate?.value} {textWithUpdate?.status === "new" && <Pill>Nuevo</Pill>}
          </Text>
        ))}
      </div>
    )}
  </div>
);

const CardSession = ({
  session,
  originData,
  editedData = {} as SessionData,
  isDeletedSession,
  isNewSession,
}: CardSessionProps) => {
  const hasInstructors = !!originData?.instructors?.length || !!editedData?.instructors?.length;
  const hasClassrooms = !!originData?.classrooms?.length || !!editedData?.classrooms?.length;

  const { t } = useTranslation();

  const blockRangeStatus = useMemo(() => {
    const newRange = [
      {
        value: originData?.blockRange,
        status:
          originData?.blockRange === editedData?.blockRange || isNewSession ? "same" : "deleted",
      },
    ];

    if (originData?.blockRange !== editedData?.blockRange && !isNewSession && !isDeletedSession) {
      newRange.push({
        value: editedData?.blockRange,
        status: "new",
      });
    }

    return newRange as DetailsItemProps["textsWithUpdates"];
  }, [originData?.blockRange, editedData?.blockRange]);

  const instructorsStatus = useMemo(() => {
    const ogInstructorsCodes = {};
    const ogInstructorsStatus = (originData?.instructors ?? [])?.map(instructor => {
      const editedInstructor = editedData?.instructors?.find(
        editedInstructor => editedInstructor.code === instructor.code,
      );

      ogInstructorsCodes[instructor.code] = true;

      return {
        value: `${instructor.code} | ${instructor.name}`,
        status: editedInstructor || isNewSession ? "same" : "deleted",
      };
    });

    const newInstructors = [];
    editedData?.instructors?.forEach(instructor => {
      if (!ogInstructorsCodes[instructor.code]) {
        newInstructors.push({
          value: `${instructor.code} | ${instructor.name}`,
          status: "new",
        });
      }
    });

    return [...ogInstructorsStatus, ...newInstructors] as DetailsItemProps["textsWithUpdates"];
  }, [originData?.instructors, editedData?.instructors]);

  const classroomStatus = useMemo(() => {
    const ogClassroomsCodes = {};
    const ogClassroomsStatus = (originData?.classrooms ?? [])?.map(classroom => {
      const editedClassroom = editedData?.classrooms?.find(
        editedClassroom => editedClassroom.code === classroom.code,
      );

      ogClassroomsCodes[classroom.code] = true;

      return {
        value: `${classroom.code} ${classroom.name} ${classroom.building ? "|" : ""} ${
          classroom?.building
        }`,
        status: editedClassroom || isNewSession ? "same" : "deleted",
      };
    });

    const newClassrooms = [];
    editedData?.classrooms?.forEach(classroom => {
      if (!ogClassroomsCodes[classroom.code]) {
        newClassrooms.push({
          value: `${classroom.code} ${classroom.name} ${classroom.building ? "|" : ""} ${
            classroom?.building
          }`,
          status: "new",
        });
      }
    });

    return [...ogClassroomsStatus, ...newClassrooms] as DetailsItemProps["textsWithUpdates"];
  }, [originData?.classrooms, editedData?.classrooms]);

  const intervalStatus = useMemo(() => {
    const newIntervals = [];
    const deletedIntervals = [];

    (editedData?.intervals ?? [])?.forEach(interval => {
      if (!originData?.intervals?.includes(interval)) {
        newIntervals.push({
          value: interval,
          status: "same",
        });
      }
    });

    (originData?.intervals ?? [])?.forEach(interval => {
      if (!editedData?.intervals?.includes(interval) && !isNewSession) {
        deletedIntervals.push({
          value: interval,
          status: "deleted",
        });
      }

      if (isNewSession) {
        newIntervals.push({
          value: interval,
          status: "same",
        });
      }
    });

    return {
      newIntervals,
      deletedIntervals,
    };
  }, [originData?.intervals, editedData?.intervals]);

  return (
    <div className={styles.cardSession}>
      <Heading className={styles.cardSession_title} type="h2">
        {isNewSession
          ? t("assignation-edit.incidence-table.session-card.new-session")
          : isDeletedSession
          ? t("assignation-edit.incidence-table.session-card.session-to-delete")
          : t("assignation-edit.incidence-table.session-card.session", {
              sessionId: session?.id,
            })}
      </Heading>

      <div className={styles.cardSession_details}>
        <DetailsItem
          title={t("assignation-edit.incidence-table.session-card.section")}
          text={session?.section?.id}
        />
        {originData?.vacancies !== undefined && (
          <DetailsItem
            title={t("assignation-edit.incidence-table.session-card.vacancies")}
            text={originData?.vacancies}
          />
        )}
        <DetailsItem
          title={t("assignation-edit.incidence-table.session-card.schedule")}
          textsWithUpdates={blockRangeStatus}
        />
      </div>

      <div className={styles.cardSession_details}>
        <DetailsItem
          title={t("assignation-edit.incidence-table.session-card.instructor")}
          text={
            !hasInstructors ? t("assignation-edit.incidence-table.session-card.no-asignation") : ""
          }
          textsWithUpdates={instructorsStatus}
        />
      </div>

      <div className={styles.cardSession_details}>
        <DetailsItem
          title={t("assignation-edit.incidence-table.session-card.classroom")}
          text={
            !hasClassrooms ? t("assignation-edit.incidence-table.session-card.no-asignation") : ""
          }
          textsWithUpdates={classroomStatus}
        />
      </div>

      {intervalStatus?.newIntervals?.length > 0 && (
        <div className={styles.cardSession_details}>
          <DetailsItem
            title={t("assignation-edit.incidence-table.session-card.new-weeks")}
            textsWithUpdates={intervalStatus?.newIntervals}
            isGroupedText
          />
        </div>
      )}

      {intervalStatus?.deletedIntervals?.length > 0 && (
        <div className={styles.cardSession_details}>
          <DetailsItem
            title={t("assignation-edit.incidence-table.session-card.deleted-weeks")}
            textsWithUpdates={intervalStatus?.deletedIntervals}
            isGroupedText
          />
        </div>
      )}
    </div>
  );
};

export default CardSession;
