import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useQuery } from "react-apollo";
import queryString from "query-string";
import Layout from "@common/layout/Layout";
import CourseTable from "./CourseTable";
import useGetQueryVariables from "@dimensions/hooks/useGetQueryVariables";
import coursesCubeQuery from "../graphql/coursesCubeQuery.graphql";
import coursesFilterQuery from "../graphql/coursesFilterQuery.graphql";
import { CoursePage } from "@models/ISchema";

const CourseSearch: React.FC = () => {
  // Using React Router's useLocation hook to get the query parameters from the URL
  const location = useLocation();
  const params: queryString.ParsedQuery<string> = queryString.parse(location.search);

  // State for managing current page and size per page for pagination
  const [page, setPageCount] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(20);

  // Get the query variables using the custom hook, based on the URL parameters and pagination settings
  const variables = useGetQueryVariables(params, page, undefined, sizePerPage);

  // Choose the appropriate query based on the 'advance' parameter from the URL
  const querySearch = params?.advance ? coursesFilterQuery : coursesCubeQuery;

  // Fetch data using the Apollo useQuery hook with the chosen query and variables
  const { loading, error, data } = useQuery(querySearch, { variables });

  // Assign the fetched data depending on the query used
  let courses: CoursePage = data?.cube?.coursesWithSections;
  if (params?.advance) {
    courses = data?.filter?.courses;
  }

  // Render the Layout component and pass the courses data to the <CourseTable> component for display
  return (
    <Layout contextSearch>
      <CourseTable
        data={courses?.items} // Items from courses data
        pageInfo={courses?.pageInfo} // Page information for pagination
        search={params?.search?.toString()} // Search query from URL
        setPage={(value: number) => setPageCount(value)} // Function to update the page number
        loading={loading} // Pass loading state from useQuery
        error={error} // Pass error state from useQuery
        onChangeSizePerPage={setSizePerPage} // Function to update the size of items per page
      />
    </Layout>
  );
};

export default CourseSearch;
