import React from "react";
import { useTranslation } from "react-i18next";
import DimensionsSearchResults from "@dimensions/common/dimensions-search-results";
import useGetTitle from "@dimensions/hooks/useGetTitle";
import { getColumnCell, getHeadCell, getLinkCell } from "@dimensions/utils/tableUtils";
import { ISearchProps } from "@dimensions/common/ISearch";
import { DimensionNameEs } from "@models/IDimension";
import { Package } from "@models/ISchema";
import useNavigationUrl from "@common/hooks/useNavigationUrl";

/**
 * <PackageTable> component that displays a table of packages with relevant information.
 *
 * This component fetches packages data and renders it in a table format, displaying information
 * such as packages code, period, campus, carrear, etc.
 * It integrates search, and internationalization (i18n) for multi-language support.
 *
 * @param {DimensionSearchResultsProps} props - The properties passed to the component.
 * @param {Array<Package>} props.data - Data of packages to display in the table.
 * @param {string} props.search - The search query to filter results.
 * @param {Object} props.pageInfo - Pagination information (total results, current page, etc.).
 * @param {Function} props.setPage - Function to change the current page.
 * @param {boolean} props.loading - Boolean indicating if the data is still loading.
 * @param {boolean} props.error - Boolean indicating if there was an error during data fetch.
 * @returns {JSX.Element} - The rendered PackageTable component.
 *
 * @example
 * <PackageTable
 *   data={packagesData}
 *   search="Math"
 *   pageInfo={pageInfo}
 *   setPage={setPage}
 *   loading={isLoading}
 *   error={hasError}
 * />
 */

const PackageTable = (props: ISearchProps) => {
  const { data, search, pageInfo, loading, error, setPage, onChangeSizePerPage } = props;

  // Get the dynamic title for the search results using the custom hook `useGetTitle`
  const title = useGetTitle(search, pageInfo?.total || 0, "paquete");

  // Get the context URL for advanced search functionality
  const { advancedSearchUrl, editorUrl } = useNavigationUrl();

  // Use the i18n hook to get translation functions
  const { t } = useTranslation();

  const getGroupsTotal = ({ links }: Package) => {
    let groupCount = 0;

    links?.forEach(link => {
      groupCount += link?.groups?.length || 0;
    });

    const total = (links?.length || 0) + groupCount;
    return getColumnCell(total);
  };

  // Define the columns for the table, including the header and cell rendering logic
  const columns = [
    {
      header: getHeadCell(t("search-results.dimension.package.table.header.code")),
      renderCell: ({ code, id }: Package) => getLinkCell(editorUrl("package", id), null, code),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "200px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.package.table.header.period")),
      renderCell: ({ population }: Package) => getColumnCell(population?.term?.code),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "110px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.package.table.header.campus")),
      renderCell: ({ population }: Package) => getColumnCell(population?.campus?.code),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "110px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.package.table.header.modality")),
      renderCell: ({ population }: Package) => getColumnCell(population?.modality?.code),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "124px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.package.table.header.shift")),
      renderCell: ({ population }: Package) => getColumnCell(population?.shift?.code),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "110px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.package.table.header.career")),
      renderCell: ({ population }: Package) =>
        getColumnCell(`${population?.program?.code} ${population?.program?.name}`),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "270px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.package.table.header.curriculum")),
      renderCell: ({ population }: Package) => getColumnCell(population?.curriculum),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "110px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.package.table.header.level")),
      renderCell: ({ population }: Package) => getColumnCell(population?.level),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "110px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.package.table.header.package")),
      renderCell: ({ index }: Package) => getColumnCell(index),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "110px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.package.table.header.links")),
      renderCell: ({ links }: Package) => getColumnCell(links?.length),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "110px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.package.table.header.groups")),
      renderCell: getGroupsTotal,
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "110px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.package.table.header.quota")),
      renderCell: ({ vacancies }: Package) => getColumnCell(vacancies),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "110px" },
      },
    },
  ];

  // Render the DimensionsSearchResults component with the table data and configurations
  return (
    <DimensionsSearchResults
      onChangeSizePerPage={onChangeSizePerPage}
      title={title.getTitle}
      columns={columns}
      data={data}
      pageInfo={pageInfo}
      setPage={setPage}
      loading={loading}
      error={error}
      dimension={t("dimension.package.title") as DimensionNameEs}
      advanceLink={advancedSearchUrl("package")}
    />
  );
};

export default PackageTable;
