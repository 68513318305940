import React, { useState, useEffect, useCallback, useMemo } from "react";
import cx from "classnames";
import { Pill, Tooltip, Text, Loading } from "@foris/avocado-suite";
import { Icon } from "@foris/avocado-icons";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useApolloClient } from "react-apollo";
import Layout from "../../../../common/layout/Layout";
import { EmptyResults, Header } from "../../../../common/components";
import SectionsContainer from "../../../sections/Sections";
import { ISectionData } from "../../../sections/ISections";
import { functionsData } from "../../../sections/utils/";
import { CubeQuery, Population } from "../../../../models/ISchema";
import { IParams } from "../../../../models/IParams";
import { populationSection } from "../graphql/populationSection.query";
import useGetDimension from "@dimensions/hooks/useGetDimension";
import { Dimension } from "@models/IDimension";
import css from "./population.module.scss";

const GroupCalendar: React.FC = () => {
  const client = useApolloClient();
  const { id, origin, scenario, workspace }: IParams = useParams();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const [calendarData, setCalendar] = useState(null);
  const [ogHeaderData, setOgHeaderData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [error, setError] = useState(false);

  const [dimension] = useGetDimension(Dimension.population);

  const variables = {
    query: populationSection,
    variables: {
      resourceId: id,
      scenarioId: scenario,
      originId: origin,
      resourceType: "POPULATION",
    },
  };

  const headerInfo = (info: Population) => {
    const moreDetails = [
      {
        title: t("dimension.population.header.more-details.vacancies"),
        content: info?.size?.toString() || "",
      },
      {
        title: t("dimension.population.header.more-details.package"),
        content: info?.packageCount?.toString() || "0",
      },
    ];

    const programName = info?.program?.name || "-";
    const campus = info?.campus?.code || "-";
    const programCode = info?.program?.code || "-";
    const modality = info?.modality?.code || "-";
    const shift = info?.shift?.code || "-";
    const curriculum = info?.curriculum || "-";
    const level = info?.level || "-";
    const termCode = info?.term?.code ?? "-";

    const handleCopyToClipboard = async () => {
      try {
        const codeToCopy = `${info?.code}`;
        await navigator.clipboard.writeText(codeToCopy);

        setShowTooltip(true);
        setTimeout(() => {
          setShowTooltip(false);
        }, 500);
      } catch (err) {
        console.error("No se pudo copiar en el portapapeles");
      }
    };

    return {
      title: programName,
      topTitle: (
        <div className={cx(css.ctnClipBoard, "avocado-light-theme")}>
          <Text darkMode>{info?.code || ""}</Text>
          <Tooltip placement="top" visible={showTooltip} label={t("dimension.population.btn-copy")}>
            <button className={css.ctnClipBoard_btn} onClick={handleCopyToClipboard}>
              <Icon name="copy" color="gray-30" />
            </button>
          </Tooltip>
        </div>
      ),
      subTitle: (
        <>
          <Pill textColor="gray-10" color="gray-70">
            {t("dimension.population.header.more-details.campus")}: {campus}
          </Pill>
          <Pill textColor="gray-10" color="gray-70">
            {t("dimension.population.header.more-details.shift")}: {shift}
          </Pill>
          <Pill textColor="gray-10" color="gray-70">
            {t("dimension.population.header.more-details.modality")}: {modality}
          </Pill>
          <Pill textColor="gray-10" color="gray-70">
            {t("dimension.population.header.more-details.career")}: {programCode}
          </Pill>
          <Pill textColor="gray-10" color="gray-70">
            {t("dimension.population.header.more-details.curriculum")}: {curriculum}
          </Pill>
          <Pill textColor="gray-10" color="gray-70">
            {t("dimension.population.header.more-details.level")}: {level}
          </Pill>
          <Pill textColor="gray-10" color="gray-70">
            {t("dimension.population.header.more-details.period")}: {termCode}
          </Pill>
        </>
      ),
      moreDetails,
    };
  };

  const getDataSections = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await client.query(variables);
      if (data) {
        const cubeQuery: CubeQuery = data.cube;
        const info: any = cubeQuery.editorView.info;
        const populationInfo: Population = info.population;
        if (populationInfo) {
          setOgHeaderData(populationInfo);
          const sections: ISectionData = functionsData.serializeSections(data);
          setCalendar(sections);
        } else {
          setError(true);
        }
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setError(true);
      setLoading(false);
    }
  }, [client, variables]);

  const headerData = useMemo(() => {
    if (ogHeaderData) {
      return headerInfo(ogHeaderData);
    }
    return {};
  }, [ogHeaderData, language, showTooltip]);

  const setDataCalendar = (data: ISectionData, callback: () => void) => {
    setCalendar(data);
    callback && callback();
  };

  useEffect(() => {
    if (calendarData === null && error === false) getDataSections();
  }, [calendarData, error, getDataSections]);

  return (
    <Layout contextSearch>
      {headerData && (
        <Header
          breadcrumbItems={[
            {
              path: "/",
              title: t("dimension.population.breadcrumb.previous"),
            },
            {
              title: t("dimension.population.breadcrumb.current"),
            },
          ]}
          dimension={dimension.label}
          {...headerData}
        />
      )}
      {loading && <Loading />}
      {error && <EmptyResults dimension={t("dimension.population.title")} />}
      {calendarData && (
        <SectionsContainer
          config={{
            scenarioId: parseInt(scenario),
            originId: parseInt(origin),
            workspaceId: parseInt(workspace),
          }}
          data={calendarData}
          setDataGrid={setDataCalendar}
        />
      )}
    </Layout>
  );
};

export default GroupCalendar;
