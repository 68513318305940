import React, { useContext, useEffect, useMemo } from "react";
import { AppContext } from "../../context/EditSessionsContext";
import { Session } from "@models/ISchema";
import { Types } from "../../context/result.reducer";
import { EditedSession } from "../../context/formData.reducer";
import { Accordion as SuiteAccordion } from "@foris/avocado-suite";
import SessionCard from "../session-card";
import styles from "./savedSectionsList.module.scss";

type SessionsBySectionIdType = {
  [key: string]: {
    edited: (Session | EditedSession)[];
    created: (Session | EditedSession)[];
    deleted: (Session | EditedSession)[];
  };
};

const SavedSectionsList = () => {
  const { state, dispatch } = useContext(AppContext);
  const selectSession = state?.result?.selectSession;
  const selectNewSession = state?.result?.selectCreateSession;
  const sessionToDeleteSelected = state?.result?.sessionToDeleteSelected;

  const savedSessionsToCreateIds = useMemo(() => {
    const ids = {};

    if (state?.form?.sessionsToCreate) {
      for (const session of state.form.sessionsToCreate) {
        if (session?.id) {
          ids[session.id] = true;
        }
      }
    }

    return ids;
  }, [state?.form?.sessionsToCreate]);

  const sessionsWithErrorsById = useMemo(() => {
    const sessionsPayload = [
      ...(state?.result?.createValidation?.sessionsPayload ?? []),
      ...(state?.result?.resultValidation?.sessionsPayload ?? []),
    ];

    return sessionsPayload.reduce((acc, payload) => {
      const hasErrors =
        Boolean(payload?.intervalValidationErrors?.length) ||
        Boolean(payload?.validationErrors?.length) ||
        Boolean(payload?.customValidations?.length);

      if (hasErrors) {
        const id =
          payload?.sessionUuid in savedSessionsToCreateIds
            ? payload?.sessionUuid
            : payload?.sessionId;
        acc[id] = true;
      }

      return acc;
    }, {});
  }, [
    state?.result?.createValidation?.sessionsPayload,
    state?.result?.resultValidation?.sessionsPayload,
    savedSessionsToCreateIds,
  ]);

  const sectionsById = useMemo(() => {
    const sessions = [
      ...(state?.form?.savedSessions ?? []),
      ...(Object.values(state?.form?.sessionsToDelete) ?? []),
      ...(state?.form?.sessionsToCreate ?? []),
    ];

    return sessions.reduce((acc, session: EditedSession) => {
      const sectionId = session?.section?.id || session?.session?.section?.id;
      const section = session?.section || session?.session?.section;
      if (sectionId && section) {
        acc[sectionId] = section;
      }
      return acc;
    }, {});
  }, [state?.form?.savedSessions, state?.form?.sessionsToDelete, state?.form?.sessionsToCreate]);

  const sessionsBySectionId = useMemo(() => {
    const sessions = [
      ...(state?.form?.savedSessions ?? []),
      ...(Object.values(state?.form?.sessionsToDelete) ?? []),
      ...(state?.form?.sessionsToCreate ?? []),
    ];

    return sessions.reduce((acc, session: EditedSession) => {
      const sectionId = session?.section?.id || session?.session?.section?.id;
      if (!acc[sectionId]) {
        acc[sectionId] = { edited: [], created: [], deleted: [] };
      }
      if (state?.form?.sessionsToDelete?.[session.id]) {
        acc[sectionId].deleted.push(session);
      } else if (session.id.includes("-")) {
        acc[sectionId].created.push(session);
      } else {
        acc[sectionId].edited.push(session.session);
      }
      return acc;
    }, {} as SessionsBySectionIdType);
  }, [state?.form?.savedSessions, state?.form?.sessionsToDelete, state?.form?.sessionsToCreate]);

  useEffect(() => {
    if (selectSession) {
      dispatch({ type: Types.SelectSession, payload: selectSession });
    }
    if (selectNewSession) {
      dispatch({ type: Types.SelectCreateSession, payload: selectNewSession });
    }
  }, [selectSession, selectNewSession]);

  if (
    !state?.form?.savedSessions?.length &&
    !Object.keys(state?.form?.sessionsToDelete)?.length &&
    !state?.form?.sessionsToCreate?.length
  ) {
    return null;
  }

  const getSectionTitle = (sectionId: string) => {
    const section = sectionsById?.[sectionId];
    const component = section?.component ?? section?.courseComponent?.component;

    return `${component?.code ? `${component?.code} ` : ""}${section?.code}`;
  };

  return (
    <>
      {Object.entries(sessionsBySectionId).map(([sectionId, sessions], index) => (
        <SuiteAccordion
          key={sectionId}
          title={getSectionTitle(sectionId)}
          opened={index === 0}
          className={styles.savedSectionsList}
        >
          <div className={styles.savedSectionsList_content}>
            {sessions.edited.map(session => (
              <SessionCard
                key={session.id}
                colorIndex={index + 1}
                session={session}
                linkWeeks={state.link.weeks}
                isSelected={session?.id === selectSession?.id}
                status={sessionsWithErrorsById?.[session?.id] ? "error" : "success"}
                onClick={session => {
                  if (session?.id !== selectSession?.id) {
                    dispatch({ type: Types.SelectSession, payload: session as Session });
                  }
                }}
              />
            ))}

            {sessions.created.map(session => (
              <SessionCard
                key={session.id}
                colorIndex={index + 1}
                session={session}
                linkWeeks={state.link.weeks}
                isSelected={session?.id === selectNewSession?.id}
                status={sessionsWithErrorsById?.[session?.id] ? "error" : "success"}
                onClick={session => {
                  if (session?.id !== selectNewSession?.id) {
                    dispatch({ type: Types.SelectCreateSession, payload: session as Session });
                  }
                }}
              />
            ))}

            {sessions.deleted.map(session => (
              <SessionCard
                key={session.id}
                colorIndex={index + 1}
                session={session}
                linkWeeks={state.link.weeks}
                isSelected={session?.id === sessionToDeleteSelected?.id}
                isDeleted
                status={sessionsWithErrorsById?.[session?.id] ? "error" : undefined}
                onClick={session => {
                  if (session?.id !== sessionToDeleteSelected?.id) {
                    dispatch({ type: Types.SelectDeletedSession, payload: session });
                  }
                }}
              />
            ))}
          </div>
        </SuiteAccordion>
      ))}
    </>
  );
};

export default SavedSectionsList;
