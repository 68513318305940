import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { Loading } from "@foris/avocado-suite";
import { Snackbar } from "@foris/avocado-ui";
import { ICurrentData } from "./Sections";
import { ISectionData } from "../../../sections/ISections";
import Layout from "../../../../common/layout/Layout";
import { EmptyResults, Header } from "../../../../common/components";
import Sections from "../../../sections/Sections";
import ItemEdit from "../../../sections/GridSections/ItemEdit/ItemEdit";
import DisabledSession from "../../../sections/GridSections/DisabledSession/DisabledSession";
import ChangeComponent from "../../../sections/GridSections/ChangeComponent/ChangeComponent";
import { IParams } from "../../../../models/IParams";
import useGetDimension from "@dimensions/hooks/useGetDimension";
import { Dimension } from "@models/IDimension";

interface ISectionActionProps {
  current: ICurrentData;
  calendarData: any;
  headerData: any;
  loading: boolean;
  error: boolean;
  setDataCalendar: (data: ISectionData, callback: () => void) => void;
  setCurrentData: (value: any) => void;
}

const SectionAction: React.FC<ISectionActionProps> = (props: ISectionActionProps) => {
  const {
    current,
    calendarData,
    headerData,
    loading,
    setDataCalendar,
    setCurrentData,
    error,
  } = props;

  const history = useHistory();
  const { origin, scenario, workspace }: IParams = useParams();
  const { t } = useTranslation();
  const [activeEdit, setActiveEdit] = useState(false);
  const [activeSession, setActiveSession] = useState(false);
  const [activeComponent, setActiveComponent] = useState(false);
  const [showError, setShowError] = useState(false);
  const contextUrl = `${workspace}/${scenario}/${origin}`;

  const [dimension] = useGetDimension(Dimension.section);

  const handleOpenEdit = () => {
    const linkId = current?.links?.[0]?.id;
    history.push(`/editor/form-edit/${contextUrl}/${linkId}}`);
  };

  return (
    <Layout contextSearch>
      <Snackbar
        type="error"
        setValueActive={() => setShowError(false)}
        active={showError}
        icon="circle-full-error"
        duration={5}
      >
        Sección tiene sesiones programadas y tu rol no cuenta con permisos para eliminar sesiones
      </Snackbar>

      {headerData && (
        <Header
          dimension={dimension.label}
          {...headerData}
          callbackOpenEdit={handleOpenEdit}
          callbackOpenDisabledSession={(value: boolean) => {
            setActiveSession(value);
          }}
          callbackOpenChangeComponent={(value: any) => setActiveComponent(value)}
          onShowError={() => setShowError(true)}
          breadcrumbItems={[
            {
              path: "/",
              title: t("dimension.section.breadcrumb.previous"),
            },
            {
              title: t("dimension.section.breadcrumb.current"),
            },
          ]}
          shouldRenderSectionMenu={!activeSession && !activeComponent}
        />
      )}
      {loading && <Loading />}
      {error && <EmptyResults dimension="Sección" />}
      {activeEdit && !activeSession && !activeComponent && (
        <div className="container-row">
          <ItemEdit
            onClose={() => setActiveEdit(false)}
            createSession={true}
            weeks={current.weekList}
            selected={current.week}
            event={current.selectedEvent}
            sectionId={current.sectionId}
            component={current.component.code}
            vacancies={current.vacancies}
            title={current.title}
            recommendationsAvailable={false}
          />
        </div>
      )}
      {activeSession && !activeEdit && !activeComponent && (
        <div className="container-row">
          <DisabledSession
            onClose={() => setActiveSession(false)}
            createSession={true}
            event={current.selectedEvent}
            sectionId={current.sectionId}
            component={current.component.code}
            vacancies={current.vacancies}
            title={current.title}
            onInvalidateSectionDeletion={() => setShowError(true)}
          />
        </div>
      )}
      {activeComponent && !activeSession && !activeEdit && (
        <div className="container-row">
          <ChangeComponent
            onClose={() => setActiveComponent(false)}
            event={current.selectedEvent}
            createSession={true}
            component={current.component}
            sectionId={current.sectionId}
            vacancies={current.vacancies}
            title={current.title}
            courseId={current.courseId}
            courseComponent={current.courseComponent}
          />
        </div>
      )}
      {calendarData && current && !activeEdit && !activeSession && (
        <Sections
          config={{
            scenarioId: parseInt(scenario),
            originId: parseInt(origin),
            workspaceId: parseInt(workspace),
          }}
          data={calendarData}
          setDataGrid={setDataCalendar}
          currentWeek={current.week}
          setCurrentWeek={(value: any) => {
            const cloneCurrent = { ...current };
            setCurrentData({ ...cloneCurrent, week: value });
          }}
        />
      )}
    </Layout>
  );
};

export default SectionAction;
