import React, { useContext, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import cx from "classnames";
import { enums, messages } from "../../utils";
import * as mouseflow from "@utils/mouseflow";
import { IContextApp, ContextApp } from "@config/Context/contextApp";
import { ContextEDH } from "@context/ContextEDH";
import { IParams } from "../../../../models/IParams";
import { Classroom } from "@models/ISchema";
import { Text, Link, CardNotification, Button, Select } from "@foris/avocado-suite";
import { useTranslation } from "react-i18next";
import ItemInfoRow from "../item-info-row";
import css from "./item_info.module.scss";

const ItemInfo = (props: any) => {
  const { event, error, currentSelectedWeek } = props;
  const { origin, scenario, workspace }: IParams = useParams();
  const history = useHistory();
  const context: IContextApp = useContext(ContextApp);
  const { state } = useContext(ContextEDH);
  const [linkValue, setLinkValue] = useState(null);

  const { t } = useTranslation();

  let instructors = null;
  let classrooms = null;
  let campus = null;
  let day = "";
  let startDate = "";
  let endDate = "";
  let nameComponent = "";
  let sectionId = "";
  let vacancies = "";
  let enrollments = "";
  let waitList = "";
  let demand = "";
  let groups = [];
  const userCanEdit =
    context?.user?.permissions?.update && context?.user?.abilities?.can_edit_assignment;

  const optionsLinks = useMemo(() => {
    return event?.links?.map((link: any) => {
      return { label: `Liga: ${link?.id}`, value: link?.id };
    });
  }, [event?.links]);

  if (event && event.resource) {
    const resource = event?.resource;
    instructors = resource?.instructors ?? [];
    classrooms = resource?.classrooms ?? [];
    campus = resource?.info?.course?.curriculum?.program?.campus?.name || "-";

    if (resource?.blockRange) {
      const block = resource?.blockRange;
      if (block?.start) {
        day = (block?.start?.day && enums.DAY_NAMES_ES[block?.start?.day]) || "";
        startDate = block?.start?.startingTime?.replace(":00", "") || "";
      }
      endDate = block?.end ? block?.end?.endingTime?.replace(":00", "") : "";
    }
    if (resource?.info?.courseComponent) {
      nameComponent = resource?.info?.courseComponent?.component?.code ?? "";
    }
    if (resource?.info?.section) {
      const infoSection = resource?.info?.section;
      sectionId = infoSection?.id ?? "";
      vacancies = infoSection?.vacancies ?? "";
      groups = infoSection?.groups ?? [];
      enrollments = infoSection?.usedCapacity ?? "";
      waitList = infoSection?.waitingLists ?? "";
    }
    demand = resource?.info?.course?.demand?.crosslist
      ? `(${resource?.info?.course?.demand?.crosslist})`
      : "";
  }

  const errors = error && event.error.details;
  const errorList = error && errors.map((item: any) => item.__typename);
  const uniqueList =
    error && errorList.filter((item: any, pos: any) => errorList.indexOf(item) === pos);

  const typeGroup = [];
  if (groups) groups.forEach(group => group.type && typeGroup.push(group.type));

  const contextUrl = `${workspace}/${scenario}/${origin}`;

  const displayNoResource = (mandatoriness: boolean, resource: string) => {
    const classname = mandatoriness
      ? css.absent_resource__required
      : css.absent_resource__not_required;
    const text = mandatoriness
      ? t("calendar-view.event-modal.columns.empty-required", {
          resource,
        })
      : t("calendar-view.event-modal.columns.empty-not-required", {
          resource,
        });

    return <Text className={cx(css.textLabel, classname)}>{text}</Text>;
  };

  return (
    <div className={css.itemInfo}>
      <div className={css.itemInfo_content}>
        <ItemInfoRow
          columns={[
            {
              icon: "calendar",
              title: `${t("calendar-view.event-modal.columns.schedule")}:`,
              text: `${day ? `${day},` : ""} ${startDate} - ${endDate}`,
            },
          ]}
        />

        <ItemInfoRow
          columns={[
            {
              icon: "user",
              title: `${t("calendar-view.event-modal.columns.instructor")}:`,
              customContent:
                instructors?.length > 0 ? (
                  <div className={css.contentGroup}>
                    {instructors.map((dataInstructor: any, index: number) => {
                      const codeInstructor = dataInstructor?.code ?? "";
                      const nameInstructor =
                        dataInstructor?.name ??
                        t("calendar-view.event-modal.columns.empty-instructor");
                      const label = `${codeInstructor} - ${nameInstructor}`;

                      return dataInstructor?.id ? (
                        <Link
                          key={index}
                          href={`/scheduler/editor/instructor/${contextUrl}/${dataInstructor.id}`}
                        >
                          {label}
                        </Link>
                      ) : (
                        <Text key={index} className={css.textValue}>
                          {label}
                        </Text>
                      );
                    })}
                  </div>
                ) : (
                  displayNoResource(
                    event?.resource?.info?.courseComponent?.requiresInstructor,
                    t("calendar-view.event-modal.columns.instructor"),
                  )
                ),
            },
          ]}
        />

        {!!classrooms?.length ? (
          <div className={css.classroomGroup}>
            {classrooms?.map((classroom: Classroom, index: number) => (
              <ItemInfoRow
                key={index}
                className={css.classroomRow}
                columns={[
                  {
                    icon: "building",
                    title: `${t("calendar-view.event-modal.columns.classroom")}:`,
                    text: classroom?.name ?? "Sin sala asignada",
                    link: classroom?.id
                      ? `/scheduler/editor/classroom/${contextUrl}/${classroom.id}`
                      : "",
                    isTextTruncated: true,
                  },
                  {
                    title: `${t("calendar-view.event-modal.columns.building")}:`,
                    text: classroom?.building?.name || "-",
                    isTextTruncated: true,
                  },
                  {
                    title: `${t("calendar-view.event-modal.columns.campus")}:`,
                    text: classroom?.building?.campus?.name || "-",
                    isTextTruncated: true,
                  },
                ]}
              />
            ))}
          </div>
        ) : (
          displayNoResource(
            event?.resource?.info?.courseComponent?.requiresClassroom,
            t("calendar-view.event-modal.columns.classroom"),
          )
        )}

        <ItemInfoRow
          className={cx(css.contentRow, css.contentRow__details)}
          columns={[
            {
              title: `${t("calendar-view.event-modal.columns.vacancies")}:`,
              text: vacancies,
              isVertical: true,
            },
            ...(!!event?.bundle?.id
              ? [
                  {
                    title: `${t("calendar-view.event-modal.columns.bundle")}:`,
                    text: event?.bundle?.code ?? "-",
                    link: `/editor/vacancies/${contextUrl}/${event?.bundle?.id}`,
                    isVertical: true,
                  },
                ]
              : []),
            {
              title: `${t("calendar-view.event-modal.columns.section")}:`,
              text: sectionId,
              link: sectionId ? `/scheduler/editor/section/${contextUrl}/${sectionId}` : "",
              isVertical: true,
            },
            {
              title: `${t("calendar-view.event-modal.columns.component")}:`,
              text: nameComponent,
              isVertical: true,
            },
            {
              title: `${t("calendar-view.event-modal.columns.enrollments")}:`,
              text: enrollments,
              isVertical: true,
            },
            {
              title: `${t("calendar-view.event-modal.columns.waiting-list")}:`,
              text: waitList,
              isVertical: true,
            },
            {
              title: `${t("calendar-view.event-modal.columns.campus")}:`,
              text: campus,
              isVertical: true,
            },
            ...(!!typeGroup?.length
              ? [
                  {
                    title: `${t("calendar-view.event-modal.columns.group-type")}:`,
                    text: typeGroup.join(", "),
                    isVertical: true,
                  },
                ]
              : []),
            {
              title: `${t("calendar-view.event-modal.columns.groups", {
                demand,
              })}:`,
              isVertical: true,
              customContent: groups?.length ? (
                <div className={css.contentGroup}>
                  {groups?.map((group: any, index) => {
                    const codeGroup =
                      index + 1 === groups.length ? group.code && group.code : `${group.code}, `;

                    return group?.id ? (
                      <Link
                        key={index}
                        className={css.textLink}
                        href={`/scheduler/editor/group/${contextUrl}/${group.id}`}
                      >
                        {codeGroup}
                      </Link>
                    ) : (
                      <Text key={index} className={css.textValue}>
                        {codeGroup}
                      </Text>
                    );
                  })}
                </div>
              ) : (
                "-"
              ),
            },
          ]}
        />
      </div>

      {event?.links?.length > 1 ? (
        <Select
          className={css.itemInfo_linkSelect}
          label={t("calendar-view.event-modal.link-selector.label")}
          value={linkValue}
          placeholder={t("calendar-view.event-modal.link-selector.placeholder")}
          options={optionsLinks}
          onChange={(value: any) => {
            setLinkValue(value);
          }}
          {...{ activeMaxHeight: true }}
        />
      ) : null}

      {error && (
        <CardNotification
          className={css.errorCard}
          state="error"
          title={`${uniqueList.length} ${
            uniqueList.length > 1
              ? t("calendar-view.event-modal.errors.title-plural")
              : t("calendar-view.event-modal.errors.title")
          }`}
        >
          <ul className={css.errorList}>
            {uniqueList.map((item: any, index: number) => (
              <li key={index} className={css.errorList_item}>
                {messages.errors[item]}
              </li>
            ))}
          </ul>
        </CardNotification>
      )}

      {userCanEdit && event.canEditCourse && event?.links?.length >= 1 && (
        <footer className={css.itemInfo_footer}>
          <Button
            className={css.sectionFooter_btn}
            onClick={() => {
              const linkUrl = event?.links?.length === 1 ? event?.links[0]?.id : linkValue?.value;
              mouseflow.actionTag("action_edit_multiple", state?.base?.isMouseflowEnabled);

              history.push(`/editor/form-edit/${contextUrl}/${linkUrl}`, {
                sessionId: event?.sessionId,
                currentSelectedWeek,
              });
            }}
            disabled={event?.links?.length > 1 && !linkValue}
          >
            {t("calendar-view.event-modal.action-btn")}
          </Button>
        </footer>
      )}
    </div>
  );
};

export default ItemInfo;
