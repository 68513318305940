import gql from "graphql-tag";

export const packageSearchMutation = gql`
  mutation($scenarioId: ID!, $originId: ID!, $filter: PackageFilterInput) {
    filter(scenarioId: $scenarioId, originId: $originId) {
      createPackageFilter(filter: $filter) {
        id
        packages {
          items {
            id
            code
            index
          }
          pageInfo {
            hasPreviousPage
            hasNextPage
            page
            size
            total
          }
        }
      }
    }
  }
`;
