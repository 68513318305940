import React, { useState, useContext } from "react";
import { Select } from "@foris/avocado-suite";
import { AppContext } from "../../context/EditSessionsContext";
import { Types } from "../../context/formData.reducer";
import { useTranslation } from "react-i18next";

interface AutocomleteProps {
  options: any;
  loading: boolean;
  disabled?: boolean;
  className: string;
  setValueSearch: (value: any) => void;
  formatOptionLabel?: (option: any) => any;
}

const AutocomleteSearch = ({
  options,
  loading,
  className,
  setValueSearch,
  disabled,
  formatOptionLabel,
}: AutocomleteProps) => {
  const { state, dispatch } = useContext(AppContext);
  const [value, setValue] = useState(null);
  const { t } = useTranslation();

  return (
    <Select
      isClearable
      disabled={disabled}
      isLoading={loading}
      options={options}
      value={value}
      className={className}
      isOptionDisabled={option => !!(option as any)?.isDisabled}
      formatOptionLabel={formatOptionLabel}
      placeholder={t("assignation-edit.form.instructor.selector-placeholder")}
      onInputChange={value => setValueSearch(value)}
      onChange={(value: any) => {
        const disabled = value
          ? state?.form?.editedSessions?.instructors?.find(item => item.id === value.value)
          : true;
        if (!disabled) {
          const instructorsList = state?.form?.editedSessions?.instructors
            ? [...state?.form?.editedSessions?.instructors]
            : [];
          instructorsList.push(value);
          dispatch({
            type: Types.InstructorEditedSessions,
            payload: {
              instructors: instructorsList,
            },
          });
        }
        setValue(null);
      }}
    />
  );
};

export default AutocomleteSearch;
