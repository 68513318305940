import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox, Tag, Heading, Divider } from "@foris/avocado-suite";
import SelectLabels from "@dimensions/group/EditLabels/SelectLabels";
import LabelCategorySelector from "@modules/shared/selectors/Selectors/LabelCategoriesSelector";
import { ILabel } from "@dimensions/group/EditLabels/ContextEditLabels";
import css from "./advancedLabelsSelectors.module.scss";

interface AdvancedLabelsSelectorsProps {
  scenario: string;
  contentType: "group" | "package" | "all";
  onChange?: (payload: { categoryId?: string | null; labelsIds: string[] }) => void;
}

const AdvancedLabelsSelectors = ({
  scenario,
  contentType,
  onChange,
}: AdvancedLabelsSelectorsProps) => {
  // States for handling the visibility of the category and label selectors
  const [showCategorySelector, setShowCategorySelector] = useState(false);
  const [showLabelsSelector, setShowLabelsSelector] = useState(false);
  const [categoryId, setCategoryId] = useState<string | null>(null);
  const [selectedLabels, setSelectedLabels] = useState<{ [key: string]: ILabel }>({});
  // Hooks
  const { t } = useTranslation();

  const handleShowCategorySelector = (isVisible: boolean) => {
    setCategoryId(null);
    setShowCategorySelector(isVisible);
  };

  const handleShowLabelsSelector = (isVisible: boolean) => {
    setSelectedLabels({});
    setShowLabelsSelector(isVisible);
  };

  const handleSetCategoryId = (categoryId: string) => {
    setSelectedLabels({});
    setCategoryId(categoryId);
  };

  const handleSetSelectedLabels = (label: ILabel) => {
    if (selectedLabels?.[label.id]) {
      return;
    }

    setSelectedLabels({ ...selectedLabels, [label.id]: label });
  };

  const handleRemoveSelectedLabel = (labelId: string) => {
    const newLabels = structuredClone(selectedLabels);
    newLabels?.[labelId] && delete newLabels[labelId];

    setSelectedLabels(newLabels);
  };

  useEffect(() => {
    onChange?.({ categoryId, labelsIds: Object.values(selectedLabels)?.map(({ id }) => id) });
  }, [selectedLabels, categoryId]);

  const labelList = useMemo(() => {
    return Object.values(selectedLabels).map(({ id, name, code }) => (
      <Tag key={id} type="outlined" onIconClick={() => handleRemoveSelectedLabel(id)} icon="close">
        {name}
        {code ? ` | ${code}` : ""}
      </Tag>
    ));
  }, [selectedLabels]);

  return (
    <div className={css.advancedLabelsSelectors}>
      <Heading className={css.advancedLabelsSelectors_title} type="h4">
        {t("advanced-search.package.forms.advanced-filters.title")}
      </Heading>
      <div className={css.advancedLabelsSelectors_control}>
        <Checkbox
          labelRight={t("advanced-search.package.forms.advanced-filters.checkbox-type-tag.label")}
          onChange={e => handleShowCategorySelector(e?.target?.checked)}
        />
        {showCategorySelector && (
          <LabelCategorySelector scenario={scenario} onChange={handleSetCategoryId} />
        )}
      </div>

      <div className={css.advancedLabelsSelectors_control}>
        <Checkbox
          labelRight={t("advanced-search.package.forms.advanced-filters.checkbox-tag.label")}
          onChange={e => handleShowLabelsSelector(e?.target?.checked)}
        />
        {showLabelsSelector && (
          <SelectLabels
            key={`labels-${categoryId}`}
            className={css.labelsSelector}
            labelCategoryId={categoryId}
            contentType={contentType}
            onSelectLabel={({ value }) => handleSetSelectedLabels(value)}
          />
        )}
      </div>

      {!!labelList?.length && <Divider className={css.advancedLabelsSelectors_divider} />}

      {!!labelList?.length && <div className={css.advancedLabelsSelectors_labels}>{labelList}</div>}
    </div>
  );
};

export default AdvancedLabelsSelectors;
