import React from "react";
import { Button, Heading, Modal, Text } from "@foris/avocado-suite";
import { IGroup } from "@modules/vacancies/contextVacancies/ContextVacancies";
import { useTranslation } from "react-i18next";

interface GroupVisibilityModalProps {
  selectedGroup?: IGroup;
  isOpen?: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
}

const GroupVisibilityModal = ({
  selectedGroup,
  isOpen,
  onCancel,
  onConfirm,
}: GroupVisibilityModalProps) => {
  const { t } = useTranslation();

  const newVisibilityLabel = selectedGroup?.openGroup
    ? t("bundle.modals.visibility-modal.no-visible")
    : t("bundle.modals.visibility-modal.visible");

  return (
    <Modal
      header={
        <Heading type="h2">
          {t("bundle.modals.visibility-modal.title", {
            visibility: newVisibilityLabel,
          })}
        </Heading>
      }
      open={isOpen}
      onClose={onCancel}
      actions={
        <>
          <Button variant="secondary" onClick={onCancel}>
            {t("bundle.actions.cancel")}
          </Button>
          <Button onClick={onConfirm}>{t("bundle.actions.confirm")}</Button>
        </>
      }
    >
      <Text>
        {t("bundle.modals.visibility-modal.description", {
          groupLabel: selectedGroup?.label,
          visibility: newVisibilityLabel,
        })}
      </Text>
    </Modal>
  );
};

export default GroupVisibilityModal;
