import {
  AcademicUnit,
  Campus,
  GroupsManagerSearchFilterFieldsInput,
  Subject,
} from "@models/ISchema";
import { FiltersReducerType, TableFiltersReducerType } from "../context/types";

const addCrnFilter = (
  filters: FiltersReducerType,
  fields: GroupsManagerSearchFilterFieldsInput,
): GroupsManagerSearchFilterFieldsInput => {
  return {
    ...fields,
    clientCode: {
      eq: filters?.selectedCrn,
    },
  };
};

const addMainFilters = (
  filters: FiltersReducerType,
  fields: GroupsManagerSearchFilterFieldsInput,
): GroupsManagerSearchFilterFieldsInput => {
  const eq = (field: Campus | AcademicUnit | Subject): any => {
    if (!field || field?.id === "*") return undefined;
    return { eq: field?.id };
  };

  const courseTypesToFilterBy = Object.values(filters?.courseTypesById).map(eq);

  return {
    ...fields,
    campus: eq(filters?.campus),
    department: eq(filters?.department),
    school: eq(filters?.school),
    subject: eq(filters?.subject),
    courseTypes: courseTypesToFilterBy.length ? courseTypesToFilterBy : undefined,
  };
};

const addAdvancedFilters = (
  filters: FiltersReducerType,
  fields: GroupsManagerSearchFilterFieldsInput,
): GroupsManagerSearchFilterFieldsInput => {
  const updatedFields = { ...(fields ?? {}) };

  if (!filters) return updatedFields;

  if (filters?.state?.active !== filters?.state?.inactive) {
    updatedFields.isActive = { is: Boolean(filters?.state?.active) };
  }

  if (filters?.visibility?.visible !== filters?.visibility?.invisible) {
    updatedFields.visibleForEnrollment = { is: Boolean(filters?.visibility?.visible) };
  }

  if (filters?.availability?.available !== filters?.availability?.unavailable) {
    updatedFields.hasVacancies = { is: Boolean(filters?.availability?.available) };
  }

  if (filters?.enrollments?.with !== filters?.enrollments?.without) {
    updatedFields.hasEnrollments = { is: Boolean(filters?.enrollments?.with) };
  }

  if (filters?.owned) {
    updatedFields.isOwner = { is: true };
  }

  return updatedFields;
};

/**
 * Recursively cleans an object by removing properties with `undefined` or `null` values.
 *
 * @param obj - The object to be cleaned. Can be of any type.
 * @returns A new object or array with `undefined` and `null` values removed.
 *
 * @remarks
 * - If the input is an array, it will recursively clean each element and filter out `undefined` and `null` values.
 * - If the input is an object, it will recursively clean each property and remove properties with `undefined` or `null` values.
 * - If the input is neither an array nor an object, it will return the input as is.
 */
const cleanObject = (obj: any) => {
  if (Array.isArray(obj)) {
    return obj.map(cleanObject).filter(item => item !== undefined && item !== null);
  } else if (obj !== null && typeof obj === "object") {
    return Object.entries(obj)
      .map(([k, v]) => [k, cleanObject(v)])
      .reduce((a, [k, v]) => (v === undefined || v === null ? a : { ...a, [k]: v }), {});
  }
  return obj;
};

export const getReportVariables = (
  filters: FiltersReducerType,
  orderBy: TableFiltersReducerType["orderBy"],
  searchBy: TableFiltersReducerType["searchBy"],
) => {
  let fields = {};

  if (filters?.selectedCrn) {
    fields = addCrnFilter(filters, fields);
  } else {
    fields = addMainFilters(filters, fields);
    fields = addAdvancedFilters(filters, fields);
  }

  const variables = cleanObject({
    fields: {
      ...fields,
      isGroupedByClientCode: { is: true },
      sumCapacities: { is: !filters?.selectedPackage },
      package: filters?.selectedPackage ? { eq: filters?.selectedPackage } : undefined,
    },
    orderBy: orderBy?.field ? { ...orderBy, header: undefined } : undefined,
    searchBy: searchBy?.text ? searchBy : undefined,
  });

  return variables;
};
