import React, { useEffect, useState, useCallback, FC } from "react";
import { useParams } from "react-router-dom";
import { Select } from "@foris/avocado-suite";
import { useApolloClient } from "react-apollo";
import { SECTION_OPTIONS } from "../../graphql/sections.queries";
import { IOption } from "@common/components/Select/Select";
import { CubeQuery } from "@models/ISchema";
import { PathParams } from "@edhtypes/general";

export interface SelectSectionProps {
  className?: string;
  courseComponentId: string;
  courseId: number;
  onChange: (value: IOption) => void;
  placeholder?: string;
}
const SelectSection: FC<SelectSectionProps> = ({
  courseId,
  courseComponentId,
  className,
  placeholder,
  ...props
}) => {
  // State for section options
  const [sectionOptions, setSectionOptions] = useState(null);
  // Hooks for Apollo Client and URL params
  const client = useApolloClient();
  const { origin, scenario }: PathParams = useParams();

  const getSectionOptions = useCallback(async () => {
    try {
      const variables = {
        originId: origin,
        scenarioId: scenario,
        filter: {
          fields: {
            and: [
              {
                courseId: {
                  eq: courseId,
                },
              },
              {
                courseComponentId: {
                  eq: courseComponentId,
                },
              },
            ],
          },
        },
      };
      const { data } = await client.query({ query: SECTION_OPTIONS, variables });
      const sections: CubeQuery = data.cube;
      const options: IOption[] =
        sections &&
        sections.sectionsSearch &&
        sections.sectionsSearch.items &&
        sections.sectionsSearch.items.map(item => {
          const sectionItem: IOption = {
            label: `${item.code} - ${item.course.name}`,
            value: item.id,
          };
          return sectionItem;
        });
      setSectionOptions(options);
    } catch (e) {
      console.error(e);
    }
  }, [client, courseComponentId, courseId, origin, scenario]);

  useEffect(() => {
    if (sectionOptions === null) getSectionOptions();
  }, [getSectionOptions, sectionOptions]);

  if (sectionOptions === null) return null;
  return (
    <Select
      className={className}
      placeholder={placeholder || "Buscar sección"}
      options={sectionOptions}
      isClearable
      {...props}
    />
  );
};

export default SelectSection;
