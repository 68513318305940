import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useApolloClient } from "react-apollo";
import { Loading } from "@foris/avocado-suite";
import Layout from "../../../../common/layout/Layout";
import { EmptyResults, Header } from "../../../../common/components";
import SectionsContainer from "../../../sections/Sections";
import { ISectionData } from "../../../sections/ISections";
import { functionsData } from "../../../sections/utils/";
import { CubeQuery, InstructorInfo, EditorInfoUnion, Tag } from "../../../../models/ISchema";
import { IParams } from "../../../../models/IParams";
import { instructorSection } from "../graphql/instructorSection.query";
import useGetDimension from "@dimensions/hooks/useGetDimension";
import { Dimension } from "@models/IDimension";

const InstructorCalendar: React.FC = () => {
  const client = useApolloClient();
  const { id, origin, scenario, workspace }: IParams = useParams();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const [calendarData, setCalendar] = useState(null);
  const [ogHeaderData, setOgHeaderData] = useState(null);
  const [customFields, setCustomFields] = useState<Tag[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [dimension] = useGetDimension(Dimension.instructor);

  const variables = {
    query: instructorSection,
    variables: {
      resourceId: id,
      scenarioId: scenario,
      originId: origin,
      resourceType: "INSTRUCTOR",
    },
  };

  const headerInfo = (info: InstructorInfo, customFields?: Tag[]) => {
    const details = [];
    const uniqueRow = [];
    let itemsDetail = [
      {
        title: t("dimension.instructor.header.more-details.contract"),
        content: info.instructor.contractType ? info.instructor.contractType.name : "-",
      },
      {
        title: t("dimension.instructor.header.more-details.priority"),
        content: info.instructor.priority ? info.instructor.priority : "-",
      },
      {
        title: t("dimension.instructor.header.more-details.blocks"),
        content: info.assignedBlocks ? `${info.assignedBlocks} ` : "-",
      },
      {
        title: t("dimension.instructor.header.more-details.sections"),
        content: info.assignedSections ? `${info.assignedSections} ` : "-",
      },
      {
        title: t("dimension.instructor.header.more-details.subjects"),
        content: info.assignedCourses ? `${info.assignedCourses}` : "-",
      },
      {
        title: t("dimension.instructor.header.more-details.blocks-assigned-current-year"),
        content: info.assignedCourses ? `${info.instructor.assignment.currentYearBlocks} ` : "-",
      },
      {
        title: t("dimension.instructor.header.more-details.blocks-assigned-next-year"),
        content: info.assignedCourses ? `${info.instructor.assignment.nextYearBlocks} ` : "-",
      },
      {
        title: t("dimension.instructor.header.more-details.total-blocks-allocated"),
        content: info.assignedCourses ? `${info.instructor.assignment.totalAssignedBlocks} ` : "-",
      },
    ];

    // Only for UEuropea
    const totalAssignedBlocksWithBTR = info.instructor.assignment.totalAssignedBlocksWithBTR;
    const totalRemainingBlocksCurrentYear =
      info.instructor.assignment.totalRemainingBlocksCurrentYear;
    const totalRemainingBlocksNextYear = info.instructor.assignment.totalRemainingBlocksNextYear;

    if (totalAssignedBlocksWithBTR !== null) {
      itemsDetail = [
        ...itemsDetail,
        {
          title: t("dimension.instructor.header.more-details.total-blocks-allocated-with-btr"),
          content: info.assignedCourses ? `${totalAssignedBlocksWithBTR} ` : "-",
        },
        {
          title: t("dimension.instructor.header.more-details.remaining-blocks-current-year"),
          content: info.assignedCourses ? `${totalRemainingBlocksCurrentYear} ` : "-",
        },
        {
          title: t("dimension.instructor.header.more-details.remaining-blocks-next-year"),
          content: info.assignedCourses ? `${totalRemainingBlocksNextYear} ` : "-",
        },
      ];
    }

    details.push(itemsDetail);
    if (customFields) {
      customFields.forEach(item => {
        const value = info.instructor.customFields.find(
          customData => customData.code === item.code,
        );
        if (value) {
          uniqueRow.push({
            title: item?.label || "-",
            content: value?.value?.replace(",", ", ") || "-",
          });
        }
      });
    }
    details.push(uniqueRow);
    const avatar = info.instructor.name
      ? info.instructor.name
          .split(" ")
          .map((n, i, a) => (i === 0 || i + 1 === a.length - 1 ? n[0] : null))
          .join("")
      : "";
    return {
      title: info.instructor?.name || "",
      topTitle: info.instructor?.code || "",
      moreDetails: details,
      iconTitle: avatar,
    };
  };

  const headerData = useMemo(() => {
    if (ogHeaderData) {
      return headerInfo(ogHeaderData, customFields);
    }
    return {};
  }, [ogHeaderData, customFields, language]);

  const getDataSections = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await client.query(variables);
      if (data) {
        const customFields: Tag[] = data?.base?.customFields;
        const cubeQuery: CubeQuery = data?.cube;
        const info: EditorInfoUnion = cubeQuery?.editorView?.info;
        if (info && info.__typename === "InstructorInfo") {
          setOgHeaderData(info);
          setCustomFields(customFields);
          const sections: ISectionData = functionsData.serializeSections(data);
          setCalendar(sections);
        } else {
          setError(true);
        }
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setError(true);
      setLoading(false);
    }
  }, [client, variables]);

  const setDataCalendar = (data: ISectionData, callback: () => void) => {
    setCalendar(data);
    callback && callback();
  };

  useEffect(() => {
    if (calendarData === null && error === false) getDataSections();
  }, [calendarData, error, getDataSections]);

  return (
    <Layout contextSearch>
      {headerData && (
        <Header
          dimension={dimension.label}
          breadcrumbItems={[
            {
              path: "/",
              title: t("dimension.instructor.breadcrumb.previous"),
            },
            {
              title: t("dimension.instructor.breadcrumb.current"),
            },
          ]}
          {...headerData}
        />
      )}
      {loading && <Loading />}
      {error && <EmptyResults dimension={"dimension.instructor.breadcrumb.title"} />}
      {calendarData && (
        <SectionsContainer
          config={{
            scenarioId: parseInt(scenario),
            originId: parseInt(origin),
            workspaceId: parseInt(workspace),
          }}
          data={calendarData}
          setDataGrid={setDataCalendar}
        />
      )}
    </Layout>
  );
};

export default InstructorCalendar;
