import React, { useContext, useState, useCallback, useRef, useEffect, useMemo } from "react";
import AddGroup from "../Groups/AddGroup";
import {
  IGroupValue,
  IContextVacancies,
  ContextVacancies,
  ILeague,
} from "../contextVacancies/ContextVacancies";
import { VacanciesType } from "../Vacancies";
import { ContextApp, IContextApp } from "@config/Context/contextApp";
import GroupMigration from "../GroupMigration";
import LinkCard from "./components/link-card";

export interface ICardVacanciesProps {
  data: Array<ILeague>;
  activeEdit: boolean;
  setValidationArray?: any;
  type: VacanciesType;
  nameGroup?: string;
  bundleId: number | string;
}

const Links: React.FC<ICardVacanciesProps> = ({ data, ...props }: ICardVacanciesProps) => {
  const { activeEdit, setValidationArray, type, nameGroup, bundleId } = props;
  const contextApp: IContextApp = useContext(ContextApp);
  const context: IContextVacancies = useContext(ContextVacancies);
  const [responsibilityChange, setResponsibilityChange] = useState<Array<Array<IGroupValue>>>([]);
  const refContext = useRef(context);
  const [openGroup, setOpenGroup] = useState(false);
  const [groupToMigrate, setGroupToMigrate] = useState<string | number | null>(null);
  const [linkId, setLinkId] = useState(null);
  const [subTitle, setSubtitle] = useState(null);
  const userCanEdit =
    contextApp?.user?.permissions?.update && contextApp?.user?.abilities?.can_edit_assignment;

  const selectedLinkDetails = useMemo(() => {
    return [...(data ?? [])].find((item: ILeague) => item.linkId === linkId);
  }, [data, linkId]);

  const selectedGroupDetails = useMemo(() => {
    return selectedLinkDetails?.groups?.find(group => group.id === groupToMigrate);
  }, [selectedLinkDetails, groupToMigrate]);

  const setValuesChange = useCallback(
    (value: Array<IGroupValue>, index: number) => {
      const arrayData: Array<Array<IGroupValue>> = [];
      const countContext = refContext.current.changeValues
        ? refContext.current.changeValues.length
        : 0;
      if (value) {
        if (data.length > countContext) {
          arrayData.push(value);
          refContext.current.setChangeValues(arrayData);
        } else {
          const cloneContext = [...refContext.current.changeValues];
          cloneContext.splice(index, 1);
          cloneContext.splice(index, 0, value);
          refContext.current.setChangeValues(cloneContext);
        }
      }
    },
    [data, refContext],
  );

  const setValuesResponsability = (value: Array<IGroupValue>, index: number) => {
    const cloneResponsability = [...responsibilityChange];
    if (cloneResponsability.length > 0) {
      cloneResponsability.splice(index, 1);
      cloneResponsability.splice(index, 0, value);
    } else {
      cloneResponsability.push(value);
    }
    setResponsibilityChange(cloneResponsability);
  };

  const handleOpenGroup = (link: string) => {
    setOpenGroup(true);
    setLinkId(link);
  };

  useEffect(() => {
    context.setInstructorValues(responsibilityChange);
  }, [responsibilityChange]);

  const isModalVisible = openGroup || !!groupToMigrate;

  return (
    <>
      {!isModalVisible &&
        data &&
        data.map((item: ILeague, index: number) => {
          return (
            <LinkCard
              key={index}
              link={item}
              userCanEdit={userCanEdit}
              tableType={type}
              isEditing={activeEdit}
              setValidationArray={setValidationArray}
              onUpdateGroupValues={values => setValuesChange(values, index)}
              onUpdateInstructorValues={values => setValuesResponsability(values, index)}
              onCreateGroup={() => {
                handleOpenGroup(item.linkId);
                setSubtitle(item.code);
              }}
              onMigrateGroup={groupId => {
                setLinkId(item.linkId);
                setGroupToMigrate(groupId);
              }}
            />
          );
        })}
      {openGroup && (
        <AddGroup
          onClose={() => setOpenGroup(false)}
          linkId={linkId}
          bundleId={bundleId}
          headerData={{ subTitle: subTitle, title: nameGroup }}
        />
      )}

      {!!groupToMigrate && (
        <GroupMigration
          linkDetails={selectedLinkDetails}
          groupDetails={selectedGroupDetails}
          linkId={linkId}
          groupName={nameGroup}
          groupId={groupToMigrate}
          onCancel={() => setGroupToMigrate(null)}
        />
      )}
    </>
  );
};

export default Links;
