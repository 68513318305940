import { useEffect, useState } from "react";
import { InstructorLink } from "@models/ISchema";
import { IGroupValue } from "@modules/vacancies/contextVacancies/ContextVacancies";

interface useInstructorTableInputsStateProps {
  instructors: InstructorLink[];
  linkTotalBlocks: number;
  linkId: string;
  onChange?: (values: IGroupValue[]) => void;
}

/**
 * Custom hook to manage the state of instructor table inputs.
 *
 * @param props - Optional properties to initialize the state.
 * @param props.instructors - Array of instructors to initialize the state.
 * @param props.linkId - Unique identifier for the link.
 * @param props.linkTotalBlocks - Total number of blocks for the link.
 *
 * @returns An object containing:
 * - `countResponsibility`: Array of instructor group values.
 * - `updateResponsibilityValues`: Function to update responsibility values for an instructor.
 * - `getInstructorValues`: Function to get the values of a specific instructor.
 *
 * @example
 * ```typescript
 * const { countResponsibility, updateResponsibilityValues, getInstructorValues } = useInstructorTableInputsState({
 *   instructors: [...],
 *   linkId: 'link123',
 *   linkTotalBlocks: 100,
 * });
 *
 * // Update responsibility values
 * updateResponsibilityValues(50, 'link123', 'instructor456', 'responsibility');
 *
 * // Get instructor values
 * const instructorValues = getInstructorValues('instructor456');
 * ```
 */
const useInstructorTableInputsState = (props?: useInstructorTableInputsStateProps) => {
  const { instructors = [], linkId, linkTotalBlocks, onChange } = props;
  const [countResponsibility, setCountResponsibility] = useState<Array<IGroupValue>>(null);

  const setInitialState = () => {
    setCountResponsibility(
      instructors.map((instructor: InstructorLink) => {
        const blockPercent = (instructor.totalBlocks * 100) / linkTotalBlocks;
        const groupValue: IGroupValue = {
          linkId,
          instructor,
          blockPercent: blockPercent ? Math.round(blockPercent) : 0,
          teachingHoursPercentage: instructor.teachingHoursPercentage || 0,
          paymentPercentage: instructor.paymentPercentage || 0,
          isPrimary: instructor.isPrimary || false,
        };

        return groupValue;
      }),
    );
  };

  /**
   * Updates the responsibility values for an instructor based on the provided parameters.
   *
   * @param newValue - The new value to be set. It can be a number or a boolean.
   * @param linkId - The unique identifier for the link.
   * @param instructorId - The unique identifier for the instructor.
   * @param typeParam - The type of parameter to update. It can be "responsibility", "payment", or "isPrimary".
   *
   * @remarks
   * - If `typeParam` is "responsibility", both `teachingHoursPercentage` and `paymentPercentage` will be updated with `newValue`.
   * - If `typeParam` is "payment", only `paymentPercentage` will be updated with `newValue`.
   * - If `typeParam` is "isPrimary", `isPrimary` will be updated with `newValue`. Additionally, if `newValue` is `true`, all other instructors with the same `linkId` will have their `isPrimary` set to `false`.
   *
   * @example
   * ```typescript
   * updateResponsibilityValues(50, 'link123', 'instructor456', 'responsibility');
   * updateResponsibilityValues(30, 'link123', 'instructor456', 'payment');
   * updateResponsibilityValues(true, 'link123', 'instructor456', 'isPrimary');
   * ```
   */
  const updateResponsibilityValues = (
    newValue: number | boolean,
    linkId: string,
    instructorId: string,
    typeParam: "responsibility" | "payment" | "isPrimary",
  ) => {
    const updatedResponsibility = countResponsibility.map(instructor => {
      if (linkId === instructor.linkId && instructorId === instructor.instructor.id) {
        if (typeParam === "responsibility") {
          return {
            ...instructor,
            teachingHoursPercentage: (newValue ? Number(newValue) : newValue) as number,
            paymentPercentage: (newValue ? Number(newValue) : newValue) as number,
          };
        } else if (typeParam === "payment") {
          return {
            ...instructor,
            paymentPercentage: (newValue ? Number(newValue) : newValue) as number,
          };
        } else if (typeParam === "isPrimary") {
          return { ...instructor, isPrimary: newValue as boolean };
        }
      }

      if (
        typeParam === "isPrimary" &&
        newValue === true &&
        instructor.instructor.id !== instructorId &&
        linkId === instructor.linkId
      ) {
        return { ...instructor, isPrimary: false };
      }

      return instructor;
    });

    setCountResponsibility(updatedResponsibility);
    onChange?.(updatedResponsibility);
  };

  useEffect(() => {
    if (!countResponsibility) setInitialState();
  }, []);

  return { countResponsibility, updateResponsibilityValues };
};

export default useInstructorTableInputsState;
