export const colors = [
  { id: 1, color: "#f69b70" },
  { id: 2, color: "#afcea7" },
  { id: 3, color: "#a5d3d8" },
  { id: 4, color: "#f9cf85" },
  { id: 5, color: "#cca89f" },
  { id: 6, color: "#9db2b4" },
  { id: 7, color: "#cdd89d" },
  { id: 8, color: "#d9c69e" },
  { id: 9, color: "#b79e72" },
  { id: 10, color: "#9dd8b9" },
  { id: 11, color: "#81b1b6" },
  { id: 12, color: "#8ca792" },
  { id: 13, color: "#e0ac52" },
  { id: 14, color: "#a9bdcd" },
  { id: 15, color: "#f7b07d" },
  { id: 16, color: "#b4c8b9" },
  { id: 17, color: "#a49473" },
  { id: 18, color: "#628387" },
  { id: 19, color: "#dd8e53" },
  { id: 20, color: "#a27e76" },
  { id: 21, color: "#6f9e85" },
  { id: 22, color: "#768c9e" },
  { id: 23, color: "#d17b55" },
  { id: 24, color: "#83ab78" },
  { id: 25, color: "#d0c2a7" },
  { id: 26, color: "#a3ac7c" },
];

/**
 * An array of objects representing link colors.
 * Each object contains an id, a color (CSS variable), and a code name.
 *
 * @type {Array<{ id: number, color: string, codeName: string }>}
 *
 * @property {number} id - The unique identifier for the color.
 * @property {string} color - The CSS variable representing the color.
 * @property {string} codeName - The code name associated with the color.
 */
export const colorsLeague: Array<{ id: number; color: string; codeName: string }> = [
  { id: 0, color: "color_palette_2", codeName: "L-A" },
  { id: 1, color: "color_palette_1", codeName: "L-B" },
  { id: 2, color: "color_palette_3", codeName: "L-C" },
  { id: 3, color: "color_palette_4", codeName: "L-D" },
  { id: 4, color: "color_palette_5", codeName: "L-E" },
  { id: 5, color: "color_palette_6", codeName: "L-F" },
  { id: 6, color: "color_palette_7", codeName: "L-G" },
  { id: 7, color: "color_palette_8", codeName: "L-H" },
  { id: 8, color: "color_palette_9", codeName: "L-I" },
  { id: 9, color: "color_palette_10", codeName: "L-J" },
  { id: 10, color: "color_palette_11", codeName: "L-K" },
  { id: 11, color: "color_palette_12", codeName: "L-L" },
  { id: 12, color: "color_palette_13", codeName: "L-M" },
  { id: 13, color: "color_palette_14", codeName: "L-N" },
  { id: 14, color: "color_palette_15", codeName: "L-O" },
  { id: 15, color: "color_palette_16", codeName: "L-P" },
  { id: 16, color: "color_palette_17", codeName: "L-Q" },
  { id: 17, color: "color_palette_1", codeName: "L-R" },
  { id: 18, color: "color_palette_2", codeName: "L-S" },
  { id: 19, color: "color_palette_3", codeName: "L-T" },
  { id: 20, color: "color_palette_4", codeName: "L-U" },
  { id: 21, color: "color_palette_5", codeName: "L-V" },
  { id: 22, color: "color_palette_6", codeName: "L-W" },
  { id: 23, color: "color_palette_7", codeName: "L-X" },
  { id: 24, color: "color_palette_8", codeName: "L-Y" },
  { id: 25, color: "color_palette_9", codeName: "L-Z" },
];

export const getColorId = (color: number | null): number => {
  if (!color) return 1;

  return color > 17 ? 16 : color;
};
