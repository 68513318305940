import React, { useContext, useMemo } from "react";
import { Button, Heading, Text } from "@foris/avocado-suite";
import { Week } from "@modules/sections/ISections";
import { Types } from "../../context/formData.reducer";
import { AppContext } from "../../context/EditSessionsContext";
import styles from "./weeksForkCard.module.scss";
import { useTranslation } from "react-i18next";

interface WeeksForkCardProps {
  onWeeksForked?: () => void;
}

const WeeksForkCard = ({ onWeeksForked }: WeeksForkCardProps) => {
  const { state, dispatch } = useContext(AppContext);
  const { t } = useTranslation();

  const removedWeeksLabels = useMemo(() => {
    if ((state?.form?.selectedSessions ?? []).length === 0) return "";

    const removedWeekIdsBySessionId = {};

    Object.keys(state?.form?.unmarkedWeekIdsBySessionId ?? {}).forEach(sessionId => {
      const unmarkedWeeksIdArr = Object.keys(state?.form?.unmarkedWeekIdsBySessionId[sessionId]);
      removedWeekIdsBySessionId[sessionId] = unmarkedWeeksIdArr;
    });

    const weeksLabelById = (state?.form?.editedSessions?.intervals ?? []).reduce(
      (acc, week: Week) => {
        acc[week.id] = week.name;
        return acc;
      },
      {},
    );

    const firstSelectedSessionId = state?.form?.selectedSessions[0]?.id;
    const removedWeekIds = removedWeekIdsBySessionId[firstSelectedSessionId] || [];
    const removedWeekLabels = removedWeekIds.map(id => weeksLabelById[id]).join(", ");

    return removedWeekLabels;
  }, [
    state?.form?.selectedSessions,
    state?.form?.unmarkedWeekIdsBySessionId,
    state?.form?.editedSessions,
  ]);

  return (
    <div className={styles.weeksForkCard}>
      <Heading className={styles.weeksForkCard_title} type="h4">
        {t("assignation-edit.fork-card.title")}
      </Heading>

      <Text className={styles.weeksForkCard_description}>
        {t("assignation-edit.fork-card.description", {
          weeks: removedWeeksLabels,
        })}
      </Text>

      <Button
        className={styles.weeksForkCard_actionBtn}
        onClick={() => {
          dispatch({ type: Types.ForkSelectedSessions });
          onWeeksForked?.();
        }}
      >
        {t("assignation-edit.fork-card.action-btn")}
      </Button>
    </div>
  );
};

export default WeeksForkCard;
