import gql from "graphql-tag";

export const BUNDLES_QUERY_ID = gql`
  query bundlesId($scenarioId: ID!, $originId: ID!) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      bundles {
        items {
          id
          code
          label
          name
        }
      }
    }
  }
`;

export const BUNDLES_MUTATION = gql`
  mutation Editar($scenarioId: ID!, $originId: ID!, $bundleInput: EditBundleInput) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      editBundle(input: $bundleInput) {
        commited
        validationErrors {
          ... on InvalidLinkChanges {
            link {
              id
              code
            }
            sectionChanges {
              section {
                id
                courseComponent {
                  id
                  academicStandard
                }
              }
              validationErrors {
                ... on InvalidComponentSize {
                  reason
                }
              }
            }
            groupChanges {
              group {
                id
                code
                label
                enrollmentStats {
                  id
                  enrollmentsCount
                }
              }
              validationErrors {
                ... on InvalidGroupEnrollment {
                  reason
                }
                ... on ExternalGroupValidation {
                  allowed
                  rejected_groups
                  reason
                  __typename
                }
              }
            }
            sessionChanges {
              session {
                id
                vacancies
                section {
                  id
                }
              }
              validationErrors {
                __typename
                ... on InvalidClassroomCapacity {
                  classroom {
                    id
                    capacity
                  }
                  reason
                }
              }
            }
          }
        }
      }
      __typename
    }
  }
`;
