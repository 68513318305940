import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-apollo";
import { useHistory } from "react-router-dom";
import { Button, Loading, Box, Divider } from "@foris/avocado-suite";
import { courseSearchMutation } from "../graphql/advanceSearch.queries";
import useNavigationUrl from "@common/hooks/useNavigationUrl";
import useContextUrl from "@common/hooks/useContextUrl";
import { objectEmpty } from "@utils/validations";
import { validations } from "@modules/advance-search/utils";
import AdvancedSelectorsWrapper from "@modules/advance-search/components/advanced-selectors-wrapper";
import { ComponentType } from "@modules/shared/selectors";
import EmptyResultsModal from "@modules/shared/empty-results-modal";
import { IComponentTypeId } from "@modules/advance-search/IAdvanceSearch";
import css from "./advanceSearch.module.scss";

const AdvanceSearch: React.FC<any> = () => {
  // State for handle validations
  const [isEmpty, setIsEmpty] = useState(false);
  const [objComponentType, setObjComponentType] = useState<IComponentTypeId>({});
  const [loading, setLoading] = useState(false);
  const [selectorsState, setSelectorsState] = useState({
    campus: null,
    department: null,
    modality: null,
    shift: null,
    program: null,
    curriculum: null,
    level: null,
  });
  // Hooks for handling navigation
  const { t } = useTranslation();
  const { advancedSearchResultsUrl } = useNavigationUrl();
  const { origin, scenario } = useContextUrl();
  const history = useHistory();
  const [advanceSearchMutation] = useMutation(courseSearchMutation, {});

  const hasSomeFilters = useMemo(() => {
    return Object.values(selectorsState).some(value => !!value) || !objectEmpty(objComponentType);
  }, [selectorsState, objComponentType]);

  const setComponentType = (value: string) => {
    const validation = validations.componentType(value, objComponentType);
    setObjComponentType(validation);
  };

  const createObjectMutation = () => {
    const filterArray = [];
    const programFilters = [];

    !objectEmpty(objComponentType) && filterArray.push(objComponentType);

    selectorsState.level && filterArray.push({ level: { eq: selectorsState.level } });
    (selectorsState.curriculum || selectorsState?.program) &&
      filterArray.push({
        curriculum: {
          code: selectorsState?.curriculum ? { eq: selectorsState.curriculum } : undefined,
          program: selectorsState?.program ? { code: { eq: selectorsState.program } } : undefined,
        },
      });
    selectorsState.campus && programFilters.push({ campusId: { eq: selectorsState.campus } });
    selectorsState.modality && programFilters.push({ modalityId: { eq: selectorsState.modality } });
    selectorsState.department &&
      programFilters.push({ departmentId: { eq: selectorsState.department } });
    selectorsState.shift && programFilters.push({ shiftId: { eq: selectorsState.shift } });

    if (programFilters.length > 0) {
      filterArray.push({
        program: {
          and: programFilters,
        },
      });
    }
    const mutationFilter = {
      originId: origin,
      scenarioId: scenario,
      filter: {
        fields: {
          and: [
            // Set default filter 'cause now we bring only the ones with assignment
            { hasEvents: true },
            ...filterArray,
          ],
        },
        pagination: {
          page: 1,
          size: 10,
        },
      },
    };
    return mutationFilter;
  };
  const callUpdateHook = async () => {
    setLoading(true);
    try {
      const variables = createObjectMutation();
      const { data } = await advanceSearchMutation({ variables });
      setLoading(false);
      const dataItems = data.filter.createCourseFilter;
      if (dataItems.courses.items.length > 0) {
        const idSearch = dataItems.id;
        history.push(advancedSearchResultsUrl("course", idSearch));
      } else {
        setIsEmpty(true);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <div className={css.ctnAdvanceSearch}>
      <EmptyResultsModal show={isEmpty} onClose={() => setIsEmpty(false)} />

      <Box className={css.ctnAdvanceSearch_boxContent}>
        <AdvancedSelectorsWrapper
          scenario={scenario}
          selectors={[
            "campus",
            "department",
            "modality",
            "shift",
            "program",
            "curriculum",
            "level",
          ]}
          onChangeState={setSelectorsState}
        />

        <Divider className={css.divider} />

        <ComponentType
          className={css.typeComponent}
          label={t("advanced-search.course.forms.select-component.label")}
          placeholder={t("advanced-search.course.forms.select-component.placeholder")}
          onCallback={value => setComponentType(value?.value)}
        />
      </Box>

      {loading ? (
        <Loading />
      ) : (
        <Button
          className={css.ctnAdvanceSearch_actionButton}
          onClick={() => callUpdateHook()}
          disabled={!hasSomeFilters}
        >
          {t("advanced-search.course.forms.btn-search")}
        </Button>
      )}
    </div>
  );
};

export default AdvanceSearch;
