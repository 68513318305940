import React, { useContext, useMemo } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { CardNotification, Heading, Text } from "@foris/avocado-suite";
import { Session } from "@models/ISchema";
import { AppContext } from "../../context/EditSessionsContext";
import { FormPageType, EditedSession } from "../../context/formData.reducer";
import styles from "./formHeader.module.scss";

const FormHeader: React.FC = () => {
  const { state } = useContext(AppContext);
  const { t } = useTranslation();

  /**
   * Return a title for the form considering the sessions that has been selected
   */
  const sessionTitle = (): string => {
    if (!state?.form?.selectedSessions?.length && !state?.form?.selectedCreateSession) {
      return t("assignation-edit.form.header.session-edit-general");
    }

    const selectedSessionToCreate = ({
      selectedSessions,
      sessionsToCreate,
    }: FormPageType): EditedSession[] => {
      const selectedSessionsById = selectedSessions.reduce((acc, session) => {
        acc[session?.id] = true;
        return acc;
      }, {} as Record<string, boolean>);
      return sessionsToCreate.filter(session => selectedSessionsById[session?.id]);
    };

    const { selectedSection, savedSessions, selectedSessions } = state?.form;
    const numberOfSelectedSessions = selectedSessions?.length ?? 0;
    const sessionsToCreateSelected = selectedSessionToCreate(state?.form);

    if (numberOfSelectedSessions > 1) {
      return t("assignation-edit.form.header.sessions-to-edit", {
        count: numberOfSelectedSessions,
      });
    } else if (sessionsToCreateSelected.length === 1) {
      const section = selectedSessions[0].section;
      return t("assignation-edit.form.header.new-session-to", {
        componentCode: section?.component?.code ?? "",
        sectionId: section?.id,
      });
    } else if (numberOfSelectedSessions === 1) {
      const session = selectedSessions[0];
      const findNewSession = savedSessions.find(value => value?.id === session?.id && value.isNew);
      return findNewSession
        ? t("assignation-edit.form.header.new-session-edition")
        : t("assignation-edit.form.header.session-edit", {
            sessionId: session?.id,
          });
    }

    return selectedSection
      ? t("assignation-edit.form.header.new-session-to", {
          componentCode: selectedSection?.component?.code ?? "",
          sectionId: selectedSection?.id,
        })
      : "";
  };

  /**
   * Return the number of blocks between selected, created and to-create sessions
   */
  const numberOfSelectedBlocks = useMemo(() => {
    const selectedSessions = state?.form?.selectedSessions ?? [];
    const savedSessions = state?.form?.savedSessions ?? [];
    const sessionsToCreate = state?.form?.sessionsToCreate ?? [];

    const isClonedSession = (session: Session | EditedSession) => session?.id?.includes("-");

    const numberOfBlocksFromSessions = (sessions: Session[]): number => {
      if (!sessions?.length) return 0;
      return sessions
        .filter(session => session?.blocksCount || session?.assignment?.intervals)
        .reduce(
          (acc, session) =>
            acc + (session?.blocksCount || 0) * (session?.assignment?.intervals?.length || 1),
          0,
        );
    };

    const numberOfBlocksFromEditedSessions = (sessions: EditedSession[]): number => {
      const lteTime = (time1: string, time2: string) => {
        const [hour1, min1] = time1.split(":").map(Number);
        const [hour2, min2] = time2.split(":").map(Number);
        return hour1 < hour2 || (hour1 === hour2 && min1 <= min2);
      };

      if (sessions.length) {
        const rawBlocks = state?.link?.rawBlocks;
        return sessions
          .filter(({ blocks }) => !!blocks?.startTime && !!blocks?.endTime)
          .reduce((acc, { blocks: { startTime, endTime }, intervals }) => {
            const numberOfBlocks =
              rawBlocks?.filter(
                ({ startingTime, endingTime }) =>
                  lteTime(startTime, startingTime) && lteTime(endingTime, endTime),
              ).length || 0;
            const numberOfIntervals =
              (intervals ?? []).filter(interval => interval.checked).length || 1;
            return acc + numberOfBlocks * numberOfIntervals;
          }, 0);
      }

      return 0;
    };

    return (
      numberOfBlocksFromSessions(
        selectedSessions.filter(session => !isClonedSession(session)) as Session[],
      ) +
      numberOfBlocksFromEditedSessions(sessionsToCreate) +
      numberOfBlocksFromEditedSessions(savedSessions.filter(session => session.isNew))
    );
  }, [
    state?.form?.selectedSessions,
    state?.form?.savedSessions,
    state?.form?.sessionsToCreate,
    state?.link?.rawBlocks,
  ]);

  /**
   * Return the number of blocks of the edited sessions
   */
  const modifiedBlocks = useMemo(() => {
    const editedSessions = state?.form?.savedSessions.filter(session => !session.isNew);
    if (!editedSessions) return 0;

    return editedSessions.reduce((blocks, { session }) => {
      if (session.blocksCount || session.assignment?.intervals) {
        const numberOfBlocks = session.blocksCount || 1;
        const numberOfIntervals = session.assignment?.intervals?.length || 1;
        return blocks + numberOfBlocks * numberOfIntervals;
      }
      return blocks;
    }, 0);
  }, [state?.form?.savedSessions]);

  const someSelectedSessionHasBeenDeleted = useMemo(() => {
    return (sessions: EditedSession[], deletedSessionsIds: { [key: string]: boolean }) =>
      (sessions ?? []).some(session => session?.id && deletedSessionsIds[session.id]);
  }, []);

  return (
    <>
      <header className={styles.formHeader}>
        <Heading className={styles.formHeader_title} type="h2">
          {sessionTitle()}
        </Heading>

        <section className={styles.formHeader_details}>
          <Text className={styles.detailsText}>
            {t("assignation-edit.form.header.selected-blocks")}: <b>{numberOfSelectedBlocks}</b>
          </Text>

          <span className={styles.divider}></span>

          <Text className={styles.detailsText}>
            {t("assignation-edit.form.header.modified-count")}: <b>{modifiedBlocks}</b>
          </Text>
        </section>
      </header>

      {someSelectedSessionHasBeenDeleted(
        state?.form?.selectedSessions,
        state?.form?.sessionsToDelete,
      ) && (
        <CardNotification
          className={cx(styles.formHeader_card)}
          state="info"
          title={t("assignation-edit.form.notifications.session-to-delete.title")}
          outlined
        >
          <Text type="sm">
            {t("assignation-edit.form.notifications.session-to-delete.description")}
          </Text>
        </CardNotification>
      )}
    </>
  );
};

export default FormHeader;
