import React from "react";
import { useTranslation } from "react-i18next";
import { Option } from "react-select/lib/filters";
import useGetSubjectOptions from "@modules/shared/selectors/hooks/useGetSubjectOptions";
import AdvanceSelector from "../advance-selector";

interface AdvancedSubjectSelectorProps {
  campusId?: string;
  curriculumId?: string;
  departmentId?: string;
  levelId?: string;
  modalityId?: string;
  onChange?: (option: Option) => void;
  programId?: string;
  scenario: string;
  shiftId?: string;
}

const AdvancedSubjectSelector = ({ onChange, ...filters }: AdvancedSubjectSelectorProps) => {
  const { loadOptions } = useGetSubjectOptions({
    ...filters,
  });

  const { t } = useTranslation();

  return (
    <AdvanceSelector
      isPaginated
      dimension={t("advanced-search.instructor.forms.select-wrapper.subject.label")}
      selectPlaceholder={t("advanced-search.instructor.forms.select-wrapper.subject.placeholder")}
      loadOptions={loadOptions}
      onChange={onChange}
      notShowLabel
    />
  );
};

export default AdvancedSubjectSelector;
