import { objectEmpty } from "@utils/validations";

// Handles dependency logic between building and campus
const buildingDependency = (values: any, objBuilding: any, objCampus: any) => {
  const cloneBuilding = { ...objBuilding };
  let newBuilding = JSON.parse(JSON.stringify(cloneBuilding));
  const cloneCampus = { ...objCampus };
  let newCampus = JSON.parse(JSON.stringify(cloneCampus));

  // If a campus is selected, update the structure
  if (values.campus) {
    newCampus = {
      ...newCampus,
      building: {
        campusId: {
          eq: values.campus,
        },
      },
    };
  } else {
    // If no campus is selected, remove the building property
    if (newCampus.hasOwnProperty("building")) {
      delete newCampus.building;
    }
  }

  // If a building is selected, update the structure
  if (values.building) {
    newBuilding = {
      ...newBuilding,
      buildingId: {
        eq: values.building,
      },
    };
  } else {
    // If no building is selected, remove the buildingId property
    if (newBuilding.hasOwnProperty("buildingId")) {
      delete newBuilding.buildingId;
    }
  }

  return { objBuilding: newBuilding, objCampus: newCampus, error: false };
};

// Handles logic for classroom type
const classRoomType = (values: any, objClassroomType: any) => {
  const clone = { ...objClassroomType };
  let newValues = JSON.parse(JSON.stringify(clone));

  // If a classroom type is selected, update the structure
  if (values.classroomType) {
    newValues = {
      ...newValues,
      classroomTypeId: {
        eq: values.classroomType,
      },
    };
  } else {
    // If no classroom type is selected, remove the classroomTypeId property
    if (newValues.hasOwnProperty("classroomTypeId")) {
      delete newValues.classroomTypeId;
    }
  }
  return { objClassroomType: newValues };
};

// Handles logic for classroom capacity
const capacity = (values: any, objCapacity: any) => {
  const clone = { ...objCapacity };
  let newFilter = JSON.parse(JSON.stringify(clone));
  const _capacity = { capacity: { allowedOverflow: null, value: {} } };

  // allowedOverflow property
  if (values.overflow) {
    _capacity.capacity.allowedOverflow = values.overflow;
  } else {
    delete _capacity.capacity.allowedOverflow;
  }

  // Define min and max capacity values
  const _capacityValues = { value: { gte: null, lte: null } };

  if (values.minCapacity && values.minCapacity !== "") {
    _capacityValues.value.gte = values.minCapacity;
  }
  if (values.maxCapacity && values.maxCapacity !== "") {
    _capacityValues.value.lte = values.maxCapacity;
  }

  // If no capacity values exist, remove the value property
  if (!_capacityValues.value.gte && !_capacityValues.value.lte) {
    delete _capacity.capacity.value;
  } else {
    _capacity.capacity = { ..._capacity.capacity, ..._capacityValues };
  }

  // If the object is empty, remove the capacity property
  if (objectEmpty(_capacity.capacity)) {
    delete newFilter.capacity;
  } else {
    newFilter = { ...newFilter, ..._capacity };
  }
  return { objCapacity: newFilter, error: false };
};

// Export validation functions
const validations = {
  classRoomType,
  buildingDependency,
  capacity,
};

export { validations };
