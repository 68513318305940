import { OrderByDirection, LinkLogEntryOrderByFieldsEnum } from "@models/ISchema";

export const columnKeys = {
  Fecha: LinkLogEntryOrderByFieldsEnum.Date,
  Liga: LinkLogEntryOrderByFieldsEnum.LinkCode,
  "Recurso ID": LinkLogEntryOrderByFieldsEnum.ObjectId,
  "Tipo de edición": LinkLogEntryOrderByFieldsEnum.ResourceChanges,
  Responsable: LinkLogEntryOrderByFieldsEnum.UserId,
};

export const linkChangesTableHeaderToOrderByObj = (header: string) => {
  if (header == null || !(header in columnKeys)) {
    return {
      header: "Fecha",
      field: columnKeys["Fecha"],
      direction: OrderByDirection.Desc,
    };
  }

  return {
    header,
    field: columnKeys?.[header],
    direction: OrderByDirection.Desc,
  };
};
