import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// en folder
import enCommonJSON from "../locale/en/common.json";
import enCommonAssignationEditJSON from "../locale/en/commonAssignationEdit.json";
import enCommonDaysJSON from "../locale/en/commonDays.json";
import enCommonHomeJSON from "../locale/en/commonHome.json";

// es folder
import esCommonJSON from "../locale/es/common.json";
import esCommonAssignationEditJSON from "../locale/es/commonAssignationEdit.json";
import esCommonDaysJSON from "../locale/es/commonDays.json";
import esCommonHomeJSON from "../locale/es/commonHome.json";

const namespaces = ["common"];

i18n.use(initReactI18next).init({
  resources: {
    en: {
      common: {
        ...enCommonJSON,
        ...enCommonAssignationEditJSON,
        ...enCommonDaysJSON,
        ...enCommonHomeJSON,
      },
    },
    es: {
      common: {
        ...esCommonJSON,
        ...esCommonAssignationEditJSON,
        ...esCommonDaysJSON,
        ...esCommonHomeJSON,
      },
    },
  },
  lng: window?.localStorage?.getItem("locale") ?? "es",
  ns: namespaces,
  defaultNS: "common",
});

export default i18n;
