import React, { useEffect, useMemo } from "react";
import { Dimension } from "@models/IDimension";
import { IParams } from "@models/IParams";
import { useParams } from "react-router-dom";
import { Icon } from "@foris/avocado-icons";
import {
  Heading,
  Loading,
  Table,
  TableColumn,
  CardNotification,
  RoundButton,
  Button,
} from "@foris/avocado-suite";
import { Header } from "@common/components";
import { LinkSelector } from "@modules/shared/selectors/Selectors";
import { Link } from "@models/ISchema";
import { getColumnCell, getLinkCell } from "@dimensions/utils/tableUtils";
import Layout from "@common/layout/Layout";
import useGetDimension from "@dimensions/hooks/useGetDimension";
import useGetPackageSection from "../hooks/useGetPackageSection";
import PackageHeaderSubtitle from "../components/PackageHeaderSubtitle";
import useAssignLinks, { ErrorMessagesByType } from "../hooks/useAssignLinks";
import useLinksState from "../hooks/useLinksState";
import LinkAssignmentErrors, { isErrorMessage } from "../components/LinkAssignmentErrors";
import { useTranslation } from "react-i18next";
import i18n from "@config/i18n";
import cx from "classnames";
import css from "./packageLinkManagement.module.scss";

const getLeftActionColumn = (
  id: string,
  linksToAdd = {},
  linksToDelete = {},
  errorMessagesByType: ErrorMessagesByType[] = [],
) => {
  const hasError = errorMessagesByType.some(err => Object.keys(err.links).includes(id));

  if (hasError) {
    return (
      <Icon
        className={cx(css.icon, css.alert_icon)}
        name="alert-triangle"
        size="sm"
        color="neutral-70"
      />
    );
  }

  if (id in linksToDelete) {
    return (
      <Icon className={cx(css.icon, css.trashIcon)} name="trash" size="sm" color="neutral-70" />
    );
  }

  if (id in linksToAdd) {
    return (
      <Icon
        className={cx(css.icon, css.addIcon)}
        filled
        name="circle-plus"
        size="sm"
        color="neutral-70"
      />
    );
  }

  return "";
};

const getTableColumns = (
  workspace = "",
  scenario = "",
  origin = "",
  linksToAdd = {},
  linksToDelete = {},
) => {
  return [
    {
      header: i18n.t("package-link-management.table.header.link"),
      renderCell: ({ code, id }: Link) => (
        <div className={css.cellWithIcon}>
          {getLeftActionColumn(id, linksToAdd, linksToDelete)}
          {getLinkCell(`/scheduler/editor/link/${workspace}/${scenario}/${origin}`, id, code)}
        </div>
      ),
      desktopOptions: { headCellStyles: { minWidth: "80px", width: "176px" } },
    },
    {
      header: i18n.t("package-link-management.table.header.campus"),
      renderCell: ({ course }: Link) => getColumnCell(course?.curriculum?.program?.campus?.code),
      desktopOptions: { headCellStyles: { minWidth: "80px", width: "100px" } },
    },
    {
      header: i18n.t("package-link-management.table.header.term"),
      renderCell: ({ bundle }: Link) => getColumnCell(bundle?.term?.code),
      desktopOptions: { headCellStyles: { minWidth: "80px", width: "120px" } },
    },
    {
      header: i18n.t("package-link-management.table.header.groups"),
      renderCell: ({ groups }: Link) => getColumnCell(groups?.map(({ code }) => code).join(", ")),
      desktopOptions: { headCellStyles: { minWidth: "80px", width: "200px" } },
    },
    {
      header: i18n.t("package-link-management.table.header.course"),
      renderCell: ({ course }: Link) => getColumnCell(course?.name),
      desktopOptions: { headCellStyles: { minWidth: "80px" } },
    },
    {
      header: i18n.t("package-link-management.table.header.vacancies"),
      accesor: "vacancies",
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "124px" },
        headCellClassName: css.vacanciesHead,
        bodyCellClassName: css.vacanciesBody,
      },
    },
  ] as TableColumn[];
};

const PackageLinkManagement = () => {
  const { id, origin, scenario, workspace }: IParams = useParams();
  const [dimension] = useGetDimension(Dimension.package);
  const {
    linksToAdd,
    linksToDelete,
    assignmentIds,
    deletionsIds,
    assignmentsWithMultipleGroups,
    handleResetChanges,
    setLinksToAdd,
    setLinksToDelete,
  } = useLinksState({
    packageId: id,
  });

  const { t } = useTranslation();

  const { isLoading, headerData, packageData, loadPackageData } = useGetPackageSection({
    resourceId: id,
    scenarioId: scenario,
    originId: origin,
    workspaceId: workspace,
    fetchWithlinks: true,
  });

  const { handleLinkAssignment, isLoading: isLoadingChanges, errorMessagesByType } = useAssignLinks(
    {
      scenarioId: scenario,
      originId: origin,
      assignments: assignmentIds,
      deletions: deletionsIds,
      onSavedChanges: () => {
        loadPackageData();
        handleResetChanges();
      },
    },
  );

  const isEditionEnabled = !packageData?.isPublished;

  const hasErrors = useMemo(() => {
    return errorMessagesByType.some(err => isErrorMessage(err.type, err.userCanSkipValidations));
  }, [errorMessagesByType]);

  useEffect(() => {
    loadPackageData();
  }, []);

  const linksToAvoidInSelector = useMemo(() => {
    return packageData?.links?.map(link => link.id) || [];
  }, [packageData?.links]);

  const packagesLinks = useMemo(() => {
    return [...(packageData?.links || []), ...Object.values(linksToAdd)];
  }, [packageData?.links, linksToDelete, linksToAdd]);

  if (isLoading) {
    return <Loading />;
  }

  const handleDeleteLink = (link: Link) => {
    if (link?.id in linksToDelete) {
      const newLinksToDelete = structuredClone(linksToDelete);
      delete newLinksToDelete[link.id];

      setLinksToDelete(newLinksToDelete);
      return;
    }

    if (link?.id in linksToAdd) {
      const newLinksToAdd = structuredClone(linksToAdd);
      delete newLinksToAdd[link.id];

      setLinksToAdd(newLinksToAdd);
      return;
    }

    setLinksToDelete({ ...linksToDelete, [link.id]: link });
  };

  const handleSelectLink = (link: Link) => {
    if (!link?.id || !!linksToAdd?.[link.id]) {
      return;
    }

    handleLinkAssignment(true, true, [
      ...assignmentIds,
      {
        linkId: link.id,
        packageId: id,
      },
    ]);
    setLinksToAdd({ ...linksToAdd, [link.id]: link });
  };

  return (
    <Layout contextSearch>
      {isLoadingChanges && <Loading />}

      <Header
        dimension={dimension.label}
        {...(headerData ?? {})}
        subTitle={<PackageHeaderSubtitle packageData={packageData} />}
      />

      <main className={css.packageLinkManagement}>
        <Heading className={css.packageLinkManagement_title} type="h2">
          {t("package-link-management.title")}
        </Heading>

        {isEditionEnabled && (
          <LinkSelector
            className={css.packageLinkManagement_linkSelector}
            placeholder={t("package-link-management.selector-placeholder")}
            label=""
            onSelectLink={handleSelectLink}
            linksToAvoid={linksToAvoidInSelector}
            keepSelection={false}
            selectedExternalOptions={linksToAdd}
            splitByGroups
            campusId={packageData?.population?.campus?.id}
          />
        )}

        {assignmentsWithMultipleGroups > 0 && (
          <CardNotification
            className={css.infoCard}
            state="info"
            title={t("package-link-management.info-title", {
              letter: assignmentsWithMultipleGroups > 1 ? "s" : "",
            })}
            outlined
          >
            {assignmentsWithMultipleGroups > 1
              ? t("package-link-management.plural-info-message")
              : t("package-link-management.singular-info-message")}
          </CardNotification>
        )}

        <Table
          className={css.packageLinkManagement_table}
          columns={getTableColumns(workspace, scenario, origin, linksToAdd, linksToDelete)}
          data={packagesLinks}
          rightActions={
            isEditionEnabled
              ? [
                  {
                    renderContent: link => (
                      <RoundButton
                        variant="ghost"
                        size="sm"
                        disabled={packageData?.isPublished}
                        icon="circle-minus"
                        onClick={() => handleDeleteLink(link as Link)}
                      />
                    ),
                  },
                ]
              : undefined
          }
        />

        <LinkAssignmentErrors
          origin={origin}
          scenario={scenario}
          workspace={workspace}
          errorMessagesByType={errorMessagesByType}
        />

        <div className={css.packageLinkManagement_actions}>
          <Button disabled={isLoadingChanges} variant="ghost" onClick={handleResetChanges}>
            {isEditionEnabled
              ? t("package-link-management.btn-cancel")
              : t("package-link-management.btn-back")}
          </Button>

          {isEditionEnabled && (
            <Button
              disabled={
                isLoadingChanges || (!assignmentIds?.length && !deletionsIds?.length) || hasErrors
              }
              onClick={() => handleLinkAssignment(false, false)}
            >
              {t("package-link-management.btn-save")}
            </Button>
          )}
        </div>
      </main>
    </Layout>
  );
};

export default PackageLinkManagement;
