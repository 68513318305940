import React, { useContext, useState, useEffect, useMemo } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { Snackbar } from "@foris/avocado-ui";
import { Text, Button } from "@foris/avocado-suite";
import { AppContext } from "../../context/EditSessionsContext";
import { Types, FormActions } from "../../context/formData.reducer";
import { buildAssignmentSame } from "../../utils/compareSelected";
import { ContextApp } from "@config/Context/contextApp";
import ClassroomSection from "./classroom-section";
import Repeat from "../../components/Repeat/Repeat";
import BlockRange from "../../components/BlockRange/BlockRange";
import FormHeader from "../../components/FormEdit/FormHeader";
import InstructorSection from "./instructor-section";
import DaysSection from "../../components/days-section";
import AbilitiesMessage from "./AbilitiesMessage";
import Modal from "@modules/sections/GroupsManager/components/Modal/Modal";
import css from "./formEdit.module.scss";

interface CreateSessionProps {
  warning: boolean;
  setWarning: (warning: boolean) => void;
}

const CreateSession: React.FC<CreateSessionProps> = props => {
  const { user } = useContext(ContextApp);
  const { warning, setWarning } = props;
  const { state, dispatch } = useContext(AppContext);
  const [variables, setVariables] = useState<FormActions>(null);
  const [activeSanckbar, setActiveSanckbar] = useState(false);
  const { t } = useTranslation();

  const abilities = user?.abilities;
  const editedSessions = state?.form?.editedSessions;
  let edited = false;

  useEffect(() => {
    if (state?.form?.savedSessions?.length > 0) {
      const findSaved = state?.form?.savedSessions
        ?.filter(value => value.isNew === true)
        .find(value => value?.id === editedSessions?.id);
      if (findSaved) {
        edited = Object.values(buildAssignmentSame(findSaved, editedSessions)).every(
          val => val === true,
        );
      }
    } else {
      edited =
        !!editedSessions?.intervals?.some(interval => interval.checked) ||
        !!editedSessions?.blocks?.selected ||
        !!editedSessions?.classrooms?.length ||
        !!editedSessions?.instructors?.length;
    }
  }, [state?.form?.savedSessions, editedSessions]);

  const creationButtonMessage = (num: number) => {
    if (!num) return t("assignation-edit.form.creation-section.create");
    return num === 1
      ? t("assignation-edit.form.creation-section.create-session")
      : t("assignation-edit.form.creation-section.create-n-sessions", {
          count: num,
        });
  };

  const scrollToTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  const uncreatableSessions = useMemo((): boolean => {
    const selection = state?.form?.editedSessions?.blocks?.selected;
    const blocks = state?.form?.editedSessions?.blocks;

    const blocksNotConfigured =
      !selection ||
      (selection === "blocks" && !blocks?.blocks) ||
      (selection === "hours" && !blocks?.days?.length) ||
      (selection === "hours" && blocks?.endTime <= blocks?.startTime);

    const weeksNotDefined = !(
      state?.form?.editedSessions?.intervals?.filter(interval => interval.checked).length > 0
    );

    return blocksNotConfigured || weeksNotDefined;
  }, [state?.form?.editedSessions?.blocks, state?.form?.editedSessions?.intervals]);

  const cancelEditForm = () => {
    if (edited) {
      setWarning(true);
      setVariables({ type: Types.CleanFormCreateSession });
    } else {
      dispatch({ type: Types.CleanFormCreateSession });
    }
  };

  useEffect(() => {
    if (activeSanckbar) {
      if (state?.form?.editedSessions?.blocks?.days?.length) {
        dispatch({ type: Types.AddSessionsToCreateBySelectedDays });
      } else {
        dispatch({ type: Types.AddSessionsToCreateWithNoDays });
      }
      setTimeout(() => setActiveSanckbar(false), 500);
    }
  }, [activeSanckbar]);

  return (
    <>
      <Snackbar
        type="confirm"
        setValueActive={(value: any) => setActiveSanckbar(value)}
        active={activeSanckbar}
        icon="circle-check"
        duration={3}
      >
        {t("assignation-edit.form.notifications.session-saved")}
      </Snackbar>

      <Modal
        typeState="warning"
        title={t("assignation-edit.form.notifications.cancel-creation.title")}
        show={warning}
        textButtonPrincipal={t("assignation-edit.form.notifications.cancel-creation.confirm")}
        onClose={() => setWarning(false)}
        onClickPrincipal={() => {
          setWarning(false);
          if (variables) {
            dispatch(variables);
          } else {
            dispatch({ type: Types.NotSelectedCreateSession, payload: false });
            dispatch({ type: Types.CleanFormCreateSession });
          }
        }}
        textButtonSecondary={t("assignation-edit.form.notifications.cancel-creation.cancel")}
        onClickSecondary={() => setWarning(false)}
      >
        <Text>{t("assignation-edit.form.notifications.cancel-creation.description")}</Text>
      </Modal>

      <section className={cx(css.formEdit)}>
        <div className={css.formEdit_content}>
          <section className={css.fields}>
            <section className={css.fields_content}>
              <FormHeader />
              <AbilitiesMessage className={css.infoMessage} />
              <BlockRange disabled={!abilities?.can_assign_blocks} />
              <DaysSection
                disabled={!abilities?.can_assign_blocks}
                infoMessage={t("assignation-edit.form.days.creation-info")}
              />
              <InstructorSection disabled={!abilities?.can_assign_instructors} />
              <ClassroomSection disabled={!abilities?.can_assign_classrooms} />
              <Repeat disabled={!abilities?.can_assign_intervals} originalWeeksBySessionId={{}} />
            </section>
          </section>
        </div>
        <footer className={cx(css.footer, "container-row")}>
          <Button variant="ghost" className={cx(css.footer_btn)} onClick={() => cancelEditForm()}>
            {t("assignation-edit.form.creation-section.cancel")}
          </Button>
          <Button
            onClick={() => {
              scrollToTop();
              setActiveSanckbar(true);
            }}
            disabled={uncreatableSessions}
            className={cx(css.footer_btn)}
          >
            {creationButtonMessage(editedSessions?.blocks?.days?.length)}
          </Button>
        </footer>
      </section>
    </>
  );
};

export default CreateSession;
