import React, { useMemo } from "react";
import { Link, RadioButton, Table, Text, TextField } from "@foris/avocado-suite";
import useContextUrl from "@common/hooks/useContextUrl";
import { InstructorLink } from "@models/ISchema";
import { getTotalBlocksPercent } from "@modules/vacancies/utils";
import styles from "./instructorsTable.module.scss";
import useInstructorTableState from "../../hooks/useInstructorTableState";
import useInstructorTableInputsState from "../../hooks/useInstructorTableInputsState";
import { IGroupValue } from "@modules/vacancies/contextVacancies/ContextVacancies";
import { useTranslation } from "react-i18next";

interface InstructorsTableProps {
  instructors: InstructorLink[];
  linkTotalBlocks?: number;
  isEditing?: boolean;
  linkId: string;
  onUpdateValues?: (values: IGroupValue[]) => void;
}

const isUnAssigment = (instructorId: string) => instructorId === "unassignment";

const InstructorsTable = ({
  instructors = [],
  linkTotalBlocks = 0,
  isEditing = false,
  linkId,
  onUpdateValues,
}: InstructorsTableProps) => {
  const { workspace, origin, scenario } = useContextUrl();
  const { t } = useTranslation();

  const { countResponsibility, updateResponsibilityValues } = useInstructorTableInputsState({
    instructors,
    linkTotalBlocks,
    linkId,
    onChange: onUpdateValues,
  });

  const {
    orderInstructor,
    totalInstructorBlocks,
    totalInstructorBlocksPercent,
    totalResponsability,
    totalPayment,
    errors,
  } = useInstructorTableState({
    instructors,
    countResponsibility,
    linkTotalBlocks,
    linkId,
  });

  const countResponsibilityById = useMemo(() => {
    return countResponsibility?.reduce((acc, item) => {
      acc[item.instructor.id] = item;
      return acc;
    }, {});
  }, [countResponsibility]);

  return (
    <Table
      className={styles.instructorsTable}
      data={[
        ...orderInstructor,
        {
          isFooter: true,
        },
      ]}
      columns={[
        {
          key: "instructor",
          header: t("bundle.link-card.instructor-table.header.instructor"),
          renderCell: (data: any) => {
            if (data?.isFooter)
              return <Text weight="medium">{t("bundle.link-card.table-footer.total")}</Text>;

            const instructor = data as InstructorLink;
            const isUnassigment = isUnAssigment(instructor?.instructor?.id);

            return (
              <div className={styles.instructorsTable_instructor}>
                <RadioButton
                  checked={!!countResponsibilityById?.[instructor?.instructor?.id]?.isPrimary}
                  onChange={() =>
                    updateResponsibilityValues(
                      true,
                      linkId,
                      instructor?.instructor?.id,
                      "isPrimary",
                    )
                  }
                  disabled={!isEditing || isUnassigment}
                />
                {!isUnassigment ? (
                  <Link
                    colored
                    href={`/scheduler/editor/instructor/${workspace}/${scenario}/${origin}/${instructor?.id}`}
                  >
                    <span>{instructor?.instructor?.code} </span> {instructor?.instructor?.name}
                  </Link>
                ) : (
                  <Text>{instructor?.instructor?.name}</Text>
                )}
              </div>
            );
          },
          desktopOptions: {
            headCellStyles: {
              width: "400px",
              minWidth: "80px",
            },
          },
        },
        {
          key: "totalBlocks",
          header: t("bundle.link-card.instructor-table.header.blocks"),
          renderCell: (data: any) => {
            if (data?.isFooter)
              return (
                <Text weight="medium">
                  {totalInstructorBlocks} ({totalInstructorBlocksPercent}%)
                </Text>
              );

            const instructor = data as InstructorLink;
            const blockPercent = instructor?.totalBlocks
              ? `${getTotalBlocksPercent(instructor?.totalBlocks, linkTotalBlocks)}`
              : "";

            return (
              <Text>{`${instructor?.totalBlocks} ${
                blockPercent ? `(${blockPercent}%)` : ""
              } `}</Text>
            );
          },
          desktopOptions: {
            headCellStyles: {
              width: "112px",
              minWidth: "80px",
            },
          },
        },
        {
          key: "responsability",
          header: t("bundle.link-card.instructor-table.header.responsibility"),
          renderCell: (data: any) => {
            if (data?.isFooter) {
              return <Text weight="medium">{totalResponsability}%</Text>;
            }

            const instructor = data as InstructorLink;
            const isDisabled = isUnAssigment(instructor?.instructor?.id) || !isEditing;

            return (
              <TextField
                className={styles.instructorsTable_inputField}
                type="number"
                value={
                  countResponsibilityById?.[instructor?.instructor?.id]?.teachingHoursPercentage ??
                  0
                }
                onChange={e =>
                  updateResponsibilityValues(
                    e.target.value as any,
                    linkId,
                    instructor?.instructor?.id,
                    "responsibility",
                  )
                }
                disabled={isDisabled}
                statusDisplay="icon"
                status={errors.responsabilityError ? "error" : undefined}
              />
            );
          },
          desktopOptions: {
            headCellStyles: {
              width: "132px",
              minWidth: "80px",
            },
          },
        },
        {
          key: "paymentPercentage",
          header: t("bundle.link-card.instructor-table.header.charge"),
          renderCell: (data: any) => {
            if (data?.isFooter) {
              return <Text weight="medium">{totalPayment}%</Text>;
            }

            const instructor = data as InstructorLink;
            const isDisabled = isUnAssigment(instructor?.instructor?.id) || !isEditing;

            return (
              <TextField
                className={styles.instructorsTable_inputField}
                type="number"
                value={
                  countResponsibilityById?.[instructor?.instructor?.id]?.paymentPercentage ?? 0
                }
                onChange={e =>
                  updateResponsibilityValues(
                    e.target.value as any,
                    linkId,
                    instructor?.instructor?.id,
                    "payment",
                  )
                }
                disabled={isDisabled}
                statusDisplay="icon"
                status={errors.paymentError ? "error" : undefined}
              />
            );
          },
          desktopOptions: {
            headCellStyles: {
              width: "96px",
              minWidth: "80px",
            },
          },
        },
        {
          key: "components",
          header: t("bundle.link-card.instructor-table.header.components"),
          renderCell: (data: any) => {
            if (data?.isFooter) return "";

            const instructor = data as InstructorLink;

            return (
              <Text>
                {instructor?.components?.length ? instructor?.components?.join(", ") : "-"}
              </Text>
            );
          },
          desktopOptions: {
            headCellStyles: {
              width: "112px",
              minWidth: "80px",
            },
          },
        },
      ]}
    />
  );
};

export default InstructorsTable;
