import React from "react";
import { useTranslation } from "react-i18next";
import DimensionsSearchResults from "@dimensions/common/dimensions-search-results";
import useGetTitle from "../../hooks/useGetTitle";
import { DimensionSearchResultsProps, DimensionNamesEs } from "@edhtypes/dimensions";
import { Classroom } from "@models/ISchema";
import {
  getColumnCell,
  getHeadCell,
  getLinkCell,
  getPillCell,
  getUsableCell,
} from "@dimensions/utils/tableUtils";
import useNavigationUrl from "@common/hooks/useNavigationUrl";

/**
 * ClassRoomTable component that displays a table of classrooms with relevant information.
 *
 * This component fetches classroom data and renders it in a table format, displaying information
 * such as classroom code, name, building, campus, floor, type, capacity, and utilization percentages.
 * It integrates pagination, search, and internationalization (i18n) for multi-language support.
 *
 * @param {DimensionSearchResultsProps} props - The properties passed to the component.
 * @param {Array<Classroom>} props.data - Data of classrooms to display in the table.
 * @param {string} props.search - The search query to filter results.
 * @param {Object} props.pageInfo - Pagination information (total results, current page, etc.).
 * @param {Function} props.setPage - Function to change the current page.
 * @param {boolean} props.loading - Boolean indicating if the data is still loading.
 * @param {boolean} props.error - Boolean indicating if there was an error during data fetch.
 * @param {Function} props.onChangeSizePerPage - Function to handle page size changes.
 * @returns {JSX.Element} - The rendered ClassRoomTable component.
 *
 * @example
 * <ClassRoomTable
 *   data={classroomData}
 *   search="Math"
 *   pageInfo={pageInfo}
 *   setPage={setPage}
 *   loading={isLoading}
 *   error={hasError}
 *   onChangeSizePerPage={handlePageSizeChange}
 * />
 */
const ClassRoomTable = (props: DimensionSearchResultsProps) => {
  const { data, search, pageInfo, setPage, loading, error, onChangeSizePerPage } = props;

  // Get the dynamic title for the search results using the custom hook `useGetTitle`
  const title = useGetTitle(search, pageInfo?.total || 0, "sala");

  // Get the context URL for advanced search functionality
  const { advancedSearchUrl, editorUrl } = useNavigationUrl();
  const getEditorUrl = (id: string) => editorUrl("classroom", id);

  // Use the i18n hook to get translation functions
  const { t } = useTranslation();

  // Define the columns for the table, including the header and cell rendering logic
  const columns = [
    {
      header: getHeadCell(t("search-results.dimension.classroom.table.header.code")),
      renderCell: ({ id, code }: Classroom) => getLinkCell(getEditorUrl(id), null, code),
    },
    {
      header: getHeadCell(t("search-results.dimension.classroom.table.header.name")),
      renderCell: ({ id, name }: Classroom) => getLinkCell(getEditorUrl(id), null, name),
    },
    {
      header: getHeadCell(t("search-results.dimension.classroom.table.header.building")),
      renderCell: ({ building }: Classroom) => getColumnCell(building?.name),
      desktopOptions: {
        headCellStyles: {
          minWidth: "80px",
          width: "124px",
        },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.classroom.table.header.campus")),
      renderCell: ({ building }: Classroom) => getColumnCell(building?.campus?.code),
      desktopOptions: {
        headCellStyles: {
          minWidth: "80px",
          width: "120px",
        },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.classroom.table.header.floor")),
      renderCell: ({ floor }: Classroom) => getColumnCell(floor),
    },
    {
      header: getHeadCell(t("search-results.dimension.classroom.table.header.classroom-type")),
      renderCell: ({ classroomType }: Classroom) => getColumnCell(classroomType?.name),
    },
    {
      header: getHeadCell(t("search-results.dimension.classroom.table.header.capacity")),
      renderCell: ({ capacity }: Classroom) => getColumnCell(capacity),
    },
    {
      header: getHeadCell(t("search-results.dimension.classroom.table.header.usable")),
      renderCell: ({ usable }: Classroom) => getUsableCell(usable),
      desktopOptions: {
        headCellStyles: {
          minWidth: "80px",
          width: "100px",
        },
      },
    },
    {
      header: getHeadCell(
        t("search-results.dimension.classroom.table.header.utilization-percentage"),
      ),
      renderCell: ({ assignment }: Classroom) => getPillCell(assignment?.usagePercentage),
    },
    {
      header: getHeadCell(
        t("search-results.dimension.classroom.table.header.occupation-percentage"),
      ),
      renderCell: ({ assignment }: Classroom) =>
        getPillCell(assignment?.chairsOccupationPercentage),
    },
  ];

  // Render the DimensionsSearchResults component with the table data and configurations
  return (
    <DimensionsSearchResults
      onChangeSizePerPage={onChangeSizePerPage}
      title={title.getTitle}
      columns={columns}
      data={data}
      pageInfo={pageInfo}
      setPage={setPage}
      loading={loading}
      error={error}
      dimension={t("dimension.classroom.title") as DimensionNamesEs}
      advanceLink={advancedSearchUrl("classroom")}
    />
  );
};

export default ClassRoomTable;
