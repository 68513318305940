import React, { useState, FC } from "react";
import { useTranslation } from "react-i18next";
import { RadioButton, Text } from "@foris/avocado-suite";
import SelectSection from "../select-section";
import { IOption } from "@common/components/Select/Select";
import { CourseComponent } from "@models/ISchema";
import css from "./componentItem.module.scss";

export type CreateType = "NEW_SECTION" | "OLD_SECTION";

export interface IReturnComponent {
  courseComponent: CourseComponent;
  typeSection: CreateType;
  section: IOption;
}

interface ComponentItemProps {
  courseId: number;
  courseComponent: CourseComponent;
  onCallback: (value: IReturnComponent) => void;
}

const ComponentItem: FC<ComponentItemProps> = ({ courseComponent, courseId, onCallback }) => {
  // State for type section
  const [typeSection, setTypeSection] = useState<CreateType>("NEW_SECTION");
  // Hooks for translation
  const { t } = useTranslation();

  return (
    <li className={css.componentItem}>
      <Text type="sm" className={css.componentItem_title}>
        {courseComponent.component.name}
      </Text>
      <div className={css.componentItem_options}>
        <RadioButton
          className={css.radioButton}
          labelRight={t("create-group-link.step-2.component.new-section.label")}
          checked={typeSection == "NEW_SECTION"}
          onChange={() => {
            setTypeSection("NEW_SECTION");
            const returnValue: IReturnComponent = {
              courseComponent,
              typeSection: "NEW_SECTION",
              section: null,
            };
            onCallback(returnValue);
          }}
        />
        <RadioButton
          className={css.radioButton}
          labelRight={t("create-group-link.step-2.component.section-existing.label")}
          checked={typeSection == "OLD_SECTION"}
          onChange={() => {
            setTypeSection("OLD_SECTION");
            const returnValue: IReturnComponent = {
              courseComponent,
              typeSection: "OLD_SECTION",
              section: null,
            };
            onCallback(returnValue);
          }}
        />
      </div>

      {typeSection === "OLD_SECTION" && (
        <SelectSection
          placeholder={t("create-group-link.step-2.component.select-search-section.placeholder")}
          className={css.componentItem_select}
          courseId={courseId}
          courseComponentId={courseComponent.id}
          onChange={(value: IOption) => {
            const returnValue: IReturnComponent = {
              courseComponent,
              typeSection,
              section: value,
            };
            onCallback(returnValue);
          }}
        />
      )}
    </li>
  );
};

export default ComponentItem;
