import React, { useMemo, useState } from "react";
import { useMutation } from "react-apollo";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Loading, Button, Box } from "@foris/avocado-suite";
import { populationSearchMutation } from "../graphql/advanceSearch.queries";
import useContextUrl from "@common/hooks/useContextUrl";
import useNavigationUrl from "@common/hooks/useNavigationUrl";
import EmptyResultsModal from "@modules/shared/empty-results-modal";
import AdvancedSelectorsWrapper from "@modules/advance-search/components/advanced-selectors-wrapper";
import css from "./advanceSearch.module.scss";

const AdvanceSearch: React.FC<any> = () => {
  // State for handle the empty result
  const [isEmpty, setIsEmpty] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectorsState, setSelectorsState] = useState({
    campus: null,
    department: null,
    modality: null,
    shift: null,
    program: null,
    curriculum: null,
    level: null,
  });
  // Hooks for translation and navigation
  const { t } = useTranslation();
  const history = useHistory();
  const { origin, scenario } = useContextUrl();
  const { advancedSearchResultsUrl } = useNavigationUrl();
  const [advanceSearchMutation] = useMutation(populationSearchMutation, {});

  const hasSomeFilter = useMemo(() => {
    return Object.values(selectorsState).some(value => !!value);
  }, [selectorsState]);

  const createObjectMutation = () => {
    const filterArray = [];

    selectorsState?.program &&
      filterArray.push({
        program: {
          code: selectorsState?.program ? { eq: selectorsState?.program } : undefined,
        },
      });
    selectorsState.curriculum &&
      filterArray.push({ curriculum: { eq: selectorsState.curriculum } });
    selectorsState.level && filterArray.push({ level: { eq: selectorsState.level } });
    selectorsState.campus && filterArray.push({ campusId: { eq: selectorsState.campus } });
    selectorsState.modality && filterArray.push({ modalityId: { eq: selectorsState.modality } });
    selectorsState.department &&
      filterArray.push({ departmentId: { eq: selectorsState.department } });
    selectorsState.shift && filterArray.push({ shiftId: { eq: selectorsState.shift } });

    const mutationFilter = {
      originId: origin,
      scenarioId: scenario,
      filter: {
        fields: {
          and: [
            // Set default filter 'cause now we bring only the ones with assignment
            { hasEvents: true },
            ...filterArray,
          ],
        },
      },
      pagination: {
        page: 1,
        size: 10,
      },
    };
    return mutationFilter;
  };
  const callUpdateHook = async () => {
    setLoading(true);
    try {
      const variables = createObjectMutation();
      const { data } = await advanceSearchMutation({ variables });
      setLoading(false);
      const dataItems = data.filter.createPopulationFilter;
      if (dataItems.populations.items.length > 0) {
        const idSearch = dataItems.id;
        history.push(advancedSearchResultsUrl("population", idSearch));
      } else {
        setIsEmpty(true);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <div className={css.ctnAdvanceSearch}>
      <EmptyResultsModal show={isEmpty} onClose={() => setIsEmpty(false)} />

      <Box className={css.ctnAdvanceSearch_boxContent}>
        <AdvancedSelectorsWrapper
          scenario={scenario}
          selectors={[
            "campus",
            "department",
            "modality",
            "shift",
            "program",
            "curriculum",
            "level",
          ]}
          onChangeState={setSelectorsState}
        />
      </Box>

      {loading ? (
        <Loading />
      ) : (
        <Button
          className={css.ctnAdvanceSearch_actionButton}
          onClick={() => callUpdateHook()}
          disabled={!hasSomeFilter}
        >
          {t("advanced-search.population.forms.btn-search")}
        </Button>
      )}
    </div>
  );
};

export default AdvanceSearch;
