import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useApolloClient, useMutation } from "react-apollo";
import { useParams, useHistory } from "react-router-dom";
import cx from "classnames";
import { Loading, Text, RadioButton, Button } from "@foris/avocado-suite";
import { enums } from "../../utils";
import HeaderEdit from "./../HeaderEdit/HeaderEdit";
import TableSession from "./TableSession";
import Validations from "./Validations";
import {
  ContextDisabledSession,
  useContextDisabledSession,
} from "./ContextDisabled/ContextDisabled";
import { sessionsQuery, disableSectionMutation, disableSessionMutation } from "./graphql";
import { IParams } from "../../../../models/IParams";
import { EditSectionPayload, DisableSessionPayload } from "../../../../models/ISchema";
import { IErrorWarning } from "../../../../models/Errors/ErrorWarning";
import SectionDelete from "../../../../models/Errors/SectionDelete";
import SessionDelete from "../../../../models/Errors/SessionDelete";
import css from "./disabledSession.module.scss";

interface IDeleteSession {
  onClose: (value: any) => void;
  onInvalidateSectionDeletion?: () => void;
  event: any;
  createSession?: boolean;
  component?: any;
  sectionId?: any;
  vacancies?: number;
  title?: string;
}

const DeleteSession: React.FC<IDeleteSession> = (props: IDeleteSession) => {
  const {
    event,
    onClose,
    createSession,
    component,
    sectionId,
    vacancies,
    title,
    onInvalidateSectionDeletion,
  } = props;
  const { scenario, origin, workspace }: IParams = useParams();
  const { t } = useTranslation();
  const history = useHistory();
  const client = useApolloClient();
  const [dataSession, setDataSession] = useState(null);
  const [activeLoading, setActiveLoading] = useState(false);
  const [sectionCheck, setSectionCheck] = useState(false);
  const [valueCheck, setValueCheck] = useState(null);
  const [validations, setValidations] = useState<IErrorWarning[]>([]);
  const [deleteSession] = useMutation(disableSessionMutation, {});
  const [deleteSection] = useMutation(disableSectionMutation, {});
  const contextDisabled = useContextDisabledSession();
  const dataTable = [];

  // mutation delete sessions
  const onCallDeleteSessionMutation = async (variables: any, e: React.MouseEvent) => {
    setActiveLoading(true);
    try {
      const data = await deleteSession({ variables });
      const result: DisableSessionPayload = data.data.cube.disableSessions;

      if (result.validationErrors && result.validationErrors.length > 0) {
        const errorsValidations = [];
        result.validationErrors.forEach(error => {
          const errorSection = new SessionDelete(error);
          errorsValidations.push(errorSection.getMessage());

          if (error?.__typename === "InvalidateSectionDeletion") {
            onInvalidateSectionDeletion?.();
          }
        });

        setValidations(errorsValidations);
        setActiveLoading(false);
      } else {
        onClose(e);
        window.location.reload();
        setActiveLoading(false);
        setDataSession(null);
        contextDisabled.setSessionData(null);
      }
    } catch (error) {
      console.error("[deleteSession]", error);
    }
  };

  // variables: mutation delete sessions
  const onDeleteSession = (e: React.MouseEvent) => {
    let intervals = [];
    const ids = [];

    contextDisabled.sessionData.forEach((item: any) => {
      const idIntervals = [];
      item.intervals.map((element: any) => idIntervals.push(parseInt(element.id)));
      intervals = [...intervals, ...idIntervals];
      ids.push(parseInt(item.id));
    });

    const uniqueIntervals = intervals.filter((value: any, index: number, self: any) => {
      return self.indexOf(value) === index;
    });

    const variables = {
      originId: origin,
      scenarioId: scenario,
      sessionIds: ids,
      intervalIds: uniqueIntervals,
    };

    onCallDeleteSessionMutation(variables, e);
  };

  const onDeleteSection = async (commit: boolean) => {
    setActiveLoading(true);
    try {
      const variables = {
        scenarioId: scenario,
        originId: origin,
        input: {
          sectionId: sectionId,
          dryRun: !commit,
          skipValidations: commit,
        },
      };
      if (!commit) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        const error = await onCallDeleteSectionMutation(variables);
        error && setActiveLoading(false);
      } else {
        const { data } = await deleteSection({ variables });
        const result: EditSectionPayload = data.cube.disableSection;
        if (result.commited) history.push(`/home/${workspace}/${scenario}/${origin}`);
      }
    } catch (error) {
      console.error("[deleteSection]", error);
    }
  };

  const onCallDeleteSectionMutation = async (variables: any) => {
    try {
      const { data } = await deleteSection({ variables });
      const result: EditSectionPayload = data.cube.disableSection;
      if (result.validationErrors && result.validationErrors.length > 0) {
        const errorsValidations = [];
        result.validationErrors.forEach(error => {
          const errorSection = new SectionDelete(error);
          errorsValidations.push(errorSection.getMessage());
        });

        setValidations(errorsValidations);

        return true;
      } else {
        onDeleteSection(true);
      }
    } catch (error) {
      console.error("[deleteSection]", error);
    }
  };

  // mutation delete section
  useEffect(() => {
    // get initial data: sessions
    const getData = async () => {
      setActiveLoading(true);
      try {
        const { data } = await client.query({
          query: sessionsQuery,
          variables: {
            scenarioId: scenario,
            originId: origin,
            sectionId: sectionId,
          },
        });
        setDataSession(data);
        setActiveLoading(false);
      } catch (error) {
        console.error("[sessionsQuery]", error);
      }
    };
    if (!dataSession) {
      getData();
    }
  }, [dataSession, client, scenario, origin, sectionId]);

  if (dataSession) {
    dataSession.cube.section.sessions.map((item: any) => {
      const day: string = item.assignment.blockRange.start
        ? enums.DAY_NAMES_ES[item.assignment.blockRange.start.day]?.toUpperCase().substring(0, 3) ||
          "-"
        : "-";
      const startingTime = item.assignment.blockRange.start
        ? item.assignment.blockRange.start.startingTime.replace(":00", "") || "-"
        : "-";
      const endingTime = item.assignment.blockRange.end
        ? item.assignment.blockRange.end.endingTime.replace(":00", "") || "-"
        : "-";

      const objData = {
        id: item.id,
        blockRange: `${day} ${startingTime} - ${endingTime}`,
        instructors:
          item.assignment.instructors
            .map((instructor: any) => instructor.name || "-")
            ?.join(", ") || "-",
        classRooms:
          item.assignment.classrooms.map((classroom: any) => classroom.name || "-")?.join(", ") ||
          "-",
        vacancies: item.vacancies ?? "-",
        dataSession: {
          id: item.id,
          intervals: item.assignment.intervals || [],
          checked: false,
        },
      };
      dataTable.push(objData);
      return objData;
    });
  }

  let disabledDelete = false;
  if (sectionCheck) disabledDelete = false;
  if (!sectionCheck) {
    disabledDelete = contextDisabled.sessionData && contextDisabled.sessionData.length === 0;
  }

  const countSessions = contextDisabled.sessionData ? contextDisabled.sessionData.length : null;
  const warnings = validations ? validations.filter(warning => warning.type === "WARNING") : [];

  return (
    <ContextDisabledSession.Provider value={contextDisabled}>
      {activeLoading && <Loading />}
      {!activeLoading && (
        <section className={cx(css.cntDisabledSession, "contaianer-row")}>
          <HeaderEdit
            dimension={t("dimension.section.delete-section.title")}
            event={event}
            createSession={createSession}
            component={component}
            sectionId={sectionId}
            vacancies={vacancies}
            title={title}
            breadcrumbItems={[
              {
                title: t("dimension.section.breadcrumb.previous"),
                path: "/",
              },
              {
                title: t("dimension.section.breadcrumb.current"),
                path: "/",
              },
            ]}
          />
          <section className={cx(css.content)}>
            <Text className={cx(css.content_title, "col_12")} weight="medium" type="lg">
              {t("dimension.section.delete-section.radioButton.title")}
            </Text>
            <div className={css.cntOption}>
              <RadioButton
                className={css.cntOption_item}
                labelRight={`${t("dimension.section.delete-section.radioButton.option.label")} ${
                  countSessions && !sectionCheck ? `(${countSessions})` : ""
                }`}
                name="selectedSessions"
                disabled={dataTable.length === 0}
                checked={dataTable.length > 0 ? !sectionCheck : false}
                onChange={() => {
                  setSectionCheck(!sectionCheck);
                  setValidations([]);
                }}
              />
              <RadioButton
                className={css.cntOption_item}
                labelRight={t("dimension.section.delete-section.radioButton.second-option.label")}
                name="selectedSection"
                checked={sectionCheck || dataTable.length === 0}
                onChange={() => {
                  setSectionCheck(!sectionCheck);
                  setValidations([]);
                }}
              />
            </div>
            {dataTable.length > 0 && (
              <TableSession dataTable={dataTable} sectionCheck={sectionCheck} />
            )}

            {validations && validations.length > 0 && (
              <Validations
                validations={validations}
                onCallback={(valueCheck: boolean) => setValueCheck(valueCheck)}
              />
            )}

            <div className={css.actionsButton}>
              <Button
                variant="secondary"
                className={css.cntFooter_btn}
                onClick={(e: any) => onClose(e)}
              >
                {t("dimension.section.delete-section.btn-cancel")}
              </Button>
              {validations.length === 0 && (
                <Button
                  className={css.cntFooter_btn}
                  variant="danger"
                  disabled={disabledDelete}
                  onClick={(e: React.MouseEvent) => {
                    if (!sectionCheck && dataTable.length > 0) onDeleteSession(e);
                    if (sectionCheck || dataTable.length === 0) onDeleteSection(false);
                  }}
                >
                  {t("dimension.section.delete-section.btn-delete")}
                </Button>
              )}
              {warnings.length > 0 && (
                <Button
                  className={css.cntFooter_btn}
                  variant="danger"
                  disabled={!valueCheck || activeLoading}
                  onClick={() => {
                    if (sectionCheck || dataTable.length === 0) onDeleteSection(true);
                  }}
                >
                  {t("dimension.section.delete-section.btn-confirm")}
                </Button>
              )}
            </div>
          </section>
        </section>
      )}
    </ContextDisabledSession.Provider>
  );
};

export default DeleteSession;
