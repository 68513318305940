import React from "react";
import { reject } from "lodash";
import { Table, Pager, Loading, Checkbox, CardNotification } from "@foris/avocado-suite";
import { Instructor } from "@models/ISchema";
import { Icon } from "@foris/avocado-icons";
import { getColumnCell } from "@dimensions/utils/tableUtils";
import css from "./advanceSearch.module.scss";
import { useTranslation } from "react-i18next";

interface AdvanceTable {
  loading: boolean;
  data: Instructor[];
  size: number;
  total: number;
  page: number;
  selected: Instructor[];
  setSelected: (value: Instructor[]) => void;
  onPageChange: (page: number) => void;
}

const AdvanceSearch: React.FC<AdvanceTable> = (props: AdvanceTable) => {
  const { loading, data, size, total, page, onPageChange, setSelected, selected } = props;
  const { t } = useTranslation();

  const renderCheck = (instructor: Instructor) => {
    const findSelected = selected?.find(value => value.id === instructor?.id);
    const onChange = () => {
      let cloneSelected = selected ? [...selected] : [];
      if (!findSelected) cloneSelected.push(instructor);
      if (findSelected) cloneSelected = reject(cloneSelected, value => value.id === instructor?.id);
      setSelected(cloneSelected);
    };

    return <Checkbox checked={!!findSelected} disabled={!instructor?.usable} onChange={onChange} />;
  };

  const columns = [
    {
      key: "id",
      header: "",
      renderCell: (row: any) => renderCheck(row),
    },
    {
      key: "instructor",
      header: t("assignation-edit.advanced-search.instructor.table-columns.instructor"),
      renderCell: (row: any) => getColumnCell(`${row?.code} ${row?.name}`),
    },
    {
      key: "priority",
      header: t("assignation-edit.advanced-search.instructor.table-columns.priority"),
      renderCell: (row: any) => getColumnCell(row?.priority ?? "-"),
    },
    {
      key: "usable",
      header: t("assignation-edit.advanced-search.instructor.table-columns.usable"),
      renderCell: (row: any) => <Icon name={!!row?.usable ? "check" : "close"} />,
    },
    {
      key: "averagePerIntervalBlocks",
      header: t("assignation-edit.advanced-search.instructor.table-columns.assigned-blocks"),
      renderCell: (row: any) =>
        getColumnCell(row?.assignment ? `${row?.assignment?.averagePerIntervalBlocks}%` : "-"),
    },
    {
      key: "blocks",
      header: t("assignation-edit.advanced-search.instructor.table-columns.total-assigned-blocks"),
      renderCell: (row: any) => getColumnCell(row?.assignment?.blocks ?? "-"),
    },
    {
      key: "usagePercentage",
      header: t("assignation-edit.advanced-search.instructor.table-columns.usage"),
      renderCell: (row: any) =>
        getColumnCell(row?.assignment ? `${row?.assignment.usagePercentage}%` : "-"),
    },
  ];

  if (loading && !data) {
    return <Loading />;
  }

  if (total === 0)
    return (
      <section className={css.cardState}>
        <CardNotification
          state="info"
          title={t("assignation-edit.advanced-search.notifications.empty-results.title")}
          outlined
        >
          {t("assignation-edit.advanced-search.notifications.empty-results.instructor-message")}
        </CardNotification>
      </section>
    );

  return (
    <div className={css.advanceTable}>
      {loading && <Loading />}

      <Table columns={columns} data={data} />

      {!!total && <Pager size={size} total={total} page={page} onChange={onPageChange} />}
    </div>
  );
};

export default AdvanceSearch;
