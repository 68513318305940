import React, { useContext, useEffect, useMemo, useState } from "react";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import {
  Heading,
  Loading,
  TextField,
  Tooltip,
  Button,
  Text,
  Divider,
  CardNotification,
} from "@foris/avocado-suite";
import { useLazyQuery, useMutation, useQuery } from "react-apollo";
import { useHistory, useParams } from "react-router-dom";
import { LINK_CRUD_MUTATION } from "@dimensions/link/graphql/linkCrudMutation";
import bundlesCubeQuery from "../../../../vacancies/graphql/bundlesCube.query.graphql";
import HeaderVacancies from "@modules/vacancies/Header";
import Layout from "@common/layout/Layout";
import useGoBack from "@common/hooks/useGoBack";
import { ContextApp, IContextApp } from "@config/Context/contextApp";
import {
  Bundle,
  CubeMutation,
  GenericInvalidValidation,
  InvalidLabelLink,
  Link,
} from "@models/ISchema";
import { courseMultiKeyQuery } from "@modules/vacancies/graphql/courseMultiKey.queries";
import { IParams } from "@models/IParams";
import { LINK_ERROR_LABELS } from "@models/Errors/LinkErrors/LinkErrors";
import css from "./clientCodeManagement.module.scss";

const getErrorMessage = (label: string, isValid: boolean) => {
  if (!label || isValid) {
    return "";
  }

  if (label?.length !== 2) {
    return i18n.t("bundle.create-link.forms.code-link-input.error-text");
  }

  return i18n.t("bundle.create-link.forms.code-link-input.tooltip.text-2");
};

const getMutationErrorMessage = (reason: string, params: any) => {
  if (!reason || !(reason in LINK_ERROR_LABELS)) {
    return null;
  }

  return LINK_ERROR_LABELS?.[reason](params);
};

const ClientCodeManagement = () => {
  const { origin, scenario, workspace, id }: IParams = useParams();
  const history = useHistory();
  const context: IContextApp = useContext(ContextApp);
  const [linkLabel, setLinkLabel] = useState("");
  const { t } = useTranslation();

  const { handleGoBack } = useGoBack({ workspace, scenario, origin });

  const [getLinkData, { data, loading: loadingData }] = useLazyQuery<any, any>(
    courseMultiKeyQuery,
    {
      variables: {
        scenarioId: scenario,
        originId: origin,
        id,
      },
    },
  );

  const { data: bundleResponse, loading: isLoadingBundle } = useQuery(bundlesCubeQuery, {
    variables: {
      scenarioId: scenario,
      originId: origin,
      bundleId: data?.cube?.link?.bundle?.id,
    },
    fetchPolicy: "no-cache",
    skip: !data?.cube?.link?.bundle?.id,
  });

  const bundleData = bundleResponse?.cube?.bundle as Bundle;
  const bundlePath = `/editor/vacancies/${workspace}/${scenario}/${origin}/${bundleData?.id}`;

  const [updateLinkLabel, { loading, data: mutationData }] = useMutation(LINK_CRUD_MUTATION, {
    onCompleted: data => {
      const cube = data?.cube as CubeMutation;

      if (cube?.linksCrud?.commited) {
        history.replace(bundlePath);
      }
    },
  });

  const isLabelValid = /^(?!.*[\s\n'""])(?!.*\\n)[^\s\n'""]{2}$/.test(linkLabel);
  const errorMessage = getErrorMessage(linkLabel, isLabelValid);

  const mutationError = useMemo(() => {
    const error = (mutationData?.cube as CubeMutation)?.linksCrud?.payload?.updates?.[0]
      ?.validationErrors?.[0] as GenericInvalidValidation | InvalidLabelLink;
    const errorLink = !!error && "link" in error ? error?.link : undefined;
    const errorPayload = !!errorLink
      ? {
          link: errorLink,
          scenario,
          workspace,
          origin,
        }
      : undefined;

    return getMutationErrorMessage(error?.reason ?? "", errorPayload);
  }, [mutationData]);

  useEffect(() => {
    if (context?.user?.abilities?.can_edit_links_and_sections) {
      getLinkData();
    } else {
      handleGoBack();
    }
  }, []);

  useEffect(() => {
    const link = data?.cube?.link as Link | null;

    if (!!link && link?.label) {
      handleGoBack();
    }
  }, [data]);

  const handleUpdateLinkLabel = () => {
    updateLinkLabel({
      variables: {
        originId: origin,
        scenarioId: scenario,
        input: {
          dryRun: false,
          skipValidations: false,
          changesets: {
            updates: [
              {
                linkId: id,
                label: linkLabel,
              },
            ],
          },
        },
      },
    });
  };

  const selectedLeague = data?.cube?.link?.code;
  const subject = data?.cube?.link?.course?.name || "-";
  const campus = data?.cube?.link?.course?.curriculum?.program?.campus?.code || "-";
  const period = data?.cube?.link?.bundle?.term?.code || "-";

  return (
    <Layout contextSearch>
      {loading || loadingData || isLoadingBundle ? <Loading /> : null}

      {data?.cube?.link?.bundle && <HeaderVacancies data={bundleData} />}

      <div className={css.clientCodeManagement}>
        <Heading type="h3" className={css.clientCodeManagement_title}>
          {t("bundle.create-link.title")}
        </Heading>

        <div className={css.clientCodeManagement_informationBox}>
          <Heading className={css.title} type="h4">
            {t("bundle.create-link.forms.information-box.title")}
          </Heading>

          <Text className={css.selectedLeague} weight="medium" type="sm">
            {selectedLeague}
          </Text>

          <Text weight="medium" className={css.subtitle} type="sm">
            {t("bundle.create-link.forms.information-box.subtitle.title")}
          </Text>

          <div className={css.content}>
            <Text type="sm" className={css.content_text}>
              {t("bundle.create-link.forms.information-box.subtitle.course")}:
            </Text>
            <Text weight="medium" type="sm" className={css.content_bold}>
              {subject}
            </Text>

            <Divider vertical className={css.content_divider} />
            <Text type="sm" className={css.content_text}>
              {t("bundle.create-link.forms.information-box.subtitle.campus")}:
            </Text>

            <Text weight="medium" type="sm" className={css.content_bold}>
              {campus}
            </Text>

            <Divider vertical className={css.content_divider} />

            <Text type="sm" className={css.content_text}>
              {t("bundle.create-link.forms.information-box.subtitle.period")}:
            </Text>

            <Text weight="medium" type="sm" className={css.content_bold}>
              {period}
            </Text>
          </div>
        </div>

        <div className={css.clientCodeManagement_control}>
          <Tooltip
            label={
              (
                <ul className={css.tooltipLabel}>
                  <li>{t("bundle.create-link.forms.code-link-input.tooltip.text-1")}</li>
                  <li>{t("bundle.create-link.forms.code-link-input.tooltip.text-2")}</li>
                  <li>{t("bundle.create-link.forms.code-link-input.tooltip.text-3")}</li>
                </ul>
              ) as any
            }
            placement="right"
          >
            <label className={css.label} htmlFor="linkLabel">
              {t("bundle.create-link.forms.code-link-input.label")}
            </label>
          </Tooltip>

          <TextField
            id="linkLabel"
            className={css.textField}
            placeholder={t("bundle.create-link.forms.code-link-input.placeholder")}
            value={linkLabel}
            status={!!errorMessage ? "error" : null}
            statusDisplay="inline"
            statusText={t("bundle.create-link.forms.code-link-input.error-text")}
            onChange={e => setLinkLabel(e?.target?.value ?? "")}
          />

          {mutationError && !loading && (
            <CardNotification
              outlined
              state="error"
              className={css.errorCard}
              title={mutationError?.title as string}
            >
              <Text type="sm">{mutationError?.message}</Text>
            </CardNotification>
          )}
        </div>

        <div className={css.clientCodeManagement_actions}>
          <Button variant="ghost" onClick={() => history.replace(bundlePath)}>
            {t("bundle.create-link.forms.btn-cancel")}
          </Button>
          <Button disabled={!isLabelValid || loading} onClick={handleUpdateLinkLabel}>
            {t("bundle.create-link.forms.btn-confirm")}
          </Button>
        </div>
      </div>
    </Layout>
  );
};

export default ClientCodeManagement;
