import React, { useCallback, useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useApolloClient } from "react-apollo";
import { SelectPagination } from "@foris/avocado-suite";
import { orderBy } from "lodash";
import { groupLabels } from "../graphql/groupLabels.query";
import { ContextEditLabel, ILabel } from "./ContextEditLabels";
import { IParams } from "@models/IParams";
import { LabelPage, PageInfo } from "@models/ISchema";

interface LabelOption {
  label: string;
  value: ILabel;
}

interface SelectLabelsProps {
  className?: string;
  contentType?: "group" | "package" | "all";
  label?: string;
  labelCategoryId?: string;
  onSelectLabel?: (label: LabelOption) => void;
  plaholder?: string;
}

const SelectLabels = ({
  className = "col_3",
  contentType = "group",
  labelCategoryId,
  label,
  plaholder,
  onSelectLabel,
}: SelectLabelsProps) => {
  // Hooks for handle the state of the component
  const context = useContext(ContextEditLabel);
  const client = useApolloClient();
  const { scenario }: IParams = useParams();
  const { t } = useTranslation();
  // State
  const [loading, setLoading] = useState(false);
  const [termToSearch, setTermToSearch] = useState("");
  const [groupsLabelsPage, setGroupsLabelsPage] = useState(1);
  const [error, setError] = useState(false);

  const defaultPlaceholder = t("edit-tag-manually.select-option.placeholder");

  const getGroupLabels = useCallback(
    async (searchTerm = "", page = 1) => {
      const optionsPerPage = 10;
      setLoading(true);

      try {
        const variables = {
          query: groupLabels,
          variables: {
            scenarioId: scenario,
            filter: {
              fields: {
                contentType:
                  contentType && contentType !== "all"
                    ? {
                        eq: contentType,
                      }
                    : undefined,
                labelSourceCode: {
                  // Only get labels that can be selected by the user
                  eq: "USER-DEFINED",
                },
                labelCategoryId: labelCategoryId
                  ? {
                      eq: labelCategoryId,
                    }
                  : undefined,
              },
              pagination: {
                page,
                size: optionsPerPage,
                searchTerm,
              },
            },
          },
        };
        const { data } = await client.query(variables);
        const labels: LabelPage = data?.data?.labels;
        const pageInfo: PageInfo = data?.data?.labels?.pageInfo;
        const options = [];
        if (labels) {
          labels.items.forEach(item => {
            const option = {
              label: `${item?.code} | ${item?.name} | ${item?.category?.name}`,
              value: { ...item, isNew: true },
            };
            options.push(option);
          });
        }
        context.setOptions(options);
        setLoading(false);

        return { pageInfo, options };
      } catch (error) {
        console.error(error);
        setError(true);
        setLoading(false);
        return null;
      }
    },
    [client],
  );

  const labelsLoadOptions = async (searchTerm: string) => {
    let searchPage = 1;
    if (termToSearch === searchTerm) {
      searchPage = groupsLabelsPage;
    } else {
      setTermToSearch(searchTerm);
    }
    const { pageInfo, options: groupsLabelsOptions } = await getGroupLabels(searchTerm, searchPage);
    setGroupsLabelsPage(groupsLabelsPage + 1);
    return {
      options: groupsLabelsOptions,
      hasMore: pageInfo?.hasNextPage,
      additional: {
        page: groupsLabelsPage,
      },
    };
  };

  useEffect(() => {
    if (!context.options && !loading && !error) getGroupLabels();
  }, [context.options]);

  useEffect(() => {
    const { options } = context;
    const optionClone = options ? [...options] : [];
    if (options && context.labels) {
      context.labels.forEach(label => {
        const find = options.findIndex(value => value.value.id === label.id);
        if (find >= 0) optionClone.splice(find, 1);
      });
      if (options.length !== optionClone.length) context.setOptions(optionClone);
    }
  }, [context.options, context.labels]);

  const orderOptions = orderBy(context.options, ["value.code"], ["asc"]);

  const customStyles = {
    option: (styles: any) => {
      return {
        ...styles,
        color: "#6a7075",
      };
    },
  };

  return (
    <SelectPagination
      label={label}
      className={className}
      placeholder={plaholder ?? defaultPlaceholder}
      loadOptions={labelsLoadOptions}
      options={orderOptions}
      styles={customStyles}
      noOptionsMessage={() => (
        <>{t("advanced-search.package.forms.advanced-filters.select-tag.empty-options")}</>
      )}
      value={null}
      onChange={(selectedLabel: LabelOption) => {
        if (onSelectLabel) {
          return onSelectLabel(selectedLabel);
        }

        const cloneLabels = context.labels ? [...context.labels] : [];

        // if selectedLabel was already selected, don't add it again
        for (let i = 0; i < cloneLabels.length; i++) {
          const currLabel = cloneLabels[i];
          if (currLabel.id === selectedLabel.value.id) return;
        }

        cloneLabels.push(selectedLabel.value);
        context.setLabels(cloneLabels);
      }}
    />
  );
};

export default SelectLabels;
