// Hook to create a new link for a course section based on scenario and origin
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { useApolloClient, useMutation } from "react-apollo";
import { NEW_LINK_CODE } from "../create-section/graphql/links.queries";
import LinkCreationMutation from "../create-section/graphql/linkCreation.mutation.graphql";
import { getValidationErrorReason, linkValidation } from "@modules/vacancies/utils";
import { IReturnComponent } from "../create-section/components/component-item/ComponentItem";
import { CubeMutation, LinkCrudResult } from "@models/ISchema";

interface useCreateLinkProps {
  origin: string;
  scenario: string;
  workspace: string;
}

interface newSectionByLinkProps {
  courseId: string | number;
  createSections: IReturnComponent[];
  dryRun?: boolean;
  groupCode?: string;
  groupVacancies?: string;
  programId?: string;
  termId: string;
}

const useCreateLink = ({ scenario, origin, workspace }: useCreateLinkProps) => {
  // Apollo client instance
  const client = useApolloClient();
  // Router history object for navigation
  const history = useHistory();
  // Mutation to get a new link code
  const [codeLinkMutation] = useMutation(NEW_LINK_CODE, {});
  // Mutation to create the section link
  const [newLinkSectionMutation] = useMutation(LinkCreationMutation);
  // State to store validation errors
  const [linkCreationErrors, setLinkCreationErrors] = useState({
    validationArray: [],
    validationStandardArray: [],
  });
  // Loading state
  const [isLoading, setIsLoading] = useState(false);

  // Get a new link code based on course and scenario
  const getCodeLink = async ({
    courseId,
    dryRun = false,
  }: {
    courseId: string | number;
    dryRun?: boolean;
  }) => {
    try {
      const variables = {
        originId: origin,
        scenarioId: scenario,
        courseId: courseId,
        dryRun,
      };
      const { data } = await codeLinkMutation({ variables });
      const code: CubeMutation = data.cube;
      return code.inferLinkCode;
    } catch (e) {
      console.debug(e);
    }
  };

  /**
   * Create a new section using a generated link code
   */
  const newSectionByLink = async (
    {
      courseId,
      termId,
      dryRun = false,
      createSections,
      programId,
      groupCode,
      groupVacancies,
    }: newSectionByLinkProps,
    linkCode: string,
  ) => {
    try {
      // Arrays to hold section and component references
      const assignSections = [];
      const courseComponentId = [];
      let errorsValidations = [];
      const errorStandard = [];

      // Separate OLD and NEW sections
      createSections.forEach(value => {
        if (value.typeSection === "OLD_SECTION") {
          assignSections.push(parseInt(value.section.value));
        }
        if (value.typeSection === "NEW_SECTION") {
          courseComponentId.push({
            courseComponentId: parseInt(value.courseComponent.id),
          });
        }
      });

      // Prepare mutation variables
      const variables = {
        scenarioId: scenario,
        originId: origin,
        input: {
          dryRun,
          skipValidations: false,
          changesets: {
            creates: [
              {
                courseId,
                termId,
                code: linkCode,
                assignSections,
                createSections: courseComponentId,
                createGroup:
                  groupCode !== undefined
                    ? {
                        programId,
                        code: groupCode,
                        capacity: groupVacancies,
                      }
                    : undefined,
              },
            ],
          },
        },
      };

      // Run mutation to create section by link
      const { data } = await newLinkSectionMutation({ variables });
      const payload: LinkCrudResult = data.cube.linksCrud;
      const validationErrors = payload?.payload?.creates?.[0]?.validationErrors;

      // Handle validation errors from response
      if (validationErrors?.length) {
        validationErrors.forEach((error: any) => {
          const internalValidations: any[] = error?.validationErrors ?? [];

          // Handle nested/internal validations
          if (internalValidations.length) {
            internalValidations?.forEach(err => {
              const errorObj = getValidationErrorReason(err.reason);
              errorStandard.push(errorObj);
            });
          }

          // Handle different types of validation errors
          if (error && error.__typename === "InvalidFieldValidation") {
            const errorObj = {
              ...getValidationErrorReason(error.reason),
              reason: error.reason,
            };
            errorsValidations.push(errorObj);
          } else if (error && error.__typename === "InvalidLinkChanges") {
            const errorLinks = linkValidation(error);
            errorsValidations = [...errorsValidations, ...errorLinks];
          } else if (error && error?.__typename === "InvalidGroupCodeValidation") {
            const errors = [
              ...errorsValidations,
              {
                type: "error",
                text: "Ingresa números enteros del 1 al 999",
                reason: error?.reason ?? "INVALID_GROUP_CODE",
              },
            ];

            // Prevent group codes that start with zero
            if (String(groupCode).startsWith("0")) {
              errors.push({
                type: "error",
                text: "El número no puede ser antecedido por uno o más ceros (01, 001)",
                reason: error?.reason ?? "INVALID_GROUP_CODE",
              });
            }

            errorsValidations = errors;
          } else if (error && error.__typename === "GenericInvalidValidation") {
            const isGroupReason = ["GROUP_CODE_ALREADY_TAKEN"].includes(error?.reason);
            const errorArr = isGroupReason ? errorsValidations : errorStandard;
            const errorObj = getValidationErrorReason(error.reason);

            errorObj?.text
              ? errorArr.push({
                  ...errorObj,
                  reason: error.reason,
                })
              : console.error(error && error.__typename);
          } else {
            console.error(error && error.__typename);
          }
        });
      }

      // Return the result and validation status
      return {
        canCreateLink: !validationErrors?.length,
        linkId: payload?.payload?.creates?.[0]?.link?.id,
        commited: !!payload?.commited,
        bundle: payload?.payload?.creates?.[0]?.link?.bundle,
        errors: {
          validationArray: errorsValidations,
          validationStandardArray: errorStandard,
        },
      };
    } catch (e) {
      console.error(e);
    }
  };

  // High-level handler that triggers the whole link creation flow
  const handleCreateLink = async (
    {
      courseId,
      termId,
      createSections,
      groupCode,
      programId,
      groupVacancies,
    }: newSectionByLinkProps,
    isSimulated = false,
  ) => {
    try {
      setIsLoading(true);
      // First get a new link code (in dry run mode)
      const newCode = await getCodeLink({ courseId, dryRun: true });
      // Then create the section using that code
      const { commited, bundle, errors } = await newSectionByLink(
        {
          courseId,
          termId,
          dryRun: isSimulated,
          createSections,
          groupCode,
          programId,
          groupVacancies,
        },
        newCode,
      );

      // If successful, redirect to editor
      if (commited) {
        client.resetStore();
        const contextUrl = `${workspace}/${scenario}/${origin}`;
        history.push(`/editor/vacancies/${contextUrl}/${bundle?.id}`);
      } else {
        // Otherwise, store validation errors
        setLinkCreationErrors({
          validationArray: errors.validationArray ?? [],
          validationStandardArray: errors.validationStandardArray ?? [],
        });
      }

      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  return {
    handleCreateLink, // Function to execute the entire link creation process
    linkCreationErrors, // Stores any validation errors
    isLoading, // Loading state for UI feedback
  };
};

export default useCreateLink;
