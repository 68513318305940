import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-apollo";
import { useHistory } from "react-router-dom";
import { Loading, Button, Box, Divider } from "@foris/avocado-suite";
import { groupSearchMutation } from "./../graphql/advanceSearch.queries";
import useNavigationUrl from "@common/hooks/useNavigationUrl";
import useContextUrl from "@common/hooks/useContextUrl";
import EmptyResultsModal from "@modules/shared/empty-results-modal";
import AdvancedSelectorsWrapper from "@modules/advance-search/components/advanced-selectors-wrapper";
import AdvancedLabelsSelectors from "@modules/advance-search/components/advanced-labels-selectors";
import { getCurriculumAndProgramFilters } from "@modules/advance-search/utils/filterVariables";
import css from "./advanceSearch.module.scss";

const AdvanceSearch: React.FC<any> = () => {
  // States for handle the visibility of the empty result modal
  const [isEmpty, setIsEmpty] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedLabelCategory, setSelectedLabelCategory] = useState<string | null>(null);
  const [selectedLabels, setSelectedLabels] = useState<string[]>([]);
  const [selectorsState, setSelectorsState] = useState({
    campus: null,
    department: null,
    modality: null,
    shift: null,
    program: null,
    curriculum: null,
    level: null,
    course: null,
  });
  // Hooks for handle the state of the component
  const history = useHistory();
  const [advanceSearchMutation] = useMutation(groupSearchMutation, {});
  const { origin, scenario } = useContextUrl();
  const { advancedSearchResultsUrl } = useNavigationUrl();
  const { t } = useTranslation();

  const handleOnChangeLabels = (payload: { categoryId?: string | null; labelsIds: string[] }) => {
    setSelectedLabelCategory(payload.categoryId);
    setSelectedLabels(payload.labelsIds);
  };

  const labelsFilters = useMemo(() => {
    if (!selectedLabels?.length) {
      return selectedLabelCategory
        ? [
            {
              label: {
                labelCategoryId: { eq: selectedLabelCategory },
              },
            },
          ]
        : [];
    }

    return selectedLabels?.map(labelId => ({
      label: {
        id: {
          eq: labelId,
        },
      },
    }));
  }, [selectedLabelCategory, selectedLabels]);

  const hasSomeFilter = useMemo(() => {
    return Object.values(selectorsState).some(value => value !== null) || labelsFilters.length > 0;
  }, [selectorsState, labelsFilters]);

  const createObjectMutation = () => {
    const filterArray = [];
    const curriculumFilters = getCurriculumAndProgramFilters({
      curriculumCode: selectorsState.curriculum,
      programCode: selectorsState.program,
    });

    (selectorsState?.curriculum || selectorsState?.program) && filterArray.push(curriculumFilters);
    selectorsState.campus && filterArray.push({ campusId: { eq: selectorsState.campus } });
    selectorsState.modality && filterArray.push({ modalityId: { eq: selectorsState.modality } });
    selectorsState.department &&
      filterArray.push({ departmentId: { eq: selectorsState.department } });
    selectorsState.shift && filterArray.push({ shiftId: { eq: selectorsState.shift } });
    selectorsState.course && filterArray.push({ courseId: { eq: selectorsState.course } });
    selectorsState.level && filterArray.push({ level: { eq: selectorsState.level } });
    filterArray.push(...labelsFilters);

    const mutationFilter = {
      originId: origin,
      scenarioId: scenario,
      filter:
        filterArray?.length > 0
          ? {
              fields: {
                and: filterArray,
              },
            }
          : undefined,
      pagination: {
        page: 1,
        size: 10,
      },
    };
    return mutationFilter;
  };

  const callUpdateHook = async () => {
    setLoading(true);
    try {
      const variables = createObjectMutation();
      const { data } = await advanceSearchMutation({ variables });
      setLoading(false);
      const dataItems = data.filter.createGroupFilter;
      if (dataItems.groups.items.length > 0) {
        const idSearch = dataItems.id;
        history.push(advancedSearchResultsUrl("group", idSearch));
      } else {
        setIsEmpty(true);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <div className={css.ctnAdvanceSearch}>
      <EmptyResultsModal show={isEmpty} onClose={() => setIsEmpty(false)} />

      <Box className={css.ctnAdvanceSearch_boxContent}>
        <AdvancedSelectorsWrapper scenario={scenario} onChangeState={setSelectorsState} />

        <Divider className={css.divider} />

        <AdvancedLabelsSelectors
          scenario={scenario}
          contentType="group"
          onChange={handleOnChangeLabels}
        />
      </Box>

      {loading ? (
        <Loading />
      ) : (
        <Button
          className={css.ctnAdvanceSearch_actionButton}
          onClick={() => callUpdateHook()}
          disabled={!hasSomeFilter}
        >
          {t("advanced-search.group.forms.btn-search")}
        </Button>
      )}
    </div>
  );
};

export default AdvanceSearch;
