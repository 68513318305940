import React from "react";
import { Icon, IconTypes } from "@foris/avocado-icons";
import { Link, Text, Tooltip } from "@foris/avocado-suite";
import cx from "classnames";
import styles from "./itemInfoRow.module.scss";

interface ItemInfoRowProps {
  className?: string;
  columns?: {
    icon?: IconTypes;
    title: string;
    text?: string;
    link?: string;
    isTextTruncated?: boolean;
    isVertical?: boolean;
    customContent?: React.ReactNode;
  }[];
}

const ItemInfoRow = ({ columns = [], className = "" }: ItemInfoRowProps) => {
  if (!columns.length) return null;

  return (
    <div className={cx(styles.itemInfoRow, className)}>
      {columns?.map((col, key) => (
        <div key={key} className={styles.itemInfoRow_column}>
          {col?.icon && <Icon className={styles.columnIcon} name={col?.icon} size="md" />}

          <div className={cx(styles.columnText, col?.isVertical && styles.columnText__vertical)}>
            <Text weight="medium" className={styles.columnText_label}>
              {col?.title}
            </Text>

            {col?.customContent ? (
              col?.customContent
            ) : col?.link ? (
              <Tooltip label={col?.text} visible={!col?.isTextTruncated ? false : undefined}>
                <Link
                  className={cx(
                    styles.columnText_link,
                    col?.isTextTruncated && styles.columnText_link__truncated,
                  )}
                  href={col?.link}
                >
                  {col?.text ?? "-"}
                </Link>
              </Tooltip>
            ) : (
              <Tooltip label={col?.text} visible={!col?.isTextTruncated ? false : undefined}>
                <Text
                  className={cx(
                    styles.columnText_text,
                    col?.isTextTruncated && styles.columnText_text__truncated,
                  )}
                >
                  {col?.text ?? "-"}
                </Text>
              </Tooltip>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ItemInfoRow;
