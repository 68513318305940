import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Heading, Checkbox, Text, TextField } from "@foris/avocado-suite";
import css from "./capacity.module.scss";

export interface ICapacityValues {
  minCapacity: string;
  maxCapacity: string;
  overflow: boolean;
}

export interface ICapacityReturn {
  onCallback: (value: ICapacityValues, error?: boolean) => void;
  desactivePaddging?: boolean;
}

const CapacityInput: React.FC<ICapacityReturn> = ({ onCallback }) => {
  // States for validations
  const [error, setErrorCapacity] = useState(false);
  const [capacityCheck, setCapacityCheck] = useState(false);
  const [minCapacity, setMinCapacity] = useState("");
  const [maxCapacity, setMaxCapacity] = useState("");
  // Hooks
  const { t } = useTranslation();

  const handleError = (min: string, max: string) => {
    if (parseInt(min) > parseInt(max)) {
      setErrorCapacity(true);
      return true;
    } else {
      setErrorCapacity(false);
      return false;
    }
  };

  return (
    <>
      <Heading className={css.capacityTitle} type="h4">
        {t("advanced-search.classroom.forms.capacity.title")}
      </Heading>
      <div className={css.sharedCapacity}>
        <div className={css.sharedCapacity_content}>
          <TextField
            className={css.textField}
            type="number"
            placeholder={t("advanced-search.classroom.forms.capacity.input-min.placeholder")}
            value={minCapacity}
            onChange={e => {
              const value = e.target.value;
              if (parseInt(value) >= 0) {
                setMinCapacity(value);
                const error = handleError(value, maxCapacity);
                onCallback(
                  {
                    minCapacity: value,
                    maxCapacity: maxCapacity,
                    overflow: capacityCheck,
                  },
                  error,
                );
              }
            }}
          />

          <span className={css.separator}>-</span>

          <TextField
            className={css.textField}
            type="number"
            placeholder={t("advanced-search.classroom.forms.capacity.input-max.placeholder")}
            value={maxCapacity}
            onChange={e => {
              const value = e.target.value;
              if (parseInt(value) >= 0) {
                setMaxCapacity(value);
                const error = handleError(minCapacity, value);
                onCallback(
                  {
                    minCapacity: minCapacity,
                    maxCapacity: value,
                    overflow: capacityCheck,
                  },
                  error,
                );
              }
            }}
          />
        </div>

        <Checkbox
          className={css.sharedCapacity_checkbox}
          labelRight={t("advanced-search.classroom.forms.capacity.checkbox.label")}
          checked={capacityCheck}
          onChange={() => {
            setCapacityCheck(!capacityCheck);
            onCallback(
              {
                minCapacity: minCapacity,
                maxCapacity: maxCapacity,
                overflow: !capacityCheck,
              },
              error,
            );
          }}
        />

        {error && (
          <Text type="sm" className={css.sharedCapacity_error}>
            {t("advanced-search.classroom.forms.capacity.error-message")}
          </Text>
        )}
      </div>
    </>
  );
};

export default CapacityInput;
