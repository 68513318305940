import React from "react";
import { reject } from "lodash";
import { Table, Pager, CardNotification, Loading, Checkbox } from "@foris/avocado-suite";
import { Classroom } from "@models/ISchema";
import { Icon } from "@foris/avocado-icons";
import css from "./advanceTable.module.scss";
import { getColumnCell } from "@dimensions/utils/tableUtils";
import { useTranslation } from "react-i18next";

interface AdvanceTableProps {
  loading: boolean;
  data: Classroom[];
  size: number;
  total: number;
  page: number;
  selected: Classroom[];
  setSelected: (value: Classroom[]) => void;
  onPageChange: (page: number) => void;
}

const AdvanceTable: React.FC<AdvanceTableProps> = (props: AdvanceTableProps) => {
  const { loading, data, size, total, page, onPageChange, setSelected, selected } = props;
  const { t } = useTranslation();

  const renderCheck = (classroom: Classroom) => {
    const findSelected = selected?.find(value => value.id === classroom?.id);
    const onChange = () => {
      let cloneSelected = selected ? [...selected] : [];
      if (!findSelected) cloneSelected.push(classroom);
      if (findSelected) cloneSelected = reject(cloneSelected, value => value.id === classroom?.id);
      setSelected(cloneSelected);
    };

    return <Checkbox disabled={!classroom?.usable} checked={!!findSelected} onChange={onChange} />;
  };

  const columns = [
    {
      key: "id",
      header: "",
      accessor: "id",
      renderCell: (row: any) => renderCheck(row),
    },
    {
      key: "classroom",
      header: t("assignation-edit.advanced-search.classroom.table-columns.classroom"),
      renderCell: (row: any) => getColumnCell(`${row?.code} ${row?.name}`),
    },
    {
      key: "building",
      header: t("assignation-edit.advanced-search.classroom.table-columns.building-name"),
      renderCell: (row: any) => getColumnCell(row?.building?.name ?? "-"),
    },
    {
      key: "campus",
      header: t("assignation-edit.advanced-search.classroom.table-columns.campus"),
      renderCell: (row: any) => getColumnCell(row?.building?.campus?.name ?? "-"),
    },
    {
      key: "floor",
      header: t("assignation-edit.advanced-search.classroom.table-columns.floor"),
      renderCell: row => getColumnCell(row?.floor ?? "-"),
    },
    {
      key: "type",
      header: t("assignation-edit.advanced-search.classroom.table-columns.type"),
      renderCell: (row: any) => getColumnCell(row?.classroomType?.name ?? "-"),
    },
    {
      key: "capacity",
      header: t("assignation-edit.advanced-search.classroom.table-columns.capacity"),
      renderCell: (row: any) => getColumnCell(row?.capacity ?? "-"),
    },
    {
      key: "usable",
      header: t("assignation-edit.advanced-search.classroom.table-columns.usable"),
      renderCell: (row: any) => <Icon name={!!row?.usable ? "check" : "close"} />,
    },
    {
      key: "usagePercentage",
      header: t("assignation-edit.advanced-search.classroom.table-columns.usage"),
      renderCell: (row: any) =>
        getColumnCell(row?.assignment ? `${row?.assignment?.usagePercentage}%` : "-"),
    },
    {
      key: "chairsOccupationPercentage",
      header: t("assignation-edit.advanced-search.classroom.table-columns.ocupation"),
      renderCell: (row: any) =>
        getColumnCell(row?.assignment ? `${row?.assignment?.chairsOccupationPercentage}%` : "-"),
    },
  ];

  if (!data && loading) return <Loading />;

  if (total === 0)
    return (
      <section className={css.cardState}>
        <CardNotification
          state="info"
          title={t("assignation-edit.advanced-search.notifications.empty-results.title")}
          outlined
        >
          {t("assignation-edit.advanced-search.notifications.empty-results.classroom-message")}
        </CardNotification>
      </section>
    );

  if (data)
    return (
      <div className={css.advanceTable}>
        {loading && <Loading />}

        <Table columns={columns} data={data} />
        <Pager size={size} total={total} page={page} onChange={onPageChange} />
      </div>
    );

  return null;
};

export default AdvanceTable;
