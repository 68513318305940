import gql from "graphql-tag";

export const groupSearchMutation = gql`
  mutation groups($scenarioId: ID!, $originId: ID!, $filter: GroupFilterInput) {
    filter(scenarioId: $scenarioId, originId: $originId) {
      createGroupFilter(filter: $filter) {
        id
        groups {
          items {
            id
          }
        }
      }
    }
  }
`;
