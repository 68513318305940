import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Pill, Loading } from "@foris/avocado-suite";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useApolloClient } from "react-apollo";
import Layout from "../../../../common/layout/Layout";
import { EmptyResults, Header } from "../../../../common/components";
import SectionsContainer from "../../../sections/Sections";
import { ISectionData } from "../../../sections/ISections";
import { functionsData } from "../../../sections/utils/";
import { CubeQuery, Classroom, EditorInfoUnion } from "../../../../models/ISchema";
import { IParams } from "../../../../models/IParams";
import { classroomSection } from "../graphql/classroomSection.query";
import { Dimension } from "@models/IDimension";
import useGetDimension from "@dimensions/hooks/useGetDimension";

const ClassRoomCalendar: React.FC = () => {
  const client = useApolloClient();
  const { id, origin, scenario, workspace }: IParams = useParams();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const [calendarData, setCalendar] = useState(null);
  const [ogHeaderData, setOgHeaderData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [dimension] = useGetDimension(Dimension.classroom);

  const variables = {
    query: classroomSection,
    variables: {
      resourceId: id,
      scenarioId: scenario,
      originId: origin,
      resourceType: "CLASSROOM",
    },
  };

  const headerInfo = (infoRoom: Classroom) => {
    const details = [
      {
        title: t("dimension.classroom.header.more-details.campus"),
        content: `${infoRoom?.building?.campus?.code} - ${infoRoom?.building?.campus?.name}`,
      },

      {
        title: t("dimension.classroom.header.more-details.building"),
        content: infoRoom?.building?.name || "-",
      },

      {
        title: t("dimension.classroom.header.more-details.room-code"),
        content: infoRoom?.building?.code || "-",
      },

      {
        title: t("dimension.classroom.header.more-details.floor"),
        content: infoRoom?.floor ?? "-",
      },

      {
        title: t("dimension.classroom.header.more-details.capacity"),
        content: infoRoom?.capacity ?? "-",
      },

      {
        title: t("dimension.classroom.header.more-details.overload"),
        content: infoRoom?.allowedOverflow ?? "-",
      },

      {
        title: t("dimension.classroom.header.more-details.type"),
        content: infoRoom?.classroomType?.name || "-",
      },

      {
        title: t("dimension.classroom.header.more-details.usable.title"),
        content: infoRoom?.usable
          ? t("dimension.classroom.header.more-details.usable.yes")
          : t("dimension.classroom.header.more-details.usable.no"),
      },
    ];

    const subTitle = (infoRoom: Classroom) => (
      <>
        <Pill textColor="gray-10" color="gray-70">
          {t("dimension.classroom.header.more-details.campus")}:{" "}
          {infoRoom?.building?.campus?.code || "-"}
        </Pill>
        <Pill textColor="gray-10" color="gray-70">
          {t("dimension.classroom.header.more-details.building")}: {infoRoom?.building?.code || "-"}
        </Pill>
      </>
    );

    return {
      title: `${infoRoom?.name || "-"} (${infoRoom?.code || "-"})`,
      topTitle: infoRoom?.building?.name || "",
      subTitle: subTitle(infoRoom),
      moreDetails: details,
    };
  };

  const headerData = useMemo(() => {
    if (ogHeaderData) {
      return headerInfo(ogHeaderData);
    }
    return {};
  }, [ogHeaderData, language]);

  const getDataSections = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await client.query(variables);
      if (data) {
        const cubeQuery: CubeQuery = data.cube;
        const info: EditorInfoUnion = cubeQuery.editorView.info;
        if (info.__typename === "ClassroomInfo") {
          const classroomInfo: Classroom = info?.classroom;
          if (classroomInfo) {
            setOgHeaderData(classroomInfo);
            const sections: ISectionData = functionsData.serializeSections(data);
            setCalendar(sections);
          } else {
            setError(true);
          }
        }
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setError(true);
      setLoading(false);
    }
  }, [client, variables]);

  const setDataCalendar = (data: ISectionData, callback: () => void) => {
    setCalendar(data);
    callback && callback();
  };

  useEffect(() => {
    if (calendarData === null && error === false) getDataSections();
  }, [calendarData, error, getDataSections]);

  return (
    <Layout contextSearch>
      {headerData && (
        <Header
          title={t("dimension.classroom.title")}
          dimension={dimension.label}
          breadcrumbItems={[
            {
              title: t("dimension.classroom.breadcrumb.previous"),
              path: "/",
            },
            {
              title: t("dimension.classroom.breadcrumb.current"),
            },
          ]}
          {...headerData}
        />
      )}
      {loading && <Loading />}
      {error && <EmptyResults dimension={t("dimension.classroom.title")} />}
      {calendarData && (
        <SectionsContainer
          config={{
            scenarioId: parseInt(scenario),
            originId: parseInt(origin),
            workspaceId: parseInt(workspace),
          }}
          data={calendarData}
          setDataGrid={setDataCalendar}
        />
      )}
    </Layout>
  );
};

export default ClassRoomCalendar;
