import gql from "graphql-tag";

export const courseSearchMutation = gql`
  mutation filter(
    $scenarioId: ID!
    $originId: ID!
    $filter: CourseFilterInput!
    $pagination: PaginationInput
  ) {
    filter(scenarioId: $scenarioId, originId: $originId) {
      createCourseFilter(filter: $filter) {
        id
        courses(pagination: $pagination) {
          items {
            id
            code
            name
          }
        }
      }
    }
  }
`;
