import React, { useState } from "react";
import cx from "classnames";
import { Text } from "@foris/avocado-suite";
import { useTranslation } from "react-i18next";
import Cleave from "cleave.js/react";
import css from "./timeRange.module.scss";

export interface ITimeRangeValues {
  minTime: string;
  maxTime: string;
}

export interface ITimeRangeReturn {
  required: boolean;
  onCallback: (value: ITimeRangeValues, error?: boolean) => void;
}

const TimeRangeInput: React.FC<ITimeRangeReturn> = ({ required, onCallback }) => {
  // States for handle the time range
  const [minTime, setMinTime] = useState("");
  const [maxTime, setmaxTime] = useState("");
  const [error, setErrorCapacity] = useState(false);
  const [errorText, setErrorText] = useState("");

  // Hooks
  const { t } = useTranslation();

  const validateMin = (min: string, max: string) => {
    min = min.replace(":", "");
    max = max.replace(":", "");
    return parseInt(min) > parseInt(max);
  };

  const formatValue = (value: any) => {
    const re = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
    return value !== "" && !value.match(re);
  };

  const validateInput = (value: string, min: string, max: string) => {
    const isWrongFormat = formatValue(value);
    const isWrongMin = validateMin(min, max);

    const hasError = isWrongFormat || isWrongMin;
    const errorMessage = isWrongFormat
      ? t("advanced-search.classroom.forms.time-range.wrong-format.option-1")
      : t("advanced-search.classroom.forms.time-range.wrong-format.option-2");

    setErrorCapacity(hasError);
    setErrorText(hasError ? errorMessage : "");

    return hasError;
  };

  const styleError = (required && !error) || error;

  return (
    <div className={css.timeRange}>
      <div className={css.timeRange_fields}>
        <div className={css.fieldControl}>
          <Text type="sm" className={css.fieldControl_label}>
            {t("advanced-search.classroom.forms.time-range.start.label")}
          </Text>

          <Cleave
            options={{ time: true, timePattern: ["h", "m"], type: "text" }}
            className={cx(css.fieldControl_input, styleError && css.fieldControl_input__error)}
            placeholder={t("advanced-search.classroom.forms.time-range.start.placeholder")}
            onChange={(value: any) => {
              const newValue = value.target.value;
              setMinTime(newValue);
              const error = validateInput(newValue, newValue, maxTime);
              if (newValue) {
                onCallback(
                  {
                    minTime: newValue,
                    maxTime: maxTime,
                  },
                  error,
                );
              } else {
                onCallback(
                  {
                    minTime: "",
                    maxTime: maxTime,
                  },
                  error,
                );
              }
            }}
          />
        </div>

        <span className={css.fieldSeparator}>-</span>

        <div className={css.fieldControl}>
          <Text type="sm" className={css.fieldControl_label}>
            {t("advanced-search.classroom.forms.time-range.end.label")}
          </Text>

          <Cleave
            options={{ time: true, timePattern: ["h", "m"], type: "text" }}
            className={cx(css.fieldControl_input, styleError && css.fieldControl_input__error)}
            placeholder={t("advanced-search.classroom.forms.time-range.end.placeholder")}
            onChange={(value: any) => {
              const newValue = value.target.value;
              setmaxTime(newValue);
              const error = validateInput(newValue, minTime, newValue);
              if (newValue) {
                onCallback(
                  {
                    minTime: minTime,
                    maxTime: newValue,
                  },
                  error,
                );
              } else {
                onCallback(
                  {
                    minTime: minTime,
                    maxTime: "",
                  },
                  error,
                );
              }
            }}
          />
        </div>
      </div>

      {error && (
        <Text type="sm" className={css.timeRange_error}>
          {errorText}
        </Text>
      )}

      {required && !error && (
        <Text type="sm" className={css.timeRange_error}>
          {t("advanced-search.classroom.forms.time-range.error-message")}
        </Text>
      )}
    </div>
  );
};

export default TimeRangeInput;
