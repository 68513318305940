import React from "react";
import cx from "classnames";
import { Icon } from "@foris/avocado-icons";
import styles from "./usableLabel.module.scss";

interface UsableLabelProps {
  label: string;
  isUsable?: boolean;
}

const UsableLabel = ({ label, isUsable = false }: UsableLabelProps) => {
  return (
    <div className={cx(styles.usableLabel, !isUsable && styles.usableLabel__notUsable)}>
      <p className={styles.usableLabel_text}>{label}</p>

      {!isUsable && (
        <span className={styles.usableLabel_status}>
          No usable <Icon name="circle-denied" size="sm" color="neutral-30" />
        </span>
      )}
    </div>
  );
};

export default UsableLabel;
