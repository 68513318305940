import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Text } from "@foris/avocado-suite";
import Modal from "@modules/sections/GroupsManager/components/Modal/Modal";

interface EmptyResultsModalProps {
  onClose: any;
  show: boolean;
}

const EmptyResultsModal: FC<EmptyResultsModalProps> = ({ onClose, show }) => {
  const { t } = useTranslation();
  return (
    <Modal
      onClose={() => onClose()}
      show={show}
      title={t("advanced-search.no-results.title")}
      typeState="confirm"
      textButtonPrincipal={t("advanced-search.no-results.btn-accept")}
      onClickPrincipal={onClose}
    >
      <Text type="md">{t("advanced-search.no-results.description")}</Text>
    </Modal>
  );
};

export default EmptyResultsModal;
