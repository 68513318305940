import React, { useEffect, useState, useContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useApolloClient } from "react-apollo";
import cx from "classnames";
import { AppContext } from "../../../context/EditSessionsContext";
import { Types } from "../../../context/switchPage.reducer";
import { Types as FormDataTypes } from "../../../context/formData.reducer";
import { IParams } from "@models/IParams";
import { Classroom, CubeQuery } from "@models/ISchema";
import FormItem from "../../../components/FormEdit/FormItem";
import Autocomplete from "../../../components/Classroom/Autocomplete";
import { useDebounce } from "../../../hooks/useDebounce";
import { classroomQuery } from "../../../graphql/classroom.query";
import UsableLabel from "@common/components/Autocomplete/usabe-label";
import SimpleResourceList from "@modules/sections/EditSessions/components/simple-resource-list";
import styles from "./classroomSection.module.scss";
import { Link } from "@foris/avocado-suite";
import { useTranslation } from "react-i18next";

interface ClassroomSectionProps {
  disabled?: boolean;
}

interface ClassroomOption extends Classroom {
  label: string;
}

const getFormattedLabel = (option: ClassroomOption) => {
  return (
    <UsableLabel
      label={`${option.code} - ${option.name} - ${option?.building?.campus?.code || ""}`}
      isUsable={option?.usable}
    />
  );
};

const ClassroomSection = ({ disabled = false }: ClassroomSectionProps) => {
  const client = useApolloClient();
  const { state, dispatch } = useContext(AppContext);
  const { scenario, origin }: IParams = useParams();
  const [valueSearch, setValueSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const debouncedSearch = useDebounce(valueSearch, 500);

  const { t } = useTranslation();

  const someDeletedSessionIsSelected = useMemo(() => {
    return state?.form?.selectedSessions?.some(session =>
      state?.form?.sessionsToDelete?.hasOwnProperty(session?.id),
    );
  }, [state?.form?.selectedSessions, state?.form?.sessionsToDelete]);

  const adaptedClassrooms = useMemo(() => {
    return state?.form?.editedSessions?.classrooms?.map(classroom => ({
      id: classroom?.code,
      label: classroom?.name,
      rawData: classroom,
    }));
  }, [state?.form?.editedSessions?.classrooms]);

  const serializeOptions = (classrooms: Classroom[]) => {
    const classroomOptions = [];

    if (classrooms.length === 0) return classroomOptions;

    classrooms.forEach(classroom => {
      classroomOptions.push({
        ...classroom,
        label: `${classroom.code} - ${classroom.name} - ${classroom?.building?.campus?.code || ""}`,
        value: classroom.id,
        isDisabled: !classroom?.usable,
      });
    });
    return classroomOptions;
  };

  const queryOptions = (value: string) => {
    return {
      query: classroomQuery,
      variables: {
        originId: origin,
        scenarioId: scenario,
        filter: {
          page: 1,
          size: 20,
          searchTerm: value,
        },
      },
    };
  };

  const getDataOptions = async (value: string) => {
    try {
      setLoading(true);
      if (value !== "") {
        const query = queryOptions(value);
        const { data } = await client.query(query);
        const dataQuery: CubeQuery = data?.cube;
        const insOptions = serializeOptions(dataQuery?.classrooms?.items);
        setOptions(insOptions);
        setLoading(false);
      }
    } catch (error) {
      setOptions([]);
      setLoading(false);
    }
  };

  const handleDeleteClassroom = (classroom: Classroom) => {
    const classroomList = [...state?.form?.editedSessions?.classrooms];
    const indexInstructor = classroomList.findIndex(item => item.id === classroom?.id);
    classroomList.splice(indexInstructor, 1);

    dispatch({
      type: FormDataTypes.ClassroomEditedSessions,
      payload: {
        classrooms: classroomList,
      },
    });
  };

  useEffect(() => {
    if (debouncedSearch) {
      setLoading(true);
      getDataOptions(debouncedSearch);
    } else {
      setOptions([]);
    }
  }, [debouncedSearch]);

  return (
    <FormItem
      title={t("assignation-edit.form.classroom.title")}
      type="classrooms"
      isDisabled={disabled}
    >
      <div className={styles.classrooms}>
        <div
          className={cx(
            styles.classrooms_fields,
            !adaptedClassrooms?.length && styles.classrooms_fields__noSpacing,
          )}
        >
          <Autocomplete
            disabled={someDeletedSessionIsSelected || disabled}
            loading={loading}
            options={options}
            className={cx(styles.item_select)}
            formatOptionLabel={getFormattedLabel}
            setValueSearch={value => setValueSearch(value)}
          />

          <Link
            className={styles.fieldLink}
            iconRight="external-link"
            size="sm"
            disabled={someDeletedSessionIsSelected || disabled}
            onClick={() => {
              dispatch({
                type: Types.AdvanceSearchClassroom,
                payload: true,
              });
            }}
          >
            {t("assignation-edit.form.classroom.advanced-search")}
          </Link>
        </div>

        <SimpleResourceList
          items={adaptedClassrooms}
          isDisabled={someDeletedSessionIsSelected || disabled}
          onDeleteResource={handleDeleteClassroom}
        />
      </div>
    </FormItem>
  );
};

export default ClassroomSection;
