import React, { useContext } from "react";
import cx from "classnames";
import { Button, Card, DataTable, Icon, IconType, Pager } from "@foris/avocado-ui";
import { IDataTableContentColumn } from "@foris/avocado-ui/lib/types/components/DataTable/components/DataTableContent/DataTableContent";
import { ContextEDH } from "@context/ContextEDH";
import { GroupChange, PageInfo } from "@models/ISchema";
import * as mouseflow from "@utils/mouseflow";
import buildReport from "../../utils/reports";
import css from "./changeRequestsTable.module.scss";

interface Props {
  groupId: string;
  scenarioId: string;
  origin: string;
  groupChanges: GroupChange[];
  pageInfo: PageInfo;
  isReferent?: boolean;
  clientCode?: string;
  requestGroupCapacityChangeDetails: (page: number) => void;
}

const ROWS_PER_PAGE = 25;

const ChangeRequestsTable: React.FC<Props> = ({
  groupId,
  scenarioId,
  origin,
  groupChanges,
  pageInfo,
  isReferent,
  clientCode,
  requestGroupCapacityChangeDetails,
}) => {
  const { state: outerState } = useContext(ContextEDH);

  const columns: IDataTableContentColumn[] = [
    {
      header: "Fecha de cambio",
      accessor: "createdAt",
      renderCell: (groupChange: GroupChange) => {
        return <span>{groupChange?.createdAt}</span>;
      },
      responsive: {
        allDevices: {
          styles: {
            width: "15%",
            textAlign: "left",
          },
        },
      },
    },
    {
      header: "Grupo",
      accessor: "group",
      renderCell: (groupChange: GroupChange) => {
        return groupChange?.group?.code ?? "-";
      },
      responsive: {
        allDevices: {
          styles: {
            width: "15%",
            textAlign: "left",
          },
        },
      },
    },
    {
      header: "Tipo de cambio",
      accessor: "propertyUpdated",
      renderCell: (groupChange: GroupChange) => {
        const prop = groupChange?.propertyUpdated === "capacity" ? "Cupos" : "Visibilidad";
        return <span>{prop}</span>;
      },
      responsive: {
        allDevices: {
          styles: {
            width: "15%",
            textAlign: "left",
          },
        },
      },
    },
    {
      header: "Inicial | solicitado",
      accessor: "",
      renderCell: (groupChange: GroupChange) => {
        const getCapacityIcon = (before: string, after: string): IconType => {
          if (before < after) return "chevron-up";
          if (before === after) return "minus";
          else return "chevron-down";
        };

        const current = groupChange?.current;
        const expected = groupChange?.expected;

        if (groupChange?.propertyUpdated === "capacity") {
          return (
            <span className={css.state}>
              {current} | {expected}
              <Icon icon={getCapacityIcon(current, expected)} />
            </span>
          );
        }

        return (
          <span className={css.state}>
            <Icon icon={current === "0" ? "closed-eye" : "open-eye"} />
            |
            <Icon icon={expected === "0" ? "closed-eye" : "open-eye"} />
          </span>
        );
      },
      responsive: {
        allDevices: {
          styles: {
            width: "15%",
            textAlign: "left",
          },
        },
      },
    },
    {
      header: "Realizado por",
      accessor: "",
      renderCell: (groupChange: GroupChange) => {
        return <span>{groupChange?.username ?? ""}</span>;
      },
      responsive: {
        allDevices: {
          styles: {
            width: "55%",
            textAlign: "left",
          },
        },
      },
    },
    {
      header: "Estado de solicitud",
      accessor: "",
      renderCell: (groupChange: GroupChange) => {
        const translation =
          {
            SUCCESS: "Guardado",
            FAILED: "Fallado",
            PENDING: "Pendiente",
          }[groupChange?.status] ?? "-";
        return <span>{translation}</span>;
      },
      responsive: {
        allDevices: {
          styles: {
            width: "15%",
            textAlign: "left",
          },
        },
      },
    },
  ];

  const displayTable = () => Boolean(groupChanges?.length);

  return (
    <section className={css.table}>
      <Card.Simple className={cx("container-row", css.tableCard)}>
        <Card.Content className="container-row">
          <header className={css.tableCard__header}>
            <div className={css.tableCard__header__left}>
              <label className={css.tableCard__title}>Historial de cambios</label>
              <Button
                className={css.tableCard__header__button}
                onClick={() => {
                  const reportLink = buildReport("changeHistory", {
                    groupId,
                    isReferent,
                    clientCode,
                    context: { origin, scenario: scenarioId },
                  });
                  mouseflow.actionTag(
                    "action_groups_manager_change_history_report",
                    outerState?.base?.isMouseflowEnabled,
                  );
                  window.open(reportLink, "_blank");
                }}
                variant="ghost"
              >
                <Icon className={css.tableCard__icon} icon="edit" />
                <span>Descargar reporte</span>
              </Button>
            </div>
          </header>

          {displayTable() ? (
            <section className={cx("col_12", "row--center", css.tableCard_pager)}>
              <Pager
                page={pageInfo?.page ?? 0}
                size={ROWS_PER_PAGE}
                total={pageInfo?.total ?? 0}
                onChange={requestGroupCapacityChangeDetails}
              />
            </section>
          ) : (
            <></>
          )}

          {displayTable() ? (
            <section className="col_12">
              <DataTable.Content columns={columns} data={groupChanges} />
            </section>
          ) : (
            <></>
          )}

          {displayTable() ? (
            <section className={cx("col_12", "row--center", css.tableCard_pager)}>
              <Pager
                page={pageInfo?.page ?? 0}
                size={ROWS_PER_PAGE}
                total={pageInfo?.total ?? 0}
                onChange={requestGroupCapacityChangeDetails}
              />
            </section>
          ) : (
            <></>
          )}
        </Card.Content>
      </Card.Simple>
    </section>
  );
};

export default ChangeRequestsTable;
