import { useState } from "react";
import { useMutation } from "react-apollo";
import { Group } from "@models/ISchema";
import { UPDATE_GROUP_IS_ACTIVE } from "./../graphql/updateGroupIsActive.mutation";
import useContextUrl from "@common/hooks/useContextUrl";

interface useUpdateGroupIsActiveProps {
  onSuccess?: (group: Group) => void;
  onValidationArray?: (array: any[]) => void;
}

type ReturnType = [{ isLoading: boolean }, (groupId: string, isActive: boolean) => void];

const useUpdateGroupIsActive = (props?: useUpdateGroupIsActiveProps): ReturnType => {
  const { scenario, origin } = useContextUrl();
  const { onSuccess, onValidationArray } = props ?? {};

  const [isLoading, setIsLoading] = useState(false);
  const [updateGroupIsActiveMutation] = useMutation(UPDATE_GROUP_IS_ACTIVE, {});

  const updateGroupIsActive = async (groupId: string, isActive: boolean): Promise<boolean> => {
    try {
      setIsLoading(true);

      const { data } = await updateGroupIsActiveMutation({
        variables: {
          originId: origin,
          scenarioId: scenario,
          groupId,
          isActive,
        },
      });

      if (data?.cube?.updateGroupIsActive?.commited) {
        onSuccess?.(data?.cube?.updateGroupIsActive?.group);
      }

      const validationErrors = data?.cube?.updateGroupIsActive?.validationErrors;
      if (validationErrors?.length) {
        onValidationArray?.(validationErrors);
      }

      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.error(e);
      return false;
    }
  };

  return [{ isLoading }, updateGroupIsActive];
};

export default useUpdateGroupIsActive;
