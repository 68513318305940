import React, { useMemo } from "react";
import { Heading, Link, Pill, Table } from "@foris/avocado-suite";
import styles from "./linkComponentsTable.module.scss";
import { ILink, ISection } from "@modules/vacancies/contextVacancies/ContextVacancies";
import useContextUrl from "@common/hooks/useContextUrl";
import { Color } from "@foris/avocado-icons";
import { useTranslation } from "react-i18next";

interface LinkComponentsTableProps {
  sections?: ISection[];
  showTitle?: boolean;
}

const compareSections = (a: ISection, b: ISection) =>
  a.typeComponent.localeCompare(b.typeComponent);

const compareLinks = (a: ILink, b: ILink) => a.codeName.localeCompare(b.codeName);

const LinkComponentsTable = ({ sections = [], showTitle = true }: LinkComponentsTableProps) => {
  const { workspace, origin, scenario } = useContextUrl();
  const { t } = useTranslation();

  const sortedSections = useMemo(() => sections.sort(compareSections), [sections]);

  return (
    <div className={styles.linkComponentsTable}>
      {showTitle && (
        <Heading className={styles.linkComponentsTable_title} type="h4">
          {t("bundle.link-card.section-table.title")}
        </Heading>
      )}

      <Table
        className={styles.linkComponentsTable_table}
        data={sortedSections}
        columns={[
          {
            key: "component",
            header: t("bundle.link-card.section-table.header.component"),
            accesor: "typeComponent",
          },
          {
            key: "section",
            header: t("bundle.link-card.section-table.header.section"),
            renderCell: (data: any) => (
              <Link
                href={`/scheduler/editor/section/${workspace}/${scenario}/${origin}/${data.sectionId}`}
                colored
              >
                {data.sectionId}
              </Link>
            ),
          },
          {
            key: "vacancies",
            header: t("bundle.link-card.section-table.header.vacancies"),
            accesor: "vacancies",
          },
          {
            key: "shared",
            header: t("bundle.link-card.section-table.header.shared"),
            renderCell: (data: any) =>
              data?.link?.sort(compareLinks)?.map((bundle: ILink) => {
                const colorName = bundle.color
                  .split("_")
                  .slice(1)
                  .join("-");

                return (
                  <Pill
                    className={styles.sharedLinkPill}
                    key={bundle.code}
                    color={`${colorName}-50-20` as Color}
                    textColor={`${colorName}-80` as Color}
                  >
                    {t("dimension.link.title")} {bundle.codeName}
                  </Pill>
                );
              }),
          },
        ]}
      />
    </div>
  );
};

export default LinkComponentsTable;
