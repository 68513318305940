import React, { ReactElement } from "react";
import { Tooltip } from "@foris/avocado-suite";
import css from "./groupComponentInputs.module.scss";

interface ComponentInputWrapperProps {
  isDisabled: boolean;
  children: ReactElement;
}

const ComponentInputWrapper = ({ isDisabled, children }: ComponentInputWrapperProps) => {
  return isDisabled ? (
    <Tooltip
      className={css.groupComponentInputs_tooltip}
      label={
        (
          <span className={css.tooltipLabel}>
            Sección compartida. <br /> Código asignado por sistema
          </span>
        ) as any
      }
      placement="topLeft"
    >
      {children}
    </Tooltip>
  ) : (
    <>{children}</>
  );
};
export default ComponentInputWrapper;
