import React, { useMemo } from "react";
import { Accordion, Button } from "@foris/avocado-suite";
import { Section, Session } from "@models/ISchema";
import { Week } from "@modules/sections/ISections";
import { EditedSession } from "../../context/formData.reducer";
import SessionCard from "../session-card";
import styles from "./sessionsAccordion.module.scss";
import { useTranslation } from "react-i18next";

interface SessionsAccordionProps {
  section: Section;
  colorIndex?: number;
  selectedSessions?: (Session | EditedSession)[];
  savedSessions?: EditedSession[];
  newSessions?: EditedSession[];
  mockSessionsToCreate?: EditedSession[];
  linkWeeks?: Week[];
  sessionsToDelete?: { [key: string]: EditedSession };
  canCreateSessions?: boolean;
  isCreatingSession?: boolean;
  onSessionClick?: (session: EditedSession, type: "new" | "cloned" | "saved") => void;
  onCreateSession?: () => void;
  onChangeSelectAll?: (
    sessions: Session[],
    section: Section,
    newSessions: EditedSession[],
    checked: boolean,
  ) => void;
}

/**
 * Formats the sessions by merging saved sessions with the current sessions.
 *
 * @param savedSessionsById - An object where the key is the session ID and the value is the edited session.
 * @param sessions - An array of session objects to be formatted.
 * @returns An array of formatted session objects. If a session exists in savedSessionsById, it will be used; otherwise, the original session will be used.
 */
const formatSessions = (savedSessionsById: { [key: string]: EditedSession }, sessions: Session[]) =>
  sessions.map(session =>
    savedSessionsById?.[session?.id]
      ? savedSessionsById[session?.id]
      : { ...session, id: session.id, session },
  );

const getSessionsById = (sessions: (Session | EditedSession)[]) => {
  const formattedSessions: { [key: string]: Session | EditedSession } = {};

  sessions.forEach(session => {
    formattedSessions[session.id] = session;
  });

  return formattedSessions;
};

const SessionsAccordion = ({
  section,
  colorIndex,
  savedSessions = [],
  newSessions = [],
  selectedSessions = [],
  mockSessionsToCreate = [],
  linkWeeks = [],
  sessionsToDelete = {},
  canCreateSessions,
  isCreatingSession,
  onSessionClick,
  onCreateSession,
  onChangeSelectAll,
}: SessionsAccordionProps) => {
  const savedSessionsById = useMemo(() => getSessionsById(savedSessions), [savedSessions]);
  const selectedSessionsById = useMemo(() => getSessionsById(selectedSessions), [selectedSessions]);

  const { t } = useTranslation();

  const sessions = useMemo(() => {
    return formatSessions(savedSessionsById, [
      ...(section?.sessions ?? []),
      ...(section?.unasignedSessions ?? []),
    ]);
  }, [section, savedSessionsById]);

  const handleChangeSelectAll = (checked: boolean) => {
    const sessions = [...section.sessions, ...section.unasignedSessions];
    const savedNewSessions = [...savedSessions]?.filter(
      value => value?.section?.id === section.id && (value?.isNew || value?.isCloned),
    );

    onChangeSelectAll?.(sessions, section, savedNewSessions, !checked);
  };

  return (
    <Accordion
      className={styles.sessionsAccordion}
      title={`${section?.component?.code} ${section?.code}`}
      hasCheckbox
      opened
      onChanceCheckbox={e => handleChangeSelectAll(e.target.checked)}
    >
      <div className={styles.sessionsAccordion_content}>
        {sessions.map(session => (
          <SessionCard
            key={session.id}
            colorIndex={colorIndex}
            session={session}
            linkWeeks={linkWeeks}
            isSelected={!!selectedSessionsById?.[session?.id]}
            isUpdated={!!savedSessionsById?.[session?.id]}
            isDeleted={!!sessionsToDelete?.[session?.id]}
            onClick={session => onSessionClick?.(session, "saved")}
          />
        ))}

        {newSessions.map(session => (
          <SessionCard
            key={`new-${section?.id}-${session?.id}`}
            colorIndex={colorIndex}
            session={session}
            isSelected={!!selectedSessionsById?.[session?.id]}
            onClick={session => onSessionClick?.(session, "new")}
          />
        ))}

        {mockSessionsToCreate.map(session => (
          <SessionCard
            key={`mock-${section?.id}-${session?.id}`}
            colorIndex={colorIndex}
            session={session}
            isSelected={true}
            isMock
          />
        ))}

        {canCreateSessions && (
          <Button
            className={styles.addBtn}
            size="sm"
            variant="ghost"
            disabled={isCreatingSession}
            onClick={onCreateSession}
          >
            {t("assignation-edit.sessions-list.create-session")}
          </Button>
        )}
      </div>
    </Accordion>
  );
};

export default SessionsAccordion;
