import React from "react";
import { useTranslation } from "react-i18next";
import DimensionsSearchResults from "@dimensions/common/dimensions-search-results";
import useGetTitle from "@dimensions/hooks/useGetTitle";
import { Population } from "@models/ISchema";
import { getColumnCell, getHeadCell, getLinkCell } from "@dimensions/utils/tableUtils";
import { ISearchProps } from "@dimensions/common/ISearch";
import { DimensionNameEs } from "@models/IDimension";
import useNavigationUrl from "@common/hooks/useNavigationUrl";

/**
 * <PopulationTable> component that displays a table of populations with relevant information.
 *
 * This component fetches populations data and renders it in a table format, displaying information
 * such as populations code, period, campus, level, etc.
 * It integrates search, and internationalization (i18n) for multi-language support.
 *
 * @param {DimensionSearchResultsProps} props - The properties passed to the component.
 * @param {Array<Population>} props.data - Data of populations to display in the table.
 * @param {string} props.search - The search query to filter results.
 * @param {Object} props.pageInfo - Pagination information (total results, current page, etc.).
 * @param {Function} props.setPage - Function to change the current page.
 * @param {boolean} props.loading - Boolean indicating if the data is still loading.
 * @param {boolean} props.error - Boolean indicating if there was an error during data fetch.
 * @returns {JSX.Element} - The rendered PopulationTable component.
 *
 * @example
 * <PopulationTable
 *   data={populationsData}
 *   search="Math"
 *   pageInfo={pageInfo}
 *   setPage={setPage}
 *   loading={isLoading}
 *   error={hasError}
 * />
 */

const PopulationTable = (props: ISearchProps) => {
  const { data, search, pageInfo, setPage, loading, error, onChangeSizePerPage } = props;

  // Get the dynamic title for the search results using the custom hook `useGetTitle`
  const title = useGetTitle(search, pageInfo?.total || 0, "población");

  // Get the context URL for advanced search functionality
  const { advancedSearchUrl, editorUrl } = useNavigationUrl();
  const getEditorUrl = (id: string) => editorUrl("population", id);

  // Use the i18n hook to get translation functions
  const { t } = useTranslation();

  // Get the total number of unique subjects
  const getSubjectsTotal = ({ courses }: Population) => {
    const uniqueCourses = new Set(courses?.map(course => course?.id)).size;
    return getColumnCell(uniqueCourses);
  };

  // Define the columns for the table, including the header and cell rendering logic
  const columns = [
    {
      header: getHeadCell(t("search-results.dimension.population.table.header.code")),
      renderCell: ({ id }: Population) => getLinkCell(getEditorUrl(id), null, id),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "170px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.population.table.header.period")),
      renderCell: ({ term }: Population) => getColumnCell(term?.code),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "90px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.population.table.header.campus")),
      renderCell: ({ campus }: Population) => getColumnCell(campus?.code),
      desktopOptions: {
        headCellStyles: { width: "80px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.population.table.header.modality")),
      renderCell: ({ modality }: Population) => getColumnCell(modality?.code),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "110px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.population.table.header.shift")),
      renderCell: ({ shift }: Population) => getColumnCell(shift?.code),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "110px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.population.table.header.career")),
      renderCell: ({ program }: Population) => getColumnCell(`${program?.code} ${program?.name}`),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "270px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.population.table.header.curriculum")),
      renderCell: ({ curriculum }: Population) => getColumnCell(curriculum),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "110px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.population.table.header.level")),
      renderCell: ({ level }: Population) => getColumnCell(level),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "90px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.population.table.header.packages")),
      renderCell: ({ packageCount }: Population) => getColumnCell(packageCount),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "110px" },
      },
    },
    {
      header: getHeadCell(t("search-results.dimension.population.table.header.subjects")),
      renderCell: getSubjectsTotal,
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "124px" },
      },
    },

    {
      header: getHeadCell(t("search-results.dimension.population.table.header.vacancies")),
      renderCell: ({ size }: Population) => getColumnCell(size),
      desktopOptions: {
        headCellStyles: { minWidth: "80px", width: "110px" },
      },
    },
  ];

  // Render the DimensionsSearchResults component with the table data and configurations
  return (
    <DimensionsSearchResults
      onChangeSizePerPage={onChangeSizePerPage}
      title={title.getTitle}
      columns={columns}
      data={data}
      pageInfo={pageInfo}
      setPage={setPage}
      loading={loading}
      error={error}
      dimension={t("dimension.population.title") as DimensionNameEs}
      advanceLink={advancedSearchUrl("population")}
    />
  );
};

export default PopulationTable;
