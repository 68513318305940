import React, { useMemo, useState } from "react";
import { useMutation } from "react-apollo";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Loading, Box, Button, Divider } from "@foris/avocado-suite";
import useContextUrl from "@common/hooks/useContextUrl";
import useNavigationUrl from "@common/hooks/useNavigationUrl";
import EmptyResultsModal from "@modules/shared/empty-results-modal";
import { PackageFilterInput } from "@models/ISchema";
import { packageSearchMutation } from "../graphql/advanceSearch.queries";
import AdvancedSelectorsWrapper from "@modules/advance-search/components/advanced-selectors-wrapper";
import AdvancedLabelsSelectors from "@modules/advance-search/components/advanced-labels-selectors";
import css from "./advanceSearch.module.scss";

const AdvanceSearch: React.FC = () => {
  // State for handle the empty result
  const [error, setError] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedLabelCategory, setSelectedLabelCategory] = useState<string | null>(null);
  const [selectedLabels, setSelectedLabels] = useState<string[]>([]);
  const [selectorsState, setSelectorsState] = useState({
    campus: null,
    department: null,
    modality: null,
    shift: null,
    program: null,
    curriculum: null,
    level: null,
  });
  // Hooks for translation and navigation
  const { t } = useTranslation();
  const history = useHistory();
  const [advanceSearchMutation] = useMutation(packageSearchMutation, {});
  const { origin, scenario } = useContextUrl();
  const { advancedSearchResultsUrl } = useNavigationUrl();

  const handleOnChangeLabels = (payload: { categoryId?: string | null; labelsIds: string[] }) => {
    setSelectedLabelCategory(payload.categoryId);
    setSelectedLabels(payload.labelsIds);
  };

  const labelsFilters = useMemo(() => {
    if (!selectedLabels?.length) {
      return selectedLabelCategory
        ? [
            {
              label: {
                labelCategoryId: { eq: selectedLabelCategory },
              },
            },
          ]
        : [];
    }

    return selectedLabels?.map(labelId => ({
      label: {
        id: {
          eq: labelId,
        },
      },
    }));
  }, [selectedLabelCategory, selectedLabels]);

  const hasSomeFilter = useMemo(() => {
    return Object.values(selectorsState).some(value => !!value) || labelsFilters.length > 0;
  }, [selectorsState, labelsFilters]);

  const createObjectMutation = () => {
    const filterArray = [];

    (selectorsState?.curriculum || selectorsState?.program) &&
      filterArray.push({
        curriculum: selectorsState.curriculum ? { eq: selectorsState.curriculum } : undefined,
        program: selectorsState.program ? { code: { eq: selectorsState.program } } : undefined,
      });
    selectorsState.level && filterArray.push({ level: { eq: selectorsState.level } });
    selectorsState.campus && filterArray.push({ campusId: { eq: selectorsState.campus } });
    selectorsState.modality && filterArray.push({ modalityId: { eq: selectorsState.modality } });
    selectorsState.department &&
      filterArray.push({ departmentId: { eq: selectorsState.department } });
    selectorsState.shift && filterArray.push({ shiftId: { eq: selectorsState.shift } });

    const filter: PackageFilterInput = {
      fields: {
        population: filterArray?.length
          ? {
              and: filterArray,
            }
          : undefined,
        and: labelsFilters?.length ? labelsFilters : undefined,
      },
    };

    const variables = {
      originId: origin,
      scenarioId: scenario,
      filter,
      pagination: {
        page: 1,
        size: 10,
      },
    };
    return variables;
  };

  const callUpdateHook = async () => {
    setLoading(true);
    try {
      const variables = createObjectMutation();
      const { data } = await advanceSearchMutation({ variables });
      setLoading(false);
      const dataItems = data.filter.createPackageFilter;
      if (dataItems.packages.items.length > 0) {
        const idSearch = dataItems.id;
        history.push(advancedSearchResultsUrl("package", idSearch));
      } else {
        setIsEmpty(true);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      setError(true);
    }
  };

  return (
    <div className={css.ctnAdvanceSearch}>
      <EmptyResultsModal show={isEmpty} onClose={() => setIsEmpty(false)} />

      <Box className={css.ctnAdvanceSearch_boxContent}>
        <AdvancedSelectorsWrapper
          scenario={scenario}
          selectors={[
            "campus",
            "department",
            "modality",
            "shift",
            "program",
            "curriculum",
            "level",
          ]}
          onChangeState={setSelectorsState}
        />

        <Divider className={css.divider} />

        <AdvancedLabelsSelectors
          scenario={scenario}
          contentType="all"
          onChange={handleOnChangeLabels}
        />
      </Box>

      {loading ? (
        <Loading />
      ) : (
        <Button
          className={css.ctnAdvanceSearch_actionButton}
          onClick={() => callUpdateHook()}
          disabled={error || !hasSomeFilter}
        >
          {t("advanced-search.package.forms.btn-search")}
        </Button>
      )}
    </div>
  );
};

export default AdvanceSearch;
