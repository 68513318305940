import React, { useContext, useMemo, useState } from "react";
import cx from "classnames";
import { Accordion, Link, Loading, Tooltip } from "@foris/avocado-suite";
import { Icon } from "@foris/avocado-icons";
import { IGroup, IGroupValue, ILeague } from "@modules/vacancies/contextVacancies/ContextVacancies";
import { VacanciesType } from "@modules/vacancies/Vacancies";
import { ContextApp } from "@config/Context/contextApp";
import GroupsTable from "../../../Groups/groups-table";
import LinkComponentsTable from "../link-components-table";
import useContextUrl from "@common/hooks/useContextUrl";
import styles from "./linkCard.module.scss";
import InstructorsTable from "@modules/vacancies/Instructor/components/instructors-table";
import GroupDeleteModal from "@modules/vacancies/Groups/group-delete-modal";
import useDisableGroup from "@modules/vacancies/Groups/hooks/useDisableGroup";
import GroupVisibilityModal from "@modules/vacancies/Groups/group-visibility-modal";
import useToggleGroupState from "@modules/vacancies/Groups/hooks/useToggleGroupState";
import useUpdateGroupIsActive from "@modules/vacancies/hooks/useUpdateGroupIsActive";
import GroupActiveModal from "@modules/vacancies/Groups/group-active-modal";
import i18n from "@config/i18n";
import { useTranslation } from "react-i18next";

interface LinkCardProps {
  link: ILeague;
  tableType: VacanciesType;
  userCanEdit?: boolean;
  isEditing?: boolean;
  onUpdateGroupValues?: (values: IGroupValue[]) => void;
  onUpdateInstructorValues?: (values: IGroupValue[]) => void;
  onCreateGroup?: () => void;
  onMigrateGroup?: (groupId?: string | number) => void;
  setValidationArray?: (validations: any[]) => void;
}

const itemCode = (item: ILeague) => {
  return (
    <Tooltip
      className={styles.headerCard_code__underline}
      label={i18n.t("bundle.link-card.system-code-tooltip")}
      placement="right"
    >
      {item?.clientCode ? <b>{item?.code}</b> : `(${item.code})`}
    </Tooltip>
  );
};

const itemClientCode = (item: ILeague) => {
  return (
    <Tooltip
      className={styles.headerCard_code__underline}
      label={i18n.t("bundle.link-card.user-code-tooltip")}
      placement="right"
    >
      ({i18n.t("bundle.link-card.user-code")} <b>{item?.clientCode}</b>)
    </Tooltip>
  );
};

const LinkCard = ({
  link,
  userCanEdit,
  tableType,
  isEditing,
  onUpdateGroupValues,
  onUpdateInstructorValues,
  onCreateGroup,
  onMigrateGroup,
  setValidationArray,
}: LinkCardProps) => {
  const contextApp = useContext(ContextApp);
  const { origin, scenario, workspace } = useContextUrl();
  const { t } = useTranslation();

  const [showGroupActiveModal, setShowGroupActiveModal] = useState(false);
  const [showGroupVisibilityModal, setShowGroupVisibilityModal] = useState(false);
  const [showGroupDeleteModal, setShowGroupDeleteModal] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<IGroup | null>(null);

  const { isLoading, handleDisableGroup } = useDisableGroup({
    selectedGroup,
    onValidationArray: setValidationArray,
  });

  const { isLoading: isUpdatingVisibility, handleToggleGroupVisibility } = useToggleGroupState({
    selectedGroup,
    onValidationArray: setValidationArray,
  });

  const [{ isLoading: isUpdatingActiveState }, updateGroupIsActive] = useUpdateGroupIsActive({
    onValidationArray: setValidationArray,
    onSuccess(group) {
      if (group) {
        selectedGroup.openGroup = Boolean(group?.visibleForEnrollment);
        selectedGroup.isActive = Boolean(group?.isActive);
      }
    },
  });

  const unassigmentInstructors = useMemo(() => {
    if (link.blocksWithoutInstructorAssigment > 0) {
      const blockPercent = (link.blocksWithoutInstructorAssigment * 100) / link.totalBlocks;

      return {
        ...link,
        id: "unassignment",
        instructor: {
          id: "unassignment",
          code: "",
          name: "Sin docente asignado",
          contract: null,
        },
        totalBlocks: link.blocksWithoutInstructorAssigment,
        link: null,
        blocksPercentage: Math.round(blockPercent) || 0,
      };
    }

    return null;
  }, [link]);

  return (
    <>
      {(isLoading || isUpdatingVisibility || isUpdatingActiveState) && <Loading />}

      <Accordion
        data-linkColor={link.color}
        type="neutral"
        className={cx(styles.linkCard, styles[`linkCard__${link.color}`])}
        title={
          ((
            <>
              <div className={styles.linkCard_circle} />
              {`${t("dimension.link.title")} ${link.codeName}`}
            </>
          ) as unknown) as string
        }
        customContent={
          <div className={styles.linkCard_customHeader}>
            <Link
              href={`/scheduler/editor/link/${workspace}/${scenario}/${origin}/${link?.linkId}`}
              className={styles.linkCode}
            >
              {itemCode(link)} {!!link?.clientCode && itemClientCode(link)}
            </Link>

            {contextApp?.user?.abilities?.can_edit_links_and_sections && !link?.clientCode && (
              <Link
                href={`/editor/link/client-code/${workspace}/${scenario}/${origin}/${link?.linkId}`}
                className={styles.codeManagement}
              >
                (<Icon className={styles.icon} name="edit" size="sm" />{" "}
                {t("bundle.link-card.add-code")})
              </Link>
            )}

            {userCanEdit && link?.course?.edhCanEdit && (
              <Link
                href={`/editor/form-edit/${workspace}/${scenario}/${origin}/${link?.linkId}`}
                className={styles.scheduleEdit}
                iconRight="external-link"
              >
                {t("bundle.link-card.edit-schedule")}
              </Link>
            )}

            {userCanEdit && !!link?.course?.generatesPackages && (
              <Link
                href={`/editor/packages-edit/${workspace}/${scenario}/${origin}/${link?.linkId}`}
                className={styles.packageEdit}
                iconRight="external-link"
              >
                {t("bundle.link-card.edit-packages")}
              </Link>
            )}
          </div>
        }
        opened
      >
        <div className={styles.linkCard_content}>
          {tableType === VacanciesType.vacancies && (
            <GroupsTable
              linkId={link?.linkId}
              groups={link?.groups}
              edhCanEdit={link?.course?.edhCanEdit}
              isEditing={isEditing}
              onCreateGroup={onCreateGroup}
              onToggleActive={group => {
                setSelectedGroup(group);
                setShowGroupActiveModal(true);
              }}
              onToggleVisibility={group => {
                setSelectedGroup(group);
                setShowGroupVisibilityModal(true);
              }}
              onDeleteGroup={group => {
                setSelectedGroup(group);
                setShowGroupDeleteModal(true);
              }}
              onMigrateGroup={(group: IGroup) => onMigrateGroup?.(group?.id)}
              onChangeInputValue={onUpdateGroupValues}
            />
          )}

          {tableType === VacanciesType.instructor && (
            <InstructorsTable
              instructors={unassigmentInstructors ? [unassigmentInstructors] : link?.instructors}
              linkTotalBlocks={unassigmentInstructors ? 0 : link?.totalBlocks}
              linkId={link?.linkId}
              isEditing={unassigmentInstructors ? false : isEditing}
              onUpdateValues={onUpdateInstructorValues}
            />
          )}

          <LinkComponentsTable
            sections={link?.sections}
            showTitle={tableType === VacanciesType.vacancies}
          />
        </div>
      </Accordion>

      <GroupDeleteModal
        isOpen={showGroupDeleteModal}
        groupLabel={selectedGroup?.label}
        onCancel={() => {
          setShowGroupDeleteModal(false);
          setSelectedGroup(null);
        }}
        onConfirm={() => {
          handleDisableGroup();
          setShowGroupDeleteModal(false);
        }}
      />

      <GroupVisibilityModal
        isOpen={showGroupVisibilityModal}
        selectedGroup={selectedGroup}
        onCancel={() => {
          setShowGroupVisibilityModal(false);
          setSelectedGroup(null);
        }}
        onConfirm={() => {
          handleToggleGroupVisibility();
          setShowGroupVisibilityModal(false);
        }}
      />

      <GroupActiveModal
        isOpen={showGroupActiveModal}
        selectedGroup={selectedGroup}
        onCancel={() => {
          setShowGroupActiveModal(false);
          setSelectedGroup(null);
        }}
        onConfirm={() => {
          updateGroupIsActive(selectedGroup?.id, !selectedGroup?.isActive);
          setShowGroupActiveModal(false);
        }}
      />
    </>
  );
};

export default LinkCard;
