import { useContext, useMemo } from "react";
import { InstructorLink, ValidationErrorReason } from "@models/ISchema";
import {
  ContextVacancies,
  IContextVacancies,
  IGroupValue,
} from "@modules/vacancies/contextVacancies/ContextVacancies";
import { getTotalBlocksPercent } from "@modules/vacancies/utils";

interface useInstructorTableStateProps {
  instructors: InstructorLink[];
  countResponsibility: IGroupValue[];
  linkTotalBlocks: number;
  linkId: string;
}

const compareGroups = (a: InstructorLink, b: InstructorLink) =>
  a.instructor.name.localeCompare(b.instructor.name);

const useInstructorTableState = (props?: useInstructorTableStateProps) => {
  const { instructors = [], countResponsibility = [], linkTotalBlocks, linkId } = props;

  const context: IContextVacancies = useContext(ContextVacancies);

  const orderInstructor = useMemo(() => instructors.sort(compareGroups), [instructors]);
  const totalInstructorBlocks = useMemo(() => {
    return orderInstructor.reduce((acc, item) => (acc += item.totalBlocks), 0);
  }, [orderInstructor]);

  const totalInstructorBlocksPercent = useMemo(() => {
    return getTotalBlocksPercent(totalInstructorBlocks, linkTotalBlocks);
  }, [totalInstructorBlocks, linkTotalBlocks]);

  const totalResponsability = useMemo(() => {
    return countResponsibility?.reduce(
      (acc, item) => (acc += Number(item.teachingHoursPercentage) ?? 0),
      0,
    );
  }, [countResponsibility]);

  const totalPayment = useMemo(() => {
    return countResponsibility?.reduce(
      (acc, item) => (acc += Number(item.paymentPercentage) ?? 0),
      0,
    );
  }, [countResponsibility]);

  const errors = useMemo(() => {
    const errors = {
      paymentError: false,
      responsabilityError: false,
    };

    if (context?.errorsInstructors && context?.errorsInstructors.length > 0) {
      context?.errorsInstructors.forEach(item => {
        if (item.link?.linkId === linkId) {
          if (item?.item?.code === ValidationErrorReason.InvalidPaymentPercentage) {
            errors.paymentError = true;
          }
          if (item?.item?.code === ValidationErrorReason.InvalidTeachingHoursPercentage) {
            errors.responsabilityError = true;
          }
        }
      });
    }

    return errors;
  }, [context?.errorsInstructors, linkId]);

  return {
    orderInstructor,
    totalInstructorBlocks,
    totalInstructorBlocksPercent,
    totalResponsability,
    totalPayment,
    errors,
  };
};

export default useInstructorTableState;
