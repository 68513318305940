import React, { useEffect, useMemo, useState } from "react";
import { TextField, CardNotification, Text } from "@foris/avocado-suite";
import { getGroupComponentErrorMessages } from "@modules/vacancies/utils";
import { SharedPackComponent, SharedPackComponents } from "@models/ISchema";
import ComponentInputWrapper from "./ComponentInputWrapper";
import css from "./groupComponentInputs.module.scss";

export interface AdaptedSharedPackComponent extends SharedPackComponent {
  prefix: string;
}

export interface AdaptedSharedPackComponentPayload {
  groupCode: string;
  sharedPackComponent: AdaptedSharedPackComponent[];
}

interface GroupComponentInputsProps {
  sharedPackComponents: SharedPackComponents[];
  onChangeComponents?: (payload: AdaptedSharedPackComponentPayload) => void;
  onFormValid?: (isValid: boolean) => void;
}

const getInputLabel = (label = "", id = "") => {
  return (
    <div className={css.inputLabel}>
      {label} {id && <span className={css.inputLabel_id}>(ID {id})</span>}
    </div>
  ) as any;
};

const getGroupCode = (components: AdaptedSharedPackComponent[] = []) => {
  return components
    ?.map(({ prefix, label }) => `${prefix ? `-${prefix}${label}` : label}`)
    .join("");
};

const GroupComponentInputs = ({
  sharedPackComponents,
  onChangeComponents,
  onFormValid,
}: GroupComponentInputsProps) => {
  const [state, setState] = useState<AdaptedSharedPackComponent[]>([]);

  useEffect(() => {
    const components = sharedPackComponents?.map(sharedPackComponent => ({
      component: sharedPackComponent.component,
      label: sharedPackComponent.code,
      prefix: sharedPackComponent.prefix,
    }));

    setState([...components]);
  }, [sharedPackComponents]);

  useEffect(() => {
    onChangeComponents?.({
      groupCode: getGroupCode(state),
      sharedPackComponent: state,
    });
  }, [state]);

  const handleOnChange = (index: number, value: string) => {
    const newState = [...state];

    newState[index] = {
      ...newState[index],
      label: value,
    };

    setState(newState);
  };

  const existingLabels = useMemo(
    () =>
      [...sharedPackComponents]?.reduce((acc: string[], current: SharedPackComponents) => {
        return [...acc, ...(current?.existingLabels || [])];
      }, []),
    [sharedPackComponents],
  );

  const errors = useMemo(() => {
    return state
      ?.map((inputComponent, i) => {
        if (!sharedPackComponents[i]?.code && !!inputComponent.label) {
          return getGroupComponentErrorMessages(
            inputComponent?.component,
            inputComponent?.label,
            existingLabels,
          );
        }

        return null;
      })
      ?.filter(inputComponent => !!inputComponent);
  }, [state]);

  useEffect(() => {
    if (errors?.length) {
      onFormValid?.(false);
      return;
    }

    const hasAllInputsFilled = state?.every(inputComponent => !!inputComponent?.label);
    onFormValid?.(hasAllInputsFilled);
  }, [state, errors]);

  return (
    <div className={css.groupComponentInputs}>
      <div className={css.groupComponentInputs_list}>
        {sharedPackComponents?.map((groupComponent, key) => (
          <ComponentInputWrapper key={key} isDisabled={!!groupComponent?.code}>
            <TextField
              label={getInputLabel(groupComponent.component)}
              placeholder="Número de sección"
              value={groupComponent?.code || state?.[key]?.label}
              disabled={!!groupComponent?.code}
              onChange={e => handleOnChange(key, e?.target?.value)}
            />
          </ComponentInputWrapper>
        ))}
      </div>

      {!!errors?.length && (
        <div className={css.groupComponentInputs_errors}>
          {errors?.map((err, key) => (
            <CardNotification
              key={key}
              className={css.errorWrapper}
              title="Error en la creación de ID"
              state="error"
              outlined
            >
              <div>
                <span className={css.errorWrapper_component}>Sección {err?.component}</span>

                <ul className={css.errorWrapper_messages}>
                  {err?.errors?.map((msg, errKey) => (
                    <li key={errKey} className={css.errorMessage}>
                      <Text type="sm">{msg}</Text>
                    </li>
                  ))}
                </ul>
              </div>
            </CardNotification>
          ))}
        </div>
      )}
    </div>
  );
};

export default GroupComponentInputs;
