import { useState } from "react";
import { map, pipe, applySpec, prop, view, lensPath, toPairs, last } from "ramda";
import { useMutation } from "react-apollo";
import { PackageCrudResult } from "@models/ISchema";
import packagesCrud from "../graphql/packagesCrud.mutation.graphql";
import { DataReducerType } from "../context/types";

interface Props {
  origin: string;
  scenario: string;
}

type ReturnType = [
  { result: any; error: boolean; isLoading: boolean },
  (data: DataReducerType, dryRun?: boolean, skipValidations?: boolean) => Promise<void>,
];

const usePackagesCrud = (props: Props): ReturnType => {
  const { scenario, origin } = props;
  const [packagesCrudMutation] = useMutation(packagesCrud, {});
  const [result, setResult] = useState<PackageCrudResult>({});
  const [error, setError] = useState(false);
  const [isLoading, setIsloading] = useState(false);

  /**
   * @param creations - LinkPopulationObj
   * @return LinkPopulationPair
   */
  const buildCreations = pipe(
    toPairs,
    map(
      pipe(
        last,
        applySpec({
          linkId: prop("linkId"),
          termId: prop("termId"),
          populationCode: view(lensPath(["population", "code"])),
          packageName: prop("packageName"),
        }),
      ),
    ),
  );

  const setInput = ({ creations }: DataReducerType, dryRun: boolean, skipValidations: boolean) => ({
    dryRun,
    skipValidations,
    changesets: {
      creates: buildCreations(creations),
      deletes: [],
    },
  });

  const submitPackagesCrud = async (
    data: DataReducerType,
    dryRun = true,
    skipValidations = true,
  ) => {
    setIsloading(true);

    try {
      const variables = {
        scenarioId: scenario,
        originId: origin,
        input: setInput(data, dryRun, skipValidations),
      };
      const { data: responseData } = await packagesCrudMutation({ variables });
      setResult(responseData?.cube?.packagesCrud);
    } catch (e) {
      setError(true);
      console.error("[PackagesCrud]: ", e);
    } finally {
      setIsloading(false);
    }
  };

  return [{ result, error, isLoading }, submitPackagesCrud];
};

export default usePackagesCrud;
