import useContextUrl from "@common/hooks/useContextUrl";
import { CubeMutation, GroupValidationErrorUnion } from "@models/ISchema";
import { IGroup } from "@modules/vacancies/contextVacancies/ContextVacancies";
import { groupValidation } from "@modules/vacancies/utils";
import { useState } from "react";
import { useMutation } from "react-apollo";
import updateGroupVisibilityMutation from "../../graphql/updateGroupVisibility.mutation.graphql";

interface useToggleGroupStateProps {
  selectedGroup?: IGroup;
  onValidationArray?: (array: any[]) => void;
}

const useToggleGroupState = (props?: useToggleGroupStateProps) => {
  const { origin, scenario } = useContextUrl();
  const { selectedGroup, onValidationArray } = props;

  const [groupVisibilityMutation] = useMutation(updateGroupVisibilityMutation);
  const [isLoading, setIsLoading] = useState(false);

  const handleUpdateGroupMutation = async () => {
    try {
      setIsLoading(true);

      if (selectedGroup) {
        const variables = {
          originId: parseInt(origin),
          scenarioId: parseInt(scenario),
          groupId: parseInt(selectedGroup.id),
          visible: !selectedGroup.openGroup,
        };

        const data = await groupVisibilityMutation({ variables });
        const dataMutation: CubeMutation = data.data.cube;

        const validationErrors: Array<GroupValidationErrorUnion> =
          dataMutation.updateGroupVisible.validationErrors;
        let errorsValidations = [];

        if (validationErrors && validationErrors.length > 0) {
          validationErrors.forEach((error: GroupValidationErrorUnion) => {
            const link = dataMutation.updateGroupVisible.link;
            const errorLinks = groupValidation([error], link);
            errorsValidations = [...errorsValidations, ...errorLinks];
          });

          onValidationArray?.(errorsValidations);
        } else {
          selectedGroup.openGroup = !selectedGroup.openGroup;
        }

        setIsLoading(false);
      }
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    handleToggleGroupVisibility: handleUpdateGroupMutation,
  };
};

export default useToggleGroupState;
