import React from "react";
import { Bundle } from "../../models/ISchema";
import { Header } from "../../common/components";
import { useTranslation } from "react-i18next";
interface IHeaderVacancies {
  data: Bundle;
}

const HeaderVacancies: React.FC<IHeaderVacancies> = (props: IHeaderVacancies) => {
  const { t } = useTranslation();
  const course = props?.data?.links?.[0]?.course;

  return (
    <Header
      breadcrumbItems={[
        {
          path: "/",
          title: t("dimension.link.breadcrumb.previous"),
        },
        {
          title: t("bundle.title"),
        },
      ]}
      topTitle={`${t("bundle.title")}: ${props.data?.code}`}
      title={`${course?.code} ${course?.name}`}
      moreDetails={[
        {
          title: t("bundle.link-card.group-table.body.subject-key.campus"),
          content: course?.curriculum?.program?.campus?.code || "-",
        },
        {
          title: t("bundle.link-card.group-table.body.subject-key.department"),
          content: course?.curriculum?.program?.department?.code || "-",
        },
        {
          title: t("bundle.link-card.group-table.body.subject-key.modality"),
          content: course?.curriculum?.program?.modality?.code || "-",
        },
        {
          title: t("bundle.link-card.group-table.body.subject-key.shift"),
          content: course?.curriculum?.program?.shift?.code || "-",
        },
        {
          title: t("bundle.link-card.group-table.body.subject-key.program"),
          content: course?.curriculum?.program?.code || "-",
        },
        {
          title: t("bundle.link-card.group-table.body.subject-key.curriculum"),
          content: course?.curriculum?.code || "-",
        },
        {
          title: t("bundle.link-card.group-table.body.subject-key.term"),
          content: (course?.level ?? "-") as string,
        },
      ]}
    />
  );
};

export default HeaderVacancies;
