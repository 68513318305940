import gql from "graphql-tag";

export const instructorSearchMutation = gql`
  mutation filter(
    $scenarioId: ID!
    $originId: ID!
    $filter: InstructorFilterInput!
    $pagination: PaginationInput
  ) {
    filter(scenarioId: $scenarioId, originId: $originId) {
      createInstructorFilter(filter: $filter) {
        id
        instructors(pagination: $pagination) {
          items {
            id
            code
            name
          }
          pageInfo {
            hasPreviousPage
            hasNextPage
            page
            size
            total
          }
        }
      }
    }
  }
`;
