import React from "react";
import DimensionsSearchResults from "@dimensions/common/dimensions-search-results";
import useGetTitle from "../../hooks/useGetTitle";
import { getColumnCell, getHeadCell, getLinkCell } from "@dimensions/utils/tableUtils";
import { ISearchProps } from "@dimensions/common/ISearch";
import { useTranslation } from "react-i18next";
import { DimensionNameEs } from "@models/IDimension";
import useNavigationUrl from "@common/hooks/useNavigationUrl";

/**
 * <CourseTable> component that displays a table of courses with relevant information.
 *
 * This component fetches courses data and renders it in a table format, displaying information
 * such as courses code, name, campus, sections, demand-vacancies, and demand-crosslist.
 * It integrates search, and internationalization (i18n) for multi-language support.
 *
 * @param {DimensionSearchResultsProps} props - The properties passed to the component.
 * @param {Array<Course>} props.data - Data of courses to display in the table.
 * @param {string} props.search - The search query to filter results.
 * @param {Object} props.pageInfo - Pagination information (total results, current page, etc.).
 * @param {Function} props.setPage - Function to change the current page.
 * @param {boolean} props.loading - Boolean indicating if the data is still loading.
 * @param {boolean} props.error - Boolean indicating if there was an error during data fetch.
 * @param {Function} props.onChangeSizePerPage - Function to handle page size changes.
 * @returns {JSX.Element} - The rendered CourseTable component.
 *
 * @example
 * <CourseTable
 *   data={coursesData}
 *   search="Math"
 *   pageInfo={pageInfo}
 *   setPage={setPage}
 *   loading={isLoading}
 *   error={hasError}
 * />
 */

const CourseTable = (props: ISearchProps) => {
  const { data, search, pageInfo, loading, error } = props;

  // Get the dynamic title for the search results using the custom hook `useGetTitle`
  const title = useGetTitle(search, pageInfo?.total, "asignatura");

  // Get the context URL for advanced search functionality
  const { advancedSearchUrl, editorUrl } = useNavigationUrl();
  const getEditorUrl = (id: string) => editorUrl("course", id);

  // Use the i18n hook to get translation functions

  const { t } = useTranslation();

  // Define the columns for the table, including the header and cell rendering logic
  const columns = [
    {
      header: getHeadCell(t("search-results.dimension.course.table.header.code")),
      renderCell: ({ id, code }) => getLinkCell(getEditorUrl(id), null, code),
    },
    {
      header: getHeadCell(t("search-results.dimension.course.table.header.name")),
      renderCell: ({ id, name }) => getLinkCell(getEditorUrl(id), null, name),
    },
    {
      header: getHeadCell(t("search-results.dimension.course.table.header.campus")),
      renderCell: ({ curriculum }) => getColumnCell(curriculum?.program?.campus?.name || "-"),
    },
    {
      header: getHeadCell(t("search-results.dimension.course.table.header.sections")),
      renderCell: ({ sectionsCount }) => getColumnCell(sectionsCount ?? "-"),
    },
    {
      header: getHeadCell(t("search-results.dimension.course.table.header.demand-vacancies")),
      renderCell: ({ demand }) => getColumnCell(demand?.vacancies ?? "-"),
    },
    {
      header: getHeadCell(t("search-results.dimension.course.table.header.demand-crosslist")),
      renderCell: ({ demand }) => getColumnCell(demand?.crosslist || "-"),
    },
  ];

  // Render the DimensionsSearchResults component with the table data and configurations
  return (
    <DimensionsSearchResults
      title={title.getTitle}
      columns={columns}
      data={data}
      pageInfo={pageInfo}
      setPage={props.setPage}
      loading={loading}
      error={error}
      dimension={t("dimension.course.title") as DimensionNameEs}
      advanceLink={advancedSearchUrl("course")}
      onChangeSizePerPage={props.onChangeSizePerPage}
    />
  );
};

export default CourseTable;
