import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Heading,
  Loading,
  Text,
  Divider,
  CardNotification,
  Link as LinkAvocado,
} from "@foris/avocado-suite";
import { useHistory, useParams } from "react-router-dom";
import { useMutation } from "react-apollo";
import { MIGRATE_GROUP_MUTATION } from "../graphql/migrateGroup.queries";
import { Header } from "@common/components";
import { LinkSelector } from "@modules/shared/selectors/Selectors";
import { ContextEDH } from "@context/ContextEDH";
import { CubeMutation, GroupValidationErrorUnion, Link } from "@models/ISchema";
import { IGroup, ILeague } from "../contextVacancies/ContextVacancies";
import { IParams } from "@models/IParams";
import css from "./groupMigration.module.scss";

interface GroupMigrationProps {
  groupName?: string;
  groupId?: string | number;
  linkId?: string;
  onCancel?: () => void;
  linkDetails?: ILeague;
  groupDetails?: IGroup;
}

const GroupMigration = ({
  groupId,
  groupName,
  onCancel,
  linkId,
  linkDetails,
  groupDetails,
}: GroupMigrationProps) => {
  const history = useHistory();
  const { state } = useContext(ContextEDH);
  const { origin, scenario, workspace }: IParams = useParams();
  const [migrateGroupMutation] = useMutation(MIGRATE_GROUP_MUTATION, {});
  const [selectedLink, setSelectedLink] = useState<Link | null>(null);
  const [isMigrationValidated, setIsMigrationValidated] = useState(false);
  const [hasClassroomCapacityError, setHasClassroomCapacityError] = useState(false);
  const [hasSizeError, setHasSizeError] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const { t } = useTranslation();

  const routeBundle = `/editor/vacancies/${workspace}/${scenario}/${origin}/${selectedLink?.bundle?.id}`;

  const handleMigrateGroup = async () => {
    try {
      setIsloading(true);
      const data = await migrateGroupMutation({
        variables: {
          scenarioId: scenario,
          originId: origin,
          input: {
            linkId: selectedLink?.id,
            groupId,
            dryRun: !isMigrationValidated,
            skipValidations: isMigrationValidated,
          },
        },
      });

      const dataMutation: CubeMutation = data.data.cube;
      const validationErrors: Array<GroupValidationErrorUnion> =
        dataMutation.migrateGroup.validationErrors;

      if (validationErrors && validationErrors.length > 0 && !isMigrationValidated) {
        validationErrors.forEach((error: GroupValidationErrorUnion) => {
          if (error && error.__typename === "InvalidLinkChanges") {
            const hasComponentSizeError = error?.sectionChanges?.some(
              sectionChange =>
                !!sectionChange?.validationErrors?.find(
                  err => err.__typename === "InvalidComponentSize",
                ),
            );
            const hasInvalidClassroomCapacityError = error?.sessionChanges?.some(
              sessionChange =>
                !!sessionChange?.validationErrors?.find(
                  err => err.__typename === "InvalidClassroomCapacity",
                ),
            );

            setHasClassroomCapacityError(hasInvalidClassroomCapacityError);
            setHasSizeError(hasComponentSizeError);
          }
        });
      }

      if (isMigrationValidated && !!dataMutation?.migrateGroup?.commited) {
        history.replace(routeBundle);
        window.location.reload();
      }

      setIsMigrationValidated(true);
    } catch (error) {
      console.error(error);
      setIsloading(false);
    }
  };

  useEffect(() => {
    setIsMigrationValidated(false);
    setHasClassroomCapacityError(false);
    setHasSizeError(false);
  }, [selectedLink]);

  useEffect(() => {
    if (isMigrationValidated) {
      !hasClassroomCapacityError && !hasSizeError && handleMigrateGroup();
      (hasClassroomCapacityError || hasSizeError) && setIsloading(false);
    }
  }, [isMigrationValidated]);

  return (
    <div className={css.groupMigration}>
      {isLoading && <Loading />}

      <Header topTitle="Bundle" title={groupName} />

      <div className={css.groupMigration_content}>
        <Heading type="h3" className={css.title}>
          {t("bundle.migrated-group-link.title")}
        </Heading>

        <div className={css.informationBox}>
          <Heading type="h4" className={css.informationBox_title}>
            {t("bundle.migrated-group-link.forms.information-box.title")}
          </Heading>

          <div className={css.informationBox_content}>
            <Text type="sm" className={css.text}>
              {t("bundle.migrated-group-link.forms.information-box.group")}:
            </Text>
            <Text type="sm" weight="medium" className={css.bold}>
              {groupDetails?.label || groupDetails?.clientCode || "-"}
            </Text>
            <Divider vertical className={css.divider} />
            <Text type="sm" className={css.text}>
              {t("bundle.migrated-group-link.forms.information-box.vacancies")}:
            </Text>
            <Text type="sm" weight="medium" className={css.bold}>
              {groupDetails?.vacancies ?? "-"}
            </Text>
            <Divider vertical className={css.divider} />
            <Text type="sm" className={css.text}>
              {t("bundle.migrated-group-link.forms.information-box.enrollments")}:
            </Text>
            <Text type="sm" weight="medium" className={css.bold}>
              {groupDetails?.enrollment ?? "-"}
            </Text>
            <Divider vertical className={css.divider} />
            <Text type="sm" className={css.text}>
              {t("bundle.migrated-group-link.forms.information-box.waiting-list")}:
            </Text>
            <Text type="sm" weight="medium" className={css.bold}>
              {groupDetails?.waitList ?? "-"}
            </Text>
          </div>

          <Text type="sm" className={css.informationBox_subtitle}>
            {t("bundle.migrated-group-link.forms.information-box.subtitle.title")}:
          </Text>

          <div className={css.informationBox_content}>
            <Text type="sm" className={css.text}>
              {t("bundle.migrated-group-link.forms.information-box.subtitle.course")}:
            </Text>
            <Text type="sm" weight="medium" className={css.bold}>
              {linkDetails?.course?.name || "-"}
            </Text>
            <Divider vertical className={css.divider} />
            <Text type="sm" className={css.text}>
              {t("bundle.migrated-group-link.forms.information-box.subtitle.campus")}:
            </Text>
            <Text type="sm" weight="medium" className={css.bold}>
              {linkDetails?.course?.curriculum?.program?.campus?.code ?? "-"}
            </Text>
            <Divider vertical className={css.divider} />
            <Text type="sm" className={css.text}>
              {t("bundle.migrated-group-link.forms.information-box.subtitle.period")}:
            </Text>
            <Text type="sm" weight="medium" className={css.bold}>
              {linkDetails?.bundle?.term?.code || "-"}
            </Text>
          </div>
        </div>

        <LinkSelector
          className={css.linkSelector}
          label={t("bundle.migrated-group-link.forms.select-link-destiny.label")}
          placeholder={t("bundle.migrated-group-link.forms.select-link-destiny.placeholder")}
          onSelectLink={setSelectedLink}
          isDisabled={isLoading}
          linksToAvoid={[linkId]}
          getUnrestrictedLinks={state?.base?.groupsInLinkRestriction?.enabled ? true : undefined}
        />

        {selectedLink && (
          <div className={css.customInformationBox}>
            <Heading type="h4" className={css.informationBox_title}>
              {t("bundle.migrated-group-link.forms.information-box.key-destiny.title")}:
            </Heading>

            <div className={css.informationBox_content}>
              <Text type="sm" className={css.text}>
                {t("bundle.migrated-group-link.forms.information-box.key-destiny.course")}:
              </Text>
              <Text type="sm" weight="medium" className={css.bold}>
                {selectedLink?.course?.name || "-"}
              </Text>
              <Divider vertical className={css.divider} />
              <Text type="sm" className={css.text}>
                {t("bundle.migrated-group-link.forms.information-box.key-destiny.campus")}:
              </Text>
              <Text type="sm" weight="medium" className={css.bold}>
                {selectedLink?.course?.curriculum?.program?.campus?.code ?? "-"}
              </Text>
              <Divider vertical className={css.divider} />
              <Text type="sm" className={css.text}>
                {t("bundle.migrated-group-link.forms.information-box.key-destiny.period")}:
              </Text>
              <Text type="sm" weight="medium" className={css.bold}>
                {selectedLink?.bundle?.term?.code || "-"}
              </Text>
            </div>
          </div>
        )}

        {(hasClassroomCapacityError || hasSizeError) && (
          <CardNotification
            outlined
            className={css.notificationWarn}
            title={t("bundle.migrated-group-link.forms.notification-warn-exceeded-capacity.title")}
            state="warning"
            extraContent={
              <LinkAvocado
                className={css.notificationWarn_link}
                onClick={() => setHasSizeError(false)}
              >
                {t("bundle.migrated-group-link.forms.notification-warn-exceeded-capacity.btn-link")}
              </LinkAvocado>
            }
          >
            <>
              <Text type="sm" className={css.notificationWarn_text}>
                {t(
                  "bundle.migrated-group-link.forms.notification-warn-exceeded-capacity.list.title",
                )}
              </Text>
              {(hasClassroomCapacityError || hasSizeError) && (
                <ul className={css.notificationWarn_list}>
                  {hasClassroomCapacityError && (
                    <li>
                      <Text type="sm">
                        {t(
                          "bundle.migrated-group-link.forms.notification-warn-exceeded-capacity.list.item-1",
                        )}
                      </Text>
                    </li>
                  )}

                  {hasSizeError && (
                    <li>
                      <Text type="sm">
                        {t(
                          "bundle.migrated-group-link.forms.notification-warn-exceeded-capacity.list.item-2",
                        )}
                      </Text>
                    </li>
                  )}
                </ul>
              )}
            </>
          </CardNotification>
        )}

        <div className={css.contentActions}>
          <Button variant="ghost" onClick={onCancel}>
            {t("bundle.migrated-group-link.forms.btn-cancel")}
          </Button>
          <Button
            disabled={!selectedLink?.id || hasClassroomCapacityError || hasSizeError || isLoading}
            onClick={handleMigrateGroup}
          >
            {t("bundle.migrated-group-link.forms.btn-save")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default GroupMigration;
