import React from "react";
import { useTranslation } from "react-i18next";
import { SelectPagination } from "@foris/avocado-suite";
import useGetLabelsCategoriesOptions from "@modules/shared/selectors/hooks/useGetLabelsCategoriesOptions";

interface LabelCategorySelectorProps {
  scenario: string;
  onChange?: (categoryId: string) => void;
}

const LabelCategorySelector = ({ scenario, onChange }: LabelCategorySelectorProps) => {
  const { loadOptions } = useGetLabelsCategoriesOptions({
    scenario,
  });

  const { t } = useTranslation();

  return (
    <SelectPagination
      loadOptions={loadOptions}
      placeholder={t("advanced-search.package.forms.advanced-filters.select-type-tag.placeholder")}
      noOptionsMessage={() => (
        <>{t("advanced-search.package.forms.advanced-filters.select-type-tag.empty-options")}</>
      )}
      onChange={option => onChange?.(option?.value)}
    />
  );
};

export default LabelCategorySelector;
