import React, { useContext, useMemo } from "react";
import { CardNotification, Text } from "@foris/avocado-suite";
import { ContextApp } from "@config/Context/contextApp";
import { useTranslation } from "react-i18next";

interface AbilitiesMessageProps {
  className?: string;
}

const permissionsLabel = {
  can_assign_instructors:
    "assignation-edit.form.notifications.permissions-error.permissions.can-assign-instructors",
  can_assign_intervals:
    "assignation-edit.form.notifications.permissions-error.permissions.can-assign-intervals",
  can_assign_classrooms:
    "assignation-edit.form.notifications.permissions-error.permissions.can-assign-classrooms",
  can_assign_blocks:
    "assignation-edit.form.notifications.permissions-error.permissions.can-assign-blocks",
};

const AbilitiesMessage = ({ className = "" }: AbilitiesMessageProps) => {
  const { user } = useContext(ContextApp);
  const { t } = useTranslation();

  const sessionAbilitiesDisabled = useMemo(
    () => Object.keys(permissionsLabel)?.filter(key => !user?.abilities?.[key]),
    [user?.abilities],
  );

  const labels = useMemo(
    () => sessionAbilitiesDisabled.map(key => t(permissionsLabel[key])).join(", "),
    [sessionAbilitiesDisabled],
  );

  if (!user?.abilities || !sessionAbilitiesDisabled?.length) {
    return null;
  }

  return (
    <CardNotification
      className={className}
      title={t("assignation-edit.form.notifications.permissions-error.title", {
        count: sessionAbilitiesDisabled.length,
      })}
      state="info"
      outlined
    >
      <Text type="sm">
        {t("assignation-edit.form.notifications.permissions-error.description")} {labels}.
      </Text>
    </CardNotification>
  );
};

export default AbilitiesMessage;
