import React, { useContext, useState, useEffect, useMemo } from "react";
import { Heading, Table, Text, CardNotification } from "@foris/avocado-suite";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../context/EditSessionsContext";
import {
  EditorValidationError,
  IntervalValidationErrors,
  StudentClash,
  CustomValidation,
  SessionPayload,
  InvalidSessionInHoliday,
} from "@models/ISchema";
import { Week } from "@modules/sections/ISections";
import IncidenceByType from "@models/Incidences/SessionByType";
import IncidenceByCode from "@models/Incidences/SessionByCode";
import WeekColumn from "./WeekColumn";
import css from "./incidenceTable.module.scss";

const getWeek = (weeks: Week[] = [], date = ""): Week => {
  return weeks?.find(week => date >= week?.startingDate && date <= week?.endingDate);
};

const IncidenceTable: React.FC = () => {
  const { state } = useContext(AppContext);
  const [validationErrors, setValidationErrors] = useState<EditorValidationError[]>([]);
  const [intervalErrors, setIntervalErrors] = useState<IntervalValidationErrors[]>([]);
  const [customValidations, setCustomValidations] = useState<CustomValidation[]>([]);

  const { t } = useTranslation();

  const selected = state?.result?.selectSession;
  const createdSessionSelected = state?.result?.selectCreateSession;
  const sessionToDeleteSelected = state?.result?.sessionToDeleteSelected;
  const arrayValidations: (
    | EditorValidationError
    | IntervalValidationErrors
    | CustomValidation
  )[] = useMemo(() => {
    const validations = {
      holidayErrors: [],
      validationErrors: [],
    };

    validationErrors?.forEach(error => {
      if (error.__typename === "InvalidSessionInHoliday") {
        const holidayErr = error as InvalidSessionInHoliday;

        holidayErr.holiday.forEach(holiday => {
          validations.holidayErrors.push({
            ...holidayErr,
            holiday,
          });
        });
      } else {
        validations.validationErrors.push(error);
      }
    });

    return [
      ...intervalErrors,
      ...validations?.holidayErrors,
      ...validations?.validationErrors,
      ...customValidations,
    ];
  }, [intervalErrors, validationErrors, customValidations]);

  const tableValidations = useMemo(() => {
    const intervalValidationsObj = {};
    const regularValidations = [];

    arrayValidations.forEach(validation => {
      if (validation.__typename === "IntervalValidationErrors") {
        const intervalId = validation.interval.id;

        if (intervalId in intervalValidationsObj) {
          intervalValidationsObj[intervalId]?.data?.validationErrors.push(validation);
        } else {
          intervalValidationsObj[intervalId] = {
            id: intervalId,
            data: validation,
          };
        }
      } else if (validation?.__typename === "InvalidPastSessionEdition") {
        validation?.intervals?.forEach(interval => {
          const intervalId = interval.id;

          if (intervalId in intervalValidationsObj) {
            const errorObj = intervalValidationsObj[intervalId]?.data as IntervalValidationErrors;
            const hasErrorSaved = (errorObj?.validationErrors ?? [])?.find(
              err => err.__typename === "InvalidPastSessionEdition",
            );

            !hasErrorSaved &&
              intervalValidationsObj[intervalId]?.data?.validationErrors.push(validation);
          } else {
            intervalValidationsObj[intervalId] = {
              id: intervalId,
              data: {
                interval,
                validationErrors: [validation],
                __typename: "IntervalValidationErrors",
              },
            };
          }
        });
      } else {
        regularValidations.push({
          id: "0",
          data: validation,
        });
      }
    });

    return [...regularValidations, ...Object.values(intervalValidationsObj)];
  }, [arrayValidations]);

  const anyErrorExists = useMemo(() => {
    const hasError = (sessionPayload: SessionPayload): boolean =>
      [
        sessionPayload.customValidations,
        sessionPayload.intervalValidationErrors,
        sessionPayload.validationErrors,
      ].some(errors => errors.length > 0);

    const creationValidationErrors = (state?.result?.createValidation?.sessionsPayload ?? []).some(
      hasError,
    );

    const editionValidationErrors = (state?.result?.resultValidation?.sessionsPayload ?? []).some(
      hasError,
    );

    return creationValidationErrors || editionValidationErrors;
  }, [state?.result]);

  const weekColumn = (data: any) => {
    const typename = data?.data?.__typename;
    const value: EditorValidationError | IntervalValidationErrors | StudentClash = data?.data;
    const errorWeek =
      typename === "InvalidSessionInHoliday"
        ? getWeek(state?.link?.weeks, data?.data?.holiday?.date)
        : undefined;

    return <WeekColumn column={value} week={errorWeek} />;
  };

  const sortIncidence = (a: any, b: any) => {
    return parseInt(a.id) - parseInt(b.id);
  };

  const incidenceByInterval = (validationRow: IntervalValidationErrors) => {
    let validationErrors = [];
    if (validationRow.validationErrors) validationErrors = validationRow.validationErrors;

    if (validationErrors.length === 0) {
      return <Text>Semana sin incidencia</Text>;
    }

    return validationErrors.map((incidence: any, indexVal: number) => {
      const errorType = new IncidenceByType({ incidence });
      const incidences = incidence.sessions || [];

      if (!incidences?.length) {
        return (
          <Text key={indexVal} className={css.incidenceValidation}>
            {errorType.getMessage()}
          </Text>
        );
      }

      return incidences?.map((session: any, index: number) => {
        if (session?.id !== selected?.id) {
          return (
            <Text key={index} className={css.incidenceValidation}>
              {errorType.getMessage()}:{" "}
              {session?.section ? ` Sección ${session?.section.id} - ` : ""} Sesión {session?.id}
            </Text>
          );
        }

        return null;
      });
    });
  };

  const incidenceValidation = (data: any) => {
    const value = data?.data;
    const errorType = new IncidenceByType({ incidence: value });

    switch (value.__typename) {
      case "IntervalValidationErrors":
        return incidenceByInterval(value);

      case "CustomValidation":
        const errorCode = new IncidenceByCode(value);
        return <Text>{errorCode.getMessage()}</Text>;

      case "InvalidSessionInHoliday":
        return (
          <Text className={css.weekHoliday}>
            <span className={css.weekHoliday_title}>Día festivo:</span>
            {errorType.getMessage()}
          </Text>
        );

      default:
        return <Text>{errorType.getMessage()}</Text>;
    }
  };

  const columns = [
    {
      key: "week",
      header: t("assignation-edit.incidence-table.table-head.week"),
      renderCell: weekColumn,
    },
    {
      key: "incidence",
      header: t("assignation-edit.incidence-table.table-head.incidence"),
      renderCell: incidenceValidation,
    },
  ];

  useEffect(() => {
    if (selected || sessionToDeleteSelected) {
      const result = state?.result?.resultValidation;
      if (!result?.commited) {
        const innerSelected = selected || sessionToDeleteSelected;
        const sessionPayload = result?.sessionsPayload?.find(
          res => res.sessionId === innerSelected.id,
        );
        setValidationErrors(sessionPayload?.validationErrors ?? []);
        setIntervalErrors(sessionPayload?.intervalValidationErrors ?? []);
        setCustomValidations(sessionPayload?.customValidations ?? []);
      }
    }

    if (createdSessionSelected) {
      const result = state?.result?.createValidation;
      const sessionPayload = result?.sessionsPayload?.find(
        res => res?.sessionUuid === createdSessionSelected?.id,
      );
      setValidationErrors(sessionPayload?.validationErrors ?? []);
      setIntervalErrors(sessionPayload?.intervalValidationErrors ?? []);
      setCustomValidations(sessionPayload?.customValidations ?? []);
    }
  }, [state?.result]);

  if (!selected && !createdSessionSelected && !sessionToDeleteSelected && anyErrorExists) {
    return (
      <CardNotification
        state="warning"
        title={t("assignation-edit.incidence-table.notifications.failed-validations.title")}
        className={css.notificationCard}
        outlined
      >
        {t("assignation-edit.incidence-table.notifications.failed-validations.description")}
      </CardNotification>
    );
  } else if ((sessionToDeleteSelected || tableValidations.length === 0) && !anyErrorExists) {
    return (
      <CardNotification
        state="success"
        title={t("assignation-edit.incidence-table.notifications.success-validations.title")}
        className={css.notificationCard}
        outlined
      >
        {createdSessionSelected
          ? t(
              "assignation-edit.incidence-table.notifications.success-validations.new-session-description",
            )
          : t("assignation-edit.incidence-table.notifications.success-validations.description")}
      </CardNotification>
    );
  }

  return (
    <div className={css.incidenceTable}>
      <Heading type="h2">{t("assignation-edit.incidence-table.title")}</Heading>

      <Table data={tableValidations.sort(sortIncidence)} columns={columns} />
    </div>
  );
};

export default IncidenceTable;
