import React from "react";
import { useTranslation } from "react-i18next";
import { Option } from "react-select/lib/filters";
import useGetProgramOptions from "@modules/shared/selectors/hooks/useGetProgramOptions";
import AdvanceSelector from "../advance-selector";

interface AdvancedProgramSelectorProps {
  campusId?: string;
  departmentId?: string;
  modalityId?: string;
  onChange?: (option: Option) => void;
  scenario: string;
  shiftId?: string;
}

const AdvancedProgramSelector = ({
  scenario,
  onChange,
  ...filters
}: AdvancedProgramSelectorProps) => {
  const { loadProgramOptions } = useGetProgramOptions({
    scenario,
    ...filters,
  });

  const { t } = useTranslation();

  return (
    <AdvanceSelector
      isPaginated
      dimension={t("advanced-search.instructor.forms.select-wrapper.career.label")}
      selectPlaceholder={t("advanced-search.instructor.forms.select-wrapper.career.placeholder")}
      loadOptions={loadProgramOptions}
      onChange={onChange}
      notShowLabel
    />
  );
};

export default AdvancedProgramSelector;
