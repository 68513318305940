import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { Button, Table, Pager, Heading } from "@foris/avocado-suite";
import { Header, EmptyResults } from "@common/components";
import DimensionsSearchResultsSkeleton from "./DimensionsSearchResults.skeleton";
import { DimensionNamesEs } from "@edhtypes/dimensions";
import { PageInfo } from "@models/ISchema";
import css from "./dimensionsSearchResults.module.scss";

interface DimensionsSearchResultsProps {
  title: string;
  dimension: DimensionNamesEs;
  advanceLink: string;
  columns: any[];
  data: any;
  loading?: boolean;
  search?: string;
  error?: any;
  pageInfo?: PageInfo;
  setPage?: (value: number) => void;
  onChangeSizePerPage?: (value: number) => void;
}

const DimensionsSearchResults = (props: DimensionsSearchResultsProps) => {
  const {
    title,
    data,
    columns,
    advanceLink,
    setPage,
    loading,
    search,
    error,
    dimension,
    pageInfo,
    onChangeSizePerPage,
  } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const [internalSizePerPage, setInternalSizePerPage] = useState(20);

  const pagination = {
    onChange: !loading ? setPage : () => null,
    total: pageInfo?.total,
    page: pageInfo?.page,
    size: internalSizePerPage,
  };

  const handleChangeSizePerPage = (value: number) => {
    setInternalSizePerPage(value);
    onChangeSizePerPage?.(value);
  };

  /* const nonSortableColumns = useMemo(() => {
    const columnsSet = new Set<string>();

    columns.forEach(({ header }) => {
      columnsSet.add(header);
    });

    return columnsSet;
  }, [columns]); */

  return (
    <section className={cx(css.cntResults)}>
      <Header
        className={css.searchResults}
        dimension={dimension}
        title={
          <Heading darkMode className={css.searchResults_header} type="h1">
            {title}
          </Heading>
        }
        breadcrumbItems={[
          {
            title: t("dimension.instructor.breadcrumb.previous"),
            path: "/",
          },
          {
            title: dimension,
          },
        ]}
      />

      <div className={cx(css.cntResults_table)}>
        {loading ? (
          <DimensionsSearchResultsSkeleton columns={columns?.length} rows={internalSizePerPage} />
        ) : !data || data?.length === 0 || error ? (
          <EmptyResults dimension={dimension} search={search} />
        ) : (
          <>
            <div className={cx(css.header)}>
              <Button
                onClick={() => history.push(advanceLink)}
                variant="secondary"
                rightIcon="chevrons-right"
              >
                {t("search-results.btn-advanced-search")}
              </Button>
            </div>
            <Table columns={columns} data={data ?? []} desktopVariant="regular" />
            <Pager
              {...pagination}
              labelRowsPerPage={t("search-results.table.pager.label-rows-per-page")}
              showRowsPerPage={!!onChangeSizePerPage}
              pageSizeOptions={[10, 20]}
              onRowsPerPageChange={(option: any) => handleChangeSizePerPage?.(option?.value)}
              classname={{ global: css.cntResults_pagerContainer }}
            />
          </>
        )}
      </div>
    </section>
  );
};

export default DimensionsSearchResults;
