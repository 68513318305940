import { useState } from "react";
import { FormPageType } from "../context/formData.reducer";

export type WarningType = {
  [key: string]: {
    message: string;
    active: boolean;
    predicate: (form: FormPageType) => boolean;
  };
};

/**
 * Given a WarningType object, set warnings as active/inactive evaluating the
 * `predicate` warning's param.
 *
 * @param warningsDefinition Warnings
 * @param clearIf (form: FormPageType): boolean
 * @return [string[], (form: FormPageType) => void];
 */
export const useWarnings = (
  warningsDefinition: WarningType,
  clearIf: (form: FormPageType) => boolean,
): [string[], (form: FormPageType) => void] => {
  const [warnings, setWarnings] = useState<WarningType>(warningsDefinition);
  const [warningsMessages, setWarningsMessages] = useState<string[]>([]);

  /*
   * Return the `active` warnings' `messages`.
   *
   * @param WarningType
   * @return string[]
   */
  const getWarningsMessages = (warnings: WarningType): string[] => {
    return Object.values(warnings)
      .filter(warning => warning.active)
      .map(warning => warning.message);
  };

  /**
   * If the `clearIf` predicate is true, set all warnings as false.
   * Otherwise, set the warning's `active` param to the result of each warning's
   * `predicate` evaluation.
   *
   * @param form FormPageType
   * @return void
   */
  const evaluate = (form: FormPageType) => {
    const newWarnings: WarningType = Object.keys(warningsDefinition).reduce((acc, warning) => {
      const isActive = clearIf(form) ? false : warnings[warning].predicate(form);
      acc[warning] = { ...warnings[warning], active: isActive };
      return acc;
    }, {} as WarningType);

    setWarnings(newWarnings);
    setWarningsMessages(getWarningsMessages(newWarnings));
  };

  return [warningsMessages, evaluate];
};
