import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TextField } from "@foris/avocado-suite";
import { IErrorWarning } from "@models/Errors/ErrorWarning";

interface GroupCodeInputProps {
  className?: string;
  label?: string;
  onChange?: (value: string) => void;
  onError?: (messages: IErrorWarning[]) => void;
  placeholder?: string;
  value?: string;
}

const GroupCodeInput: FC<GroupCodeInputProps> = ({
  value = "",
  className,
  onChange,
  onError,
  label,
  placeholder,
}) => {
  // States
  const [valueCode, setValueCode] = useState(value);
  const [errorMessages, setErrorMessages] = useState<IErrorWarning[]>([]);
  // Hooks
  const { t } = useTranslation();

  const handleOnChange = (value = "") => {
    const pattern = /^[A-Za-z0-9._-]*$/g;
    const parsedVal = value.toLocaleUpperCase().replace(/\s/g, "");
    const errors = [];

    if (parsedVal && !pattern.test(parsedVal)) {
      errors.push({
        type: "ERROR",
        message: t("create-group-link.step-3.error-message"),
      });
    }

    setValueCode(parsedVal);
    setErrorMessages(errors);
  };

  useEffect(() => {
    onChange?.(valueCode);
    onError?.(errorMessages);
  }, [errorMessages, valueCode]);

  return (
    <TextField
      className={className}
      status={!!errorMessages?.length ? "error" : null}
      label={label ? label : t("bundle.create-group.forms.input-group.label")}
      placeholder={
        placeholder ? placeholder : t("bundle.create-group.forms.input-group.placeholder")
      }
      value={valueCode ?? ""}
      onChange={event => handleOnChange(event.target.value)}
    />
  );
};

export default GroupCodeInput;
