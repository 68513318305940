import React from "react";
import styles from "./weekTooltipLabel.module.scss";
import { useTranslation } from "react-i18next";

interface WeekTooltipLabelProps {
  weekLabel: string;
  startingDate: string;
  endingDate: string;
}

const WeekTooltipLabel = ({ weekLabel, startingDate, endingDate }: WeekTooltipLabelProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.weekTooltipLabel}>
      <span className={styles.weekTooltipLabel_week}>{weekLabel}</span>

      {`${startingDate} ${t("calendar-view.weekly.tooltip-date-separator")} ${endingDate}`}
    </div>
  );
};

export default WeekTooltipLabel;
