import React, { useState, useContext, useMemo } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { TextField, Checkbox, Button, CardNotification, Heading } from "@foris/avocado-suite";
import { AppContext } from "../../context/EditSessionsContext";
import { Types as TypeSwitch } from "../../context/switchPage.reducer";
import CampusSelect from "@modules/shared/selectors/Selectors/Campus";
import BuildingSelect from "@modules/shared/selectors/Selectors/Building";
import ClassRoomTypeSelect from "@modules/shared/selectors/Selectors/ClassRoom";
import css from "./advanceTable.module.scss";

interface AdvanceFormProps {
  onCallback: (
    building: string,
    classroomType: string,
    minCapacity: string,
    maxCapacity: string,
    hasEvents: boolean,
    campus?: string,
  ) => void;
  setPage: () => void;
}

const AdvanceForm: React.FC<AdvanceFormProps> = (props: AdvanceFormProps) => {
  const { dispatch } = useContext(AppContext);
  const [campus, setCampus] = useState(null);
  const [building, setBuilding] = useState(null);
  const [classroomType, setClassroomType] = useState(null);
  const [minCapacity, setMinCapacity] = useState(null);
  const [maxCapacity, setMaxCapacity] = useState(null);
  const [hasEvents, setHasEvents] = useState(false);

  const { t } = useTranslation();

  const isData = !campus && !building && !classroomType && !minCapacity && !maxCapacity;
  const hasCapacityError = useMemo(
    () =>
      minCapacity !== null && maxCapacity !== null && parseInt(maxCapacity) < parseInt(minCapacity),
    [minCapacity, maxCapacity],
  );

  return (
    <section className={css.advancedForm}>
      <div className={css.advancedForm_row}>
        <Heading className={css.rowTitle} type="h3">
          {t("assignation-edit.advanced-search.classroom.form.dependencies")}
        </Heading>

        <div className={css.rowFields}>
          <CampusSelect
            label={t("assignation-edit.advanced-search.classroom.form.selectors.campus")}
            placeholder={t(
              "assignation-edit.advanced-search.classroom.form.selectors.select-an-option",
            )}
            onCallback={value => {
              setCampus(value);
              setBuilding(null);
            }}
          />
          <BuildingSelect
            label
            campus={campus?.value}
            placeholder={t(
              "assignation-edit.advanced-search.classroom.form.selectors.select-an-option",
            )}
            onCallback={value => setBuilding(value)}
          />
        </div>
      </div>

      <div className={css.advancedForm_row}>
        <Heading className={css.rowTitle} type="h3">
          {t("assignation-edit.advanced-search.classroom.form.type")}
        </Heading>

        <div className={css.rowFields}>
          <ClassRoomTypeSelect
            label
            labelValue={t("assignation-edit.advanced-search.classroom.form.selectors.classroom")}
            placeholder={t(
              "assignation-edit.advanced-search.classroom.form.selectors.select-an-option",
            )}
            onCallback={value => {
              setClassroomType(value);
            }}
          />
        </div>
      </div>

      <div className={css.advancedForm_row}>
        <Heading className={css.rowTitle} type="h3">
          {t("assignation-edit.advanced-search.classroom.form.capacity")}
        </Heading>

        <div className={css.rowControl}>
          <div className={cx(css.rowFields, css.rowFields__textFields)}>
            <TextField
              type="number"
              label={t("assignation-edit.advanced-search.classroom.form.selectors.min-capacity")}
              placeholder="1"
              value={minCapacity}
              onChange={e => {
                setMinCapacity(e?.target?.value);
              }}
            />
            <TextField
              type="number"
              label={t("assignation-edit.advanced-search.classroom.form.selectors.max-capacity")}
              placeholder="100"
              value={maxCapacity}
              onChange={e => {
                setMaxCapacity(e?.target?.value);
              }}
            />
          </div>
          <Checkbox
            className={css.checkboxField}
            checked={hasEvents}
            onChange={() => setHasEvents(!hasEvents)}
            labelRight={t("assignation-edit.advanced-search.classroom.form.selectors.overbooking")}
          />
        </div>

        {hasCapacityError && (
          <CardNotification
            state="error"
            className={css.errorCard}
            title={t("assignation-edit.advanced-search.notifications.capacity-error.title")}
            outlined
          >
            {t("assignation-edit.advanced-search.notifications.capacity-error.description")}
          </CardNotification>
        )}
      </div>

      <div className={css.advancedForm_footer}>
        <Button
          onClick={() => {
            dispatch({ type: TypeSwitch.FormPage, payload: true });
          }}
          variant="ghost"
        >
          {t("assignation-edit.advanced-search.classroom.form.cancel")}
        </Button>

        <Button
          disabled={isData || hasCapacityError}
          onClick={() => {
            props?.onCallback(
              building?.value,
              classroomType?.value,
              minCapacity,
              maxCapacity,
              hasEvents,
              campus?.value,
            );
            props.setPage();
          }}
        >
          {t("assignation-edit.advanced-search.classroom.form.search")}
        </Button>
      </div>
    </section>
  );
};

export default AdvanceForm;
