import React, { useState, useEffect, Suspense, useMemo } from "react";
import * as R from "ramda";
import moment from "moment";
import cx from "classnames";
import SchedulerAPI from "../Scheduler/SchedulerAPI";
import { ISerialzeEvent } from "./IGridSections";
import { IEvent, ISectionsProps, IWeek } from "../ISections";
import { enums } from "../utils";
import { Text, Loading } from "@foris/avocado-suite";
import ItemEdit from "./ItemEdit/ItemEdit";
import InfoSections from "../InfoSections/InfoSections";
import DropdownBox from "@common/components/dropdown-box";
import { Block, Day } from "../../../models/ISchema";
import { daysEn, daysEs } from "../../../utils/days";
import EventDetailsModal from "./event-details-modal";
import css from "./grid-sections.module.scss";

const SchedulerLazyComponent = React.lazy(() => import("../Scheduler/Scheduler"));

const GridSections: React.FC<ISectionsProps> = props => {
  const SAPI = new SchedulerAPI();
  const [state, setState] = useState({
    loading: true,
    events: props.data.eventList,
    activeInfoModal: false,
    activeEditModal: false,
    activeInfoModalAccordion: false,
    currentSelectedEvent: {} as any,
    errorSelectedEvent: false,
    currentWeekList: props.data.weekList,
    updatedWeekList: [],
    currentSelectedWeek: props.data.selectedWeek
      ? props.data.selectedWeek
      : props.data.weekList[0]
      ? props.data.weekList[0]
      : new Date(),
    unassigned: props.data.unassigned,
    notRequireSchedule: false,
  });

  useEffect(() => {
    const objWeek = props.currentWeek ? { currentSelectedWeek: props.currentWeek } : {};
    setState(prevState => ({
      ...prevState,
      events: props.data.eventList,
      currentWeekList: props.data.weekList,
      unassigned: props.data.unassigned,
      ...objWeek,
    }));
  }, [props]);

  const formatNewTimes = (event: any, start: Date, end: Date) => {
    const day = moment(start)
      .format("dddd")
      .toLocaleUpperCase();
    let newDay = "";
    daysEs.forEach((value: string, index: number) => {
      if (value === day) newDay = daysEn[index];
    });
    const lowerDay = newDay?.toLowerCase();
    const dayEnum = lowerDay.charAt(0).toUpperCase() + lowerDay.slice(1);
    const startBlock: Block = {
      id: event.resource.blockRange.start.id,
      startingTime: moment(start).format("HH:mm:ss"),
      day: Day[dayEnum],
    };
    const endBlock: Block = {
      id: event.resource.blockRange.end.id,
      endingTime: moment(end).format("HH:mm:ss"),
      day: Day[dayEnum],
    };
    return {
      start: startBlock,
      end: endBlock,
    };
  };

  const serializeErrorList = (eventStatus: any) => {
    const { data } = props;
    const tmpListError: Array<IEvent> = data.eventList || [];
    const commitedError = eventStatus.commited;
    tmpListError.forEach((item, index) => {
      if (item.sessionId === eventStatus.sessionId) {
        tmpListError[index]["error"] =
          commitedError === false
            ? { status: true, details: eventStatus.validationErrors }
            : { status: false, details: [] };
        tmpListError[index]["state"] = enums.EVENT_STATES.UPDATED;
      }
    });
    const newData = { ...data, eventList: tmpListError };
    return newData;
  };

  const eventHandlers = {
    onEventDrop: ({ event, start, end }: ISerialzeEvent) => {
      const times = formatNewTimes(event, start, end);
      const eventObj = {
        ...event,
        resource: {
          ...event.resource,
          blockRange: {
            start: times.start,
            end: times.end,
          },
        },
      };

      props?.onOpenEdit?.({
        sessionId: String(event?.sessionId),
        currentSelectedWeek: state.currentSelectedWeek,
        event: eventObj,
      });
    },
    onEventResize: ({ event, start, end }: ISerialzeEvent) => {
      const times = formatNewTimes(event, start, end);
      const eventObj = {
        ...event,
        resource: {
          ...event.resource,
          blockRange: {
            start: times.start,
            end: times.end,
          },
        },
      };

      props?.onOpenEdit?.({
        sessionId: String(event?.sessionId),
        currentSelectedWeek: state.currentSelectedWeek,
        event: eventObj,
      });
    },
    filterCategories: (
      e: React.FormEvent<HTMLSelectElement>,
      { id, checked, filtercriteria }: { id: number; checked: boolean; filtercriteria: string },
    ) => {
      console.log(e, id, checked, filtercriteria);
    },
    onFilterRestrictedAreas: (
      e: React.FormEvent<HTMLSelectElement>,
      { areatype, checked }: { areatype: string; checked: boolean },
    ) => {
      SAPI.filterRestrictedAreas({ areatype, checked });
    },
    onSelectEvent: (event: IEvent) => {
      let errorState = false;
      if (event.error.status) {
        errorState = true;
      }
      setState(prevState => ({
        ...prevState,
        currentSelectedEvent: event,
        activeInfoModal: true,
        errorSelectedEvent: errorState,
        notRequireSchedule: false,
      }));
    },
    onToolbarNavigate: (date: Date, view: string, action: string) => {
      const { currentSelectedWeek, currentWeekList, updatedWeekList } = state;
      const weekList = updatedWeekList.length ? updatedWeekList : currentWeekList;
      let newSelectedWeek = currentSelectedWeek;
      const list = R.filter(R.pipe(R.propOr("off", "status"), R.equals("on")), weekList ?? []);
      const elementToFind: IWeek = currentSelectedWeek ? currentSelectedWeek : ({} as IWeek);
      const currentIndex = list.findIndex(
        (element: IWeek) => element.startingDate === elementToFind.startingDate,
      );

      if (action === "PREV" && list.length) {
        newSelectedWeek = currentIndex > 0 ? list[currentIndex - 1] : list[list.length - 1];
      }

      if (action === "NEXT" && list.length) {
        newSelectedWeek = currentIndex < list.length - 1 ? list[currentIndex + 1] : list[0];
      }

      if (props.setCurrentWeek) {
        props.setCurrentWeek(newSelectedWeek);
      }
      setState(prevState => ({ ...prevState, currentSelectedWeek: newSelectedWeek }));
    },
    closeModals: () => {
      setState(prevState => ({ ...prevState, activeInfoModal: false, activeEditModal: false }));
    },
    openEditModal: () => {
      setState(prevState => ({ ...prevState, activeEditModal: true, activeInfoModal: false }));
    },
  };

  const undoPosition = (event: IEvent) => {
    const { data, setDataGrid } = props;
    const currentEvent = { ...event };
    SAPI.moveEvent(data.eventList, currentEvent);
    setState(prevState => ({ ...prevState, events: data.eventList }));

    const eventStatus = {
      event: currentEvent,
      sessionId: currentEvent.sessionId,
      commited: true,
      validationErrors: [],
    };
    const newData = serializeErrorList(eventStatus);
    setDataGrid(newData, () => {
      setState(prevState => ({
        ...prevState,
        events: data.eventList,
        activeInfoModal: false,
      }));
    });
  };

  const onSelectEventUnassigned = (event: IEvent) => {
    setState(prevState => ({
      ...prevState,
      currentSelectedEvent: event,
      activeInfoModal: true,
      errorSelectedEvent: false,
      notRequireSchedule: true,
    }));
  };

  const listUnassigned = () => {
    const { unassigned, currentSelectedWeek } = state;
    const dataUnassigned = [];

    unassigned.forEach((element: any) => {
      let newObj = {};

      element.resource.intervals.forEach((week: any) => {
        if (currentSelectedWeek) {
          if (parseInt(currentSelectedWeek.id) === parseInt(week.id)) {
            newObj = {
              ...newObj,
              ...element,
              id: element.sessionId,
              name: element.name,
              status: element.status,
              intervals: week,
            };
            dataUnassigned.push(newObj);
          }
        }
      });
    });

    if (dataUnassigned.length > 0) {
      return (
        <DropdownBox
          className={css.cntAccordion}
          title={`Mostrar ${dataUnassigned.length > 1 ? "Sesiones" : "Sesión"} sin horario (${
            dataUnassigned.length
          })`}
        >
          {dataUnassigned.map((item: any, index: number) => {
            const nameComponent = item.resource.info.courseComponent.component
              ? item.resource.info.courseComponent.component.code
              : "";
            return (
              <div
                className={cx(css.cntAccordion_item, "container-row")}
                key={index}
                onClick={() => {
                  onSelectEventUnassigned(item);
                }}
              >
                <Text className={cx(css.itemText, "col_2")}>{item.id}</Text>
                <Text className={cx(css.itemText, css.itemText__large, "col_6")}>{item.name}</Text>
                <Text className={cx(css.itemText, "col_2")}>{nameComponent}</Text>
                <Text className={cx(css.itemText, "col_2", !item.status && css.itemText__error)}>
                  {item.status ? "No requiere horario" : "Sin asignación"}
                </Text>
              </div>
            );
          })}
        </DropdownBox>
      );
    }
  };

  const handleUpdateWeeks = (weeks: IWeek[]) => {
    setState(prevState => ({ ...prevState, updatedWeekList: weeks }));
  };

  const {
    events,
    activeInfoModal,
    activeEditModal,
    currentSelectedEvent,
    errorSelectedEvent,
    currentWeekList,
    currentSelectedWeek,
    unassigned,
    notRequireSchedule,
  } = state;
  const { config, data, dimension } = props;

  const dateToDisplay = useMemo(() => {
    if (currentSelectedWeek && currentSelectedWeek.startingDate) {
      return currentSelectedWeek.startingDate;
    } else {
      const dateDisplay = data.eventList[0]
        ? data.eventList[0].start
        : data.weekList[0]
        ? data.weekList[0].startingDate
        : new Date();
      return dateDisplay;
    }
  }, [currentSelectedWeek, data.eventList, data.weekList]);

  return (
    <React.Fragment>
      <EventDetailsModal
        isOpen={activeInfoModal}
        event={currentSelectedEvent}
        selectedWeek={currentSelectedWeek}
        eventHasError={errorSelectedEvent}
        dimension={dimension}
        onClose={() => eventHandlers.closeModals()}
        onUndoPosition={(event: IEvent) => undoPosition(event)}
        onOpenEdit={() => eventHandlers.openEditModal()}
      />

      {activeEditModal && (
        <ItemEdit
          onClose={() => eventHandlers.closeModals()}
          weeks={currentWeekList}
          selected={currentSelectedWeek}
          event={currentSelectedEvent}
          notRequireSchedule={notRequireSchedule}
          recommendationsAvailable={true}
        />
      )}

      {!activeEditModal && (
        <div className={css.gridInfo}>
          <div className={css.infoWeeks}>
            <InfoSections
              defaultTerm={props.defaultTerm}
              onUpdateWeeks={handleUpdateWeeks}
              onSelectWeek={(currentSelectedWeek: IWeek) => {
                if (props.setCurrentWeek) {
                  props.setCurrentWeek(currentSelectedWeek);
                }

                setState(prevState => ({ ...prevState, currentSelectedWeek }));
              }}
              weeks={currentWeekList}
              selected={currentSelectedWeek}
            />
          </div>
          <div className={css.sectionGrid}>
            <Suspense fallback={<Loading />}>
              <SchedulerLazyComponent
                config={config}
                eventHandlers={eventHandlers}
                events={events}
                restrictions={data.calendarRestrictions}
                dateToDisplay={dateToDisplay}
                dataUnassigned={unassigned}
                currentSelectedWeek={currentSelectedWeek}
                listUnassigned={listUnassigned}
              />
            </Suspense>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default GridSections;
